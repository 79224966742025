import React from 'react';
import { useStyles } from './styles';
import { UserCompanyData } from '../wireData/UserCompanyData';
import { List, ListItem, Button, Grid, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle'

export interface UserCompanySelectionProps {
  onItemChanged(id: number, checked: boolean);
  userCompanies: Array<number>;
  companies: Array<UserCompanyData>;
}

export function UserCompaniesSelection(props: UserCompanySelectionProps) {

  const classes = useStyles();

  const checkedColor = (id: number): "inherit" | "secondary" => {
    if (props.userCompanies.indexOf(id) >= 0)
      return "secondary"
    return "inherit"
  }

  const handleCompanyToggle = async (id: number) => {
    props.onItemChanged(id, props.userCompanies.indexOf(id) < 0);
  }

  const CompanyList = () => {
    if (props.companies && props.companies.length)
      return (
        <div className={classes.listBox}>
          <List className={classes.attributeList}>
            {props.companies.map((company) => (
              <ListItem key={company.companyID}>
                <Button
                  variant='contained'
                  fullWidth
                  color="primary"
                  onClick={(e) => handleCompanyToggle(company.companyID)}>
                  <Grid
                    container
                    direction="row"
                    spacing={0}
                    alignContent="center"
                    alignItems="center"
                    wrap="nowrap">
                    <Grid item xs={2}>
                      <CheckIcon color={checkedColor(company.companyID)} />
                    </Grid>
                    <Grid item xs={10}>
                      {company.companyName}
                    </Grid>
                  </Grid>
                </Button>
              </ListItem>
            ))}
          </List>
        </div>
      );
    return null;
  }

  return (
    <div>
      <Typography variant='subtitle1' align='center' gutterBottom>Companies</Typography>
      <CompanyList />
    </div>
  )
}
