import React, { useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, createStyles } from "@material-ui/styles";
import { loginToken, rootURL } from "../utils/WireUtils";
import { Typography, Input } from "@material-ui/core";
import { LinearLoading } from "../common/LoadingComponent";

const useStyles = makeStyles(theme => createStyles({}));

export interface UploadDialogProps {
  open: boolean;
  onClose: (event: any) => void;
}

function UploadDialog(props: UploadDialogProps) {
  const classes = useStyles({});
  const [filename, setFilename] = useState("");
  const [file, setFile] = useState(null);
  // var fileInput = useRef(null);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleUpload = e => {
    e.preventDefault();
    setProcessing(true);
    const data = new FormData();
    data.append("file", file);

    fetch(`${rootURL}/api/file/catalog`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken}`
      },
      body: data
    }).then(async response => {
      if (response.status >= 200 && response.status < 300) {
        setError(null);
        setProcessing(false);
        props.onClose("Success");
      } else {
        setProcessing(false);
        const error = await response.json();
        setError(error.message);
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilename(event.currentTarget.value.replace("C:\\fakepath\\", ""));
    setFile(event.currentTarget.files[0]);
  };

  const handleCancel = e => {
    props.onClose(e);
    setError(null);
  };

  const trimFilename = (filename: string): string => {
    if (filename == null || filename.length < 45) return filename;
    return "..." + filename.substr(filename.length - 42);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Upload Catalog</DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
          <label htmlFor="catalog-upload-id" style={{ cursor: "pointer" }}>
            <Typography variant="h6" style={{ cursor: "pointer" }}>
              Catalog file:
            </Typography>
          </label>
          {/* </DialogContentText> */}
          <input
            // ref={fileInput}
            id="catalog-upload-id"
            type="file"
            style={{ display: "none" }}
            onChange={handleChange}
          />
          {trimFilename(filename)}
          {error ? <Typography>{error}</Typography> : null}
        </DialogContent>
        <DialogActions>
          {processing ? (
            <LinearLoading />
          ) : (
            <>
              <Button onClick={handleCancel} color="primary">
                Cancel
              </Button>
              {filename ? (
                <Button onClick={handleUpload} color="primary">
                  Upload
                </Button>
              ) : null}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UploadDialog;
