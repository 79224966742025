import React from 'react';
import { CategoryData } from "../wireData/CategoryData";
import { useStyles } from "./styles";
import { InventoryTableTabs } from "./InventoryCostsTableTabs";
import { CategoryList } from "./CategoryList";
import { Paper, Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import { InventoryTableHead } from "./InventoryCostsTableHead";
import { InventoryCostFooter } from "./InventoryCostFooter";
import { numberToDollarString } from '../utils/Convert';
import { Loading } from '../common/LoadingComponent';
import { CategoriesAndDateDialog } from './CategoriesAndDateDialog';
import { useInventoryCosts, inventoryCostsStore } from './InventoryCostsHandler';
import { useField } from '../common/useField';

export interface InventoryCostsTableProps {
  onClose: () => void;
}

export function InventoryCostsTable(props: InventoryCostsTableProps) {
  const classes = useStyles({});
  const dataStore = inventoryCostsStore();
  const [date, setDate] = useField(dataStore, 'date');
  const [categoryLabel, setCategoryLabel] = useField(dataStore, 'categoryLabel');
  const [sheetIndex, setSheetIndex] = useField(dataStore, 'sheetIndex');
  const [categoryFilter, setCategoryFilter] = useField(dataStore, 'categoryFilter');
  const [dialogOpen, setDialogOpen] = useField(dataStore, 'dialogOpen');
  const [categories, setCategories] = useField(dataStore, 'categories');
  const [{ stores }, manager, actions] = useInventoryCosts();

  const columns: Array<string> = ["Category", "Item", "Variation", "Count", "Cost", "Total"];

  const handleDialogCancel = () => {
    setDialogOpen(false);
  }

  const handleUpdateSelectedCategoriesAndDate = (selectedCategories: Array<CategoryData>, date: Date) => {
    setCategories([...selectedCategories]);
    if (date) {
      setDate(date);
      setCategoryLabel(date.toDateString());
    }
    setDialogOpen(false);
    actions.updateState({success: false});
  }

  const handleStoreTabClick = (index: number) => {
    manager.selectSheet(index);
    setSheetIndex(index);
  }

  const handleOnChangeCategories = () => {
    setDialogOpen(true);
  }

  const handleCategoryFilter = (index: number) => {
    setCategoryFilter(index);
    if (index >= 0)
      manager.selectCategory(categories[index].categoryID, categories[index].categoryName);
    else
      manager.selectCategory(-1, null);
  }

  let key = 1;
  return (
    <div className={classes.root}>
      {dialogOpen
        ? <CategoriesAndDateDialog
          open={dialogOpen}
          selectedDate={date}
          selectedCategories={categories}
          onCancel={handleDialogCancel}
          onClose={handleUpdateSelectedCategoriesAndDate} />
        : null
      }
      <div className={classes.tabs}>
        <InventoryTableTabs stores={stores} index={stores.length && sheetIndex} onTabClick={handleStoreTabClick} />
        <CategoryList
          categories={categories}
          selectedCategory={categoryFilter}
          categoriesLabel={categoryLabel}
          onChangeCategories={handleOnChangeCategories}
          onCategorySelected={handleCategoryFilter}
        />
      </div>
      <div className={classes.tableRoot}>
        <Paper>
          {manager.displayedWorksheet
            ?
            <Table >
              <TableHead>
                <InventoryTableHead columns={columns} />
              </TableHead>
              <TableBody >
                {manager.displayedWorksheet.map((costItem) => (
                  <TableRow key={key++}>
                    <TableCell className={classes.tableCell}>{costItem.categoryName}</TableCell>
                    <TableCell className={classes.tableCell}>{costItem.itemName}</TableCell>
                    <TableCell className={classes.tableCell}>{costItem.variationDescription}</TableCell>
                    <TableCell className={classes.tableCell}>{costItem.count}</TableCell>
                    <TableCell className={classes.tableCell}>{numberToDollarString(costItem.cost)}</TableCell>
                    <TableCell className={classes.tableCell}>{numberToDollarString(costItem.total)}</TableCell>
                  </TableRow>))}
              </TableBody>
            </Table>
            : categories && categories.length
              ? <div className={classes.loading}>
                <Loading />
              </div>
              : null
          }
        </Paper>
      </div>
      <InventoryCostFooter />
    </div>
  )
}