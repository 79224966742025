import React, { useState, useEffect } from 'react';
import { StateStoreInterface, useStore } from 'react-hookstore';

type Dispatch<TField> = (value: TField) => void;

export function useField<TState, K extends keyof TState>(store: StateStoreInterface<TState>, key: K): [TState[K], Dispatch<TState[K]>] {
  const [state, setState] = useStore(store);
  const [value, setValue] = useState<TState[K]>(state[key]);

  useEffect(()=>{
    setValue(state[key]);
  }, [state]);
  
  const updateValue = (value: TState[K]) => {
    setState( {...store.getState(), [key]: value});
    setValue(value);
  }

  return [value, updateValue];
}

