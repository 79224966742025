import React from 'react';
import { Dialog, DialogTitle, DialogContent, LinearProgress, DialogContentText, DialogActions, Button } from '@material-ui/core';

export interface UpdateDialogProps {
  open: boolean;
  message: string;
  onClose?: () => void;
}

export function UpdateStatus(props: UpdateDialogProps) {
  if (props.open)
    return (
      <Dialog open>
        <DialogTitle>{props.message}</DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    )
  return null;
}

export function UpdateError(props: UpdateDialogProps) {
  if (props.open)
    return (
      <Dialog open>
        <DialogTitle>Update Error</DialogTitle>
        <DialogContentText>
          {props.message}}
        </DialogContentText>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose}>OK</Button>
        </DialogActions>
      </Dialog>
    )
  return null;
}

