import { activeInventoryCatalogURL, loginToken, inventoryBaseURL, updateCountURL, getJSONHeaders, countsByStoreForDate, getCountURL } from '../utils/WireUtils';
import { StructuredCatalog, Category, Item, Variation } from '../catalog/Catalog';
import { Store } from '../store/Store';
import { StructuredCatalogManager } from '../catalog/StructuredCatalogManager';
import { InventoryManager } from './InventoryManager';
import { localISODateString } from '../utils/Convert';
import { useState, useEffect } from 'react';
import { CountEntryQueryOrUpdate } from '../wireData/inventoryCountData';
import { getStoreByName, ReducerStoreInterface, createStore, useStore, StateStoreInterface } from 'react-hookstore';
import { BaseState, BaseAction } from '../common/BaseHandler';

// type InventoryActionTypes = "INVENTORY_ERROR" | "INVENTORY_LOADING" | "GET_INVENTORY" | "INVENTORY_UPDATE" |
//   "INVENTORY_REFRESH" | "INVENTORY_CLEAR" | "INVENTORY_ENTRY_STATE_UPDATE" | "INVENTORY_COUNT_STATE_UPDATE" |
//   "INVENTORY_SHOULD_CLEAR";

// class InventoryAction {
//   actionType: InventoryActionTypes;
//   payload?: any;
// }

export const InventoryStoreName = "inventoryStore";

export class InventoryEntryState {
  category: Category;
  item: Item;
  variation: Variation
}

export class InventoryCountState {
  oldCount: string;
  newCount: string;
  completed: boolean
  shouldClear: boolean;
}

export class InventoryExpandedItem {
  id: number;
  items: Array<number>;
}

export class InventoryState extends BaseState {
  // inventoryLoading: boolean;
  // inventoryError: any;
  // inventorySuccess: boolean;
  store: Store;
  date: Date;
  dateAndStoreSelected: boolean;
  showList: boolean;
  scrolling: { top: number };
  expandedItems: Array<InventoryExpandedItem>;
  entryState: InventoryEntryState;
  countState: InventoryCountState;
  _manager: InventoryManager;
  _actions: InventoryActions;
}

// type InventoryStore = ReducerStoreInterface<InventoryState, InventoryAction>;

// const getInventoryStore = (storeName: string): InventoryStore => {
//   try {
//     return getStoreByName<InventoryState, InventoryAction>(storeName);
//   }
//   catch (e) {
//     const actions = new InventoryActions();
//     const store = createStore<InventoryState, InventoryAction>(storeName, {
//       inventoryLoading: false,
//       inventoryError: null,
//       inventorySuccess: false,
//       store: null,
//       date: null,
//       dateAndStoreSelected: false,
//       showList: true,
//       scrolling: { top: 0 },
//       expandedItems: [],
//       entryState: { category: null, item: null, variation: null },
//       countState: { oldCount: '-', newCount: '-', completed: false, shouldClear: true },
//       _manager: new InventoryManager(),
//       _actions: actions,
//     }, inventoryReducer);
//     actions.store = store;
//     return store;
//   }
// }

class InventoryActions extends BaseAction<InventoryState> {
  // private _store: InventoryStore;

  // public set store(store: InventoryStore) {
  //   this._store = store;
  // }

  // public get store(): InventoryStore {
  //   return this._store;
  // }

  get initialState(): InventoryState {
    return {
      loading: false,
      error: null,
      success: false,
      store: null,
      date: null,
      dateAndStoreSelected: false,
      showList: true,
      scrolling: { top: 0 },
      expandedItems: [],
      entryState: { category: null, item: null, variation: null },
      countState: { oldCount: '-', newCount: '-', completed: false, shouldClear: true },
      _manager: null,
      _actions: this,
    }
  }

  mapInventoryData = (body: any): Partial<InventoryState> => {
    const manager = new InventoryManager();
    manager.catalog = body;
    manager.store = this.state.store;
    manager.date = this.state.date;
    return {
      loading: false,
      error: null,
      success: true,
      scrolling: { top: 0 },
      expandedItems: [],
      _manager: manager,
    }
  }

  getInventoryData = async (store: Store, date: Date) => {
    this.getData(`${activeInventoryCatalogURL}/${store.storeID}/${localISODateString(date)}`, this.mapInventoryData);
    // try {
    //   this._store.dispatch({ actionType: "INVENTORY_LOADING" });
    //   const response = await fetch(`${activeInventoryCatalogURL}/${store.storeID}/${localISODateString(date)}`, {
    //     method: 'GET',
    //     headers: getJSONHeaders()
    //   });
    //   if (response.ok) {
    //     const body = await response.json();
    //     if (body.message) {
    //       this._store.dispatch({ actionType: "INVENTORY_ERROR", payload: body });
    //     }
    //     else {
    //       this._store.dispatch({ actionType: "GET_INVENTORY", payload: body });
    //     }
    //   }
    //   else
    //     this._store.dispatch({ actionType: "INVENTORY_ERROR", payload: { message: response.statusText } })
    // }
    // catch (e) {
    //   this._store.dispatch({ actionType: "INVENTORY_ERROR", payload: { message: e.message } })
    // }
  };

  updateInventoryState = (stateUpdate: Partial<InventoryState>) => {
    this.updateState(stateUpdate);
  }

  updateInventoryEntryState = (stateUpdate: Partial<InventoryEntryState>) => {
    this.updateState({ entryState: { ...this.state.entryState, ...stateUpdate } })
  }

  updateInventoryCountState = (stateUpdate: Partial<InventoryCountState>) => {
    this.updateState({ countState: { ...this.state.countState, ...stateUpdate } })
  }

  // refreshInventory = () => {
  //   this._store.dispatch({ actionType: "INVENTORY_REFRESH" })
  // };

  // closeInventory = () => {
  //   this._store.dispatch({ actionType: "INVENTORY_CLEAR" })
  // };
}

// const inventoryReducer = (state: InventoryState, action: InventoryAction): InventoryState => {

//   switch (action.actionType) {
//     case "INVENTORY_LOADING":
//       return { ...state, inventoryLoading: true, inventoryError: null, inventorySuccess: false };
//     case "INVENTORY_ERROR":
//       return { ...state, inventoryLoading: false, inventoryError: action.payload, inventorySuccess: false };
//     case "GET_INVENTORY":
//       state._manager.catalog = action.payload;
//       return {
//         ...state,
//         inventoryLoading: false,
//         inventoryError: null,
//         inventorySuccess: true,
//         scrolling: { top: 0 },
//         expandedItems: [],
//       }
//     case "INVENTORY_UPDATE":
//       return { ...state, ...action.payload };
//     case "INVENTORY_ENTRY_STATE_UPDATE":
//       return { ...state, entryState: { ...state.entryState, ...action.payload } };
//     case "INVENTORY_COUNT_STATE_UPDATE":
//       return { ...state, countState: { ...state.countState, ...action.payload } };
//     case "INVENTORY_REFRESH":
//       return { ...state, inventoryLoading: false, inventoryError: null, inventorySuccess: false, dateAndStoreSelected: false }
//     case "INVENTORY_CLEAR":
//       state._manager.catalog = null;
//       return { ...state, inventoryLoading: false, inventoryError: null, inventorySuccess: false, dateAndStoreSelected: false }
//   }
//   console.error("Unknown action %s", action.actionType);
//   return state;
// }

export const resetInventoryManager = () => {
  BaseAction.getAction(InventoryStoreName, InventoryActions).reset()
}

export const useInventoryManager = (): [InventoryState, InventoryManager, InventoryActions] => {
  const [state, actions] = BaseAction.getAction(InventoryStoreName, InventoryActions).useStore();
  return [state, state._manager, actions]
}
// export function useInventoryManager(store: Store, date: Date): { error: any, loading: boolean, success: boolean } {
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [success, setSuccess] = useState(false);

//   useEffect(() => {
//     setLoading(true);
//     getStoreInventory(store, date)
//       .then(inventory => {
//         //        console.log(inventory);
//         const manager = InventoryManager.Instance;
//         manager.catalog = inventory;
//         manager.store = store;
//         manager.date = date;
//         setLoading(false);
//         setSuccess(true);
//       })
//       .catch((e) => setError(e.message || e))
//   }, [store, date])

//   return { error, loading, success }
// }

export const InventoryCountsSummaryStoreName = "inventoryCountsSummaryStore"

export class StoreAndCount {
  storeID: number;
  storeName: string;
  count: number;
}

export class InventoryCountSummaryState extends BaseState {
  error: any;
  loading: boolean;
  success: boolean;
  date: Date;
  counts: Array<StoreAndCount>;
}

// const getInventoryCountSummaryStore = (storeName: string): StateStoreInterface<InventoryCountSummaryState> => {
//   try {
//     return getStoreByName<InventoryCountSummaryState>(storeName);
//   }
//   catch (e) {
//     const actions = new InventoryCountSummaryActions();
//     const store = createStore<InventoryCountSummaryState>(storeName, {
//       error: null,
//       loading: false,
//       success: false,
//       date: new Date(),
//       counts: [],
//       _actions: actions
//     })
//     actions.store = store;
//     return store;
//   }
// }

class InventoryCountSummaryActions extends BaseAction<InventoryCountSummaryState>{
  // private _store: StateStoreInterface<InventoryCountSummaryState>;

  // public set store(store: StateStoreInterface<InventoryCountSummaryState>) {
  //   this._store = store;
  // }

  get initialState(): InventoryCountSummaryState {
    return {
      error: null,
      loading: false,
      success: false,
      date: new Date(),
      counts: [],
      _actions: this,
    }
  }

  mapCounts = (body: any): Partial<InventoryCountSummaryState> => {
    return { counts: body };
  }

  getSummaryData = (date: Date) => {
    this.getData(`${countsByStoreForDate}/${localISODateString(date)}`, this.mapCounts)
    // this._store.setState({ ...this._store.getState(), error: null, loading: true, success: false, date: null, counts: [] });
    // try {
    //   const response = await fetch(`${countsByStoreForDate}/${localISODateString(date)}`, {
    //     method: 'GET',
    //     headers: getJSONHeaders(),
    //   });
    //   if (response.ok) {
    //     const data = await response.json();
    //     this._store.setState({ ...this._store.getState(), error: null, loading: false, success: true, date: date, counts: data });
    //   } else {
    //     this._store.setState({ ...this._store.getState(), error: { message: response.statusText }, loading: false, success: false });
    //   }
    // }
    // catch (e) {
    //   this._store.setState({ ...this._store.getState(), error: { message: e.message }, loading: false, success: false });
    // }
  }

  setDate = (date: Date) => {
    this.updateState({ date: date });
  }

  // clear = () => {
  //   this._store.setState({ ...this._store.getState(), error: null, loading: false, success: false, date: null, counts: [] });
  // }
}

export const resetInventoryCountsByStore = () => {
  BaseAction.getAction(InventoryCountsSummaryStoreName, InventoryCountSummaryActions).reset();
}

export function useCountsByStoreForDate(): [InventoryCountSummaryState, InventoryCountSummaryActions] {
  return BaseAction.getAction(InventoryCountsSummaryStoreName, InventoryCountSummaryActions).useStore();
  // const [state] = useStore(getInventoryCountSummaryStore("inventoryCountsSummaryState"));
  // return [state, state._actions];
}

//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [success, setSuccess] = useState(false);
//   const [counts, setCounts] = useState([]);

//   //  const dateNumber = dateToAdjustedMillis(date);

//   useEffect(() => {
//     if (date == null)
//       return;
//     setLoading(true);
//     setSuccess(false);
//     setError(null);
//     setCounts([]);
//     const url = `${countsByStoreForDate}/${localISODateString(date)}`;
//     fetch(url, {
//       method: 'GET',
//       headers: getJSONHeaders(),
//     }).then(response => {
//       if (response.ok) {
//         return response.json().then(data => {
//           //            console.log(data);
//           setCounts(data);
//           setLoading(false);
//           setSuccess(true);
//         }).catch((e) => {
//           setError({ message: "Invalid response body" })
//         })
//       } else {
//         setError({ message: 'Response not ok' });
//       }
//     }).catch((e) => {
//       setError({ message: 'Communication failure' });
//     });
//   }, [date, refresh])

//   return { error, loading, success, counts }
// }

// export function getStoreInventory(store: Store, date: Date): Promise<StructuredCatalog> {
//   const dateString = localISODateString(date);
//   const url = `${activeInventoryCatalogURL}/${store.storeID}/${dateString}`;
//   return fetch(url, {
//     method: 'GET',
//     headers: getJSONHeaders(),
//   }).then(response => {
//     if (response.ok) {
//       return response.json().then(data => {
//         //        console.log(data);
//         return data;
//       }).catch((e) => {
//         return Promise.reject({ message: "Invalid response body" })
//       })
//     } else {
//       return Promise.reject({ message: 'Response not ok' });
//     }
//   }).catch((e) => {
//     return Promise.reject({ message: 'Communication failure' });
//   });
// }

export function updateItem(item: CountEntryQueryOrUpdate): Promise<any> {
  return fetch(updateCountURL, {
    method: 'POST',
    headers: getJSONHeaders(),
    body: JSON.stringify(item)
  }).then(response => {
    if (response.ok) {
      return response.json().then(item => {
        return item;
      }).catch((e) => {
        return Promise.reject({ message: "Invalid response body" })
      })
    } else {
      return Promise.reject({ message: 'Response not ok' });
    }
  }).catch((e) => {
    return Promise.reject({ message: 'Communication failure' });
  });
}

export function useGetCount(item: CountEntryQueryOrUpdate): { error: any, loading: boolean, success: boolean, count: number } {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [count, setCount] = useState(null);

  useEffect(() => {
    setLoading(true);
    setSuccess(false);
    setError(null);
    setCount(null);
    fetch(getCountURL, {
      method: 'POST',
      headers: getJSONHeaders(),
      body: JSON.stringify(item)
    }).then(response => {
      if (response.ok) {
        return response.json().then(item => {
          //            console.log(data);
          setCount(item.count);
          setLoading(false);
          setSuccess(true);
        }).catch((e) => {
          setError({ message: "Invalid response body" })
        })
      } else {
        setError({ message: 'Response not ok' });
      }
    }).catch((e) => {
      setError({ message: 'Communication failure' });
    });
  }, [item.storeID, item.categoryID, item.itemID, item.variationID])

  return { error, loading, success, count }
}

// export async function getInventory(
//   setResult: (status: string, inventory: StructuredCatalog) => void): Promise<any> {
//   setResult("Fetching", null);
//   return fetch(activeInventoryCatalogURL, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": `Bearer ${loginToken}`
//     },
//   }).then(async function (response) {
//     if (response.status >= 200 && response.status < 300) {
//       const body = await response.json();
//       setResult("Success", body);
//     } else {
//       const body = await response.json();
//       setResult(body.message, null);
//     }
//   }).catch(function (reason) {
//     // console.log("catch:" + reason)
//     setResult(JSON.stringify(reason), null);
//   })
// }

export async function saveStoreInventory(store: Store, date: Date, catalog: StructuredCatalogManager,
  setResult: (status: string) => void): Promise<any> {
  const adjustedDate = date.valueOf() + (date.getTimezoneOffset() * 60 * 100)
  return fetch(inventoryBaseURL + "/" + store.storeID + "/" + adjustedDate, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginToken}`
    },
    body: JSON.stringify(catalog.counts)
  }).then(async function (response) {
    if (response.status >= 200 && response.status < 300) {
      const body = await response.json();
      setResult(body.message);
    } else {
      //        const body = await response.json();
      setResult(response.statusText);
    }
  }).catch(function (reason) {
    //      console.log("catch: '" + reason.message + "'")
    setResult(reason.message);
  })

}
