import { PricingWorkbookManager } from "./PricingWorkbookManager";
import { PendingOrderItemUpdate } from "../preordering/StoreOrderingData";
import { pendingOrderItemUpdateCostURL, loginToken, pricingWorkbookURL } from '../utils/WireUtils';
import { PricingWorkbook } from "./PricingData";
import { BaseState, BaseAction } from "../common/BaseHandler";

export interface PricingWorkbookState extends BaseState {
  displayedBook: PricingWorkbook;
  vendor: string;
  scrolling: { top: number };
  columns: Array<string>;
  vendors: Array<string>;
}

export const PricingWorkbookStoreName = 'pricingWorkbookStore';

class PricingWorkbookActions extends BaseAction<PricingWorkbookState>{
  manager: PricingWorkbookManager;

  get initialState(): PricingWorkbookState {
    return {
      error: null,
      loading: false,
      success: false,
      displayedBook: null,
      vendor: null,
      scrolling: { top: 0 },
      vendors: [],
      columns: [],
      _actions: this,
    }
  }

  private mapWorkbook = (body: any): Partial<PricingWorkbookState> => {
    this.manager = new PricingWorkbookManager();
    this.manager.workbook = body;
    const vendors = this.manager.getVendors();
    if (vendors.length)
      this.manager.filterVendors(vendors[0]);
    return {
      displayedBook: this.manager.workbook,
      vendor: vendors.length ? vendors[0] : null,
      vendors: vendors,
      columns: this.manager.getColumns(),
      scrolling: { top: 0 },
    };
  }

  getPricingWorkbook = () => {
    this.getData(pricingWorkbookURL, this.mapWorkbook)
  }

  setVendor = (vendor: string) => {
    this.manager.filterVendors(vendor);
    this.updateState({
      scrolling: { top: 0 },
      displayedBook: this.manager.workbook,
      vendor: vendor
    })
  }
}

export function resetPricingWorkbookState() {
  BaseAction.getAction(PricingWorkbookStoreName, PricingWorkbookActions).reset();
}

export const usePricingWorkbook = (): [PricingWorkbookState, PricingWorkbookManager, PricingWorkbookActions] => {
  const [state, actions] = BaseAction.getAction(PricingWorkbookStoreName, PricingWorkbookActions).useStore();
  return [state, actions.manager, actions];
}

export function updateCost(pending: PendingOrderItemUpdate): Promise<any> {
  return fetch(pendingOrderItemUpdateCostURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${loginToken}`
    },
    body: JSON.stringify(pending)
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
          .then((update: PendingOrderItemUpdate) => {
            return update;
          })
          .catch((e) => {
            return Promise.reject({ message: "Invalid response body" })
          })
      } else {
        return Promise.reject({ message: 'Response not ok' });
      }
    })
    .catch((e) => {
      return Promise.reject({ message: 'Communication failure' });
    });
}