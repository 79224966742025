import { Variation, Item, Category, StructuredCatalog } from './Catalog';
import { Store } from '../store/Store';
import { CountEntryQueryOrUpdate } from '../wireData/inventoryCountData';
import { ItemData } from '../wireData/ItemData';
import { VariationData } from '../wireData/VariationData';
import { CategoryData } from '../wireData/CategoryData';

export class StructuredCatalogManager {

  store: Store;
  date: Date;
  private _catalog: StructuredCatalog;
  category: Category;
  item: Item;
  variant: Variation;
  private _dirty: boolean;
  private catIndex = 0;
  private itemIndex = 0;
  private varIndex = 0;

  constructor(catalog?: StructuredCatalog) {
    if (catalog)
      this.catalog = catalog;
  }

  get catalog(): StructuredCatalog {
    return this._catalog;
  }

  set catalog(catalog: StructuredCatalog) {
    this._catalog = this.mapCatalog(catalog);
    this._dirty = false;
    this.firstCategory();
  }

  mapCatalog(catalog: Array<Category>): Array<Category> {
    return catalog.map((category) => {
      if (category.items)
        category.items = category.items.map((item) => {
          if (item.variations)
            item.variations = item.variations.map((variation) => new VariationData(variation) as any);
          return new ItemData(item) as any;
        })
      return new CategoryData(category) as any;
    })
  }

  get categories(): Array<Category> {
    return this._catalog;
  }

  get counts(): { counts: Array<any> } {
    const counts = [];

    this._catalog.forEach((category: Category) => {
      category.items.forEach((item: Item) => {
        if (item.variations && item.variations.length) {
          item.variations.forEach((variation: Variation) => {
            if (variation.hasOwnProperty("count") && variation.oldCount != null) {
              counts.push({ categoryID: category.categoryID, itemID: item.itemID, variationID: variation.variationID, count: variation.oldCount })
            }
          })
        }
        else if (item.hasOwnProperty("count") && item.oldCount != null) {
          counts.push({ categoryID: category.categoryID, itemID: item.itemID, variationID: 0, count: item.oldCount })
        }
      })
    })
    console.log(counts);
    return { counts: counts };
  }

  updateEntry(update: CountEntryQueryOrUpdate): boolean {
    if (update.storeID != this.store.storeID) {
      return;
    }
    let item: any =
      this.catalog.find(category => category.categoryID == update.categoryID)
        .items.find(item => item.itemID == update.itemID);
    if (item && update.variationID) {
      item = item.variations.find((v: Variation) => v.variationID == update.variationID);
    }
    if (item) {
      item.newCount = update.count;
      return true;
    }
    return false;
  }

  isDirty(): boolean {
    return this._dirty;
  }

  dirty() {
    this._dirty = true;
  }

  clean() {
    this._dirty = false;
  }

  hasVariations(item: Item): boolean {
    return (item && item.variations && item.variations.length > 0)
  }

  hasItems(category: Category): boolean {
    return (category && category.items && category.items.length > 0);
  }

  firstCategory(): Category {
    this.category = this._catalog[this.catIndex = 0];
    this.firstItem();
    return this.category
  }

  firstItem(): Item {
    this.itemIndex = 0
    this.item = this.hasItems(this.category) ? this.category.items[0] : null;
    this.firstVariant();
    return this.item;
  }

  lastItem(): Item {
    this.itemIndex = 0
    this.item = this.hasItems(this.category) ? this.category.items[this.itemIndex = this.category.items.length - 1] : null;
    this.lastVariant();
    return this.item;
  }

  firstVariant(): Variation {
    this.varIndex = 0
    this.variant = this.hasVariations(this.item) ? this.item.variations[0] : null;
    return this.variant;
  }

  lastVariant(): Variation {
    this.varIndex = 0
    this.variant = this.hasVariations(this.item) ? this.item.variations[this.varIndex = this.item.variations.length - 1] : null;
    return this.variant;
  }

  nextVariant(): Variation {
    if (this.hasVariations(this.item) && this.varIndex < this.item.variations.length - 1) {
      this.variant = this.item.variations[++this.varIndex];
    }
    else
      this.nextItem();
    return this.variant;
  }

  nextItem(): Item {
    if (this.item && this.itemIndex < this.category.items.length - 1) {
      this.item = this.category.items[++this.itemIndex];
      this.firstVariant();
    }
    else
      this.nextCategory();
    return this.item;
  }

  nextCategory(): Category {
    if (this.catIndex < this._catalog.length - 1) {
      this.category = this._catalog[++this.catIndex];
      this.firstItem();
    }
    return this.category;
  }

  previousVariant(): Variation {
    if (this.hasVariations(this.item) && this.varIndex > 0) {
      this.variant = this.item.variations[--this.varIndex];
    }
    else
      this.previousItem();
    return this.variant;
  }

  previousItem(): Item {
    if (this.item && this.itemIndex > 0) {
      this.item = this.category.items[--this.itemIndex];
      this.lastVariant();
    }
    else
      this.previousCategory();
    return this.item;
  }

  previousCategory(): Category {
    if (this.catIndex > 0) {
      this.category = this._catalog[--this.catIndex];
      this.lastItem();
    }
    return this.category;
  }

  selectCategory(categoryID: number) {
    const index = this._catalog.findIndex((category) => category.categoryID == categoryID);
    if (index >= 0) {
      this.category = this._catalog[index];
      this.catIndex = index;
      this.firstItem();
    }
    else {
      this.firstCategory();
    }
  }

  selectItem(itemID: number) {
    const index = this.hasItems(this.category) ? this.category.items.findIndex((item) => item.itemID == itemID) : -1;
    if (index >= 0) {
      this.itemIndex = index;
      this.item = this.category.items[index];
      this.firstVariant();
    }
    else
      this.firstItem();
  }

  selectVariant(variationID: number) {
    const index = this.hasVariations(this.item) ? this.item.variations.findIndex((variation) => variation.variationID == variationID) : -1;
    if (index >= 0) {
      this.variant = this.item.variations[index];
      this.varIndex = index;
    }
    else
      this.firstVariant();
  }
}