import React from 'react';
import { useStyles } from './styles';
import { TableRow, TableCell } from '@material-ui/core';
import classNames  from 'classnames';

export interface InventoryTableHeadProps {
  columns: Array<string>;
}

export function InventoryTableHead(props: InventoryTableHeadProps) {
  const classes = useStyles({});
  return (
    <TableRow>
      {props.columns.map(column =>
        <TableCell className={classNames(classes.tableCell, classes.headerCell)} key={column}>{column}</TableCell>
      )}
    </TableRow>
  )
}

