import React, { useState } from "react";
import { Dialog, DialogTitle, Grid, Typography, Button, CssBaseline, Theme, DialogContent } from "@material-ui/core";
import { InventoryContextWrapper } from "../inventory/InventoryContainer";
import { ReceivingContainer } from "../storeShippingAndReceiving/ReceivingContainer";
import { PullInventoryContainer } from "../pullStoreTransfers/PullInventory";
import { makeStyles, createStyles } from "@material-ui/styles";
import { StoreReceiveTransfersContainer } from "../receiveStoreTransfers/ReceiveStoreTransfersContainer";
import { UserCompanyData } from "../wireData/UserCompanyData";
import { SelectCompanyContainer } from "./SelectCompany";
import { setGlobalLoginToken } from "../utils/WireUtils";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: "xs",
    maxHeight: "75vh",
  },
  gridCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
}))


export class StoreDashboardContainerProps {
  onClose: () => void;
}

export function StoreDashboardContainer(props: StoreDashboardContainerProps) {
  const [company, setCompany] = useState<UserCompanyData>(null);
  const [currentState, setCurrentState] = useState(0);

  const onSetCompany= (company: UserCompanyData, token: string) =>{
    setCompany(company);
    setGlobalLoginToken (token);
  }

  const DashboardContents = () => {
    switch (currentState) {
      case 0:
        return <StoreDashboard
          company={company}
          onSetCompany={onSetCompany}
          countInventory={() => setCurrentState(1)}
          receiveInventory={() => setCurrentState(2)}
          pullInventory={() => setCurrentState(3)}
          receiveTransfers={() => setCurrentState(4)}
          logout={() => setCurrentState(5)}
        />
      case 1:
        return <InventoryContextWrapper
          onInventoryDone={() => setCurrentState(0)}
        />
      case 2:
        return <ReceivingContainer onClose={() => setCurrentState(0)} />

      case 3:
        return <PullInventoryContainer onClose={() => setCurrentState(0)} />

      case 4:
        return <StoreReceiveTransfersContainer onClose={() => setCurrentState(0)} />

      case 5:
        props.onClose();
        return null;
    }
  }

  return (
    <div>
      <CssBaseline />
      <DashboardContents />
    </div>
  )
}

export class StoreDashboardProps {
  company: UserCompanyData;
  onSetCompany: (company: UserCompanyData, token: string) => void;
  countInventory: () => void;
  receiveInventory: () => void;
  pullInventory: () => void;
  receiveTransfers: () => void;
  logout: () => void;
}

export function StoreDashboard(props: StoreDashboardProps) {
  const classes = useStyles({});

  const handleCloseClick = () => {
    props.logout();
  }

  const DashboardMenu = () => {

    return (
      <Dialog
        className={classes.root}
        disableBackdropClick
        disableEscapeKeyDown
        open={true}
        onClose={handleCloseClick}
      >
        <DialogTitle disableTypography>
          <Grid container justify="space-around">
          <Grid item xs={12} className={classes.gridCell}>
              <Typography noWrap variant="h6">{props.company.companyName}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridCell}>
              <Typography noWrap variant="h6">Store Options</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={props.countInventory}>
                Count Inventory
            </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={props.receiveInventory}>
                Receive Inventory
            </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={props.pullInventory}>
                Pull Inventory
            </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={props.receiveTransfers}>
                Receive Transfers
            </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleCloseClick}>
                logout
            </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }

  if ( props.company)
    return <DashboardMenu />
  else
    return <SelectCompanyContainer onSetCompany={props.onSetCompany} onCancel={handleCloseClick} />
}