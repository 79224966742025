import { VariationData } from "./VariationData";

export class ItemData {
  constructor (source? : any){
    if (source)
      Object.assign(this, source);
  }

  itemID: number;
  categoryID: number;
  itemName: string;
  active; boolean;
  variations: Array<VariationData>

  key(){
    return this.itemID;
  }

  toString(){
    return this.itemName;
  }
}