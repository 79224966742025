import React from 'react';
import { CustomerData } from '../wireData/CustomerData';
import { TableRow, TableCell } from '@material-ui/core';
import { useStyles } from './styles';
import { formatPhoneNumber, numberToDateString, penniesToDollarString } from '../utils/Convert';
// import { EditCustomerContainer } from './EditCustomerContainer';
// import { getCustomerEditStore } from './CustomerHandler';

export interface CustomerRowProps {
  fields: Array<string>;
  row: CustomerData;
  onSelectCustomer: (customer: CustomerData) => void;
}

export function CustomerRow(props: CustomerRowProps) {
  const classes = useStyles({});

  const formatField = (field: string): string => {
    switch (field) {
      case "phoneNumber":
        return formatPhoneNumber(props.row.phoneNumber);
      case "credits":
        return penniesToDollarString(props.row.credits);
      case "lastVisit":
        return numberToDateString(props.row.lastVisit as number);
      default:
        return props.row[field];
    }
  }

  const onRowClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    props.onSelectCustomer(props.row);
  }

  // const handleDialogClose = () => {
  //   setSelected(null);
  // }

  // const handleUpdateCustomer = (customer: CustomerData) => {
  //   Object.assign(props.row, customer);
  //   setSelected(null);
  // }

  // const handleRemoveCustomer = (customer: CustomerData) => {
  //   setSelected(null);
  //   props.onRemoveCustomer(customer);
  // }

  // const DialogOrNull = () => {
  //   if (selected) return (
  //     <EditCustomerContainer
  //       customer={props.row}
  //       onCancel={handleDialogClose}
  //       onAddCustomer={handleUpdateCustomer}
  //       onRemoveCustomer={handleRemoveCustomer}
  //     />
  //   )
  //   return null;
  // }

  return (
    <TableRow className={classes.tableRow} onClick={onRowClick}>
      {
        props.fields.map((field, index) => (
          <TableCell key={index} className={classes.tableCell}>
            {formatField(field)}
          </TableCell>
        ))
      }
      {/* <TableCell className={classes.hiddenCell}>
        <DialogOrNull />
      </TableCell> */}

    </TableRow>
  )
}