export class CompanyData {
  constructor (source: CompanyData | any){
    Object.assign(this, source);
  }
  // constructor (){
  //   this.companyID = 0;
  //   this.companyName = "";
  //   this.companyActive = true;
  //   this.companyPhoneNumber = "";
  //   this.creditPercent = "00.00";
  //   this.levelDivision = "00.00";
  //   this.parentPercent = "00.00";
  // }
  companyID: number;
  companyName: string;
  companyActive: boolean;
  companyEmail: string;
  companyPhoneNumber: string;
  creditPercent: string;
  levelDivision: string;
  parentPercent: string;

  key(){
    return this.companyID;
  }

  toString () {
    return this.companyName;
  }
}
