
export class PriceSummary {
  constructor(
    vendorID: number,
    vendorName: string,
    categoryID: number,
    categoryName: string,
    itemID: number,
    itemName: string,
    variationID: number,
    variationDescription: string,
    toOrder: number,
    itemCost: number,
    itemTotal: number
  ) {
    this.vendorID = vendorID;
    this.vendorName = vendorName;
    this.categoryID = categoryID;
    this.categoryName = categoryName;
    this.itemID = itemID;
    this.itemName = itemName;
    this.variationID = variationID;
    this.variationDescription = variationDescription;
    this.toOrder = toOrder;
    this.itemCost = itemCost;
    this.itemTotal = itemTotal;
  }
  vendorID: number;
  vendorName: string;
  categoryID: number;
  categoryName: string;
  itemID: number;
  itemName: string;
  variationID: number;
  variationDescription: string;
  toOrder: number;
  latestItemCost: number;
  itemCost: number;
  itemTotal: number;
  priorVendorID: number;
  priorVendorName: string;
}

export type PricingWorkbook = Array<PriceSummary>;