import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, TableCell } from "@material-ui/core";
import { drawerWidth } from "../dashboard/DashboardComponent";

export const footerHeight = 54;
export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {

  },
  tabs: {
    height: 96,
    flexBasis: 'auto',
    flex: '0 0'
  },
  tableRoot: {
    flex: 1,
    overflowY: 'auto',
  },
  headerCell: {
    position: 'sticky',
    top: 0,
    zIndex: 20,
    background: theme.palette.primary[800]
  },
  tableCell: {
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    fontSize: 18,
  },
  lastCountCell:{
    borderLeft: '2px solid white'
  },
  pointerCell: {
    cursor: 'pointer',
  },
  selectableCell: {
    border: '1px dotted white',
    borderRadius: 0,
  },
  noCountCell: {
    color: "tomato",
  },
  nonZeroOrderCell: {
    color: "#d4e157",
    fontWeight: "bolder"
  },
  tabScrollButton: {
//    color: 'white',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: theme.palette.primary[800],
    height: footerHeight,
    flexBasis: 'auto',
    flex: '0 0',
    width: '100%',
  },
  totalTextContainer: {
    display: 'flex-column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  fab: {
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 10,
    right: 50,
    position: 'fixed',
    zIndex: 999,
  },
}));
