import { OrderingWorkbookManager } from "./PreOrderingWorkbookManager";
import { useState, useEffect } from "react";
import { orderingWorkbookURL, pendingOrderItemUpdateURL, pendingOrderItemUpdateVendorURL, downloadOrderSummarySheetURL as downloadOrderSheetURL, pendingOrderVendorsURL, movePendingForVendorURL, getJSONHeaders } from "../utils/WireUtils";
import { PendingOrderItemUpdate, StoreOrderingWorksheet, TotalOrderingWorksheet } from "./StoreOrderingData";
import FileSaver from 'file-saver';
import { Vendor } from "../vendor/Vendor";
import { BaseAction, BaseState } from "../common/BaseHandler";


export class OrderingWorkbookState extends BaseState {
  storeIndex: number;
  category: string;
  displayedWorksheet: StoreOrderingWorksheet | TotalOrderingWorksheet;
  showTotals: boolean;
  activeRow: number;
  scrolling: { top: number };
  columns: Array<string>;
  stores: Array<string>;
  categories: Array<string>;
}

export const OrderingWorkbookStoreName = 'orderingWorkbookStore';

export class OrderingWorkbookActions extends BaseAction<OrderingWorkbookState>{
  manager: OrderingWorkbookManager;

  get initialState(): OrderingWorkbookState {
    return {
      error: null,
      loading: false,
      success: false,
      storeIndex: 0,
      category: null,
      displayedWorksheet: null,
      showTotals: false,
      activeRow: 0,
      scrolling: { top: 0 },
      columns: [],
      stores: [],
      categories: [],
      _actions: this,
    }
  }

  private mapWorkbook = (body: any): Partial<OrderingWorkbookState> => {
    this.manager = new OrderingWorkbookManager();
    this.manager.workbook = body;
    const categories = this.manager.getCategories();
    if (categories.length)
      this.manager.selectCategory(categories[0]);
    const stores = this.manager.getStoreNames();
    if (stores)
      this.manager.selectSheet(0);
    return {
      storeIndex: 0,
      category: categories.length > 0 ? categories[0] : null,
      displayedWorksheet: this.manager.displayedWorksheet,
      showTotals: false,
      activeRow: 0,
      scrolling: { top: 0 },
      columns: this.manager.getColumns(false),
      stores: stores,
      categories: categories,
      error: null,
      loading: false,
      success: true,
    };
  }

  getWorkbook = async (stores: Array<number>, date: string) => {
    this.getData(`${orderingWorkbookURL}?storeIDs=${stores}&date=${date}`, this.mapWorkbook)
  }

  setDisplayedWorksheet = (store: number) => {
    if (store >= this.manager.numSheets) {
      this.manager.filterTotalSheet();
      this.updateState({
        showTotals: true,
        storeIndex: store,
        columns: this.manager.getColumns(true),
        displayedWorksheet: this.manager.totalSheet
      })
    } else {
      this.manager.selectSheet(store);
      this.updateState({
        showTotals: false,
        storeIndex: store,
        columns: this.manager.getColumns(false),
        displayedWorksheet: this.manager.displayedWorksheet
      })
    }
  }

  setCategoryFilter = (store: number, category: string) => {
    this.manager.selectCategory(category);
    if (this.store.getState().showTotals) {
      this.manager.filterTotalSheet();
      this.updateState({
        scrolling: { top: 0 },
        category: category,
        displayedWorksheet: this.manager.totalSheet
      })
    }
    else
      this.updateState({
        scrolling: { top: 0 },
        category: category,
        displayedWorksheet: this.manager.displayedWorksheet
      })
  };

  setActiveRow = (row: number) => {
    this.updateState({ activeRow: row });
  }
}

export const resetPreOrdingStore = () =>{
  BaseAction.getAction(OrderingWorkbookStoreName, OrderingWorkbookActions).reset();
}

export const usePreOrderingWorkbook = (): [OrderingWorkbookState, OrderingWorkbookManager, OrderingWorkbookActions] => {
  const [state, actions] = BaseAction.getAction(OrderingWorkbookStoreName, OrderingWorkbookActions).useStore<OrderingWorkbookActions>();;
  return [state, actions.manager, actions];
}

export const updateItem = (pending: PendingOrderItemUpdate): Promise<any> => {
  return fetch(pendingOrderItemUpdateURL, {
    method: "POST",
    headers: getJSONHeaders(),
    body: JSON.stringify(pending)
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
          .then((update: PendingOrderItemUpdate) => {
            return update;
          })
          .catch((e) => {
            return Promise.reject({ message: "Invalid response body" })
          })
      } else {
        return Promise.reject({ message: response.statusText })
      }
    })
    .catch((e) => {
      return Promise.reject({ message: e.TypeError })
    })
}

export const updateItemVendors = (pending: PendingOrderItemUpdate): Promise<any> => {
  return fetch(pendingOrderItemUpdateVendorURL, {
    method: "POST",
    headers: getJSONHeaders(),
    body: JSON.stringify(pending)
  })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve({ message: "Success" });
      } else {
        return Promise.reject({ message: response.statusText });
      }
    })
    .catch((e) => {
      return Promise.reject({ message: e.TypeError })
    })
}

export const usePreOrderingVendors = (): {
  error: any,
  loading: boolean,
  success: boolean,
  vendors: Array<Vendor>
} => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [vendors, setVendors] = useState<Array<Vendor>>([])

  useEffect(() => {
    //    console.log("Loading PreOrdering Vendors");
    setLoading(true);
    fetch(pendingOrderVendorsURL, {
      method: 'GET',
      headers: getJSONHeaders(),
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          response.json()
            .then((vendors) => {
              setVendors(vendors)
              setSuccess(true);
            })
            .catch((e) => {
              setError(e);
            })
        } else {
          setError(new Error(response.statusText));
        }
      })
      .catch((e) => {
        setError(e);
      })
  }, []);

  return { error, loading, success, vendors };
}

export const movePendingForVendor = (vendorID: number, invoiceNumber: string): Promise<any> => {
  return fetch(movePendingForVendorURL + `/${vendorID}/${invoiceNumber}`, {
    method: "GET",
    headers: getJSONHeaders(),
  })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve({ message: "Success" });
      } else {
        return Promise.reject({ message: response.statusText });
      }
    })
    .catch((e) => {
      return Promise.reject({ message: e.TypeError })
    })
}

export const downloadOrderSheet = async (): Promise<any> => {
  return fetch(downloadOrderSheetURL, {
    method: "GET",
    headers: getJSONHeaders(),
  })
    .then((response) => response.blob())
    .then((blob) => FileSaver.saveAs(blob, 'ordersheet.csv'))
}