import { justDateFromWire } from "./WireDates";
import { Moment } from "moment";

export class CustomerData {
  constructor(source?: any) {
    this.birthdate = null;
    this.cardNumber = "";
    this.credits = 0;
    this.email = "";
    this.firstName = "";
    this.lastName = "";
    this.lastVisit =null;
    this.lastLogin = null;
    this.lifetimeChildCredits = 0;
    this.lifetimeEarnedCredits = 0;
    this.loginName = "";
    this.parentID = 0;
    this.password = "";
    this.permissions = 0;
    this.phoneNumber = "";
    this.taken = false;
    this.takenOn = null;
    this.customerID = 0;
    this.validated = false;
    this.comments = "";
    this.authorizations = "";

    if (source)
      Object.assign(this, source);
  }

  birthdate: Date | number | Moment | null;
  cardNumber: string;
  credits: number | string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  lastVisit: Date | number | Moment | null;
  lastLogin: Date | number | Moment | null;
  lifetimeChildCredits: number | string | null;
  lifetimeEarnedCredits: number | string | null;
  loginName: string | null;
  parentID: number | null;
  password: string | null;
  permissions: number | null;
  phoneNumber: string | null;
  taken: boolean | null;
  takenOn: Date | number | null;
  customerID: number;
  validated: boolean | null;
  comments: string | null;
  authorizations: string;

}

export class CustomerListFilter {
  phoneNumber: string;
  cardNumber: string;
  email: string;
  firstName: string;
  lastName: string;
}

export const customerDataToWire = (customer: CustomerData) => {
  if (customer.birthdate)
    customer.birthdate = customer.birthdate.valueOf();
  if (customer.lastVisit)
    customer.lastVisit = customer.lastVisit.valueOf();
  if (customer.lastLogin)
    customer.lastLogin = customer.lastLogin.valueOf();
  if (customer.takenOn)
    customer.takenOn = customer.takenOn.valueOf();
}

export const customerDataFromWire = (customer: CustomerData) => {
  if (customer.birthdate)
    customer.birthdate = justDateFromWire(customer.birthdate as number);
  else
    customer.birthdate = null;
  if (customer.lastVisit)
    customer.lastVisit = justDateFromWire(customer.lastVisit as number);
  else
    customer.lastVisit = null;
  if (customer.lastLogin)
    customer.lastLogin = justDateFromWire(customer.lastLogin as number);
  else
    customer.lastLogin = null;
  if (customer.takenOn)
    customer.takenOn = justDateFromWire(customer.takenOn);
  else
    customer.takenOn = null;
  if (!customer.email || !customer.email.trim())
    customer.email = null;
  if (!customer.loginName || !customer.loginName.trim())
    customer.loginName = null;
  if (!customer.comments || !customer.comments.trim())
    customer.comments = null;
  if (!customer.authorizations || !customer.authorizations.trim())
    customer.authorizations = null;
}