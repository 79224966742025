import { Store } from "../store/Store";

export class StoreOrderingWorksheet {
  store: string;
  storeID: number;
  dates: Array<string>;
  counts: Array<StoreOrderingCount>;
}

export class StoreOrderingCount {
  categoryID: number;
  category: string;
  itemID: number;
  item: string;
  variationID: number;
  variation: string | null;
  sales: Array<number>;
  lastCount: number;
  onOrder: number;
  transfer: number;
  toOrder: number;
  latestItemCost: number;
}

export class StoreOrderingWorkbook {
  worksheets: Array<StoreOrderingWorksheet>;
  totalSheet: TotalOrderingWorksheet;
}

export class TotalOrderingWorksheet {
  stores: Array<Store>;
  items: Array<PendingOrderItem>;
}

export class PendingOrderItem {
  categoryID: number;
  category: string;
  itemID: number;
  item: string;
  variationID: number;
  variation: string | null;
  pending: Array<number>;
  pendingTotal: number;
  vendorID: number;
  vendorName: string;
  latestItemCost: number;
  stockCount: number;
}

export class PendingOrderItemUpdate {
  storeIDs: Array<number>;
  storeID: number
  categoryID: number;
  itemID: number;
  variationID: number;
  pending: number;
  pendingTotal: number;
  vendorID: number;
  itemCost: number;
}