export class RegisterData {
  constructor(source?: RegisterData | any){
    if (source){
      Object.assign(this,source);
    }
  }
  registerID: number;
  companyID: number;
  storeID: number;
  registerName: string;

  toString() {
    return this.registerName;
  }

  key() {
    return this.registerID;
  }
}
