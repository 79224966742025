import React, { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitIcon from '@material-ui/icons/ExitToApp';
import CloseIcon from '@material-ui/icons/Close';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import DashboardIcon from '@material-ui/icons/Dashboard';
import RefreshIcon from '@material-ui/icons/Refresh';
import { MainListItems, DisplayState } from './ListItems';
import { CatalogContainer } from '../catalog/CatalogContainer';
import { makeStyles, createStyles } from '@material-ui/styles';
import UploadDialog from '../fileUpload/FileUploadDialogComponent';
import { CountViewContainer, resetCountViewContainer } from '../inventory/CountViewContainer';
import { InventoryContextWrapper } from '../inventory/InventoryContainer';
import { Fab, Tab, Tabs, Paper } from '@material-ui/core';
import { UserContainer, resetUserComponent } from '../user/UserContainer';
import { PreOrderingComponent, resetPreOrderingComponent } from '../preordering/PreOrderingComponent';
import { downloadOrderSheet } from '../preordering/PreOrderingHandler';
import { SalesDialog } from '../fileUpload/SalesUploadDialog';
import { PricingComponent, resetPricingWorkbookComponent } from '../pricing/PricingComponent';
import { GenerateOrdersFromPreOrdersDialog } from '../preordering/GenerateOrdersFromPreOrders';
import { InventoryCountsContainer, resetInventoryCountsContainer } from '../inventory/InventoryCounts';
import { ReceivingComponent, resetReceivingComponent } from '../purchaseOrder/ReceivingComponent';
import { downloadPurchaseOrderReceivingSheet } from '../purchaseOrder/PurchaseOrderHandler';
import { ReconcilingComponent, resetReconcilingComponent } from '../purchaseOrder/ReconcilingComponent';
import { ShippingContainer } from '../storeShippingAndReceiving/ShippingContainer';
import { ReceivingContainer, resetReceivingContainer } from '../storeShippingAndReceiving/ReceivingContainer';
import { PullInventoryContainer, resetPullInventoryContainer } from '../pullStoreTransfers/PullInventory';
import { ReceiveFromStoresContainer, resetReceiveFromStoresContainer } from '../stockroom/ReceiveFromStores';
import { TransferToStoresContainer, resetTransferToStoresContainer } from '../stockroom/SendToStores';
import { downloadDatabaseBackup } from '../backup/backupHandler';
import { StoreReceiveTransfersContainer, resetStoreReceiveTransfersContainer } from '../receiveStoreTransfers/ReceiveStoreTransfersContainer';
import { openFullscreen, closeFullscreen } from '../utils/FullScreen';
import { PointOfSaleContainer } from '../pointOfSale/PointOfSaleContainer';
import { CompanyContainer, resetCompanyComponent } from '../company/CompanyContainer';
import { SelectCompanyContainer } from './SelectCompany';
import { UserCompanyData } from '../wireData/UserCompanyData';
import { setGlobalLoginToken, setGlobalUserCompany } from '../utils/WireUtils';
import { RegisterManager, resetRegisterManager } from '../register/registerContainer';
import { DeviceContainer, resetDeviceManager } from '../device/DeviceContainer';
import { ItemCostContainer, resetItemCostContainer } from '../itemCost/ItemCostContainer';
import { InventoryCostContainer, resetInventoryCostsContainer } from '../InventoryCosts/InventoryCostsContainer';
import { InventoryTransferSummaryContainer, resetInventoryTransferHistoryContainer } from '../inventoryTransfer/InventoryTransferHistoryContainer';
import { CustomerContainer, resetCustomerContainer } from '../customer/CustomerContainer';
import { TransactionContainer } from '../transaction/TransactionContainer';
import { PendingTransactionContainer } from '../transaction/PendingTransactionContainer';
import { SystemDashboardContainer } from '../system/SystemDashboardContainer';
import { resetInventoryManager } from '../inventory/InventoryHandler';
import { resetShipToStores } from '../storeShippingAndReceiving/ShippingHandler';
import { PurchaseOrderHistoryComponent, resetPurchaseOrderHistoryComponent } from '../purchaseOrder/PurchaseOrderHistoryComponent';

export const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: "flex",
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarIcon: {
    flex: 0,
    // display: 'flex',
    // alignItems: 'baseline',
    // justifyContent: 'flex-end',
    padding: '0 8px',
    // ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    left: 0,
    // width: "100%",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    left: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 0,
  },
  tabPanel: {
    flexGrow: 1,
    flexWrap: "nowrap",
    width: 0,
  },
  tab: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRight: "solid thin",
  },
  tabIcon: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    flex: '0 0',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    height: '100vh',
    flexBasis: 'auto'
  },
  fab: {
    margin: 0,
    top: 5,
    left: 'auto',
    bottom: 'auto',
    right: 5,
    position: 'fixed',
    transform: 'scale(0.5)',
    opacity: 0.75,
    zIndex: 999,
  },
  noPadding: {
    padding: 0,
  },
  tableContainer: {
    overflowY: 'hidden',
    flex: 1,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
}));

interface DashboardProps {
  onExit: () => void;
}

class DisplayElement {
  displayState: DisplayState;
  description: string;
  onRefresh: () => void;
  onClose: () => void;
}

export function Dashboard(props: DashboardProps) {

  const classes = useStyles({});
  const [showCatalogUpload, setShowCatalogUpload] = useState(false);
  const [showSalesUpload, setShowSalesUpload] = useState(false);
  const [showGenerateOrdersFromPreOrders, setShowGenerateOrdersFromPreOrders] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [company, setCompany] = useState<UserCompanyData>(null);
  const [tabs, setTabs] = useState<Array<DisplayElement>>([]);
  const [currentTab, setCurrentTab] = useState<DisplayState>(null);

  const [dashState, setDashState] = useState(true);

  const displayElements: Array<DisplayElement> = [
    { displayState: DisplayState.CATALOG, description: "Catalog", onRefresh: null, onClose: null },
    { displayState: DisplayState.INVENTORY_COUNT_TABLE, description: "Counts", onRefresh: resetCountViewContainer, onClose: resetCountViewContainer },
    { displayState: DisplayState.TAKE_INVENTORY, description: "Inventory", onRefresh: resetInventoryManager, onClose: resetInventoryManager },
    { displayState: DisplayState.INVENTORY_COUNTS, description: "Inventory Counts", onRefresh: resetInventoryCountsContainer, onClose: resetInventoryCountsContainer },
    { displayState: DisplayState.MANAGE_USERS, description: "Users", onRefresh: resetUserComponent, onClose: resetUserComponent },
    { displayState: DisplayState.ORDERING, description: "Pre-Ordering", onRefresh: resetPreOrderingComponent, onClose: resetPreOrderingComponent },
    { displayState: DisplayState.PRICING, description: "Pricing", onRefresh: resetPricingWorkbookComponent, onClose: resetPricingWorkbookComponent },
    { displayState: DisplayState.RECEIVING_ORDERS, description: "Receive Purchases", onRefresh: resetReceivingComponent, onClose: resetReceivingComponent },
    { displayState: DisplayState.RECONCILE_ORDERS, description: "Reconcile Orders", onRefresh: resetReconcilingComponent, onClose: resetReconcilingComponent },
    { displayState: DisplayState.ORDER_HISTORY, description: "Order History", onRefresh: resetPurchaseOrderHistoryComponent, onClose: resetPurchaseOrderHistoryComponent },
    { displayState: DisplayState.SHIP_TO_STORES, description: "Ship to Stores", onRefresh: resetShipToStores, onClose: resetShipToStores },
    { displayState: DisplayState.RECEIVE_AT_STORES, description: "Receive at Stores", onRefresh: resetReceivingContainer, onClose: resetReceivingContainer },
    { displayState: DisplayState.PULL_INVENTORY, description: "Pull Inventory", onRefresh: resetPullInventoryContainer, onClose: resetPullInventoryContainer },
    { displayState: DisplayState.RECEIVE_FROM_STORES, description: "Receive from Stores", onRefresh: resetReceiveFromStoresContainer, onClose: resetReceiveFromStoresContainer },
    { displayState: DisplayState.TRANSFER_TO_STORES, description: "Transfer to Stores", onRefresh: resetTransferToStoresContainer, onClose: resetTransferToStoresContainer },
    { displayState: DisplayState.RECEIVE_TRANSFERS_AT_STORES, description: "Receive Transfers", onRefresh: resetStoreReceiveTransfersContainer, onClose: resetStoreReceiveTransfersContainer },
    { displayState: DisplayState.POINT_OF_SALE, description: "Point of Sale", onRefresh: null, onClose: null },
    { displayState: DisplayState.MANAGE_COMPANIES, description: "Companies", onRefresh: resetCompanyComponent, onClose: resetCompanyComponent },
    { displayState: DisplayState.MANAGE_REGISTERS, description: "Registers", onRefresh: resetRegisterManager, onClose: resetRegisterManager },
    { displayState: DisplayState.MANAGE_DEVICES, description: "Devices", onRefresh: resetDeviceManager, onClose: resetDeviceManager },
    { displayState: DisplayState.ITEM_COSTS, description: "Item Costs", onRefresh: resetItemCostContainer, onClose: resetItemCostContainer },
    { displayState: DisplayState.INVENTORY_COSTS, description: "Inventory Costs", onRefresh: resetInventoryCostsContainer, onClose: resetInventoryCostsContainer },
    { displayState: DisplayState.TRANSFER_HISTORY, description: "Transfer History", onRefresh: resetInventoryTransferHistoryContainer, onClose: resetInventoryTransferHistoryContainer },
    { displayState: DisplayState.CUSTOMERS, description: "Customers", onRefresh: resetCustomerContainer, onClose: resetCustomerContainer },
    { displayState: DisplayState.TRANSACTIONS, description: "Transactions", onRefresh: null, onClose: null },
    { displayState: DisplayState.PENDING_TRANSACTIONS, description: "Pending Transactions", onRefresh: null, onClose: null },
    { displayState: DisplayState.SYSTEM_DASHBOARD, description: "System Dashboard", onRefresh: null, onClose: null },
  ]


  function changeDashboardState(state: DisplayState) {
    if (state == DisplayState.EMPTY) {
      setTabs([]);
      setCurrentTab(null);
      setDashState(true);
    }
    else {
      let tab = tabs.find((tab) => tab.displayState == state);
      if (!tab) {
        if (tabs.length == 0)
          setDashState(false);
        tab = displayElements.find((element) => element.displayState == state);
        if (tab) {
          tabs.push(tab);
        }
      }
      setCurrentTab(tab.displayState);
    }
  }

  const handleDrawerClick = (state: DisplayState) => {
    switch (state) {
      case DisplayState.DOWNLOAD_ORDER_SHEET:
        handleDownloadOrderSheetClick();
        break;
      case DisplayState.GENERATE_ORDERS_FROM_PREORDERS:
        handleGenerateOrdersFromPreOrdersClick();
        break;
      case DisplayState.DOWNLOAD_DATABASE_BACKUP:
        handleDownloadDatabaseBackupClick();
        break;
      case DisplayState.SALES_UPLOAD:
        handleSalesUploadClick();
        break;
      case DisplayState.CATALOG_UPLOAD:
        handleCatalogUploadClick();
        break;
      default:
        changeDashboardState(state);
    }
  }

  function handleRefresh() {
    const tab = tabs.find((tab) => tab.displayState == currentTab)
    tab && tab.onRefresh && tab.onRefresh();
  }

  function handleCatalogUploadClick() {
    setShowCatalogUpload(true);
  }

  function handleSalesUploadClick() {
    setShowSalesUpload(true);
  }

  function handleGenerateOrdersFromPreOrdersClick() {
    setShowGenerateOrdersFromPreOrders(true);
  }

  async function handleDownloadOrderSheetClick() {
    await downloadOrderSheet();
  }

  async function handleDownloadOrderReceivingSheetClick() {
    await downloadPurchaseOrderReceivingSheet();
  }

  async function handleDownloadDatabaseBackupClick() {
    await downloadDatabaseBackup();
  }

  function handleShowHideAppBarAndDrawer(hide: boolean) {
    setHidden(hide);
    if (hide) {
      openFullscreen();
    }
    else {
      closeFullscreen();
    }
  }

  function handleCloseVisible() {
    handleChipClose(currentTab);
  }

  function handleChipClose(displayState: DisplayState) {
    if (displayState) {
      let index = tabs.findIndex((tab) => tab.displayState == displayState);
      if (index >= 0) {
        const tab = tabs[index];
        tab.onClose && tab.onClose();
        tabs.splice(index, 1);
        if (index >= tabs.length)
          --index;
        setTabs([...tabs]);
        if (tabs.length) {
          if (displayState == currentTab)
            changeDashboardState(tabs[index].displayState);
        }
        else
          changeDashboardState(DisplayState.EMPTY)
      }
    }
    else
      changeDashboardState(DisplayState.EMPTY)
  }

  function handleCompanySelect(company, token) {
    setCompany(company);
    setGlobalLoginToken(token);
    setGlobalUserCompany(company);
  }

  function renderDialogs() {
    if (showCatalogUpload)
      return <UploadDialog open={showCatalogUpload} onClose={() => setShowCatalogUpload(false)} />
    if (showSalesUpload)
      return <SalesDialog open={showSalesUpload} onClose={() => setShowSalesUpload(false)} />
    if (showGenerateOrdersFromPreOrders)
      return <GenerateOrdersFromPreOrdersDialog open={showGenerateOrdersFromPreOrders} onClose={() => setShowGenerateOrdersFromPreOrders(false)} />
    if (company == null)
      return <SelectCompanyContainer onCancel={props.onExit} onSetCompany={(company: UserCompanyData, token: string) => handleCompanySelect(company, token)} />
    return null;
  }

  function renderDisplay(state: DisplayState) {
    switch (state) {
      case DisplayState.MANAGE_USERS:
        return <UserContainer onClose={handleCloseVisible} />
      case DisplayState.CATALOG:
        return <CatalogContainer />
      case DisplayState.INVENTORY_COUNT_TABLE:
        return <CountViewContainer onClose={handleCloseVisible} />
      case DisplayState.INVENTORY_COSTS:
        return <InventoryCostContainer onClose={handleCloseVisible} />
      case DisplayState.TAKE_INVENTORY:
        return <InventoryContextWrapper onInventoryDone={handleCloseVisible} />
      case DisplayState.INVENTORY_COUNTS:
        return <InventoryCountsContainer />
      case DisplayState.ORDERING:
        return <PreOrderingComponent />
      case DisplayState.PRICING:
        return <PricingComponent />
      case DisplayState.RECEIVING_ORDERS:
        return <ReceivingComponent />
      case DisplayState.RECONCILE_ORDERS:
        return <ReconcilingComponent />
      case DisplayState.ORDER_HISTORY:
        return <PurchaseOrderHistoryComponent />
      case DisplayState.SHIP_TO_STORES:
        return <ShippingContainer onClose={handleCloseVisible} />
      case DisplayState.RECEIVE_AT_STORES:
        return <ReceivingContainer onClose={handleCloseVisible} />
      case DisplayState.PULL_INVENTORY:
        return <PullInventoryContainer onClose={handleCloseVisible} />
      case DisplayState.RECEIVE_TRANSFERS_AT_STORES:
        return <StoreReceiveTransfersContainer onClose={handleCloseVisible} />
      case DisplayState.RECEIVE_FROM_STORES:
        return <ReceiveFromStoresContainer onClose={handleCloseVisible} />
      case DisplayState.TRANSFER_TO_STORES:
        return <TransferToStoresContainer onClose={handleCloseVisible} />
      case DisplayState.POINT_OF_SALE:
        return <PointOfSaleContainer onClose={handleCloseVisible} />
      case DisplayState.MANAGE_COMPANIES:
        return <CompanyContainer onClose={handleCloseVisible} />
      case DisplayState.MANAGE_REGISTERS:
        return <RegisterManager onClose={handleCloseVisible} />
      case DisplayState.MANAGE_DEVICES:
        return <DeviceContainer onClose={handleCloseVisible} />
      case DisplayState.ITEM_COSTS:
        return <ItemCostContainer onClose={handleCloseVisible} />
      case DisplayState.TRANSFER_HISTORY:
        return <InventoryTransferSummaryContainer onClose={handleCloseVisible} />
      case DisplayState.CUSTOMERS:
        return <CustomerContainer onClose={handleCloseVisible} />
      case DisplayState.TRANSACTIONS:
        return <TransactionContainer onClose={handleCloseVisible} />
      case DisplayState.PENDING_TRANSACTIONS:
        return <PendingTransactionContainer onClose={handleCloseVisible} />;
      case DisplayState.SYSTEM_DASHBOARD:
        return <SystemDashboardContainer onClose={handleCloseVisible} />;
      case DisplayState.EMPTY:
        return null;
      default:
        return null;
    }
  }

  function CurrentTab() {
    if (currentTab) {
      return renderDisplay(currentTab);
    }
    return null;
  }

  function handleExit() {
    if (tabs.length == 0)
      props.onExit();
    else {
      handleCloseVisible();
    }
  }

  function handleChipSelect(event: React.ChangeEvent, displayState: DisplayState) {
    if (event.target.tagName !== "DIV" && event.target.tagName !== "P") {
      event.preventDefault();
      handleChipClose(displayState);
    }
    else
      changeDashboardState(displayState);
  }

  function RenderTabs() {
    return (
      <div className={classes.tabPanel}>
        <Tabs
          variant="scrollable"
          value={currentTab}
          onChange={handleChipSelect}
          indicatorColor="secondary"
          textColor="inherit"
          scrollButtons="on"
        >
          {
            tabs.map((tabData) => (
              <Tab className={classes.tab}
                component="div"
                key={tabData.displayState}
                label={
                  <div>
                    <Typography display="inline" variant="body1">
                      {tabData.description}
                    </Typography>
                    <IconButton size="small" className={classes.tabIcon}>
                      <CloseIcon color="action" />
                    </IconButton>
                  </div>
                }
                value={tabData.displayState}
              />
            ))
          }
        </Tabs>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, { [classes.appBarShift]: dashState, [classes.hide]: hidden })}
      >
        <Toolbar disableGutters={!dashState} className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={() => setDashState(true)}
            className={classNames(
              classes.menuButton,
              { [classes.menuButtonHidden]: dashState },
            )}
          >
            <MenuIcon />
          </IconButton>
          <RenderTabs />
          {tabs.length > 0 ?
            <>
              <IconButton onClick={handleRefresh} color='inherit'>
                <RefreshIcon />
              </IconButton>
              <IconButton onClick={() => handleShowHideAppBarAndDrawer(true)} color="inherit">
                <FullScreenIcon />
              </IconButton>
            </>
            : null}
          <IconButton onClick={handleExit} color="inherit">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, { [classes.drawerPaperClose]: !dashState, [classes.hide]: hidden }),
        }}
        open={dashState}
      >
        <div className={classes.toolbarHeader}>
          <div className={classes.toolbarTitle}>
            <Typography align="center" variant="h6" noWrap>
              {(company && company.companyName)}
            </Typography>
          </div>
          <div className={classes.toolbarIcon}>
            <IconButton onClick={() => setDashState(false)}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
        </div>
        <Divider />
        <List>
          <MainListItems
            disableTooltips={dashState}
            onSelect={handleDrawerClick}
          />
        </List>
        <Divider />
      </Drawer>
      <main className={classNames(classes.content, { [classes.noPadding]: hidden })}>
        {renderDialogs()}
        <div className={classNames(classes.appBarSpacer, { [classes.hide]: hidden })} />
        <div className={classes.tableContainer}>
          <CurrentTab />
        </div>
        {hidden
          ?
          <Fab onClick={() => handleShowHideAppBarAndDrawer(false)} className={classes.fab} ><DashboardIcon /></Fab>
          : null}
      </main>
    </div>
  );
}
