import moment from "moment";

export function numberToDollarString(value: number): string {
  const n = Number(value);
  if (n < 0) {
    return `$(${Math.abs(n).toFixed(2)})`
  }
  return `$${n.toFixed(2)}`
}

export function penniesToDecimalString(value: number | string): string {
  if (!value)
    value = 0;
  let result = value.toString().trim();
  if (result.length < 2)
    result = "0.0" + result;
  else if (result.length < 3)
    result = "0." + result;
  else
    result = result.substr(0, result.length - 2) + "." + result.substr(result.length - 2, 2);

  return result;
}

export function decimalStringToPennies(value: number | string): number {
  if (!value)
    return 0;
  let result = value.toString().trim();
  const parts = result.split('.');
  if (parts.length == 1)
    return Number(value) *100;
  else {
    if (parts[1].length > 2)
      parts[1] = parts[1].substr(0, 2);
    while (parts[1].length < 2)
      parts[1] += '0';
    result = parts[0] + parts[1];
  }

  return Number(result);
}

export function penniesToDollarString(value: number | string): string {
  return '$' + penniesToDecimalString(value);
}
export function dollarStringToFloat(dollarString: string): number {
  return parseFloat(dollarString.substring(1));
}

export function numberToDateString(value: Date | number) {
  if (!value || value == 0)
    return "";
  const date = new Date(value);
  return date.toLocaleDateString();
}

export function numberToDateTimeString(value: Date | number) {
  if (!value || value == 0)
    return "";
  const date = new Date(value);
  return date.toLocaleString();
}

// export function getLastSundayMillis(): number {
//   const date = new Date();
//   date.setHours(0);
//   date.setMinutes(0);
//   date.setSeconds(0);
//   date.setMilliseconds(0);
//   date.setDate(date.getDate() - date.getDay());
//   const offsetMillis = date.getTimezoneOffset() * 60 * 1000;
//   const dateValue = date.valueOf() - offsetMillis;

//   return dateValue;
// }

export function getLastSundayISODateString(): string {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  date.setDate(date.getDate() - date.getDay());
  return moment(date).toISOString(true).substr(0,10);
}

// export function dateToAdjustedMillis(date: Date): number {
//   const offsetMillis = date.getTimezoneOffset() * 60 * 1000;
//   return date.valueOf() + offsetMillis;
// }

export function localISODateString(date: Date): string {
  return moment(date).toISOString(true).substr(0,10);
}

export function bankersRound(num: number, decimalPlaces?: number | null) {
  let d = decimalPlaces || 0;
  let m = Math.pow(10, d);
  let n = Number((d ? num * m : num).toFixed(8)); // Avoid rounding errors
  let i = Math.floor(n), f = n - i;
  let e = 1e-8; // Allow for rounding errors in f
  let r = (f > 0.5 - e && f < 0.5 + e) ?
    ((i % 2 === 0) ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
}

export function formatPhoneNumber(value: number | string): string {
  if (!value)
    return "";
  value = value.toString();
  const parts = value.match(/(\d{3})(\d{3})(\d{4})/)
  if (parts && parts.length == 4)
    return "(" + parts[1] + ") " + parts[2] + "-" + parts[3];
  return value;
}