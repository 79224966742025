import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { TransactionTable } from './TransactionTable';
import { useTransactions, getTransactionEditStore } from './TransactionHandler';
import { TransactionTableHeaderColumn } from './TransactionTableHeader';
import { DataTableFooter } from '../common/DataTableFooter';
import { EditTransactionContainer } from './EditTransactionContainer';
import { TransactionData } from '../wireData/TransactionData';

export interface TransactionContainerProps {
  onClose: () => void;
}

const headers: Array<TransactionTableHeaderColumn> = [
  { header: "Date", field: "date", isSearchable: false },
  { header: "Company", field: "companyName", isSearchable: true },
  { header: "Store", field: "storeName", isSearchable: true },
  { header: "Register", field: "registerName", isSearchable: true },
  { header: "Clerk", field: "userName", isSearchable: true },
  { header: "Card #", field: "cardNumber", isSearchable: true },
  { header: "First Name", field: "customerFirstName", isSearchable: true },
  { header: "Last Name", field: "customerLastName", isSearchable: true },
  { header: "Type", field: "transactionType", isSearchable: true },
  { header: "Purchase", field: "purchaseAmount", isSearchable: false },
  { header: "Credit", field: "creditChange", isSearchable: false },
];

let filterTimer = null;

export function TransactionContainer(props: TransactionContainerProps) {
  const classes = useStyles({});

  const [{ error, loading, success, countError, countLoading, countSuccess, newTransaction,
    currentOffset, pageSize, filters, transactionCount, transactions }, actions] = useTransactions();

  let pendingFilters = filters;

  useEffect(() => {
    if (!(countError || countLoading || countSuccess))
      actions.getTransactionCount();
  }, [countError, countLoading, countSuccess]);

  useEffect(() => {
    if (!(error || loading || success))
      actions.getTransactions();
  }, [error, loading, success]);

  const handleFilterUpdate = (filter: {}) => {
    if (filterTimer) {
      clearTimeout(filterTimer);
      filterTimer = null;
    }
    pendingFilters = filter;
    filterTimer = setTimeout(() => {
      handleRefresh();
    }, 2000);
  }

  const handleRefresh = () => {
    if (filterTimer) {
      clearTimeout(filterTimer);
      filterTimer = null;
    }
    actions.updateState({ success: false, countSuccess: false, currentOffset: 0, filters: { ...pendingFilters }, pagingFilters: { ...pendingFilters, skip: 0, take: pageSize } });
    // actions.setFilters({ ...pendingFilters });
    // actions.setPagingFilters({ ...pendingFilters, skip: 0, take: pageSize });
  }

  const handlePageChange = (offset: number, pageSize: number) => {
    actions.updateState({ success: false, pageSize: pageSize, currentOffset: offset, filters: pendingFilters, pagingFilters: { ...pendingFilters, skip: offset, take: pageSize } });
    // actions.setCurrentOffset(offset);
    // actions.setFilters(pendingFilters);
    // actions.setPagingFilters({ ...pendingFilters, skip: offset, take: pageSize });
  }

  const handleAddTransaction = () => {
    const transaction= new TransactionData();
    getTransactionEditStore(transaction);
    actions.setNewTransaction(transaction);
  }

  const handleDialogClose = () => {
    actions.setNewTransaction(null);
  }

  const handleUpdateTransaction = (transaction: TransactionData) => {
    actions.setNewTransaction(null);
    transactions.unshift(transaction);
  }

  const handleSelectTransaction = (transaction: TransactionData) => {
    getTransactionEditStore(transaction);
    actions.setNewTransaction(transaction);
  }

  const TransactionsOrStatus = () => {
    if (error || countError) return <Error message={(error || countError).message} />;
    if (loading || countLoading) return <Loading />;
    if (success && countSuccess) return (
      <TransactionTable
        transactions={transactions}
        headers={headers}
        filters={pendingFilters}
        onFilterUpdate={handleFilterUpdate}
        onSelectTransaction={handleSelectTransaction}
      />
    )
    return null;
  }

  const DialogOrNull = () => {
    if (newTransaction) return (
      <EditTransactionContainer
        transaction={newTransaction}
        onCancel={handleDialogClose}
        onUpdateTransaction={handleUpdateTransaction}
      />
    )
    return null;
  }


  return (
    <div className={classes.root} >
      <TransactionsOrStatus />
      <DialogOrNull />
      <DataTableFooter
        pageSize={pageSize}
        currentOffset={currentOffset}
        transactionCount={transactionCount}
        onPage={handlePageChange}
        showAdd
        showRefresh
        onAdd={handleAddTransaction}
        onRefresh={handleRefresh}
      />
    </div>
  )
}