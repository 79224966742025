import React from 'react';
import { Grid, Button } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/CheckCircle'

export interface UserConfigurationProps {
  onItemSelected: (value: string) => void;
  currentConfig: string
}

export function UserConfiguration(props: UserConfigurationProps) {
  const configs = [
    'inventory',
    'all'
  ]

  const checkedColor = (key: string): "inherit" | "secondary" => {
    if (props.currentConfig == key)
      return "secondary"
    return "inherit"
  }

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12} sm={6}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={(e) => props.onItemSelected("inventory")}
        >
          <Grid container direction="row">
            <Grid item xs={2}>
              <CheckIcon color={checkedColor("inventory")} />
            </Grid>
            <Grid item xs={10}>
              In Store Dashboard
            </Grid>
          </Grid>
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={(e) => props.onItemSelected("all")}
        >
          <Grid container direction="row">
            <Grid item xs={2}>
              <CheckIcon color={checkedColor("all")} />
            </Grid>
            <Grid item xs={10}>
              Desktop Dashboard
            </Grid>
          </Grid>
        </Button>
      </Grid>
    </Grid>
  )
}

