import React, { useLayoutEffect } from 'react';
import { ListItem, List, ListItemText, ListSubheader, ListItemSecondaryAction, ListItemIcon, Button, CircularProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle'
import { InventoryTransferItem, InventoryTransferQuantityAndStatusUpdate } from '../wireData/InventoryTransferData';
import { CategoryItems } from './StockroomData';
import { TypographyProps } from '@material-ui/core/Typography';
import { useShipToStores } from './StockroomHandler';
import { ChangeTransferCountDialog } from '../common/ChangeTransferCountDialog';
import { useStyles } from './styles';
import { UpdateError, UpdateStatus } from '../common/TransferUpdateDialogs';

export class TransferToStoresListProps {
  storeName: string;
}

export function TransferToStoresList(props: TransferToStoresListProps) {
  const classes = useStyles({});

  const [{ scrolling, transferToUpdate, updateError, updating }, manager, actions] = useShipToStores();

  const scrollinfRef = React.useRef(null);

  useLayoutEffect(() => {
    scrollinfRef.current.scrollTop = scrolling.top;
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrolling.top = event.currentTarget.scrollTop;
  }

  class SubHeaderProps {
    category: CategoryItems;
  }

  const SubHeader = (props: SubHeaderProps) => {

    class SendTransferItemProps {
      transferItem: InventoryTransferItem;
    }

    const primaryProps: TypographyProps = {
      variant: "h5"
    }

    const secondaryProps: TypographyProps = {
      variant: "h6"
    }

    const TransferItem = (props: SendTransferItemProps) => {
      const checked = props.transferItem.status == "pickedup";

      const handleClick = async (transferItem: InventoryTransferItem) => {

        const update = new InventoryTransferQuantityAndStatusUpdate();
        update.status = transferItem.status;
        update.quantity = Math.abs(transferItem.quantityShipped);
        update.inventoryTransferID = transferItem.inventoryTransferID;

        if (checked) {
          await actions.downgradeStockroomStatus(update);
        } else {
          await actions.updateStockroomStatus(update);
        }
        if (actions.state.updateSuccess) {
          const updatedItem = actions.state.updatedItem;
          manager.updateStatus(updatedItem);
          manager.updateShippedCount(updatedItem);
        }
        actions.clearUpdate();
      }

      const handleCountButtonClick = (event) => {
        event.stopPropagation();
        actions.setTransferToUpdate(props.transferItem);
      }

      const handleChangeCountDialogClose = () => {
        actions.setTransferToUpdate(null);
      }

      const handleCountUpdate = async (newValue: number) => {
        const update = new InventoryTransferQuantityAndStatusUpdate();
        update.quantity = Math.abs(newValue);
        update.inventoryTransferID = transferToUpdate.inventoryTransferID;

        actions.setTransferToUpdate(null);
        await actions.updateStockroomShippedCount(update);
        if (actions.state.updateSuccess)
          manager.updateShippedCount(actions.state.updatedItem);
        actions.clearUpdate();
      }

      const CountDialog = () => {
        return (
          <ChangeTransferCountDialog
            open
            initialValue={transferToUpdate.quantityShipped}
            transferItem={transferToUpdate}
            onClose={handleChangeCountDialogClose}
            onUpdate={handleCountUpdate}
          />
        )
      }

      const ButtonOrProgress = () => {
        if (transferToUpdate == props.transferItem)
          return (
            <div>
              <CircularProgress />
              <CountDialog />
            </div>
          )
        return (
          <Button onClick={handleCountButtonClick}>
            <Typography variant="h4">{props.transferItem.quantityShipped}</Typography>
          </Button>
        )
      }

      return (
        <ListItem button onClick={() => handleClick(props.transferItem)} key={props.transferItem.inventoryTransferID}>
          <ListItemIcon>
            <ButtonOrProgress />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={primaryProps}
            secondaryTypographyProps={secondaryProps}
            primary={props.transferItem.itemName}
            secondary={props.transferItem.variationDescription ? props.transferItem.variationDescription : null} />
          <ListItemSecondaryAction>
            <CheckIcon style={{ color: checked ? "Chartreuse" : "lightgray" }} />
          </ListItemSecondaryAction>
        </ListItem>
      )
    }

    return (
      <li className={classes.categorySection}>
        <ul className={classes.listItem}>
          <ListSubheader className={classes.categoryHeader}><Typography variant="h4">{props.category.categoryName}</Typography></ListSubheader>
          {
            props.category.items.map((transferItem) =>
              <TransferItem key={transferItem.inventoryTransferID} transferItem={transferItem} />
            )
          }
        </ul>
      </li>
    )
  }

  const handleErrorClose = () => {
    actions.clearUpdate();
  }

  return (
    <div className={classes.list} >
      <UpdateError open={Boolean(updateError)} message={updateError && updateError.message} onClose={handleErrorClose} />
      <UpdateStatus open={updating} message="Updating..." />
      <Typography variant="h6" align="center" gutterBottom>Send Items to {props.storeName}</Typography>
      <div className={classes.categoryList} ref={scrollinfRef} onScroll={handleScroll}>
        <List>
          {manager.categories.map((category) => (
            <SubHeader category={category} key={category.categoryName} />
          ))}
        </List>
      </div>
    </div>
  )
}
