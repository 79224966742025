import React, { useState, useEffect } from "react";
import { PendingOrderItemUpdate } from "./StoreOrderingData";
import { OrderingWorkbookManager } from "./PreOrderingWorkbookManager";
import { TableCell, CircularProgress, TextField, Button } from "@material-ui/core";
import { updateItem, usePreOrderingWorkbook } from "./PreOrderingHandler";
import { useStyles } from "./styles";

// const Manager = OrderingWorkbookManager.Instance;

export interface ToOrderCellProps {
  storeID: number;
  categoryID: number;
  itemID: number;
  variationID: number | null;
  toOrder: number;
  focused: boolean;
  onNavDown: () => void;
  onNavUp: () => void;
  onNavLeft?: () => void;
  onNavRight?: () => void;
  onClick: () => void;
  onUpdated?: (newTotal: number) => void;
}

export function ToOrderCell(props: ToOrderCellProps) {
  const [{}, manager] = usePreOrderingWorkbook();
  const [isInput, setIsInput] = useState(false);
  const [displayed, setDisplayed] = useState("0");
  const [pending, setPending] = useState(false);
  const [count, setCount] = useState(props.toOrder);
  const classes = useStyles({});

  useEffect(() => {
    setIsInput(props.focused);
    setDisplayed(count.toString());
  }, [props.focused])

  useEffect(() => {
    setCount(props.toOrder)
  }, [props.storeID, props.categoryID]);

  const handleClick = event => {
    event.stopPropagation();
    setDisplayed(count.toString());
    props.onClick();
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "Enter":
      case "Tab":
      case "ArrowDown":
        event.preventDefault();
        props.onNavDown();
        onSubmit(Number(displayed));
        break;
      case "ArrowUp":
        event.preventDefault();
        props.onNavUp();
        onSubmit(Number(displayed));
        break;
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
      case "Backspace":
      case "Delete":
        break;
      case "ArrowRight":
        if (props.onNavRight) {
          event.preventDefault();
          props.onNavRight();
          onSubmit(Number(displayed));
        }
        break;
      case "ArrowLeft":
        if (props.onNavLeft) {
          event.preventDefault();
          props.onNavLeft();
          onSubmit(Number(displayed));
        }
        break;
      case "Escape":
        setIsInput(false);
        setDisplayed(props.toOrder.toString());
        event.preventDefault();
        break;
      default:
        event.preventDefault();
        break;
    }
  }

  const handleChange = (e) => {
    setDisplayed(e.currentTarget.value);
  }

  const handleBlur = (e) => {
    setIsInput(false);
    if (parseInt(displayed) != count) {
      onSubmit(parseInt(displayed));
    }
  }

  const handleFocus = (e) => {
    e.target.select();
  }

  const onSubmit = (newCount: number) => {
    if (newCount == count) {
      return;
    }
    setPending(true);
    const pendingItem = new PendingOrderItemUpdate();
    pendingItem.storeID = props.storeID;
    pendingItem.storeIDs = manager.getStoreIDs();
    pendingItem.categoryID = props.categoryID;
    pendingItem.itemID = props.itemID;
    pendingItem.variationID = props.variationID;
    pendingItem.pending = newCount;

    updateItem(pendingItem)
      .then((update: PendingOrderItemUpdate) => {
        manager.updatePending(update);
        setCount(update.pending);
        if (props.onUpdated) {
          props.onUpdated(update.pendingTotal);
        }
        setPending(false);
      })
      .catch((e) => {
        alert(e.message);
        setPending(false);
      })
  }

  const cellStyle = { maxWidth: 35 };
  const circleStyle = { maxWidth: 25 };

  return (
    <TableCell className={`${classes.tableCell} ${classes.pointerCell} ${classes.selectableCell}`} onClick={handleClick}>{
      pending
        ?
        <CircularProgress style={circleStyle} />
        :
        isInput
          ?
          <TextField
            style={cellStyle}
            type='text'
            autoFocus
            value={displayed}
            onKeyDown={handleKeyPress}
            onFocus={handleFocus}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          :
          count
          ?
          <span className={classes.nonZeroOrderCell}>{count}</span>
          :
          <span>{count}</span>
    }</TableCell>
  )
}
