import React, { useState } from 'react';
import { useStyles } from './styles';
import { IconButton, Typography, Select, MenuItem } from '@material-ui/core';
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import NextPageIcon from '@material-ui/icons/NavigateNext';
import PrevPageIcon from '@material-ui/icons/NavigateBefore';
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircle from '@material-ui/icons/AddCircleOutline';

export interface DataTableFooterProps {
  pageSize: number;
  currentOffset: number;
  transactionCount: number;
  showRefresh?: boolean;
  showAdd?: boolean;
  onPage: (offset: number, pageSize: number) => void;
  onRefresh?: () => void;
  onAdd?: () => void;
  additionalButtons?: Array<React.ReactNode>;
}

export function DataTableFooter(props: DataTableFooterProps) {
  const classes = useStyles({});
  const [pageSize, setPageSize] = useState(props.pageSize);

  const handlePageSizeChange = (event: React.ChangeEvent<{ name?: string; value: number }>) => {
    setPageSize(event.target.value)
    props.onPage(props.currentOffset, event.target.value);
  }

  const handlePageChange = (direction: string) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    let offset = props.currentOffset;
    switch (direction) {
      case "FIRST":
        offset = 0;
        break;
      case "LAST":
        if (props.transactionCount) {
          offset = props.transactionCount - pageSize;
          offset = offset < 0 ? 0 : offset;
        }
        break;
      case "CURRENT":
        break;
      case "NEXT":
        if (props.currentOffset + pageSize < props.transactionCount)
          offset = props.currentOffset + pageSize;
        break;
      case "PRIOR":
        offset = props.currentOffset - pageSize;
        offset = offset < 0 ? 0 : offset;
        break;
      default:
        offset = 0;
        break;
    }
    props.onPage(offset, pageSize);
  }

  const handleRefresh = () => {
    props.onRefresh && props.onRefresh();
  }

  const handleAdd = () => {
    props.onAdd && props.onAdd();
  }

  return (
    <div className={classes.footer}>
      {props.showRefresh
        ? <IconButton size="small" onClick={handleRefresh} className={classes.footerItem}>
          <RefreshIcon />
        </IconButton>
        : null
      }
      <IconButton size="small" className={classes.footerItem} onClick={handlePageChange("FIRST")}>
        <FirstPageIcon />
      </IconButton>
      <IconButton size="small" className={classes.footerItem} onClick={handlePageChange("PRIOR")}>
        <PrevPageIcon />
      </IconButton>
      <Typography variant="caption" className={classes.footerItem}>Page size:</Typography>
      <Select value={pageSize} IconComponent={ArrowDropUpIcon} onChange={handlePageSizeChange} className={`${classes.footerItem} ${classes.footerSelect}`}>
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={15}>15</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={25}>25</MenuItem>
      </Select>
      {props.transactionCount
        ? <>
          <Typography variant="caption" className={classes.footerItem}>Page</Typography>
          <Typography variant="caption" className={classes.footerItem}>{Math.ceil(props.currentOffset / props.pageSize) + 1}</Typography>
          <Typography variant="caption" className={classes.footerItem}>of</Typography>
          <Typography variant="caption" className={classes.footerItem}>{Math.ceil(props.transactionCount / props.pageSize)}</Typography>
        </>
        : <Typography variant="caption" className={classes.footerItem}>No data</Typography>
      }
      <IconButton size="small" className={classes.footerItem} onClick={handlePageChange("NEXT")}>
        <NextPageIcon />
      </IconButton>
      <IconButton size="small" className={classes.footerItem} onClick={handlePageChange("LAST")}>
        <LastPageIcon />
      </IconButton>
      {props.showAdd
        ? <IconButton size="small" onClick={handleAdd} className={classes.footerItem}>
          <AddCircle />
        </IconButton>
        : null
      }
      {props.additionalButtons}
    </div>
  )
}

