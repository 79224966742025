import React, { useMemo, useRef, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, InputAdornment, TextField, FormControl, InputLabel, Input, Theme, DialogActions, Button, Typography, IconButton, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { uploadSales } from './FileUploadHandler';
import { Store } from '../store/Store';
import { useStores } from '../store/StoreHandler';
import { Loading } from '../common/LoadingComponent';
import { Error } from '../common/ErrorComponent';
import { MuiPickersUtilsProvider, DatePicker, Day } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    marginTop: 24
  },
  dialogTitle: {
    backgroundColor: theme.palette.grey.A400,
  },
  dialogContents: {
    backgroundColor: theme.palette.background.paper,
  },
  gridCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  progress: {
    transpose: "scale(0.5)",
  },
}))

type FileBundle = { data: FormData, store: number, date: number };

interface ShowDialogProps {
  stores: Array<Store>;
  onClose: (e) => void;
}

function ShowDialog(props: ShowDialogProps) {
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [date, setDate] = useState<moment.Moment>(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);

  const classes = useStyles({});

  const handleSubmit = () => {
    setPending(true);
    setError(null);
    const fileBundles = new Array<FileBundle>();
    // const convertedDate = new Date(date);
    const millisDate = date.valueOf();//convertedDate.valueOf() + (convertedDate.getTimezoneOffset() * 60 * 1000);
    console.log(millisDate);
    files.forEach((file, index) => {
      if (file != null) {
        const data = new FormData();
        data.append('file', file)
        fileBundles.push({
          data: data,
          store: props.stores[index].storeID,
          date: millisDate
        })
      }
    });
    const promises = fileBundles.map(bundle => {
      return uploadSales(bundle.store, bundle.date, bundle.data);
    });

    return Promise.all(promises).then(() => {
      setPending(false);
      return;
    }).catch(e => {
      setError(e.message);
      setPending(false);
      return;
    });
  };

  const handleClose = (e) => {
    props.onClose(e);
  }

  const handleClear = (index: number) => (event) => {
    const newNames = [...fileNames];
    newNames[index] = null;
    const newFiles = [...files];
    newFiles[index] = null;
    setFileNames(newNames);
    setFiles(newFiles);
  }

  const handleChange = (index: number) => (event) => {
    const newNames = [...fileNames];
    newNames[index] = event.currentTarget.value.replace("C:\\fakepath\\", "")
    const newFiles = [...files];
    newFiles[index] = event.target.files[0];
    setFileNames(newNames);
    setFiles(newFiles);
  }

  const handleDateChange = (event) => {
    setDate(event.target.value);
  }

  const disableUpload = (): boolean => {
    return (date == null || date.valueOf() == 0 || date.year() < new Date().getFullYear() - 1 ||
      fileNames.every((name) => name == null));
  }

  const trimFilename = (filename: string): string => {
    if (filename == null || filename.length < 25)
      return filename;
    return "..." + filename.substr(filename.length - 22);
  }

  interface StoreEntryProps {
    store: Store;
    index: number;
  }

  const StoreEntry = (props: StoreEntryProps) => {
    return (
      <Grid container direction="row" spacing={1} alignItems="baseline">
        <Grid key={props.store.storeName} item xs={6}>
          {/* <Button 
          variant="contained" 
          fullWidth> */}
          <label htmlFor={"StoreID-" + props.store.storeID.toString()}>
            <Typography variant="h6" style={{ cursor: "pointer" }}>{`${props.store.storeName}`}</Typography>
          </label>
          {/* > */}
          {/* </Button> */}
          <input type="file" id={"StoreID-" + props.store.storeID.toString()}
            style={{ display: "none" }}
            onChange={handleChange(props.index)}
          />
        </Grid>
        <Grid item xs={6} className={classes.gridCell}>
          <Typography gutterBottom align='left' variant='body1'>
            {trimFilename(fileNames[props.index]) || 'No File'}
          </Typography>
          <IconButton onClick={handleClear(props.index)}>
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} >
      <div>
        <Dialog disableBackdropClick open onClose={props.onClose}>
          <DialogTitle className={classes.dialogTitle} disableTypography>
            <Typography align="center" variant="h6">
              Upload Sales
            </Typography>
            <Typography align="center" variant="body1" >
              Select a file for at least one store.
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.dialogContents}>
            <Grid container spacing={1}>
              {props.stores.map((store: Store, index) =>
                <StoreEntry key={store.storeID} store={store} index={index} />
              )}
              <Grid item xs={12} className={classes.gridCell}>
                <Typography variant='h6' gutterBottom align='left'>
                  Select Date:
                </Typography>
                <DatePicker
                  value={date}
                  onChange={setDate}
                  label="Week Ending (Sunday)"
                  InputLabelProps={{ shrink: true }}
                  autoOk
                  disableFuture
                  variant="dialog"
                  renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                    return <Day disabled={day.isoWeekday() != 7 || day > moment()} hidden={!isInCurrentMonth} children={day.format('D')} />;
                  }}
                />
                {/* <TextField onChange={handleDateChange} value={date} type='date' label='Week Ending (Sunday)' InputLabelProps={{ shrink: true }} /> */}
              </Grid>
            </Grid>
            {error ? <Typography color='error' align='center' variant='body1'>{error}</Typography> : null}
          </DialogContent>
          <DialogActions className={classes.dialogContents}>
            <Grid container>
              <Grid item xs={6}>
              </Grid>
              <Grid item xs={3}>
                <Button onClick={handleClose} color='primary'>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3}>
                {pending
                  ?
                  <CircularProgress className={classes.progress} />
                  :
                  <Button onClick={handleSubmit} disabled={disableUpload()} color='primary'>Upload</Button>
                }
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </MuiPickersUtilsProvider >
  )
}

export interface SalesDialogProps {
  open: boolean
  onClose: (e) => void;
}

export function SalesDialog(props: SalesDialogProps) {
  const [{ error, loading, success, stores }, actions] = useStores("Sales");

  useEffect(() => {
    if (!(error || loading || success))
      actions.getStores();
  }, [error, loading, success]);

  if (error) return <Error message={error.message} />
  if (loading) return <Loading />
  if (success) return <ShowDialog onClose={props.onClose} stores={stores} />
  if (stores && !stores.length) return <Error message="No stores found" />
  return null;
}

// const hardStores = [
//   {
//     storeID: 1,
//     storeName: "Sandy Springs"
//   },
//   {
//     storeID: 2,
//     storeName: "Alpharetta"
//   },
//   {
//     storeID: 3,
//     storeName: "John's Creek"
//   },
//   {
//     storeID: 4,
//     storeName: "Roswell"
//   },
//   {
//     storeID: 5,
//     storeName: "Lawrenceville"
//   },
//   {
//     storeID: 6,
//     storeName: "Dahlonega"
//   },
//   {
//     storeID: 7,
//     storeName: "Athens"
//   }
// ]
// {
//   <FormControl variant='outlined' className={classes.formControl}>
//               <InputLabel shrink>{store.storeName}</InputLabel>
//               <Input type='file' inputRef={files.current[index]}/>
//             </FormControl>
// }