export function trimDate(date: Date): Date {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

export function justDateFromWire(date: number | Date | string): Date {
  if (typeof date == "string") {
    if ((date as string).indexOf("-") > 0)
      return new Date((date as string).substr(0, 10) + "T00:00:00.000");
    date = Number(date);
  }
  const newDate = new Date(date);
  return trimDate(newDate);
}

export function isInvalidWireDate(value: string) {
  if (value.indexOf("-") > 0) {
    const date = new Date(value.substr(0, 10) + "T00:00:00.00");
    return isNaN(date.valueOf());
  }
  const intValue = parseInt(value);
  if (isNaN(intValue))
    return true;
  const date = new Date(intValue);
  return (isNaN(date.valueOf()))
}

