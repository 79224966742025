import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useUpdateCompany, useRemoveCompany } from './CompanyHandler';
import { LinearLoading } from '../common/LoadingComponent';
import { CompanyData } from '../wireData/CompanyData';
import { EditCompany } from './EditCompany';

interface StatusDialogProps {
  message: string;
  open: boolean;
}

function StatusDialog(props: StatusDialogProps) {

  return (
    <Dialog open={props.open} fullWidth maxWidth="xs">
      <DialogContent>
        <LinearLoading message={props.message} />
      </DialogContent>
    </Dialog>
  )
}

interface ErrorDialogProps {
  error: any;
  open: boolean;
  onClose: () => void;
}

function ErrorDialog(props: ErrorDialogProps) {

  return (
    <Dialog open={props.open && Boolean(props.error)}>
      <DialogTitle>
        Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.error ? props.error.message || props.error : "Unknown Error"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export interface EditCompanyContainerProps {
  company: CompanyData;
//  currentStore: Store;
  onUpdate: (company: CompanyData) => void;
  onRemove: (company: CompanyData) => void;
  onCancel: () => void;
}

export function EditCompanyContainer(props: EditCompanyContainerProps) {

  const [companyToUpdate, setCompanyToUpdate] = useState<null | CompanyData>(null);
  const [showErrors, setShowErrors] = useState(false);
  const [companyToRemove, setCompanyToRemove] = useState<null | CompanyData>(null);

  const { error: companyUpdateError, updating, company: updatedCompany } = useUpdateCompany(companyToUpdate);
  const { error: companyRemoveError, removing, company: removedCompany } = useRemoveCompany(companyToRemove);

  useEffect(() => {
    if (updatedCompany && !updating) {
      props.onUpdate(updatedCompany);
      setCompanyToUpdate(null);
    }
  }, [updatedCompany, updating])

  useEffect(() => {
    if (removedCompany && !removing) {
      props.onRemove(companyToRemove);
      setCompanyToRemove(null);
    }
  }, [removedCompany, removing])

  const handleUpdate = (company: CompanyData) => {
    setCompanyToUpdate(company);
    setShowErrors(true);
  }

  const handleCancel = () => {
    props.onCancel();
  }

  const handleRemove = () => {
    setCompanyToRemove(props.company);
    setShowErrors(true);
  }

  const handleCloseErrorDialog = () => {
    setShowErrors(false);
  }

  return (
    <div>
      <StatusDialog open={updating || removing} message={updating ? "Updating company":"removing Company"} />
      <ErrorDialog open={showErrors} error={companyUpdateError || companyRemoveError} onClose={handleCloseErrorDialog} />
      <EditCompany
        company={props.company}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
        onCancel={handleCancel} 
      />
    </div>
  )
}
