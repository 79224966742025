import React, { useState, useReducer, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, TextField, Theme, DialogActions, Button, Typography, IconButton, CircularProgress, List, ListItem, Input, Icon } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { usePreOrderingVendors, movePendingForVendor } from './PreOrderingHandler';
import { Loading } from '../common/LoadingComponent';
import { Vendor } from '../vendor/Vendor';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    marginTop: 24
  },
  gridCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  vendorListHeader: {
    width: "100%",
    padding: theme.spacing(2),
  },
  vendorList: {
    maxHeight: "50vh",
    minHeight: "25vh",
    flexBasis: 'auto',
    flex: '1 1',
    overflowX: "hidden",
    overflowY: "auto",
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionItem: {
    width: "100%",
    textAlign: "center",
  },
  rightIcon: {
    marginLeft: theme.spacing(3),
    transform: "scale(0.25)"
  },
}))

export interface GenerateOrdersFromPreOrdersProps {
  open: boolean
  onClose: (e) => void;
}

const initialState = {};

interface Action {
  vendorName: string;
  type: string;
}

const statusReducer = (state: Array<string>, action: Action): Array<string> => {
  return { ...state, [action.vendorName]: action.type };
}
const inputReducer = (state: Array<string>, action: Action): Array<string> => {
  return { ...state, [action.vendorName]: action.type };
}

export function GenerateOrdersFromPreOrdersDialog(props: GenerateOrdersFromPreOrdersProps) {
  const { error, loading, success, vendors } = usePreOrderingVendors();
  const [status, dispatchStatus] = useReducer(statusReducer, initialState);
  const [input, dispatchInput] = useReducer(inputReducer, initialState);

  const classes = useStyles({});

  const handleClose = (e) => {
    props.onClose(e);
  }

  const isProcessing = (vendor: Vendor): boolean => {
    return status[vendor.vendorName] && status[vendor.vendorName] == "processing";
  }

  const processVendor = (vendor: Vendor) => (event) => {
    dispatchStatus({ vendorName: vendor.vendorName, type: "processing" })

    //  console.log(status);
    movePendingForVendor(vendor.vendorID, input[vendor.vendorName])
      .then((result) => {
        disableVendor(vendor);
      })
      .catch((result) => {
        alert(result.message);
        dispatchStatus({ vendorName: vendor.vendorName, type: null })
      })
  }

  const isProcessed = (vendor: Vendor): boolean => {
    return status[vendor.vendorName] && status[vendor.vendorName] == "processed";
  }

  const disableVendor = (vendor: Vendor) => {
    // console.log("DisableVendor: ", vendor.vendorName);
    dispatchStatus({ vendorName: vendor.vendorName, type: "processed" })
  }

  const isButtonDisabled = (vendor: Vendor) => {
    return (status[vendor.vendorName] || !input[vendor.vendorName] || input[vendor.vendorName].trim().length < 5);
  }

  class ActionButtonProps {
    vendor: Vendor;
  }

  const ActionButton = (props: ActionButtonProps) => {
    if (isProcessing(props.vendor))
      return <div className={classes.actionItem}><Typography color='secondary' align='center' variant='button'>Processing...</Typography></div>

    if (isProcessed(props.vendor))
      return <div className={classes.actionItem}><Icon><CheckCircleIcon style={{ color: 'Chartreuse' }} /></Icon></div>

    return <Button fullWidth color="secondary" disabled={isButtonDisabled(props.vendor)} onClick={processVendor(props.vendor)}>Process</Button>
  }

  return (
    <Dialog disableBackdropClick open={props.open} onClose={props.onClose}>
      <DialogTitle disableTypography><Typography variant="h5" align="center">Generate Orders from Pre-Orders</Typography></DialogTitle>
      <DialogContent className={classes.content}>
        {/* <DialogContentText gutterBottom variant="h6"> */}
          <div className={classes.vendorListHeader}>
            <Grid container spacing={5}>
              <Grid className={classes.gridCell} item xs={6}>
                <Typography variant="h5">Vendor</Typography>
              </Grid>
              <Grid className={classes.gridCell} item xs={3}>
                <Typography align="center" variant="h5">Invoice</Typography>
              </Grid>
              <Grid className={classes.gridCell} item xs={3}>
                <Typography variant="h5">Action</Typography>
              </Grid>
            </Grid>
          </div>
        {/* </DialogContentText> */}
        {loading ? <Loading /> : null}
        {success ? (
          <div className={classes.vendorList}>
            {vendors.map((vendor, index) => (
              <Grid key={vendor.vendorID} container spacing={5}>
                <Grid className={classes.gridCell} item xs={6}>
                  <Typography variant="h6">{vendor.vendorName}</Typography>
                </Grid>
                <Grid className={classes.gridCell} item xs={3}>
                  <Input
                    defaultValue={input[vendor.vendorName]}
                    fullWidth
                    disabled={isProcessing(vendor) || isProcessed(vendor)}
                    inputProps={{maxLength:25}}
                    onChange={e => dispatchInput({ vendorName: vendor.vendorName, type: e.target.value })}
                  />
                </Grid>
                <Grid className={classes.gridCell} item xs={3}>
                  <ActionButton vendor={vendor} />
                </Grid>
              </Grid>
            ))}
          </div>
        ) : null}
        {error ? <Typography color='error' align='center' variant='body1'>{error}</Typography> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )

}

const hardVendors = [
  {
    vendorID: 1,
    vendorName: "Medusa",
    selected: false
  },
  {
    vendorID: 2,
    vendorName: "80V",
    selected: false
  },
  {
    vendorID: 3,
    vendorName: "Chain Vapes",
    selected: false
  },
  {
    vendorID: 4,
    vendorName: "Charlies Chalkdust",
    selected: false
  },
  {
    vendorID: 5,
    vendorName: "Coastal Clouds",
    selected: false
  },
  {
    vendorID: 6,
    vendorName: "Demand",
    selected: false
  },
  {
    vendorID: 7,
    vendorName: "Element",
    selected: false
  }
]
