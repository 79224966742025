import React, { memo, useState, useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import { Typography, Grid, TextField, Switch, CircularProgress, Table, TableCell, TableHead, TableRow, TableBody, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Theme, Icon, InputAdornment, FormControlLabel, Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Paper } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { numberToDollarString, bankersRound } from '../utils/Convert';
import { OrderHeader, OrderLineItem, OrderReconcilingUpdate, TotalsBreakdown, ItemCostUpdate } from './PurchaseOrderData';
import { PurchaseOrderStatus, updatePurchaseOrderAdjustments, updatePurchaseOrderDiscount, updatePurchaseOrderShipping, updateItemCost, resetClosedPurchaseOrderStore, useClosedPurchaseOrderWorkbook } from './PurchaseOrderHandler';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { Store } from '../store/Store';
import { DataTableFooter } from '../common/DataTableFooter';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  tableRoot: {
    flex: 1,
    overflowY: 'auto',
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    // overflowY: 'scroll',
    // overflowX: 'hidden',
    // height: '100%',
    // boxSizing: 'border-box',
  },
  lineItemContainer: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxSizing: 'border-box',
  },
  summaryRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  summaryText: {
    flexBasis: '25%',
    flexGrow: 0,
    flexShrink: 0,
    whiteSpace: 'normal',
    marginRight: 10,
  },
  summaryDate: {
    flexBasis: '25%',
    flexGrow: 0,
  },
  summaryInvoice: {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  summaryInvoiceLabel: {
    marginRight: 20,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary[700],
    }
  },
  checkIcon: {
    marginRight: 10,
  },
  switchContainer: {
    position: 'sticky',
    top: 0,
    height: 40,
    width: '100%',
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    background: theme.palette.primary[900],
    zIndex: 10,
  },
  tableCell: {
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    fontSize: 16,
  },
  lighterCell: {
    background: theme.palette.background.paper,
  },
  darkerCell: {
    background: theme.palette.background.default,
  },
  totalCell: {
    background: 'black'
  },
  headerCell: {
    position: 'sticky',
    top: 40,
    background: theme.palette.primary[800],
  },
  headerRow: {
    height: 30,
  },
  detailRoot: {
    // maxHeight: 700
  },
  breakdownTableBody: {
    // position: 'sticky',
    // bottom: 0,
    maxHeight: 54 * 6,
    background: theme.palette.background.paper,
  },
  orderTableBody: {
    // maxHeight: 500,
    // overflowY: 'scroll',
  },
  collapsedTable: {
    display: 'none'
  },
  statusButtonsRoot: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
type GridSize = boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export function resetPurchaseOrderHistoryComponent() {
  resetClosedPurchaseOrderStore();
}

export function PurchaseOrderHistoryComponent() {
  const classes = useStyles({});
  const [{
    error,
    loading,
    success,
    countError,
    countLoading,
    countSuccess,
    orderCount,
    currentOffset,
    pageSize,
    scrolling,
  }, manager, purchaseOrderActions] = useClosedPurchaseOrderWorkbook();

  useEffect(() => {
    if (!(error || loading || success)) {
      purchaseOrderActions.updateState({
        pagingFilters: {skip: currentOffset, take: pageSize}
      });
      purchaseOrderActions.getOrders();
    }
  }, [error, loading, success]);

  useEffect(() => {
    if (!(countError || countLoading || countSuccess)) {
      purchaseOrderActions.updateState({
        pagingFilters: {skip: currentOffset, take: pageSize}
      });
      purchaseOrderActions.getOrderCount();
    }
  }, [countError, countLoading, countSuccess]);

  const StatusOrOrders = () => {
    if (error) return <Error message={error.message || error} />
    if (loading) return <Loading />
    if (success) return <OrderList />
    return null;
  }

  const handlePageChange = (offset: number, pageSize: number) => {
    purchaseOrderActions.updateState({
      success: false,
      pageSize: pageSize,
      currentOffset: offset,
      pagingFilters: {skip: offset, take: pageSize}
    });
  }

  return (
    <div className={classes.root}>
      <StatusOrOrders />
      <DataTableFooter
        pageSize={pageSize}
        currentOffset={currentOffset}
        transactionCount={orderCount}
        onPage={handlePageChange}
      />
    </div>
  )
}

interface ExpandedItem {
  id: number;
  expandedLines: Array<number>;
}

const OrderList = memo(() => {
  const classes = useStyles({});
  const [{ expandedItems, scrolling }, manager] = useClosedPurchaseOrderWorkbook();
  const scrollingRef = React.useRef(null);

  useLayoutEffect(() => {
    if (scrollingRef.current)
      scrollingRef.current.scrollTop = scrolling.top;
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrolling.top = event.currentTarget.scrollTop;
  }


  return (
    <div className={classes.tableRoot} ref={scrollingRef} onScroll={handleScroll}>
    <Paper className={classes.paper}>
      {manager.orders.map(order => <OrderExpander expandedItems={expandedItems} key={order.orderID} order={order} />)}
    </Paper>
    </div>
  )
})

interface OrderExpanderProps {
  order: OrderHeader;
  expandedItems: Array<ExpandedItem>;
}
const OrderExpander = memo((props: OrderExpanderProps) => {
  const classes = useStyles({});
  const date = useMemo(() => new Date(props.order.orderDate).toDateString(), [props.order])
  const [expanded, setExpanded] = useState(props.expandedItems.find((expandedItem) => expandedItem.id == props.order.orderID));
  const hasExpanded = useRef(false);
  // const [orderClosed, setOrderClosed] = useState(props.order.orderStatus != 'open');
  // const [showEditInvoiceNumberDialog, setShowEditInvoiceNumberDialog] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(null);

  useEffect(() => {
    setInvoiceNumber(props.order.invoiceNumber)
  }, [props.order.invoiceNumber])

  const handleChange = (event, isExpanded) => {
    if (isExpanded) {
      const item: ExpandedItem = { id: props.order.orderID, expandedLines: [] };
      setExpanded(item);
      props.expandedItems.push(item);
      hasExpanded.current = true;
    }
    else {
      setExpanded(null);
      const index = props.expandedItems.findIndex((item) => item.id == props.order.orderID);
      if (index >= 0)
        props.expandedItems.splice(index, 1);
    }
  }

  // const handleStatusChange = (newStatus: string) => {
  //   setOrderClosed(newStatus != 'open');
  //   if (newStatus == 'closed') {
  //     setExpanded(null);
  //   }
  // }

  // const handleInvoiceNumberClick = (event: React.MouseEvent) => {
  //   event.stopPropagation();
  //   setShowEditInvoiceNumberDialog(Boolean(expanded));
  // }

  const getCheckColor = (): string => {
    switch (props.order.orderStatus) {
      case 'pending':
        return 'red';
      case 'open':
        return 'lightgray';
      case 'closed':
        return 'chartreuse';
    }
  }

  // const EditInvoiceNumberDialog = () => {
  //   const [displayed, setDisplayed] = useState(null)

  //   useEffect(() => {
  //     setDisplayed(props.order.invoiceNumber)
  //   }, [props.order.invoiceNumber])

  //   const handleInvoiceNumberChange = (event) => {
  //     setDisplayed(event.target.value);
  //   }

  //   const handleChangeInvoiceNumber = async (event: React.MouseEvent) => {
  //     event.stopPropagation();
  //     try {
  //       const invoiceNumber = await updateInvoiceNumber(props.order.orderID, displayed);
  //       props.order.invoiceNumber = invoiceNumber;
  //       setInvoiceNumber(invoiceNumber);
  //       setShowEditInvoiceNumberDialog(false);
  //     }
  //     catch (e) {
  //       alert(e.message);
  //       setShowEditInvoiceNumberDialog(false);
  //     }
  //   }

  //   const handleCancelInvoiceNumber = (event: React.MouseEvent) => {
  //     event.stopPropagation();
  //     setShowEditInvoiceNumberDialog(false);
  //   }

  //   return (
  //     <Dialog open={true}
  //       disableBackdropClick
  //       onClick={(event) => { event.stopPropagation() }}
  //     >
  //       <DialogTitle>
  //         <Typography variant="h6" align="center">Change Invoice Number</Typography>
  //       </DialogTitle>
  //       <DialogContent>
  //         <TextField
  //           autoFocus
  //           label="Invoice"
  //           InputLabelProps={{ shrink: true }}
  //           value={displayed}
  //           onChange={handleInvoiceNumberChange}
  //         />
  //       </DialogContent>
  //       <DialogActions>
  //         <Button color="secondary" onClick={handleChangeInvoiceNumber}>update</Button>
  //         <Button color="secondary" onClick={handleCancelInvoiceNumber}>cancel</Button>
  //       </DialogActions>
  //     </Dialog>
  //   )
  // }

  return (
    <ExpansionPanel onChange={handleChange} expanded={Boolean(expanded)}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.summaryRoot}>
          <div className={classes.checkIcon}>
            <Icon><CheckCircleIcon style={{ color: getCheckColor() }} /></Icon>
          </div>
          <Typography className={classes.summaryText} variant='h6'>{props.order.vendorName}</Typography>
          <div className={classes.summaryInvoice} >
            {/* {
              showEditInvoiceNumberDialog
                ? <EditInvoiceNumberDialog />
                : null
            } */}
            <Typography className={classes.summaryInvoiceLabel} variant='body2' >
              Invoice  </Typography><Typography variant='h6'>{invoiceNumber}</Typography>
          </div>
          <Typography className={classes.summaryDate} variant='body1'>{date}</Typography>
        </div>
      </ExpansionPanelSummary>
      {Boolean(expanded)
        ?
        <ExpansionPanelDetails>
          <div className={classes.lineItemContainer}>
            <OrderDetailView order={props.order} />
          </div>
        </ExpansionPanelDetails>
        :
        null
      }
    </ExpansionPanel>
  )
});

// interface RejectDialogProps {
//   open: boolean;
//   onCancel: () => void;
//   onConfirmReject: (rejectMessage: string) => void;
// }
// const RejectDialog = (props: RejectDialogProps) => {
//   const [rejectMessage, setRejectMessage] = useState('');

//   const handleChange = (event) => {
//     setRejectMessage(event.target.value);
//   }
//   const handleCancel = () => {
//     props.onCancel();
//   }
//   const handleConfirm = () => {
//     props.onConfirmReject(rejectMessage);
//   }

//   return <Dialog open={props.open}>
//     <DialogTitle>Reject Order?</DialogTitle>
//     <DialogContent>
//       <DialogContentText>
//         Please enter a reason for this rejection.
//       </DialogContentText>
//       <TextField
//         multiline
//         fullWidth
//         variant='outlined'
//         inputProps={{ maxLength: 1500 }}
//         value={rejectMessage}
//         onChange={handleChange} />
//     </DialogContent>
//     <DialogActions>
//       <Button onClick={handleCancel} variant='text'>Cancel</Button>
//       <Button onClick={handleConfirm} variant='text'>Confirm</Button>
//     </DialogActions>
//   </Dialog>
// }

interface OrderDetailViewProps {
  order: OrderHeader
  // onStatusChange: (newStatus: string) => void;
}

const OrderDetailView = (props: OrderDetailViewProps) => {
  const [{ }, manager] = useClosedPurchaseOrderWorkbook();
  const shippingBreakdown = useMemo(() => manager.generateStoreCostBreakdown(props.order.orderID, props.order.orderShipping), [props.order.orderID])
  const adjustmentsBreakdown = useMemo(() => manager.generateStoreCostBreakdown(props.order.orderID, props.order.orderAdjustment), [props.order.orderID])
  const discountBreakdown = useMemo(() => manager.generateStoreCostBreakdown(props.order.orderID, props.order.orderDiscount), [props.order.orderID])
  const hardwareSubtotalsBreakdown = useMemo(() => manager.generateSubtotalBreakdown(props.order.orderID, 'hardware'), [props.order.orderID])
  const liquidSubtotalsBreakdown = useMemo(() => manager.generateSubtotalBreakdown(props.order.orderID, 'liquid'), [props.order.orderID])

  const [shipping, setShipping] = useState(shippingBreakdown);
  const [adjustments, setAdjustments] = useState(adjustmentsBreakdown);
  const [discount, setDiscount] = useState(discountBreakdown);
  const [hardware, setHardware] = useState(hardwareSubtotalsBreakdown);
  const [liquid, setLiquid] = useState(liquidSubtotalsBreakdown);
  const [totals, setTotals] = useState<TotalsBreakdown>([{ store: 'none', total: 0 }])
  const [showCounts, setShowCounts] = useState(false);
  const [lineItemsExpanded, setLineItemsExpanded] = useState(false);
  const classes = useStyles({});
  useEffect(() => {
    if (manager.stores.length > 0) {
      setTotals(manager.stores.map(store => {
        let subTotal = manager.getTotalOrderCostForStore(store, props.order);
        const ship = shipping.find(breakdown => breakdown.store == store.storeName);
        const adjust = adjustments.find(breakdown => breakdown.store == store.storeName);
        const disc = discount.find(breakdown => breakdown.store == store.storeName);
        if (ship) subTotal += ship.total;
        if (adjust) subTotal += adjust.total;
        if (disc) subTotal += disc.total;
        return { store: store.storeName, total: subTotal };
      })
      )
    }
  }, [shipping, adjustments, discount]);

  const handleShippingChange = (newShipping: number) => {
    setShipping(manager.generateStoreCostBreakdown(props.order.orderID, newShipping))
  }
  const handleAdjustmentChange = (newAdjustment: number) => {
    setAdjustments(manager.generateStoreCostBreakdown(props.order.orderID, newAdjustment))
  }
  const handleDiscountChange = (newDiscount: number) => {
    setDiscount(manager.generateStoreCostBreakdown(props.order.orderID, -newDiscount))
  }

  const handleItemCostUpdate = (newCost: number) => {
    setHardware(manager.generateSubtotalBreakdown(props.order.orderID, 'hardware'));
    setLiquid(manager.generateSubtotalBreakdown(props.order.orderID, 'liquid'));
    setShipping(manager.generateStoreCostBreakdown(props.order.orderID, props.order.orderShipping));
    setAdjustments(manager.generateStoreCostBreakdown(props.order.orderID, props.order.orderAdjustment));
    setDiscount(manager.generateStoreCostBreakdown(props.order.orderID, -props.order.orderDiscount));
  }

  // const handleStatusChange = (newStatus: string) => {
  //   props.onStatusChange(newStatus);
  // }

  const handleCountSwitch = (event, checked) => {
    setShowCounts(checked);
  }
  const handleExpandSwitch = (event, checked) => {
    setLineItemsExpanded(checked);
  }

  return (
    <div className={classes.detailRoot}>
      <Grid container justify='center' spacing={8}>
        <GridItemLabel size={2} label='PO #' display={props.order.orderID} />
        <ShippingInput size={2} order={props.order} onShippingChanged={handleShippingChange} />
        <AdjustmentInput size={2} order={props.order} onAdjustmentChanged={handleAdjustmentChange} />
        <DiscountInput size={2} order={props.order} onDiscountChanged={handleDiscountChange} />
        <GridItemLabel size={2} label='Purchase Order Total' display={numberToDollarString(props.order.orderTotal)} />
        <Grid item xs={12}>
          <div className={classes.switchContainer}>
            <FormControlLabel control={
              <Switch checked={lineItemsExpanded} onChange={handleExpandSwitch} />
            } label="Show Line Items" />
            <FormControlLabel control={
              <Switch checked={showCounts} onChange={handleCountSwitch} />
            } label="Show Count Quantities" />
          </div>
          <div className={lineItemsExpanded ? classes.orderTableBody : classes.collapsedTable}>
            <OrderTable onItemCostUpdate={handleItemCostUpdate} order={props.order} showCounts={showCounts} />
          </div>
          <div className={classes.breakdownTableBody}>
            <Table>
              <TableHead>
                <TableRow className={classes.headerRow}>
                  <TableCell colSpan={2} className={`${classes.tableCell} ${classes.headerCell}`}>Subtotals</TableCell>
                  {manager.stores.map(store => <TableCell key={store.shortName} className={`${classes.tableCell} ${classes.headerCell}`}>{store.shortName}</TableCell>)}
                  <TableCell colSpan={2} className={`${classes.tableCell} ${classes.headerCell}`}>Totals</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TotalsBreakdownRow rowName='Hardware Subtotal' breakdown={hardware} />
                <TotalsBreakdownRow rowName='Liquid Subtotal' breakdown={liquid} />
                <TotalsBreakdownRow rowName='Shipping' breakdown={shipping} />
                <TotalsBreakdownRow rowName='Adjustment' breakdown={adjustments} />
                <TotalsBreakdownRow rowName='Discount' breakdown={discount} />
                <TotalsBreakdownRow rowName='Total' breakdown={totals} />
              </TableBody>
            </Table>
          </div>
        </Grid>
        {/* <Grid item xs={12}>
          <StatusButtons onStatusChanged={handleStatusChange} order={props.order} />
        </Grid> */}
      </Grid>
    </div>
  )
};

interface StatusButtonsProps {
  order: OrderHeader
  onStatusChanged: (newStatus: string) => void;
}
// const StatusButtons = (props: StatusButtonsProps) => {
//   const classes = useStyles({});
//   const [{ }, manager] = useClosedPurchaseOrderWorkbook();
//   const [status, setStatus] = useState(props.order.orderStatus);
//   const [pending, setPending] = useState(false);
//   const [showConfirmDialog, setShowConfirmDialog] = useState(false);

//   const handleDialogConfirm = (rejectMessage: string) => {

//     //Attach RejectMessage to URL or update

//     setShowConfirmDialog(false);
//     setPending(true);
//     const update = new OrderReconcilingUpdate();
//     update.orderID = props.order.orderID;
//     update.orderStatus = props.order.orderStatus;
//     update.comment = rejectMessage;
//     downgradePurchaseOrderStatus(update).then((confirm: OrderReconcilingUpdate) => {
//       manager.updateOrder(confirm);
//       setStatus(confirm.orderStatus);
//       setPending(false);
//       props.onStatusChanged(confirm.orderStatus);
//     }).catch(e => {
//       setPending(false);
//       alert(e.message || e);
//     })
//   }
//   const handleDialogCancel = () => {
//     setShowConfirmDialog(false);
//   }

//   const handleUpgradeClick = () => {
//     setPending(true);
//     const update = new OrderReconcilingUpdate();
//     update.orderID = props.order.orderID;
//     update.orderStatus = props.order.orderStatus;
//     updatePurchaseOrderStatus(update).then((confirm: OrderReconcilingUpdate) => {
//       manager.updateOrder(confirm);
//       setStatus(confirm.orderStatus);
//       setPending(false);
//       props.onStatusChanged(confirm.orderStatus);
//     }).catch(e => {
//       setPending(false);
//       alert(e.message || e);
//     });
//   }
//   const handleDowngradeClick = () => {
//     setShowConfirmDialog(true);
//   }

//   return (
//     <div className={classes.statusButtonsRoot}>
//       {showConfirmDialog
//         ?
//         <RejectDialog
//           onCancel={handleDialogCancel}
//           onConfirmReject={handleDialogConfirm}
//           open={showConfirmDialog}
//         />
//         :
//         null
//       }
//       {pending
//         ?
//         <CircularProgress />
//         :
//         <>
//           <Button variant='contained' color='default'
//             disabled={status == 'closed'}
//             onClick={status == 'open' ? handleDowngradeClick : handleUpgradeClick}
//           >
//             {status != 'pending' ? 'Reject Order' : 'Undo Reject'}
//           </Button>
//           <Button variant='contained' color='secondary'
//             disabled={status == 'pending'}
//             onClick={status == 'open' ? handleUpgradeClick : handleDowngradeClick}
//           >
//             {status != 'closed' ? 'Close Order' : 'Undo Close'}
//           </Button>
//         </>
//       }
//     </div>
//   )
// }

interface TotalsBreakdownRowProps {
  breakdown: TotalsBreakdown;
  rowName: string;
}
const TotalsBreakdownRow = memo((props: TotalsBreakdownRowProps) => {
  const classes = useStyles({});
  const [{ }, manager] = useClosedPurchaseOrderWorkbook();
  const breakdownTotal = manager.getBreakdownTotal(props.breakdown);//useMemo(() => manager.getBreakdownTotal(props.breakdown), [props.breakdown])
  return <TableRow>
    <TableCell className={classes.tableCell} colSpan={2}>{props.rowName}</TableCell>
    {manager.stores.map((store: Store, index: number) => {
      const total = props.breakdown.find(total => total.store == store.storeName);
      return <TableCell key={`${store.storeID}`} className={`${classes.tableCell} ${index % 2 == 0 ? classes.darkerCell : classes.lighterCell}`}>{numberToDollarString(total ? total.total : 0)}</TableCell>
    })}
    <TableCell className={`${classes.tableCell} ${classes.totalCell}`} colSpan={2}>{numberToDollarString(breakdownTotal)}</TableCell>
  </TableRow>
}, (prev, next) => (prev.breakdown == next.breakdown));

interface GridItemLabelProps {
  label: string;
  display: number | string;
  size: GridSize
}
const GridItemLabel = memo((props: GridItemLabelProps) => {
  return (
    <Grid item xs={props.size}>
      <div>
        <Typography align='left' variant='caption'>{props.label}</Typography>
        <Typography align='left' variant='body1'>{props.display.toString()}</Typography>
      </div>
    </Grid>
  )
}, (prev, next) => (prev.display == next.display));

interface ShippingInputProps {
  order: OrderHeader;
  onShippingChanged: (newShipping: number) => void;
  size: GridSize
}
const ShippingInput = (props: ShippingInputProps) => {
  const [pending, setPending] = useState(false);
  const [{ }, manager] = useClosedPurchaseOrderWorkbook();

  const handleSubmit = (shippingValue: number) => {
    setPending(true);
    const update = new OrderReconcilingUpdate();
    update.orderID = props.order.orderID;
    update.orderStatus = props.order.orderStatus;
    update.orderShipping = shippingValue;
    updatePurchaseOrderShipping(update).then((order: OrderReconcilingUpdate) => {
      manager.updateOrder(order);
      props.onShippingChanged(order.orderShipping)
      setPending(false);
    }).catch(e => {
      setPending(false);
      alert(e.message || e)
    });
  }

  return (
    <GridItemDollarEditable
      label='Shipping'
      display={props.order.orderShipping}
      pending={pending}
      onSubmit={handleSubmit}
      size={props.size}
    />
  )
}

interface AdjustmentInputProps {
  order: OrderHeader;
  onAdjustmentChanged: (newAdjustment: number) => void;
  size: GridSize;
}
const AdjustmentInput = (props: AdjustmentInputProps) => {
  const [pending, setPending] = useState(false);
  const [{ }, manager] = useClosedPurchaseOrderWorkbook();

  const handleSubmit = (adjustmentValue: number) => {
    setPending(true);
    const update = new OrderReconcilingUpdate();
    update.orderID = props.order.orderID;
    update.orderStatus = props.order.orderStatus;
    update.orderAdjustment = adjustmentValue;
    updatePurchaseOrderAdjustments(update).then((order: OrderReconcilingUpdate) => {
      manager.updateOrder(order);
      props.onAdjustmentChanged(order.orderAdjustment);
      setPending(false);
    }).catch(e => {
      setPending(false);
      alert(e.message || e);
    })
  }

  return (
    <GridItemDollarEditable
      negativeable
      label='Adjustments'
      display={props.order.orderAdjustment}
      pending={pending}
      onSubmit={handleSubmit}
      size={props.size}
    />
  )
}

interface DiscountInputProps {
  order: OrderHeader;
  onDiscountChanged: (newDiscount: number) => void;
  size: GridSize;
}
const DiscountInput = (props: DiscountInputProps) => {
  const [pending, setPending] = useState(false);
  const [{ }, manager] = useClosedPurchaseOrderWorkbook();
  const handleSubmit = (discountValue: number) => {
    setPending(true);
    const update = new OrderReconcilingUpdate()
    update.orderID = props.order.orderID;
    update.orderStatus = props.order.orderStatus;
    update.orderDiscount = discountValue;
    updatePurchaseOrderDiscount(update).then((order: OrderReconcilingUpdate) => {
      manager.updateOrder(order);
      props.onDiscountChanged(order.orderDiscount);
      setPending(false);
    }).catch(e => {
      setPending(false);
      alert(e.message || e);
    })
  }
  return (
    <GridItemDollarEditable
      label='Discounts'
      display={props.order.orderDiscount}
      pending={pending}
      onSubmit={handleSubmit}
      size={props.size}
    />
  )
}

interface GridItemDollarEditableProps {
  label: string;
  display: number;
  pending: boolean;
  onSubmit: (value: number) => void;
  size: GridSize;
  negativeable?: boolean
}
const GridItemDollarEditable = (props: GridItemDollarEditableProps) => {
  const [displayed, setDisplayed] = useState(props.display.toString())
  const changed = useRef(false);

  const handleChange = (event) => {
    changed.current = true;
    setDisplayed(event.target.value);
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "Enter":
        submit();
      case "0": case "1": case "2": case "3": case "4": case "5": case "6": case "7": case "8": case "9":
      case "Backspace":
      case "Delete":
      case "ArrowLeft":
      case "ArrowRight":
        break;
      case "-":
        if (!props.negativeable && displayed.indexOf('-') >= 0)
          event.preventDefault();
        break;
      case ".":
        if (displayed.length && displayed.indexOf(".") >= 0)
          event.preventDefault();
        break;
      default:
        event.preventDefault();
        break;
    }
  }

  function submit() {
    if (changed.current == true) {
      const n = parseFloat(displayed)
      if (!isNaN(n)) {
        props.onSubmit(n)
        changed.current = false;
      }
    }
  }

  const handleBlur = (event) => {
    submit();
    changed.current = false;
  }
  const handleFocus = (event) => {
    event.currentTarget.select();
  }

  return (
    <Grid item xs={props.size}>
      {props.pending
        ?
        <CircularProgress />
        :
        <TextField
          fullWidth
          label={props.label}
          InputLabelProps={{ shrink: true }}
          InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
          value={displayed}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      }
    </Grid>
  )
}

interface OrderTableProps {
  order: OrderHeader
  showCounts: boolean;
  onItemCostUpdate: (newCost: number) => void;
}
const OrderTable = (props: OrderTableProps) => {
  const [{ }, manager] = useClosedPurchaseOrderWorkbook();
  const lineItemArray = useMemo(() => manager.sortItemsFromHeader(props.order.orderID), [props.order.orderID])
  const classes = useStyles({});

  const handleCostUpdate = (newCost: number) => props.onItemCostUpdate(newCost);

  return (
    <Table>
      <TableHead>
        <TableRow className={classes.headerRow}>
          <TableCell className={`${classes.tableCell} ${classes.headerCell}`}>Item</TableCell>
          <TableCell className={`${classes.tableCell} ${classes.headerCell}`}>Variation</TableCell>
          {manager.stores.map(store => <TableCell key={store.storeName} className={`${classes.tableCell} ${classes.headerCell}`}>{store.shortName}</TableCell>)}
          <TableCell className={`${classes.tableCell} ${classes.headerCell}`}>Cost</TableCell>
          <TableCell className={`${classes.tableCell} ${classes.headerCell}`}>Subtotal</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.orderTableBody}>
        {lineItemArray.map((items, index) => <OrderTableRow onCostUpdate={handleCostUpdate} key={index} items={items} stores={manager.stores} showCounts={props.showCounts} />)}
      </TableBody>
    </Table>
  )
}

interface OrderTableRowProps {
  items: Array<OrderLineItem>;
  stores: Array<Store>;
  showCounts: boolean;
  onCostUpdate: (newCost: number) => void;
}
const OrderTableRow = (props: OrderTableRowProps) => {
  const classes = useStyles({});
  const [cost, setCost] = useState(props.items[0].itemCost);
  let totalQuantity = 0;

  const handleCostUpdate = (newCost: number) => {
    setCost(newCost);
    props.onCostUpdate(newCost);
  }

  return <TableRow>
    <TableCell className={classes.tableCell}>{props.items[0].itemName}</TableCell>
    <TableCell className={classes.tableCell}>{props.items[0].variationDescription}</TableCell>
    {props.stores.map(store => {
      const lineItem = props.items.find(item => item.storeID == store.storeID);
      if (lineItem) totalQuantity += lineItem.quantityReceived;
      return <TableCell
        key={store.storeName + store.storeID}
        className={classes.tableCell}>
        {lineItem ? (props.showCounts ? lineItem.quantityReceived : numberToDollarString(lineItem.receivedTotal)) : 0}</TableCell>;
    })}
    <CostCell onCostUpdated={handleCostUpdate} item={props.items[0]} />
    <TableCell className={classes.tableCell}>{props.showCounts ? totalQuantity : numberToDollarString(props.items[0].itemCost * totalQuantity)}</TableCell>
  </TableRow>;
}

interface CostCellProps {
  item: OrderLineItem;
  onCostUpdated: (newCost: number) => void;
}
const CostCell = (props: CostCellProps) => {
  const classes = useStyles({});
  const [{ }, manager] = useClosedPurchaseOrderWorkbook();
  const [isInput, setIsInput] = useState(false);
  const [displayed, setDisplayed] = useState(numberToDollarString(props.item.itemCost));
  const [pending, setPending] = useState(false);

  useEffect(() => {
    setDisplayed(numberToDollarString(props.item.itemCost));
  }, [props.item.itemCost])

  const handleClick = (e) => setIsInput(true);
  const handleFocus = (e) => e.target.select();
  const handleChange = (e) => setDisplayed(e.currentTarget.value);

  const handleBlur = (e) => {
    setIsInput(false);
    if (Number(displayed) != props.item.itemCost)
      submitChange();
    else
      setDisplayed(numberToDollarString(props.item.itemCost));
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const editKeys = ["ArrowLeft", "ArrowRight", "Backspace", "Delete"];

    if (event.key == "Enter") {
      setIsInput(false)
      submitChange();
    }
    else if ("0123456789.".indexOf(event.key) < 0 && editKeys.indexOf(event.key) < 0) {
      event.preventDefault();
    }
  }

  const submitChange = () => {
    const newPrice = parseFloat(displayed);
    if (isNaN(newPrice)) {
      setDisplayed(numberToDollarString(props.item.itemCost));
      return;
    }
    const update = new ItemCostUpdate();
    update.orderID = props.item.orderID;
    update.categoryID = props.item.categoryID;
    update.itemID = props.item.itemID;
    update.variationID = props.item.variationID;
    update.itemCost = bankersRound(newPrice, 2);
    updateItemCost(update).then((confirmation: ItemCostUpdate) => {
      manager.applyCostUpdate(confirmation)
      props.onCostUpdated(update.itemCost)
      setDisplayed(numberToDollarString(update.itemCost));
      setPending(false);
    }).catch(e => {
      alert(e.message || e);
      setPending(false);
    });
  }

  return (
    <TableCell onClick={handleClick} className={classes.tableCell}>{
      pending
        ?
        <CircularProgress style={{ maxWidth: 25 }} />
        :
        isInput
          ?
          <TextField
            style={{ maxWidth: 40 }}
            type='text'
            autoFocus
            value={displayed}
            onFocus={handleFocus}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyPress}
          />
          :
          <span>{displayed}</span>
    }</TableCell>
  )
}