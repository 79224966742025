import React, {useState} from 'react';
import { useStyles } from "./styles";
import { Moment } from "moment";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Grid, Button } from '@material-ui/core';
import { inventoryTransferForDateRangeStore } from './InventoryTransferHandler';
import { useField } from '../common/useField';

export interface InventoryTransferSummaryHeaderProps {
  refresh: () => void;
}

export const InventoryTransferSummaryHeader = (props: InventoryTransferSummaryHeaderProps) => {
  const classes = useStyles({});

  const dataStore = inventoryTransferForDateRangeStore();
  const [startDate, setStartDate] = useField(dataStore, 'startDate');
  const [endDate, setEndDate] = useField(dataStore, 'endDate');

  const handleRefresh = () => {
    props.refresh();
  }

  return (
    <div className={classes.header}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container direction="row" className={classes.totalTextContainer}>
          <Grid item xs={12} sm={5} md={3}>
            <DatePicker
              value={startDate}
              onChange={setStartDate}
              label="Start date"
              autoOk
              format="MM/DD/YYYY" />
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <DatePicker
              value={endDate}
              onChange={setEndDate}
              label="End date"
              autoOk
              format="MM/DD/YYYY" />
          </Grid>
          <Grid item xs={12} sm={2} md={1}>
            <Button variant="contained" fullWidth color="primary" onClick={handleRefresh}>Refresh</Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  )
}
