import React, {  useEffect } from 'react';
import { Paper, Typography, Grid, Button } from '@material-ui/core';
import { useRegisters, getRegisterEditStore, resetRegisterStore } from './registerHandler';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { useStores } from '../store/StoreHandler';
import { Store } from '../store/Store';
import { RegisterData } from '../wireData/RegisterData';
import { DataGridList } from '../common/DataGridList';
import { useStyles } from './styles';
import { RegisterList } from './RegisterList';
import { EditRegisterContainer } from './EditRegisterContainer';

export const resetRegisterManager = () =>{
  resetRegisterStore ('Register');
}

class RegisterManagerProps {
  onClose: () => void;
}

export function RegisterManager(props: RegisterManagerProps) {
  const classes = useStyles({});
  const [{ error: storeError, loading: storeLoading, success: storeSuccess, stores }, storeActions] = useStores('Register');
  const [{ error: registersError, loading: registersLoading, success: registersSuccess, currentStore, currentRegister, registers }, registereActions] = useRegisters('Register');

  useEffect(() => {
    if (!(storeError || storeLoading || storeSuccess))
      storeActions.getStores();

  }, [storeError, storeLoading, storeSuccess]);

  const handleSelectStore = (store: Store) => {
    registereActions.setCurrentStore(store);
    registereActions.getRegistersForStore(store.storeID);
  }

  const handleRegisterListClose = () => {
    registereActions.setCurrentStore(null);
  }

  const handleEditRegisterClose = () => {
    registereActions.setCurrentRegister(null);
  }

  const handleUpdateRegister = (update: RegisterData) => {
    const register = registers.find((register) => register.registerID == update.registerID);

    if (register)
      Object.assign(register, update);
    else
      registers.unshift(update);

    registereActions.setCurrentRegister(null);
  }

  const handleRemoveRegister = (registerRemoved: RegisterData) => {
    const register = registers.find((register) => register.registerID == registerRemoved.registerID);
    if (register) {
      const index = registers.indexOf(register);
      if (index >= 0)
        registers.splice(index, 1);
    }
    registereActions.setCurrentRegister(null);
  }

  const handleSelectRegister = (register: RegisterData) => {
    registereActions.setCurrentRegister(register);
    getRegisterEditStore(new RegisterData(register));
  }

  const ShowRegisterList = () => {
    if (registersError) return <Error message={registersError.message} />
    if (registersLoading) return <Loading />
    if (registersSuccess)
      return (
        <Grid container spacing={1} >
          <RegisterList
            store={currentStore}
            registers={registers}
            onSelectRegister={handleSelectRegister}
            onClose={handleRegisterListClose} />
        </Grid>
      )
    return null;
  }

  const ShowListOrEditor = () => {
    if (currentRegister)
      return <EditRegisterContainer
        currentStore={currentStore}
        register={currentRegister}
        onUpdate={handleUpdateRegister}
        onRemove={handleRemoveRegister}
        onCancel={handleEditRegisterClose} />;
    if (currentStore)
      return <ShowRegisterList />
    return (
      <Grid container spacing={1} >
        <DataGridList dataList={stores} label="Select a store" onSelectItem={handleSelectStore} />
        <Grid item xs={12}>
          <Button
            variant='contained'
            fullWidth
            className={classes.button}
            onClick={props.onClose}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    )
  }

  if (storeError) return <Error message={storeError.message} />
  if (storeLoading) return <Loading />
  if (storeSuccess)
    return (
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant='h6' align='center' gutterBottom>Manage Registers</Typography>
          <ShowListOrEditor />
        </Paper>
      </div >
    )
  if (stores && !stores.length) return <Error message="No stores found" />
  return null;
}