import React, { useState, useMemo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Menu, MenuItem, Typography, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { useFlatCatalogManager } from './CatalogHandler';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { FlatCatalog, FlatCatalogEntry } from './Catalog';
import { FlatCatalogManager } from './FlatCatalogManager';

type event = React.MouseEvent<HTMLElement, MouseEvent>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  filterLine: {
    maxHeight: 54,
    flexBasis: 'auto',
    flexGrow: 0,
    display: 'inline-flex',
    alignItems: 'center',
  },
  table: {
    flex: 1,
    overflowY: 'scroll',
    maxHeight: '100%'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: 10,
    // textAlign: 'center',
    fontSize: 18,
  },
  textStuff: {
    paddingLeft: 10,
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 5,
  },
  head: {
    position: 'sticky',
    top: 0,
    background: theme.palette.primary[800],
  }
}))

interface CatalogHeadProps {
  onCategoryFilterClick: (e) => void;
  onVendorFilterClick: (e) => void;
}

function CatalogHead(props: CatalogHeadProps) {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={`${classes.head} ${classes.tableCell}`}>Category<IconButton onClick={props.onCategoryFilterClick}><FilterListIcon /></IconButton></TableCell>
      <TableCell className={`${classes.head} ${classes.tableCell}`}>Class</TableCell>
      <TableCell style={{textAlign: 'right'}} className={`${classes.head} ${classes.tableCell}`}>Item</TableCell>
      <TableCell style={{textAlign: 'right'}} className={`${classes.head} ${classes.tableCell}`}>Variation</TableCell>
      <TableCell className={`${classes.head} ${classes.tableCell}`}>Vendor<IconButton onClick={props.onVendorFilterClick}><FilterListIcon /></IconButton></TableCell>
    </TableRow>
  )
}

interface CatalogBodyProps {
  catalog: FlatCatalog;
}
const CatalogBody = React.memo((props: CatalogBodyProps) => {
  let key = 0;
  return (
    <>
      {props.catalog.map(entry => <CatalogRow entry={entry} key={key++} />)}
    </>
  )
});

interface CatalogRowProps {
  entry: FlatCatalogEntry;
}

const CatalogRow = React.memo((props: CatalogRowProps) => {
  const classes = useStyles();
  return ( <TableRow>
    <TableCell className={classes.tableCell}>{props.entry.categoryName}</TableCell>
    <TableCell className={classes.tableCell}>{props.entry.inventoryClass}</TableCell>
    <TableCell className={classes.tableCell} align={props.entry.variationID ? 'right' : 'right'} colSpan={props.entry.variationID ? 1 : 2}>{props.entry.itemName}</TableCell>
    {props.entry.variationID ? <TableCell className={classes.tableCell} align={'right'}>{props.entry.variationDescription}</TableCell> : null}
    <TableCell className={classes.tableCell}>{props.entry.variationID ? props.entry.variationVendor : props.entry.itemVendor}</TableCell>
  </TableRow>
  )
}
)

interface FilterMenuProps {
  items: string[]
  anchor: HTMLElement;
  onItemClick: (item: string) => void;
  onClose: () => void;
}

function FilterMenu(props: FilterMenuProps) {
  return (<Menu id='vendor-filter'
    anchorEl={props.anchor}
    open={!!props.anchor}
    onClose={props.onClose}
    PaperProps={{ style: { maxHeight: 400, width: 200 } }}>
    {props.items.map(item => (
      <MenuItem key={item}
        value={item}
        onClick={() => props.onItemClick(item)}>
        {item}
      </MenuItem>))}
  </Menu>)
}

export interface FlatCatalogProps {
  catalogManager: FlatCatalogManager;
  activeOnly: boolean;
}

function FlatCatalogTable(props: FlatCatalogProps) {
  const classes = useStyles();
  const filterItems = useMemo(() => props.catalogManager.parseFilterItems(), []);

  if (props.activeOnly) props.catalogManager.filterActive();
  const [displayedCatalog, setDisplayedCatalog] = useState(props.catalogManager.catalog);

  const [vendorFilterAnchor, setVendorFilterAnchor] = useState(null);
  const [categoryFilterAnchor, setCategoryFilterAnchor] = useState(null);

  const [activeFilter, setActiveFilter] = useState("none");

  function showCategoryFilterMenu(e: event) {
    setCategoryFilterAnchor(e.currentTarget);
  }

  function showVendorFilterMenu(e: event) {
    setVendorFilterAnchor(e.currentTarget);
  }

  const handleCategoryFilter = (category: string) => {
    props.catalogManager.filterByCategory(category);
    setDisplayedCatalog(props.catalogManager.catalog);
    setCategoryFilterAnchor(null);
    setActiveFilter(` Category: ${category}`);
  }

  const handleVendorFilter = (vendor: string) => {
    props.catalogManager.filterByVendor(vendor);
    setDisplayedCatalog(props.catalogManager.catalog);
    setVendorFilterAnchor(null);
    setActiveFilter(` Vendor: ${vendor}`);
  }

  const clearFilters = () => {
    props.catalogManager.clearFilters()
    setDisplayedCatalog(props.catalogManager.catalog);
    setActiveFilter("none");
  }

  const handleCategoryClose = () => setCategoryFilterAnchor(null);
  const handleVendorClose = () => setVendorFilterAnchor(null);

  return (
    <div className={classes.root}>
      <div className={classes.filterLine}>
        <Typography className={classes.textStuff}>Active Filter: {activeFilter}</Typography>
        {activeFilter == "none" ? null : <IconButton onClick={clearFilters}><ClearIcon /></IconButton>}
      </div>
      <div className={classes.table}>
      <Paper>
        <Table>
          <TableHead>
            <CatalogHead
              onCategoryFilterClick={showCategoryFilterMenu}
              onVendorFilterClick={showVendorFilterMenu}
            />
          </TableHead>
          <TableBody>
            <CatalogBody catalog={displayedCatalog} />
          </TableBody>
        </Table>
      </Paper>
      </div>
      <FilterMenu 
        items={filterItems.categories} 
        anchor={categoryFilterAnchor} 
        onItemClick={handleCategoryFilter} 
        onClose={handleCategoryClose} />
      <FilterMenu 
        items={filterItems.vendors} 
        anchor={vendorFilterAnchor}
        onItemClick={handleVendorFilter} 
        onClose={handleVendorClose} />
    </div>
  )
}

export interface FlatCatalogComponentProps {
  activeOnly: boolean;
}

export function FlatCatalogComponent(props: FlatCatalogComponentProps) {
  const { error, loading, catalogManager } = useFlatCatalogManager();

  if (error) return <Error />
  if (loading) return <Loading />
  if (catalogManager) return <FlatCatalogTable catalogManager={catalogManager} activeOnly={props.activeOnly} />
  return null;
}