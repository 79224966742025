import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { DialogContent, Grid, Theme, Button, Input, DialogActions } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { NumPad } from './NumPad';
import { InventoryTransferItem } from '../wireData/InventoryTransferData';

const useStyles = makeStyles((theme: Theme) => createStyles({
  header: {
    width: "100%",
  },
  noOverflow: {
    overflow: 'hidden',
  },
  numRoot: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 'auto',
    height: 'auto',
    maxWidth: 350,
    maxHeight: 450,
  },
}))

export class ChangeTransferCountDialogProps {
  initialValue: number;
  transferItem: InventoryTransferItem;
  open: boolean;
  onClose: () => void;
  onUpdate: (newCount: number) => void;
}

export function ChangeTransferCountDialog(props: ChangeTransferCountDialogProps) {
  const classes = useStyles({});
  const [value, setValue] = useState(0);
  const [clearOnFirst, setClearOnFirst] = useState(true);

  useEffect(() => {
    setValue(props.initialValue);
    setClearOnFirst(true);
  }, [props.initialValue]);

  const handleClose = (event: React.MouseEvent) => {
    event && event.stopPropagation()
    props.onClose();
  }

  const handleUpdate = (event: React.MouseEvent) => {
    event && event.stopPropagation();
    props.onUpdate(value);
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const localValue = clearOnFirst ? 0 : value;
    setClearOnFirst(false);
    switch (event.key) {
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        if (localValue < 1000)
          setValue(localValue * 10 + Number(event.key));
        break;
      case "Backspace":
        setValue(Math.trunc(value / 10));
        break;
      case "Enter":
        handleUpdate(null);
        break;
      case "Escape":
        handleClose(null);
        break;
    }
    event.preventDefault();
  }

  const handleNumClick = (inputValue: number) => {
    const localValue = clearOnFirst ? 0 : value;
    setClearOnFirst(false);
    if (localValue < 1000)
      setValue(localValue * 10 + Number(inputValue));
  }

  const handleClearClick = () => {
    setValue(0);
  }

  const handleBackClick = () => {
    setValue(Math.trunc(value / 10));
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.open}
      onClose={handleClose}
      onKeyDown={handleKeyDown}
      onClick={(event) => event.stopPropagation()}
      onMouseDown={(event) => event.stopPropagation()}
    >
      <DialogTitle>
        <Grid container spacing={0}>
          <Grid item xs={12} className={classes.header}>
            <Typography align="center" variant="h5">Update Count</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.noOverflow }>
        <Grid container direction="column" spacing={0}>
          <Grid item xs={12}>
            <Typography align="center" variant="h5">{props.transferItem.categoryName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h6">{props.transferItem.itemName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h6">{props.transferItem.variationDescription ? props.transferItem.variationDescription : null}</Typography>
          </Grid>
          <Grid container spacing={8} direction="row" justify="center">
            <Grid item xs={3}>
              <Typography align="center" variant="h5">{value == null ? "--" : value}</Typography>
            </Grid>
          </Grid>
          <Grid>
            <NumPad
              onNumClick={handleNumClick}
              onBackClick={handleBackClick}
              onClearClick={handleClearClick}
              onKeyDown={handleKeyDown}
              rootClassname={classes.numRoot}
            />
          </Grid>
          <Grid container spacing={0} direction="row">
            <Grid item xs={6}>
              <Button fullWidth color='secondary' onClick={handleClose}>Cancel</Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth color='secondary' onClick={handleUpdate}>Update</Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}