export class StoreCountsForDate {
  inventoryDate: Date | number;
  storeID: number;
  storeName: string;
  categoryID: number;
  categoryName: string;
  itemID: number;
  itemName: string;
  variationID: number;
  variationDescription: string;
  count: number
}

export class DateAndStore{
  inventoryDate: Date | number;
  storeID: number;
  storeName: string;
}

export class CountEntryQueryOrUpdate {
  storeID: number;
  categoryID: number;
  itemID: number;
  variationID: number;
  date: string;
  count: number;
  cost: number;
}

