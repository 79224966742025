import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDatesAndStoreCounts, DateAndStore } from './CountHandler';
import { Loading } from '../common/LoadingComponent';
import { Error } from '../common/ErrorComponent';
import { CountManager } from './CountManager';
import { Dialog, DialogTitle, DialogContent, ExpansionPanel, ExpansionPanelSummary, Typography, Theme, DialogActions, Button, ExpansionPanelDetails, Grid, Paper } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// const datesAndStoresManager = CountManager.Instance;

const useStyles = makeStyles((theme: Theme) => createStyles({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    maxHeight: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.up(600 + 280 + theme.spacing(3 * 2))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxHeight: "auto",
      overflowY: "hidden",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + 280 + theme.spacing(3 * 2))]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  listBox: {
    width: "100%",
    maxHeight: '60vh',
    // flexBasis: 'auto',
    // flex: '1',
    overflowY: "auto",
    overflowX: "hidden",
  },
  centerDiv: {
    width: '100%',
    textAlign: 'center',
    margin: 'auto',
  },
  cancel: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  root: {
    maxWidth: 600,
    maxHeight: "50vh",
  },
}))

interface DateAndStoresProps {
  onClose: () => void;
  onDisplay: (stores: Array<DateAndStore>) => void;
}

function DatesAndStores(props: DateAndStoresProps) {
  const classes = useStyles({});
  const [{ scrolling, expandedItems}, manager] = useDatesAndStoreCounts();

  const scrollinfRef = React.useRef(null);

  useLayoutEffect(() => {
    scrollinfRef.current.scrollTop = scrolling.top;
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrolling.top = event.currentTarget.scrollTop;
  }

  const handleClose = () => {
    props.onClose();
  }

  interface StoreItemProps {
    store: DateAndStore;
  }

  const StoreItem = (props: StoreItemProps) => {
    const [checked, setChecked] = useState(props.store.checked ? true : false);

    const handleChange = () => {
      props.store.checked = !checked;
      setChecked(!checked);
    }
    return (
      < Grid item xs={12} >
        <Typography variant="h6">
          <Checkbox checked={checked} onClick={() => handleChange()} />
          {props.store.storeName}
        </Typography>
      </Grid >
    )
  }

  interface DatePanelProps {
    date: Date;
    onDisplay: (stores: Array<DateAndStore>) => void;
  }

  const DatePanel = (props: DatePanelProps) => {
    const [expanded, setExpanded] =useState(Boolean(expandedItems.find((date) => date == props.date.valueOf())));

    const displayAllStores = (event: React.MouseEvent) => {
      event.stopPropagation();
      props.onDisplay(manager.getStoresForDate(props.date));
    }

    const displaySelectedStores = (event: React.MouseEvent) => {
      event.stopPropagation();
      props.onDisplay(manager.getStoresForDate(props.date).filter((dateAndStore: DateAndStore) => dateAndStore.checked));
    }
    
    const handleChange = (event, isExpanded) => {
      if (isExpanded) {
        expandedItems.push(props.date.valueOf());
        setExpanded(true);
      }
      else {
        const index = expandedItems.findIndex((date) => date == props.date.valueOf());
        if (index >= 0)
          expandedItems.splice(index, 1);
        setExpanded(false);
      }
    }
  
    return (
      <ExpansionPanel onChange={handleChange} expanded={expanded}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid alignItems="baseline" justify="flex-start" direction="row" spacing={1} container>
            <Grid item xs={7}>
              <Typography noWrap variant="body1">{props.date.toDateString()}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button onClick={displaySelectedStores} color="secondary">
                selected
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button onClick={displayAllStores} color="secondary">
                all
              </Button>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container>
            {manager.getStoresForDate(props.date).map((dateAndStore: DateAndStore) => (
              <StoreItem key={dateAndStore.storeID} store={dateAndStore} />
            ))}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  return (
    <div className={classes.layout}>
      <Paper className={classes.paper}>
        <div className={classes.centerDiv}>
          <Typography variant="h6">Select Date and Stores</Typography>
        </div>
        <div className={classes.listBox}  ref={scrollinfRef} onScroll={handleScroll}>
          {
            manager.dates.map((date: Date) => (
              <DatePanel key={date.valueOf()} date={date} onDisplay={props.onDisplay} />
            ))
          }
        </div>
        <div className={classes.centerDiv}>
          <Button className={classes.cancel} onClick={handleClose} color="secondary">
            cancel
          </Button>
        </div>
      </Paper>
    </div >
  )
}

export interface SelectDateAndStoresProps {
  onClose: () => void;
  onDisplay: (stores: Array<DateAndStore>) => void;
}

export function SelectDatesAndStores(props: SelectDateAndStoresProps) {
  const [{ storesError, storesLoading, storesSuccess }, manager, actions] = useDatesAndStoreCounts();

  useEffect(() => {
    if (!storesSuccess && !storesLoading)
      actions.getDateAndStores();
  }, [storesLoading, storesSuccess]);

  if (storesError) return <Error message={storesError.message} />
  if (storesLoading) return <Loading />
  if (storesSuccess) return <DatesAndStores onClose={props.onClose} onDisplay={props.onDisplay} />
  return null;
}