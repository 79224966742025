import { PullInventoryDataManager } from "./PullInventoryDataManager";
import { storePullInventoryUpdateCountURL, storePullInventoryUpdateStatusURL, storePullInventoryDowngradeStatusURL, storePullInventoryToShipURL } from "../utils/WireUtils";
import { InventoryTransferQuantityAndStatusUpdate, InventoryTransferItem } from "../wireData/InventoryTransferData";
import { Store } from "../store/Store";
import { BaseState, BaseAction, StatusFields } from "../common/BaseHandler";

const PullStoreInventoryStoreName = 'PullStoreInventoryStore';

export class PullStoreInventoryState extends BaseState {
  transferToUpdate: InventoryTransferItem;
  updateError: any;
  updating: boolean;
  updateSuccess: boolean;
  updatedItem: InventoryTransferQuantityAndStatusUpdate;
  store: Store;
  scrolling: { top: number };
  _manager: PullInventoryDataManager;
}

const statusFields: StatusFields<PullStoreInventoryState> = {
  error: "updateError",
  processing: "updating",
  success: "updateSuccess"
};

class PullStoreInventoryActions extends BaseAction<PullStoreInventoryState>{

  get initialState(): PullStoreInventoryState {
    return {
      error: null,
      loading: false,
      success: false,
      transferToUpdate: null,
      updateError: null,
      updating: false,
      updateSuccess: false,
      updatedItem: null,
      store: null,
      scrolling: { top: 0 },
      _manager: null,
      _actions: this
    }
  }

  private mapInventory = (body: any): Partial<PullStoreInventoryState> => {
    const manager = new PullInventoryDataManager();
    manager.InventoryPullData = body;
    return {
      error: null,
      loading: false,
      success: true,
      scrolling: { top: 0 },
      _manager: manager,
    };
  }

  getInventory = async (storeID: number) => {
    return this.getData(`${storePullInventoryToShipURL}/${storeID}`, this.mapInventory)
  }
  
  private mapUpdate = (body: any): Partial<PullStoreInventoryState> => {
    return { updatedItem: body as InventoryTransferQuantityAndStatusUpdate }
  }

  updatePullInventoryCount = async (update: InventoryTransferQuantityAndStatusUpdate) => {
    return this.postData(storePullInventoryUpdateCountURL, update, this.mapUpdate, statusFields);
  }

  updatePullInventoryStatus = async (update: InventoryTransferQuantityAndStatusUpdate) => {
    return this.postData(storePullInventoryUpdateStatusURL, update, this.mapUpdate, statusFields);
  }

  downgradePullInventoryStatus = async (update: InventoryTransferQuantityAndStatusUpdate) => {
    return this.postData(storePullInventoryDowngradeStatusURL, update, this.mapUpdate, statusFields);
  }

  setStore = (store: Store) => {
    this.updateState({ store: store, _manager: null, error: null, success: false });
  }
  
  setTransferToUpdate = (transfer: InventoryTransferItem)=>{
    this.updateState({transferToUpdate: transfer})
  }

  clearUpdate = () => {
    this.updateState({ transferToUpdate: null, updatedItem: null, updateError:null, updating: false, updateSuccess: false });
  }
}

export const resetPullInventoryStore = () =>{
  BaseAction.getAction(PullStoreInventoryStoreName, PullStoreInventoryActions).reset();
}

export const usePullInventory = (): [PullStoreInventoryState, PullInventoryDataManager, PullStoreInventoryActions] => {
  const [state, actions] = BaseAction.getAction(PullStoreInventoryStoreName, PullStoreInventoryActions).useStore();
  return [state, state._manager, actions];
}
