import { justDateFromWire } from "./WireDates";

export class TransactionData {
  constructor(source?: any) {
    this.associateID = 0;
    this.userName = "";
    this.creditChange = 0;
    this.date = new Date();
    this.locationID = 0;
    this.storeName = "";
    this.originTransaction = 0;
    this.purchaseAmount = 0;
    this.registerID = 0;
    this.registerName = "";
    this.transactionID = 0;
    this.transactionType = "";
    this.customerID = 0;
    this.customerFirstName = "";
    this.customerLastName = "";
    this.cardNumber = "";
    this.companyID = 0;
    this.companyName = "";
    this.creditPercent = 0;
    this.parentPercent = 0;
    this.levelDivision = 0;
    this.levelNumber = 0;
    this.legacyID = "0";
    this.legacyParentID = "0";
    this.reverted = 0;
    if (source) {
      Object.assign(this, source);
    }
  }

  associateID: number;
  userName: string;
  creditChange: number | string;
  date: Date | number;
  locationID: number;
  storeName: string;
  originTransaction: number | null;
  purchaseAmount: number | string;
  registerID: number;
  registerName: string;
  transactionID: number;
  transactionType: string;
  customerID: number;
  customerFirstName: string;
  customerLastName: string;
  cardNumber: string;
  companyID: number;
  companyName: string;
  creditPercent: number | null;
  parentPercent: number | null;
  levelDivision: number | null;
  levelNumber: number | null;
  legacyID: string;
  legacyParentID: string;
  reverted: number | null;
}

export const transactionDataToWire = (transaction: TransactionData) => {
  if (transaction.date)
    transaction.date = transaction.date.valueOf();
  transaction.creditPercent = Number(transaction.creditPercent);
  transaction.parentPercent = Number(transaction.parentPercent);
  transaction.levelDivision = Number(transaction.levelDivision);
}

export const transactionDataFromWire = (transaction: TransactionData) => {
  if (transaction.date)
    transaction.date = new Date(transaction.date);
  else
    transaction.date = null;
  transaction.creditPercent = Number(transaction.creditPercent);
  transaction.parentPercent = Number(transaction.parentPercent);
  transaction.levelDivision = Number(transaction.levelDivision);
}
