import React from 'react';
import { Paper, Tabs, Typography, Tab } from '@material-ui/core';
import { Store } from '../store/Store';

export interface InventoryTableTabsProps {
  stores: Array<Store>;
  index: number;
  onTabClick: (index: number) => void;
}

export function InventoryTableTabs(props: InventoryTableTabsProps) {
  const handleChange = (event, value) => {
    props.onTabClick(value);
  }

  return (
    <Paper square>
      <Tabs value={props.index} onChange={handleChange}>
        {props.stores.map(store => <Tab key={store.storeID} label={<Typography>{store.storeName}</Typography>} />)}
        <Tab label={<Typography>Totals</Typography>} />
      </Tabs>
    </Paper>
  )
}

