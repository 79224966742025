import React, { useEffect } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import { CompanyData } from '../wireData/CompanyData';
import { useCompanies, getOrCreateEditCompanyStore, resetCompanyState } from './CompanyHandler';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { useStyles } from './styles';
import { CompanyList } from './CompanyList';
import { EditCompanyContainer } from './EditCompanyContainer';

export function resetCompanyComponent(){
  resetCompanyState();
}

class CompanyContainerProps {
  onClose: () => void;
}

export function CompanyContainer(props: CompanyContainerProps) {
  const classes = useStyles({});
  const [{ error, loading, success, currentCompany, companies }, actions] = useCompanies();

  useEffect(() => {
    if (!(error || loading || success))
      actions.getCompanies();
  }, [error, loading, success]);

  const handleClose = () => {
    props.onClose();
  }

  const handleEditCompanyClose = () => {
    actions.setCurrentCompany(null);
  }


  const handleCompanyUpdate = (company: CompanyData) => {
    actions.setCurrentCompany(null);
    actions.getCompanies();
  }

  const handleCompanyRemove = (company: CompanyData) => {
    actions.setCurrentCompany(null);
    actions.getCompanies();
  }

  const handleCompanySelect = (company: CompanyData) =>{
    actions.setCurrentCompany(company);
    getOrCreateEditCompanyStore(new CompanyData(company));
  }

  const ShowCompanyList = () => {
    if (error) return <Error message={error.message} />
    if (loading) return <Loading />
    if (success)
      return (
        <Grid container spacing={1} >
          <CompanyList
            companies={companies}
            onSelectCompany={handleCompanySelect}
            onClose={handleClose} />
        </Grid>
      )
    return null;
  }

  const ShowListOrEdit = () => {
    if (currentCompany)
      return <EditCompanyContainer company={currentCompany} onUpdate={handleCompanyUpdate} onRemove={handleCompanyRemove} onCancel={handleEditCompanyClose} />
    return (
      <Grid container spacing={1} >
        <ShowCompanyList />
      </Grid>
    )
  }

  return (
    <div className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography variant='h6' align='center' gutterBottom>Manage Companies</Typography>
        <ShowListOrEdit />
      </Paper>
    </div >
  )
}