import React, { useState } from 'react';
import { InventoryTransferSummaryData } from '../wireData/InventoryTransferSummaryData';
import { useStyles } from './styles';
import { InventoryTransferDetailsDialog } from './InventoryTransferDetailsDialog';
import { TableRow, TableCell } from '@material-ui/core';
import { numberToDateString, numberToDollarString } from '../utils/Convert';

export interface ShowInventoryTransferRowProps {
  row: InventoryTransferSummaryData;
}

export const ShowInventoryTransferRow = (props: ShowInventoryTransferRowProps) => {

  const classes = useStyles({});
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = () => {
    setSelectedRow(props.row);
  }

  const handleDialogClose = () => {
    setSelectedRow(null);
  }

  const ShowInventoryTransferDetailsDialog = () => {
    if (selectedRow) return <InventoryTransferDetailsDialog row={selectedRow} onClose={handleDialogClose} />;
    return null
  }

  return (
    <TableRow className={classes.tableRow} onClick={handleRowClick}>
      <TableCell className={classes.tableCell}>
        <ShowInventoryTransferDetailsDialog />
        {props.row.categoryName}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {props.row.itemName}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {props.row.variationDescription}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {numberToDateString(props.row.dateRequested)}
      </TableCell>
      {props.row.transfers.map((value, index) =>
        <TableCell key={index} className={classes.tableCell}>
          {value}
        </TableCell>
      )}
      <TableCell className={classes.tableCell}>
        {numberToDollarString(props.row.itemCost)}
      </TableCell>
    </TableRow>

  )
}

