import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';
import { processLogin } from './LoginHandler';
import { UserData } from '../wireData/UserData';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  message: {
    paddingTop: theme.spacing(2),
  },
}));

interface LoginProps {
  defaultUserName?: string;
  defaultPassword?: string;
  message: string;
  setLoginStatusCallback: (status: boolean, token: string, user: UserData, message: string) => void;
}

function rememberButton() {
  //mostly just to remember how this was done.
  return (<FormControlLabel
    control={<Checkbox value="remember" color="primary" />}
    label="Remember me"
  />);

}

export const SignIn = (props: LoginProps) => {
  const classes = useStyles();

  const [userName, setUserName] = useState(props.defaultUserName);
  const [password, setPassword] = useState(props.defaultPassword);

  //  console.log('Called sign-in');

  const login = (e: any) => {
    e.preventDefault();
    processLogin(userName, password, props.setLoginStatusCallback);
  }

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={login} autoComplete="on">
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="user">User Email</InputLabel>
            <Input id="user" name="user" autoComplete="user" defaultValue={userName} autoFocus onChange={e => setUserName(e.target.value)} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input name="password" type="password" id="password" autoComplete="current-password" defaultValue={password} onChange={e => setPassword(e.target.value)} />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign in
          </Button>
        </form>
        <Typography className={classes.message}>{props.message}</Typography>
      </Paper>
    </main>
  );
}