import { storeURL, getFilteredStoresURL, getJSONHeaders } from '../utils/WireUtils';
import { Store } from './Store';
import { BaseState, BaseAction } from '../common/BaseHandler';

export async function getStores(): Promise<Array<Store>> {
  return fetch(storeURL, {
    method: 'GET',
    headers: getJSONHeaders()
  }).then(async (response) => {
    if (response.status >= 200 && response.status < 300) {
      const body = await response.json();
      return body;
    } else {
      const body = await response.json();
      return Promise.reject(body.message);
    }
  }).catch(reason => Promise.reject(reason));
}

const storeStoreName = "storeStoreFor-";

class StoreState extends BaseState {
  currentStore: Store;
  stores: Array<Store>;
}

export class StoreActions extends BaseAction<StoreState> {

  get initialState() : StoreState {
    return {
      error: null,
      loading: false,
      success: false,
      currentStore: null,
      stores: [],
      _actions: this,
    };
  }

  private mapStores = (body: any): Partial<StoreState> => {
    return { stores: body.map((store) => new Store(store)) }
  }

  getStores = () =>{
    this.updateState({ stores: [] });
    this.getData(storeURL, this.mapStores);
  }

  getStoresForCompany = (companyID: number) =>{
    this.updateState({ stores: [] });
    this.getData(`${storeURL}/${companyID}`, this.mapStores);
  }

  setCurrentStore = ( store: Store) =>{
    this.updateState({currentStore: store});
  }
}

export function useStores(application:string): [StoreState, StoreActions] {
  return BaseAction.getAction<StoreActions>(storeStoreName + application, StoreActions).useStore();
}

export const lookupStoresByName = async (searchKey: string, max: number = 10): Promise<Array<Store>> => {
  let query = "";

  if (searchKey)
    query = `storeName=${searchKey}&max=${max}`;
  else
    query = `max=${max}`;

  query += "&sortField=storeName";

  try {
    const response = await fetch(`${getFilteredStoresURL}?${query}`, {
      method: 'GET',
      headers: getJSONHeaders(),
    })
    if (response.ok) {
      const users = await response.json();
      return users;
    }
    else
      throw ("Store lookup error")
  }
  catch (e) {
    throw ("Store lookup error")
  }
}