import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useUpdateDevices, useRemoveDevices, deviceEditStoreName, getDeviceEditStore } from './deviceHandler';
import { LinearLoading } from '../common/LoadingComponent';
import { useStores } from '../store/StoreHandler';
import { Store } from '../store/Store';
import { DeviceData } from '../wireData/DeviceData';
import { UserCompanyData } from '../wireData/UserCompanyData';
import { useGetUserCompanies } from '../company/CompanyHandler';
import { useRegisters } from '../register/registerHandler';
import { loginCompany } from '../utils/WireUtils';
import { EditDevice } from './EditDevice';
import { getStoreByName } from 'react-hookstore';
import { useField } from '../common/useField';

interface StatusDialogProps {
  message: string;
  open: boolean;
}

function StatusDialog(props: StatusDialogProps) {

  return (
    <Dialog open={props.open} fullWidth maxWidth="xs">
      <DialogContent>
        <LinearLoading message={props.message} />
      </DialogContent>
    </Dialog>
  )
}

interface ErrorDialogProps {
  error: any;
  open: boolean;
  onClose: () => void;
}

function ErrorDialog(props: ErrorDialogProps) {

  return (
    <Dialog open={props.open && Boolean(props.error)}>
      <DialogTitle>
        Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.error ? props.error.message || props.error : "Unknown Error"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export interface EditDeviceContainerProps {
  device: DeviceData;
  currentStore: Store;
  onUpdate: (device: DeviceData) => void;
  onRemove: (device: DeviceData) => void;
  onCancel: () => void;
}

export function EditDeviceContainer(props: EditDeviceContainerProps) {

  const store = getDeviceEditStore();
  const [companyID, setCompanyID] = useField(store, 'companyID');
  const [storeID, setStoreID] = useField(store, 'storeID');
  
  const [deviceToUpdate, setDeviceToUpdate] = useState<null | DeviceData>(null);
  const [showErrors, setShowErrors] = useState(false);
  const [deviceToRemove, setDeviceToRemove] = useState<null | DeviceData>(null);

  const { error: companiesError, loading: companiesLoading, companies } = useGetUserCompanies();
  const [{ error: storesError, loading: storesLoading, success: storesSuccess, stores }, storeActions] = useStores('Device');
  const [{ error: registersError, loading: registersLoading, success: registersSuccess, registers }, registerActions] = useRegisters('Device');
  const { error: deviceUpdateError, updating, device: updatedDevice } = useUpdateDevices(deviceToUpdate);
  const { error: deviceRemoveError, removing, device: removedDevice } = useRemoveDevices(deviceToRemove);

  useEffect(() => {
    if (!(storesError || storesSuccess || storesLoading))
      storeActions.getStoresForCompany(companyID);
  }, [storesError, storesLoading, storesSuccess]);

  useEffect(() => {
    if (!(registersError || registersSuccess || registersLoading))
      registerActions.getRegistersForStore(storeID);
  }, [registersError, registersLoading, registersSuccess]);

  useEffect(() => {
    if (updatedDevice && !updating) {
      props.onUpdate(updatedDevice);
      setDeviceToUpdate(null);
    }
  }, [updatedDevice, updating])

  useEffect(() => {
    if (removedDevice && !removing) {
      props.onRemove(deviceToRemove);
      setDeviceToRemove(null);
    }
  }, [removedDevice, removing])

  const handleCompanySelect = (company: UserCompanyData) => {
    if (company) {
      if (company.companyID != companyID) {
        setCompanyID(company.companyID);
        storeActions.getStoresForCompany(company.companyID);
      }
    }
    else
      storeActions.reset();
  }

  const handleStoreSelect = (store: Store) => {
    if (store) {
      if (store.storeID != storeID) {
        setStoreID(store.storeID);
        registerActions.getRegistersForStore(store.storeID);
      }
    }
    else
      registerActions.reset();
  }

  const handleUpdate = async (device: DeviceData) => {
    setDeviceToUpdate(device);
    setShowErrors(true);
  }

  const handleCancel = () => {
    props.onCancel();
  }

  const handleRemove = async () => {
    setDeviceToRemove(props.device);
    setShowErrors(true);
  }

  const handleCloseErrorDialog = () => {
    setShowErrors(false);
  }

  return (
    <div>
      <StatusDialog open={companiesLoading || storesLoading || registersLoading} message="Loading" />
      <StatusDialog open={updating || removing} message="Updating device" />
      <ErrorDialog open={showErrors} error={deviceUpdateError || deviceRemoveError || companiesError || storesError || registersError} onClose={handleCloseErrorDialog} />
      <EditDevice
        currentCompany={loginCompany}
        currentStore={props.currentStore}
        device={props.device}
        companies={companies}
        stores={stores}
        registers={registers}
        onSelectCompany={handleCompanySelect}
        onSelectStore={handleStoreSelect}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
        onCancel={handleCancel}
      />
    </div>
  )
}
