import React, { useState } from 'react';
import { Grid, Typography, TextField, Theme, Button, Paper } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useCountsByStoreForDate, resetInventoryCountsByStore } from './InventoryHandler';
import { Loading } from '../common/LoadingComponent';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from "moment";
import { Error } from '../common/ErrorComponent';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      width: 400,
      marginRight: 'auto',
      marginLeft: 'auto',
    }
    // display: "flex",
    // justifyContent:"center",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + 280 + theme.spacing(3 * 2))]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  centerDiv: {
    width: '100%',
    textAlign: 'center',
  },
  topDiv: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // //    width: "50%",
    // marginLeft: "auto",
    // marginRight: "auto",
  },
  topDivItem: {
    flex: 1,
  },
  countsDiv: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
    //    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  countLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    //    flex: 1,
  },
  countItem: {
    flex: 1,
  },
}));

export const resetInventoryCountsContainer = () =>{
  resetInventoryCountsByStore ();
}
export function InventoryCountsContainer() {
  const [{ error, loading, success, date, counts }, actions] = useCountsByStoreForDate();
  const [inputDate, setInputDate] = useState<Moment>(moment(date));
  const classes = useStyles({});

  const handleDateChange = (date: Moment) => {
    setInputDate(date)
    actions.setDate(date.toDate());
    actions.getSummaryData(date.toDate());
    // setInputDate(event.target.value)
    // setDate(new Date(event.target.value));
  }

  const handleRefresh = () => {
    actions.getSummaryData(inputDate.toDate());
  }

  const StoreCountBody = () => {
    if (error) return <Error message={error.message} />
    if (loading) return <Loading />
    if (success) {
      return (<div className={classes.countsDiv}>
        <Grid container className={classes.countLine}>
          <Grid xs={6} item className={classes.countItem}>
            <Typography variant='body1' align="center">
              Store
              </Typography>
          </Grid>
          <Grid xs={6} item className={classes.countItem}>
            <Typography variant='body1' align="center">
              Items Counted
              </Typography>
          </Grid>
        </Grid>
        {counts.map((count) => (
          <Grid key={count.storeID} container className={classes.countLine}>
            <Grid xs={6} item className={classes.countItem}>
              <Typography variant='body1'>
                {count.storeName}
              </Typography>
            </Grid>
            <Grid xs={6} item className={classes.countItem}>
              <Typography variant='body1' align="center">
                {count.count}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </div>
      )
    }
    return null;
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} >

      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* <div className={classes.centerDiv}> */}
            <Typography noWrap align="center" variant="h6" gutterBottom>Inventory Count Summary</Typography>
          {/* </div> */}
          <Grid container direction="row" justify="space-around" spacing={2}>
            <Grid xs={6} item >
              <DatePicker label="Inventory Date" value={inputDate} onChange={handleDateChange} autoOk format="MM/DD/YYYY" />
            </Grid>
            <Grid item xs={12}>
              <StoreCountBody />
            </Grid>
            <Grid xs={6} item >
              <Typography variant='body1'>
                <Button variant='contained' fullWidth onClick={handleRefresh}>Refresh</Button>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </MuiPickersUtilsProvider>
  )
}