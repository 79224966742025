export class InventoryCostItem{
  categoryID: number;
  categoryName: string;
  itemID: number;
  itemName: string;
  variationID: number;
  variationDescription: string;
  count: number;
  cost: number;
  total: number;
}

export type InventoryCostsTable = Array<InventoryCostItem>;

export class StoreInventoryCostsWorksheet{
  storeID: number;
  storeName: string;
  costs: InventoryCostsTable;
}

export class StoreInventoryCostsWorkbook{
  constructor(){
    this.date = new Date();
    this.worksheets = [];
    this.totals = [];
  }
  date: Date;
  worksheets: Array<StoreInventoryCostsWorksheet>;
  totals: InventoryCostsTable;
}

