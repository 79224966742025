import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useUpdateCustomer, useRemoveCustomer } from './CustomerHandler';
import { LinearLoading } from '../common/LoadingComponent';
import { CustomerData } from '../wireData/CustomerData';
import { EditCustomer } from './EditCustomer';

interface StatusDialogProps {
  message: string;
  open: boolean;
}

function StatusDialog(props: StatusDialogProps) {

  return (
    <Dialog open={props.open} fullWidth maxWidth="xs">
      <DialogContent>
        <LinearLoading message={props.message} />
      </DialogContent>
    </Dialog>
  )
}

interface ErrorDialogProps {
  error: any;
  open: boolean;
  onClose: () => void;
}

function ErrorDialog(props: ErrorDialogProps) {

  return (
    <Dialog open={props.open && Boolean(props.error)}>
      <DialogTitle>
        Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.error ? props.error.message || props.error : "Unknown Error"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export interface EditCustomerContainerProps {
  customer: CustomerData;
  onAddCustomer: (customer: CustomerData) => void;
  onRemoveCustomer?: (customer: CustomerData) => void;
  onCancel: () => void;
}

export function EditCustomerContainer(props: EditCustomerContainerProps) {

  const [showErrors, setShowErrors] = useState(false);
  const [customerToUpdate, setCustomerToUpdate] = useState<null | CustomerData>(null);
  const [customerToRemove, setCustomerToRemove] = useState<null | CustomerData>(null);

  const { error: customerUpdateError, updating, customer: updatedCustomer } = useUpdateCustomer(customerToUpdate);
  const { error: customerRemoveError, removing, customer: removedCustomer } = useRemoveCustomer(customerToRemove);

  useEffect(() => {
    if (updatedCustomer && !updating) {
      props.onAddCustomer(updatedCustomer);
      setCustomerToUpdate(null);
    }
  }, [updatedCustomer, updating])

  useEffect(() => {
    if (removedCustomer && !removing) {
      props.onRemoveCustomer(customerToRemove);
      setCustomerToRemove(null);
    }
  }, [removedCustomer, removing])

  const handleUpdate = (customer: CustomerData) => {
    setCustomerToUpdate(customer);
    setShowErrors(true);
  }

  const handleCancel = () => {
    props.onCancel();
  }

  const handleRemove = (customer: CustomerData) => {
    setCustomerToRemove(customer);
    setShowErrors(true);
  }

  const handleCloseErrorDialog = () => {
    setShowErrors(false);
  }

  return (
    <div>
      <StatusDialog open={updating || removing} message={updating ? "Updating Customer":"Removing Customer"} />
      <ErrorDialog open={showErrors && Boolean(customerToUpdate || customerToRemove)} error={customerUpdateError || customerRemoveError} onClose={handleCloseErrorDialog} />
      <EditCustomer
        customer={props.customer}
        onAddCustomer={handleUpdate}
        onRemoveCustomer={handleRemove}
        onCancel={handleCancel} 
      />
    </div>
  )
}
