import React, { useState, useLayoutEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import ExitIcon from '@material-ui/icons/ExitToApp';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Category, Item, } from '../catalog/Catalog';
import { ExpansionPanelSummary, ExpansionPanel, Typography, ExpansionPanelDetails, Checkbox, IconButton, CssBaseline, Button, Fab, Icon } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Theme } from '@material-ui/core';
import { InventoryManager } from './InventoryManager';
import { useInventoryManager, InventoryExpandedItem } from './InventoryHandler';

// const manager = InventoryManager.Instance;

const useStyles = makeStyles((theme: Theme) => createStyles({
  outerDiv: {
    // position: 'relative',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%',
    boxSizing: 'border-box',
  },
  root: {
    background: theme.palette.background.default,
  },
  fab: {
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 10,
    right: 50,
    position: 'fixed',
    zIndex: 999,
  },
  expander: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
  },
  summaryContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: 500,
    flexBasis: 'auto',
    justifyContent: 'flex-start',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    background: theme.palette.primary.dark,
  },
  itemButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    background: theme.palette.primary.light,
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  startButton: {

  },
}));

interface InventoryExpanderProps {
  onCategorySelect: (c: Category) => void;
  onItemSelect: (c: Category, i: Item) => void;
  onBackClick: () => void;
}

export function InventoryExpander(props: InventoryExpanderProps) {
  const classes = useStyles({});
  const [{ scrolling, expandedItems }, manager] = useInventoryManager();
  const log = manager.catalog

  function buildCategoryExpander(cat: Category, catSelect: (c: Category) => void, itemSelect: (c: Category, i: Item) => void) {
    const [expanded, setExpanded] = useState(expandedItems.find((item) => item.id == cat.categoryID));
    const completionGoal = cat.items.length;
    let completed = 0;

    const handleExpand = (category: Category) => (event, isExpanded: boolean) => {
      if (isExpanded) {
        const expanded: InventoryExpandedItem = { id: category.categoryID, items: [] };
        expandedItems.push(expanded);
        setExpanded(expanded);
      }
      else {
        const index = expandedItems.findIndex((item) => item.id == category.categoryID);
        if (index >= 0)
          expandedItems.splice(index, 1);
        setExpanded(null);
      }
    }

    const itemPanel = (item: Item) => {
      const [itemExpanded, setItemExpanded] = useState(Boolean(expanded && expanded.items.find((itemID) => itemID == item.itemID)));

      const handleExpand = (event, isExpanded: boolean) => {
        if (isExpanded) {
          expanded.items.push(item.itemID);
          setItemExpanded(true);
        }
        else {
          const index = expanded.items.findIndex((itemID) => itemID == item.itemID);
          if (index >= 0)
            expanded.items.splice(index, 1);
          setItemExpanded(false);
        }
      }
      if (Boolean(expanded))
        return (
          <ExpansionPanel expanded={itemExpanded} onChange={handleExpand} key={item.itemID}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.summaryContent}>
                {manager.isComplete(item) ? <Icon><CheckCircleIcon style={{ color: 'Chartreuse' }} /></Icon> : null}
                <Typography>{item.itemName}</Typography>
              </div>
            </ExpansionPanelSummary>
            <div className={classes.itemButtonContainer}>
              <Button className={classes.startButton} onClick={() => { itemSelect(cat, item) }} variant='contained'>Enter counts<ArrowForwardIcon className={classes.buttonIcon} /></Button>
            </div>
            {
              (manager.hasVariations(item))
                ? (
                  <ExpansionPanelDetails className={classes.expander}>
                    {item.variations.map(variant => (
                      <div className={classes.summaryContent} key={variant.variationID}>
                        {variant.newCount != null ? <Icon><CheckCircleIcon style={{ color: 'Chartreuse' }} /></Icon> : null}
                        <Typography>
                          {variant.variationDescription}
                        </Typography>
                      </div>
                    ))}
                  </ExpansionPanelDetails>
                )
                : null
            }
          </ExpansionPanel>
        )
      return <div key={item.itemID}></div>
    }
    const itemPanels = cat.items.map(item => {
      if (manager.isComplete(item)) completed++;
      return itemPanel(item);
    })

    return (
      <ExpansionPanel expanded={Boolean(expanded)} onChange={handleExpand(cat)} key={cat.categoryID}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          {completed == completionGoal ? <Icon><CheckCircleIcon style={{ color: 'Chartreuse' }} /></Icon> : null}
          <Typography>{cat.categoryName}</Typography>
        </ExpansionPanelSummary>
        <div className={classes.buttonContainer}>
          <Button className={classes.startButton} onClick={() => { catSelect(cat) }} variant='contained'>Enter counts<ArrowForwardIcon className={classes.buttonIcon} /></Button>
        </div>
        <ExpansionPanelDetails className={classes.expander}>
          {itemPanels}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const scrollingRef = React.useRef(null);

  useLayoutEffect(() => {
    if (scrollingRef.current)
      scrollingRef.current.scrollTop = scrolling.top;
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrolling.top = event.currentTarget.scrollTop;
  }
  return (
    <div className={classes.outerDiv} ref={scrollingRef} onScroll={handleScroll}>
      <Paper className={classes.root}>
        {log.map(cat => buildCategoryExpander(cat, props.onCategorySelect, props.onItemSelect))}
      </Paper>
      <Fab onClick={props.onBackClick} className={classes.fab} style={{ position: 'fixed' }}><ExitIcon /></Fab>
    </div>
  )
}