import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { InventoryCostsTotals } from './InventoryCostsDataManager';
import { Typography } from '@material-ui/core';
import { numberToDollarString } from '../utils/Convert';
import { useInventoryCosts } from './InventoryCostsHandler';

interface InventoryCostFooterProps {

}

export function InventoryCostFooter(props: InventoryCostFooterProps) {
  const classes = useStyles({});
  const [{}, manager] = useInventoryCosts();
  const [workbookTotals, setWorkbookTotals] = useState<InventoryCostsTotals>({
    workbookTotal: 0,
    worksheetTotal: 0,
    categoryTotal: 0,
    storeName: null,
    categoryName: "",
  });


  const handleTotalsUpdate = (totals: InventoryCostsTotals) => {
    setWorkbookTotals({ ...totals });
  }

  useEffect(() => {
    manager.subscribeToTotals(handleTotalsUpdate);
    return function cleanup() {
      manager.unsubscribeFromTotals();
    }
  }, [])

  return (
    <div className={classes.footer}>
      {workbookTotals.storeName
      ? <div className={classes.totalTextContainer}>
        <Typography variant='caption'>Costs for {workbookTotals.storeName}</Typography>
        <Typography variant='body1'>{numberToDollarString(workbookTotals.worksheetTotal)}</Typography>
      </div>
      : null
      }
      {workbookTotals.categoryName
        ? <div className={classes.totalTextContainer}>
          <Typography variant='caption'>Costs for {workbookTotals.categoryName}</Typography>
          <Typography variant='body1'>{numberToDollarString(workbookTotals.categoryTotal)}</Typography>
        </div>

        : null
      }
      <div className={classes.totalTextContainer}>
        <Typography variant='caption'>Total costs:</Typography>
        <Typography variant='body1'>{numberToDollarString(workbookTotals.workbookTotal)}</Typography>
      </div>
    </div>
  )
}
