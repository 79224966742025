import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  layout: {
    width: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: "100%",
    overflowY: "auto",
    [theme.breakpoints.up(600 + 280 + theme.spacing(2 * 2))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  listBox: {
    width: "100%",
    flexBasis: 'auto',
    flex: '1 1',
    overflowY: "auto",
    overflowX: "hidden",
  },
  formControl: {
    width: "100%",
  },
  companyList: {
    maxHeight: "50vh",
  },
  attributeList: {
    maxHeight: "35vh",
  },
  inputLabel: {
    fontSize: "0.75rem",
    // fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    lineHeight: 0.75
  },
  button: {
    marginTop: theme.spacing(1),
  },
  numRoot: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 'auto',
    height: 'auto',
    maxWidth: 350,
    maxHeight: 450,
  },
  configContainer: {

  },
  authContainer: {

  },
}));

