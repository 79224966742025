import React, { useState } from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import { LinearLoading } from '../common/LoadingComponent';
import { getJSONHeaders, loadCustomersFromFirestoreURL, loadTransactionsFromFirestoreURL } from '../utils/WireUtils';

export interface SystemDashboardContainerProps {
  onClose: () => void;
}

export const SystemDashboardContainer = (props: SystemDashboardContainerProps) => {
  const [dialogMessage, setDialogMessage] = useState(null);
  const [onDialogOk, setOnDialogOk] = useState(null);
  const [processing, setProcessing] = useState(null);

  const handleLoadCustomersFromFirestore = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setDialogMessage("Do you really want to load the customer data from the Firestore database?");
    setOnDialogOk(() => handleLoadFirestoreCustomers);
  }

  const handleLoadTransactionsFromFirestore = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setDialogMessage("Do you really want to load the transaction data from the Firestore database?");
    setOnDialogOk(() => handleLoadFirestoreTransactions);
  }

  const handleLoadFirestoreCustomers = async () => {
    setProcessing("Loading Customers...");
    try {
      const response = await fetch(loadCustomersFromFirestoreURL, {
        method: "GET",
        headers: getJSONHeaders(),
      })
      if (response.ok) {
        const message = await response.json();
        if (message)
          setDialogMessage("Received " + message.received + " records, loaded " + message.loaded + " records");
        else
          setDialogMessage("Did not receive response data")
      }
      else
        setDialogMessage("Response not ok. status is " + response.statusText);
    }
    catch (e) {
      setDialogMessage("Processing Exception " + e.TypeError);
    }
    setProcessing(null);
  }

  const handleLoadFirestoreTransactions = async () => {
    setProcessing("Loading Transactions...");
    try {
      const response = await fetch(loadTransactionsFromFirestoreURL, {
        method: "GET",
        headers: getJSONHeaders(),
      })
      if (response.ok) {
        const message = await response.json();
        if (message)
          setDialogMessage("Received " + message.received + " records, loaded " + message.loaded + " records");
        else
          setDialogMessage("Did not receive response data")
      }
      else
        setDialogMessage("Response not ok. status is " + response.statusText);
    }
    catch (e) {
      setDialogMessage("Processing Exception " + e.TypeError);
    }
    setProcessing(null);
  }

  const DialogOrNull = () => {

    const handleDialogOk = () => {
      setDialogMessage(null);
      onDialogOk && onDialogOk();
      setOnDialogOk(null);
    }

    const handleDialogCancel = () => {
      setDialogMessage(null);
      setOnDialogOk(null);
    }

    if (dialogMessage) {
      return (
        <Dialog open>
          <DialogTitle>{dialogMessage}</DialogTitle>
          <DialogActions>
            <Button color="default" onClick={handleDialogOk}>OK</Button>
            <Button color="secondary" onClick={handleDialogCancel}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )
    }
    return null;
  }

  const ProcessingOrNull = () => {
    if (processing)
      return <LinearLoading message={processing} />
    return null;
  }

  return (
    <div>
      <ProcessingOrNull />
      <DialogOrNull />
      <Typography variant="h4">System Dashboard</Typography>
      <Button variant="contained" color="primary" onClick={handleLoadCustomersFromFirestore}>Load Customers From Firestore</Button>
      <Button variant="contained" color="primary" onClick={handleLoadTransactionsFromFirestore}>Load Transactions From Firestore</Button>
    </div>
  )
}