import { DateAndStore, StoreCountsForDate } from './CountHandler';

export class CategoryCountDataItem {
  itemName: string;
  variationDescription: string;
  countsByStore: Array<number>;

}
export class CategoryCountData {
  categoryName: string;
  items: Array<CategoryCountDataItem>
}

export class CountManager {

  // private static _instance: CountManager = null;
  // private constructor() { }
  // public static get Instance() {
  //   if (this._instance == null) {
  //     this._instance = new CountManager();
  //   }
  //   return this._instance;
  // }

  private _datesAndStores: Array<DateAndStore>;
  private _storeCounts: Array<StoreCountsForDate>;
  private _stores: Array<string>;
  private _categories: Array<string>;
  private _categoryData: Array<CategoryCountData>;

  get datesAndStores(): Array<DateAndStore> {
    return this._datesAndStores;
  }

  set datesAndStores(datesAndStores: Array<DateAndStore>) {
    this._datesAndStores = datesAndStores;
  }

  set storeCountsForDate(storeCounts: Array<StoreCountsForDate>) {
    this._storeCounts = storeCounts;
    this._categories = null;
    this._stores = null;
    this._categoryData = [];
  }

  get storeCountsForDate() {
    return this._storeCounts;
  }

  get dates(): Array<Date> {
    const dates: Array<Date> = [];
    this._datesAndStores.forEach((dateAndStore: DateAndStore) => {
      if (!dates.find((date) => date.valueOf() == dateAndStore.inventoryDate.valueOf()))
        dates.push(dateAndStore.inventoryDate);
    })
    return dates;
  }

  getStoresForDate(date: Date): Array<DateAndStore> {
    return this._datesAndStores.filter((dateAndStore: DateAndStore) => dateAndStore.inventoryDate.valueOf() == date.valueOf());
  }

  get stores(): Array<string> {
    if (this._stores)
      return this._stores;
    const stores: Array<string> = [];
    this._storeCounts.forEach((storeCount: StoreCountsForDate) => {
      if (!stores.find((store: string) => store == storeCount.storeName))
        stores.push(storeCount.storeName);
    })

    return this._stores = stores.sort((store1, store2) => store1 >= store2 ? 1 : -1)
  }

  get categories(): Array<string> {
    if (this._categories)
      return this._categories;
    const categories: Array<string> = [];
    this._storeCounts.forEach((storeCount: StoreCountsForDate) => {
      if (!categories.find((category: string) => category == storeCount.categoryName))
        categories.push(storeCount.categoryName);
    })

    return this._categories = categories.sort((category1, category2) => category1 >= category2 ? 1 : -1)
  }

  get columnHeadings(): Array<string> {
    const headings = ["Item", "Variation"];
    this.stores.forEach((store) => headings.push(store));
    headings.push("Total");

    return headings;
  }

  private addCategoryData(categoryName: string): CategoryCountData {
    const categoryData = new CategoryCountData;

    categoryData.categoryName = categoryName;
    categoryData.items = [];

    const storeCounts = this._storeCounts.filter((storeCount) => storeCount.categoryName == categoryName);
    storeCounts.forEach((storeCount: StoreCountsForDate) => {
      var counts = categoryData.items.find((item: CategoryCountDataItem) => (
        item.itemName == storeCount.itemName &&
        item.variationDescription == storeCount.variationDescription));
      if (counts == null) {
        counts = new CategoryCountDataItem;
        counts.itemName = storeCount.itemName;
        counts.variationDescription = storeCount.variationDescription;
        counts.countsByStore = new Array<any>(this.stores.length).fill("X");
        categoryData.items.push(counts);
      }
      counts.countsByStore[this.stores.indexOf(storeCount.storeName)] = storeCount.count;
    })
    this._categoryData.push(categoryData);
    return categoryData;
  }

  getCategoryData(categoryName: string): CategoryCountData {
    const categoryData: CategoryCountData = this._categoryData.find((categoryData) => categoryData.categoryName == categoryName);

    if (categoryData != null)
      return categoryData;

    return this.addCategoryData(categoryName);
  }
}
