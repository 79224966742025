import React, { useState, useLayoutEffect } from 'react';
import { ListItem, List, ListItemText, ListSubheader, ListItemSecondaryAction, ListItemIcon, CircularProgress, Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle'
import { StoreShippingAndReceivingItem, VendorItems } from './ShippingData';
import { TypographyProps } from '@material-ui/core/Typography';
import { updateShippingItemStatus, downgradeShippingItemStatus, updateShippingItemCount, useShipToStores } from './ShippingHandler';
import { ChangeTransferCountDialog } from '../common/ChangeTransferCountDialog';
import { ShippingAndReceivingItemUpdate } from '../wireData/storeShippingAndReceivingData';
import { useStyles } from './styles';
import { useStore } from 'react-hookstore';

// const ShippingManager = StoreShippingAndReceivingDataManager.ShippingInstance;

export interface ShippingListProps {
  storeName: string;
}

export function ShippingList(props: ShippingListProps) {
  const classes = useStyles({});

  const [{ scrolling }, manager] = useShipToStores();

  const scrollinfRef = React.useRef(null);

  useLayoutEffect(() => {
    scrollinfRef.current.scrollTop = scrolling.top;
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrolling.top = event.currentTarget.scrollTop;
  }

  class SubHeaderProps {
    vendor: VendorItems;
  }

  const SubHeader = (props: SubHeaderProps) => {
    class ShippingItemProps {
      shippingItem: StoreShippingAndReceivingItem;
    }

    const primaryProps: TypographyProps = {
      variant: "h5"
    }

    const secondaryProps: TypographyProps = {
      variant: "h6"
    }

    const ShippingItem = (props: ShippingItemProps) => {
      const [checked, setChecked] = useState(props.shippingItem.shippingStatus == "shipped")
      const [shippingItemToUpdate, setShippingItemToUpdate] = useState(null);

      const handleClick = (shippingItem: StoreShippingAndReceivingItem) => (event) => {
        const update = new ShippingAndReceivingItemUpdate();
        update.itemStatus = shippingItem.shippingStatus;
        update.orderItemID = shippingItem.orderItemID;

        let operation: (update: ShippingAndReceivingItemUpdate) => Promise<any>;
        if (checked) {
          operation = downgradeShippingItemStatus;
        } else {
          operation = updateShippingItemStatus;
        }
        operation(update).then((result: ShippingAndReceivingItemUpdate) => {
          manager.updateStatus(result);
          setChecked(result.itemStatus == 'shipped');
        }).catch(e => {
          alert(e.message || e);
        });
      }

      const handleCountButtonClick = (event) => {
        event.stopPropagation();
        setShippingItemToUpdate(props.shippingItem);
      }

      const handleChangeCountDialogClose = () => {
        setShippingItemToUpdate(null);
      }

      const handleCountUpdate = (newValue: number) => {
        const update = new ShippingAndReceivingItemUpdate();
        update.quantityShipped = newValue;
        update.orderItemID = shippingItemToUpdate.orderItemID;

        updateShippingItemCount(update).then((result: ShippingAndReceivingItemUpdate) => {
          manager.updateShippedCount(result);
          setShippingItemToUpdate(null);
        }).catch(e => {
          alert(e.message || e);
        });
      }

      const CountDialog = () => {
        return (
          <ChangeTransferCountDialog
            open
            initialValue={shippingItemToUpdate.quantityShipped}
            transferItem={shippingItemToUpdate}
            onClose={handleChangeCountDialogClose}
            onUpdate={handleCountUpdate}
          />
        )
      }

      const ButtonOrProgress = () => {
        if (shippingItemToUpdate == props.shippingItem)
          return (
            <div>
              <CircularProgress />
              <CountDialog />
            </div>
          )
        return (
          <Button onClick={handleCountButtonClick}>
            <Typography variant="h4">{props.shippingItem.quantityReceived == null ? 0 : props.shippingItem.quantityShipped}</Typography>
          </Button>
        )
      }

      return (
        <ListItem button onClick={handleClick(props.shippingItem)} key={props.shippingItem.orderItemID}>
          <ListItemIcon>
            <ButtonOrProgress />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={primaryProps}
            secondaryTypographyProps={secondaryProps}
            primary={props.shippingItem.itemName}
            secondary={props.shippingItem.variationDescription ? props.shippingItem.variationDescription : null} />
          <ListItemSecondaryAction>
            <CheckIcon style={{ color: checked ? "Chartreuse" : "lightgray" }} />
          </ListItemSecondaryAction>
        </ListItem>
      )
    }

    return (
      <li className={classes.vendorSection}>
        <ul className={classes.listItem}>
          <ListSubheader className={classes.vendorHeader}><Typography variant="h4">{props.vendor.vendorName}</Typography></ListSubheader>
          {
            props.vendor.items.map((shippingItem) =>
              <ShippingItem key={shippingItem.orderItemID} shippingItem={shippingItem} />
            )
          }
        </ul>
      </li>
    )
  }

  return (
    <div className={classes.list}>
      <Typography variant="h6" align="center" gutterBottom>Send Items to {props.storeName}</Typography>
      <div className={classes.vendorList} ref={scrollinfRef} onScroll={handleScroll}>
        <List>
          {manager.vendors.map((vendor) => (
            <SubHeader vendor={vendor} key={vendor.vendorName} />
          ))}
        </List>
      </div>
    </div>
  )
}