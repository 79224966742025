import { companiesURL, loginToken, getJSONHeaders, updateCompanyURL, removeCompanyURL, addCompanyToUserURL, removeCompanyFromUserURL, getUserCompaniesURL, getFilteredCompaniesURL } from '../utils/WireUtils';
import { CompanyData } from '../wireData/CompanyData';
import { useState, useEffect } from 'react';
import { UserCompanyData } from '../wireData/UserCompanyData';
import { ReducerStoreInterface, getStoreByName, createStore, useStore, StateStoreInterface } from 'react-hookstore';
import { BaseState, BaseAction } from '../common/BaseHandler';

export const companyEditStoreName = "companyEditStore";

export const getOrCreateEditCompanyStore = (company: CompanyData): StateStoreInterface<CompanyData> => {
  try {
    const store = getStoreByName<CompanyData>(companyEditStoreName);
    store.setState(company);
    return store;
  }
  catch (e) {
    return createStore<CompanyData>(companyEditStoreName, company);
  }
}

// type CompanyActionTypes = "LOADING" | "ERROR" | "GET_DATA" | "UPDATE";

// interface CompanyError{
//   message:string;
// }

class CompanyState extends BaseState {
  // error: CompanyError;
  // loading: boolean;
  // success: boolean;
  currentCompany: CompanyData;
  companies: Array<CompanyData>;
  // _actions: CompanyActions;
}

// class CompanyAction {
//   actionType: CompanyActionTypes;
//   payload?: Partial<CompanyState>;
// }

const companyStoreName = "companyStore";

// type CompanyReducerInterface = ReducerStoreInterface<CompanyState, CompanyAction>;

// function getOrCreateCompanyStore(): CompanyReducerInterface {
//   try {
//     return getStoreByName<CompanyState, CompanyAction>(companyStoreName);
//   }
//   catch (e) {
//     const actions = new CompanyActions();
//     const store = createStore<CompanyState, CompanyAction>(companyStoreName, actions.initialState, companyReducer);
//     actions.store = store;
//     return store;
//   }
// }

// const companyReducer = (state: CompanyState, action: CompanyAction): CompanyState => {

//   switch (action.actionType) {
//     case "LOADING":
//       return { ...state, loading: true, success: false, companies: [] };
//     case "ERROR":
//       return { ...state, loading: false, success: false, ...action.payload, companies: [] }
//     case "GET_DATA":
//       return { ...state, loading: false, success: true, ...action.payload };
//     case "UPDATE":
//       return { ...state, ...action.payload };
//   }
//   console.log("Unknown company action %s", action.actionType);
//   return state;
// }

class CompanyActions extends BaseAction<CompanyState> {

  get initialState(): CompanyState {
    return {
      error: null,
      loading: false,
      success: false,
      currentCompany: null,
      companies: [],
      _actions: this,
    }
  }

  mapData = (body:any):Partial<CompanyState>=>{
    const companies = body.map((company) => new CompanyData(company));
    return {companies: companies};
  }

  getCompanies = () =>{
    this.updateState({companies: []});
    this.getData(companiesURL, this.mapData)
  }

  // getData = async () => {
  //   try {
  //     this._store.dispatch({ actionType: "LOADING" });
  //     const response = await fetch(companiesURL, {
  //       method: 'GET',
  //       headers: getJSONHeaders()
  //     });
  //     if (response.ok) {
  //       const body = await response.json();
  //       if (body.message)
  //         this._store.dispatch({ actionType: "ERROR", payload: body });
  //       else
  //         this._store.dispatch({ actionType: "GET_DATA", payload: this.mapData(body)});
  //     }
  //     else
  //       this._store.dispatch({ actionType: "ERROR", payload: {error: { message: response.statusText }} })
  //   }
  //   catch (e) {
  //     this._store.dispatch({ actionType: "ERROR", payload: {error: { message: e.message }} })
  //   }
  // };

  setCurrentCompany = (currentCompany: CompanyData) => {
    this.updateState( { currentCompany: currentCompany } );
  }
}

export function resetCompanyState() {
  BaseAction.getAction(companyStoreName, CompanyActions).reset();
}


export const useCompanies = (): [CompanyState, CompanyActions] => {
  return BaseAction.getAction(companyStoreName, CompanyActions).useStore();
}

export const useUpdateCompany = (companyToUpdate: CompanyData): {
  error: any,
  updating: boolean,
  company: CompanyData
} => {
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [company, setCompany] = useState<CompanyData>(null);

  useEffect(() => {
    setError(null);
    setUpdating(false);
    setCompany(null);
    if (companyToUpdate) {
      setUpdating(true);
      fetch(updateCompanyURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(companyToUpdate)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((company) => {
                setCompany(new CompanyData(company));
                setUpdating(false);
              })
              .catch((e) => {
                setError(e);
                setUpdating(false);
              })
          } else {
            setError(new Error(response.statusText));
            setUpdating(false);
          }
        })
        .catch((e) => {
          setError(e);
          setUpdating(false);
        })
    }
  }, [companyToUpdate]);

  return { error, updating, company };
}

export const useRemoveCompany = (companyToRemove: CompanyData): {
  error: any,
  removing: boolean,
  company: CompanyData
} => {
  const [error, setError] = useState(null);
  const [removing, setRemoving] = useState(false);
  const [company, setCompany] = useState<CompanyData>(null);

  useEffect(() => {
    setError(null);
    setRemoving(false);
    setCompany(null);
    if (companyToRemove) {
      setRemoving(true);
      fetch(removeCompanyURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(companyToRemove)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((company) => {
                setCompany(new CompanyData(company));
                setRemoving(false);
              })
              .catch((e) => {
                setError(e);
                setRemoving(false);
              })
          } else {
            setError(new Error(response.statusText));
            setRemoving(false);
          }
        })
        .catch((e) => {
          setError(e);
          setRemoving(false);
        })
    }
  }, [companyToRemove]);

  return { error, removing, company };
}

export async function getCompanies(
  setResult: (status: string, companies: CompanyData[]) => void): Promise<any> {
  setResult("Fetching", []);
  return fetch(companiesURL, {
    method: "GET",
    headers: getJSONHeaders(),
  })
    .then(async function (response) {
      if (response.status >= 200 && response.status < 300) {
        const body = await response.json();
        setResult("Success", body);
      } else {
        const body = await response.json();
        setResult(body.message, []);
      }
    }).catch(function (reason) {
      setResult(JSON.stringify(reason), []);
    })
}

export async function updateCompany(company: CompanyData) {
  const response = await fetch(updateCompanyURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginToken}`
    },
    body: JSON.stringify(company)
  });//.then(async (response) => {
  if (response.ok) {
    return "Success";
  } else {
    const body = await response.json();
    return (body.message);
  }
}

export async function removeCompany(company: CompanyData) {
  const response = await fetch(removeCompanyURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginToken}`
    },
    body: JSON.stringify(company)
  });//.then(async (response) => {
  if (response.ok) {
    return "Success";
  } else {
    const body = await response.json();
    return (body.message);
  }
}

export async function addCompanyToUser(userID: number, companyID: number) {
  const response = await fetch(addCompanyToUserURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginToken}`
    },
    body: JSON.stringify({ userID: userID, companyID: companyID })
  });
  if (response.ok) {
    return "Success";
  } else {
    const body = await response.json();
    return (body.message);
  }
}

export async function removeCompanyFromUser(userID: number, companyID: number) {
  const response = await fetch(removeCompanyFromUserURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginToken}`
    },
    body: JSON.stringify({ userID: userID, companyID: companyID })
  });
  if (response.ok) {
    return "Success";
  } else {
    const body = await response.json();
    return (body.message);
  }
}

export const useGetUserCompanies = (refresh = true): {
  error: any,
  loading: boolean,
  //  success: boolean,
  companies: Array<UserCompanyData>
} => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  //  const [success, setSuccess] = useState(false);
  const [companies, setCompanies] = useState<Array<UserCompanyData>>([]);

  useEffect(() => {
    setLoading(true);
    fetch(getUserCompaniesURL, {
      method: 'GET',
      headers: getJSONHeaders()
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          response.json()
            .then((companys) => {
              setCompanies(companys.map((company) => new CompanyData(company)));
              //              setSuccess(true);
            })
            .catch((e) => {
              setError(e);
            })
        } else {
          setError(new Error(response.statusText));
        }
      })
      .catch((e) => {
        setError(e);
      })
  }, [refresh]);

  return { error, loading, companies };
}

export const lookupCompaniesByName = async (searchKey: string, max: number = 10): Promise<Array<CompanyData>> => {
  let query = "";

  if (searchKey)
    query = `companyName=${searchKey}&max=${max}`;
  else
    query = `max=${max}`;

  query += "&sortField=companyName";

  try {
    const response = await fetch(`${getFilteredCompaniesURL}?${query}`, {
      method: 'GET',
      headers: getJSONHeaders(),
    })
    if (response.ok) {
      const companies = await response.json();
      return companies;
    }
    else
      throw ("Company lookup error")
  }
  catch (e) {
    throw ("Company lookup error")
  }
}