import { ReceiveTransferDataManager } from "./ReceiveTransfersDataManager";
import { storeReceiveTransferUpdateCountURL, storeReceiveTransferUpdateStatusURL, storeReceiveTransferDowngradeStatusURL, storeReceiveTransfersURL } from "../utils/WireUtils";
import { InventoryTransferQuantityAndStatusUpdate, InventoryTransferItem } from "../wireData/InventoryTransferData";
import { Store } from "../store/Store";
import { BaseState, BaseAction, StatusFields } from "../common/BaseHandler";

const ReceiveStoreTransfersStoreName = 'ReceiveStoreTransfersStore';

export class ReceiveStoreTransfersState extends BaseState {
  transferToUpdate: InventoryTransferItem;
  updateError: any;
  updating: boolean;
  updateSuccess: boolean;
  updatedItem: InventoryTransferQuantityAndStatusUpdate;
  store: Store;
  scrolling: { top: number };
  _manager: ReceiveTransferDataManager;
}

const statusFields: StatusFields<ReceiveStoreTransfersState> = {
  error: "updateError",
  processing: "updating",
  success: "updateSuccess"
};

class ReceiveStoreTransfersActions extends BaseAction<ReceiveStoreTransfersState> {
  get initialState(): ReceiveStoreTransfersState {
    return {
      error: null,
      loading: false,
      success: false,
      transferToUpdate: null,
      updateError: null,
      updating: false,
      updateSuccess: false,
      updatedItem: null,
      store: null,
      scrolling: { top: 0 },
      _manager: null,
      _actions: this
    }
  }

  private mapInventory = (body: any): Partial<ReceiveStoreTransfersState> => {
    const manager = new ReceiveTransferDataManager();
    manager.ReceiveTransferData = body;
    return {
      error: null,
      loading: false,
      success: true,
      scrolling: { top: 0 },
      _manager: manager,
    };
  }

  getInventory = async (storeID: number) => {
    return this.getData(`${storeReceiveTransfersURL}/${storeID}`, this.mapInventory)
  }

  private mapUpdate = (body: any): Partial<ReceiveStoreTransfersState> => {
    return { updatedItem: body as InventoryTransferQuantityAndStatusUpdate }
  }

  updateReceiveTransferItemCount = async (update: InventoryTransferQuantityAndStatusUpdate) => {
    return this.postData(storeReceiveTransferUpdateCountURL, update, this.mapUpdate, statusFields);
  }

  updateReceiveTransferItemStatus = async (update: InventoryTransferQuantityAndStatusUpdate) => {
    return this.postData(storeReceiveTransferUpdateStatusURL, update, this.mapUpdate, statusFields);
  }

  downgradeReceiveTransferItemStatus = async (update: InventoryTransferQuantityAndStatusUpdate) => {
    return this.postData(storeReceiveTransferDowngradeStatusURL, update, this.mapUpdate, statusFields);
  }

  setStore = (store: Store) => {
    this.updateState({ store: store, _manager: null, error: null, success: false });
  }

  setTransferToUpdate = (transfer: InventoryTransferItem) => {
    this.updateState({ transferToUpdate: transfer })
  }

  clearUpdate = () => {
    this.updateState({ transferToUpdate: null, updatedItem: null, updateError: null, updating: false, updateSuccess: false });
  }
}

export const resetReceiveStoreTransfersStore = () =>{
  BaseAction.getAction(ReceiveStoreTransfersStoreName, ReceiveStoreTransfersActions).reset();
}

export const useReceiveStoreTransfers = (): [ReceiveStoreTransfersState, ReceiveTransferDataManager, ReceiveStoreTransfersActions] => {
  const [state, actions] = BaseAction.getAction(ReceiveStoreTransfersStoreName, ReceiveStoreTransfersActions).useStore();
  return [state, state._manager, actions];
}

