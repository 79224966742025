import { Store } from "../store/Store";
import { StoreInventoryCostsWorkbook, StoreInventoryCostsWorksheet, InventoryCostsTable } from "../wireData/InventoryCostData";

export class InventoryCostsTotals {
  constructor() {
    this.workbookTotal = 0;
    this.worksheetTotal = 0;
    this.categoryTotal = 0;
    this.storeName = null;
    this.categoryName = null;
  }
  workbookTotal: number;
  worksheetTotal: number;
  categoryTotal: number;
  storeName: string;
  categoryName: string;
}

export class InventoryCostsDataManager {


  // private static _instance: InventoryCostsDataManager = null;
  private _totalsSubscriber: (totals: InventoryCostsTotals) => void = null;
  private _totals: InventoryCostsTotals = new InventoryCostsTotals();

  // private constructor() {

  // }

  // public static get Instance() {
  //   if (this._instance == null) {
  //     this._instance = new InventoryCostsDataManager();
  //   }
  //   return this._instance;
  // }

  private _workbook: StoreInventoryCostsWorkbook;
  displayedWorksheet: InventoryCostsTable;
  totalSheet: InventoryCostsTable;
  currentCategoryFilter: number = 0;
  currentSheetIndex = 0;
  currentStoreName: string;

  set workbook(book: StoreInventoryCostsWorkbook
  ) {
    this._workbook = book;
    this.currentCategoryFilter = -1;
    if (book) {
      // if (book.worksheets.length > 0) {
        this.selectSheet(this.currentSheetIndex);
      // }
      this.totalSheet = book.totals;
    }
    else {
      this._workbook = new StoreInventoryCostsWorkbook();
//      this.currentSheetIndex = 0;
      this.totalSheet = [];
      this.displayedWorksheet = [];
      this._totals = new InventoryCostsTotals();
    }
  }

  selectSheet(sheetIndex: number) {
    this.currentSheetIndex = sheetIndex;
    if (sheetIndex >= 0 && sheetIndex < this._workbook.worksheets.length) {
      this.displayedWorksheet = this._workbook.worksheets[this.currentSheetIndex].costs;
      this.currentStoreName = this._workbook.worksheets[this.currentSheetIndex].storeName;
    }
    else {
      this.displayedWorksheet = this._workbook.totals;
      this.currentStoreName = null;
    }
    this.filterWorksheet();
    this.calculateTotals();
}

  selectCategory(category: number, categoryName: string) {
    this.currentCategoryFilter = category;
    this._totals.categoryName = categoryName;
    if (this._workbook)
      this.selectSheet(this.currentSheetIndex);
  }

  filterWorksheet() {
    if (this.currentCategoryFilter >= 0)
      this.displayedWorksheet =
        this.displayedWorksheet.filter(count => count.categoryID == this.currentCategoryFilter)
  }

  calculateTotals() {
    this._totals.workbookTotal = this._workbook.totals.map(
      (item) => (item.total)).reduce((subTotal, value) => (subTotal + value), 0);

    if (this.currentCategoryFilter >= 0) {
      this._totals.categoryTotal = this.displayedWorksheet.map(
        (item) => (item.total)).reduce((subtotal, value) => (subtotal + value), 0);
    }
    else
      this._totals.categoryName = null;

    if (this.currentSheetIndex >= 0 && this.currentSheetIndex < this._workbook.worksheets.length ) {
      this._totals.worksheetTotal = this._workbook.worksheets[this.currentSheetIndex].costs.map(
        (item) => (item.total)).reduce((subTotal, value) => (subTotal + value), 0);

      this._totals.storeName = this.currentStoreName;
    }
    else
      this._totals.storeName = null;

    if (this._totalsSubscriber) {
      this._totalsSubscriber(this._totals)
    }
  }

  subscribeToTotals(callback: (totals: InventoryCostsTotals) => void) {
    this._totalsSubscriber = callback;
    if (callback)
      callback(this._totals);
  }

  unsubscribeFromTotals() {
    this._totalsSubscriber = null;
  }
}
