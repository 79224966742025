import React from 'react';
import { Store } from './Store';
import {  Button, Paper, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { StoreList } from './StoreList';

interface StoreSelectProps {
  message?: string;
  onStoreSelected: (store: Store) => void;
  onClose: () => void;
}

export function StoreSelect(props: StoreSelectProps) {
  const classes = useStyles({});


  const BottomMessage = () =>{
    if ( props.message) return <Typography variant="h6" align="center" color="error">{props.message}</Typography>
    return null;
  }

  return (
    <div className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography variant="h6" align="center" gutterBottom>Select a Store</Typography>
        <StoreList onSelectStore={props.onStoreSelected} />
        <Button
          variant='contained'
          fullWidth
          className={classes.button}
          onClick={props.onClose}
        >
          Close
        </Button>
        <BottomMessage />
      </Paper>
    </div>
  )
}