import React, { useState } from 'react';
import { Store } from '../store/Store';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, TextField, Button, Typography, Paper, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Moment } from "moment";
import { StoreList } from '../store/StoreList';
import { useInventoryManager } from './InventoryHandler';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: 'auto',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up(500 + theme.spacing(2 * 2))]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    // height: 600,
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(1)
  }
}))

interface StoreDateSelectProps {
  onSubmit: () => void;
  onCancel: () => void;
}

export function StoreDateSelect(props: StoreDateSelectProps) {
  const classes = useStyles({});
  const [{ store, date }, , actions] = useInventoryManager();

  const handleDateChange = (date: Moment) => {
    actions.updateInventoryState({ date: date.toDate() });
    // manager.date = date.toDate();
  }

  const handleStoreSelect = (store: Store) => {
    actions.updateInventoryState({ store: store });
    // manager.store = store;
  }
  const handleSubmit = () => {
    if (store == null || date == null) {
      return;
    }
    props.onSubmit();
  }

  const handleCancel = () => {
    props.onCancel();
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} >
      <Paper className={classes.root}>
        <DatePicker label="Inventory Date" value={date} onChange={handleDateChange} autoOk format="MM/DD/YYYY" />
        <Grid container direction="row" spacing={0}>
          <Grid item xs={12}>
            <StoreList onSelectStore={handleStoreSelect} />
          </Grid>
        </Grid>
        <Typography variant='body1' gutterBottom>{store ? store.storeName : 'No store selected'}</Typography>
        <Grid item xs={12}>
          <Grid container direction="row" justify="space-around" spacing={0}>
            <Grid item xs={3}>
              <Button fullWidth disabled={store == null || date == null} variant='contained' onClick={handleSubmit}>Start</Button>
            </Grid>
            <Grid item xs={3}>
              <Button fullWidth variant='contained' onClick={handleCancel}>Cancel</Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </MuiPickersUtilsProvider>
  )
}