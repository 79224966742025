import { StoreShippingAndReceivingDataManager } from "./ShippingAndReceivingDataManager";
import { loginToken, storeShippingItemsToShipURL, storeShippingItemUpdateCountURL, storeShippingItemUpdateStatusURL, storeShippingItemDowngradeStatusURL, getJSONHeaders, storeReceivingItemsToShipURL, storeReceivingItemUpdateCountURL, storeReceivingItemUpdateStatusURL, storeReceivingItemDowngradeStatusURL } from "../utils/WireUtils";
import { Store } from "../store/Store";
import { ShippingAndReceivingItemUpdate, StoreShippingAndReceivingItem } from "../wireData/storeShippingAndReceivingData";
import { BaseState, BaseAction } from "../common/BaseHandler";

const storeShippingAndRecivingName = (storeType: StoreType) => `store${storeType}Store`;

// const ShippingManager = StoreShippingAndReceivingDataManager.ShippingInstance;

// type StoreShippingAndReceivingActionTypes = "LOADING" | "GET_DATA" | "ERROR" | "REFRESH" | "CLEAR" | "UPDATE";

// interface StoreShippingAndReceivingAction {
//   actionType: StoreShippingAndReceivingActionTypes;
//   payload?: any;
// }

export class StoreShippingAndReceivingState extends BaseState {
  itemToUpdate: StoreShippingAndReceivingItem;
  updateError: any;
  updating: boolean;
  updateSuccess: boolean;
  updatedItem: ShippingAndReceivingItemUpdate;
  store: Store;
  scrolling: { top: number };
  _manager: StoreShippingAndReceivingDataManager;
}

type StoreType = "shipping" | "receiving"

// export const StoreShippingAndReceivingStore = (storeType: StoreType): ReducerStoreInterface<StoreShippingAndReceivingState, StoreShippingAndReceivingAction> => {
//   try {
//     return getStoreByName<StoreShippingAndReceivingState, StoreShippingAndReceivingAction>(`store${storeType}Store`);
//   }
//   catch (e) {
//     const actions = new StoreShippingAndReceivingActions();
//     const store = createStore<StoreShippingAndReceivingState, StoreShippingAndReceivingAction>(`store${storeType}Store`,
//       {
//         error: null,
//         loading: false,
//         success: false,
//         store: null,
//         scrolling: { top: 0 },
//         _manager: null,
//         _actions: actions
//       }, storeShippingAndReceivingReducer);
//     actions.store = store;
//     actions.direction = storeType;
//     return store;
//   }
// }

class StoreShippingAndReceivingActions extends BaseAction<StoreShippingAndReceivingState> {
  constructor(direction: StoreType) {
    super();
    this._direction = direction;
  }

  private _direction: StoreType;

  public set direction(direction: StoreType) {
    this._direction = direction;
  }

  get initialState(): StoreShippingAndReceivingState {
    return {
      error: null,
      loading: false,
      success: false,
      itemToUpdate: null,
      updateError: null,
      updating: false,
      updateSuccess: false,
      updatedItem: null,
      store: null,
      scrolling: { top: 0 },
      _manager: null,
      _actions: this,
    }
  }

  private mapShipping = (body: any): Partial<StoreShippingAndReceivingState> => {
    const manager = new StoreShippingAndReceivingDataManager();
    manager.ShippingAndReceivingData = body;
    return {
      error: null,
      loading: false,
      success: true,
      scrolling: { top: 0 },
      _manager: manager,
    };
  }

  getShippingOrReceiving = async (storeID: number) => {
    await this.getData(`${this._direction == "shipping" ? storeShippingItemsToShipURL : storeReceivingItemsToShipURL}/${storeID}`, this.mapShipping)
  }
  // getData = async (storeID: number) => {
  //   try {
  //     this._store.dispatch({ actionType: "LOADING" });
  //     const response = await fetch(`${this._direction == "shipping" ? storeShippingItemsToShipURL : storeReceivingItemsToShipURL}/${storeID}`, {
  //       method: 'GET',
  //       headers: getJSONHeaders()
  //     });
  //     if (response.ok) {
  //       const body = await response.json();
  //       if (body.message)
  //         this._store.dispatch({ actionType: "ERROR", payload: body });
  //       else
  //         this._store.dispatch({ actionType: "GET_DATA", payload: body });
  //     }
  //     else
  //       this._store.dispatch({ actionType: "ERROR", payload: { message: response.statusText } })
  //   }
  //   catch (e) {
  //     this._store.dispatch({ actionType: "ERROR", payload: { message: e.TypeError } })
  //   }
  // };

  // refresh = () => {
  //   this._store.dispatch({ actionType: "REFRESH" });
  // };

  // close = () => {
  //   this._store.dispatch({ actionType: "CLEAR" });
  // };

  // update = (properties: {}) => {
  //   this._store.dispatch({ actionType: "UPDATE", payload: properties });
  // };

  setStore = (store: Store) => {
    this.updateState({ store: store, _manager: null, error: null, success: false });
  }
}

// const storeShippingAndReceivingReducer = (state: StoreShippingAndReceivingState, action: StoreShippingAndReceivingAction): StoreShippingAndReceivingState => {
//   switch (action.actionType) {
//     case "LOADING":
//       return { ...state, success: false, error: null, loading: true };
//     case "GET_DATA":
//       const manager = new StoreShippingAndReceivingDataManager();
//       manager.ShippingAndReceivingData = action.payload;
//       return {
//         ...state,
//         error: null,
//         loading: false,
//         success: true,
//         scrolling: { top: 0 },
//         _manager: manager,
//       };
//     case "ERROR":
//       return { ...state, error: action.payload, loading: false, success: false };
//     case "REFRESH":
//       return { ...state, loading: false, error: null, success: false, _manager: null };
//     case "CLEAR":
//       return {
//         ...state,
//         error: null,
//         loading: false,
//         success: false,
//         store: null,
//         scrolling: { top: 0 },
//         _manager: null,
//       };
//     case "UPDATE":
//       return { ...state, ...action.payload };
//   }
//   console.error("Unknown action %s", action.actionType);
//   return state;
// }

// export function refreshShippingWorkbook() {
//   shippingActions.refresh();
// }

// export function closeStockroomShippingWorkbook() {
//   shippingActions.close();
// }

// export function setCurrentShippingStore(store: Store) {
//   shippingActions.update({ store: store, success: false });
// }

export const resetShipToStores = () =>{
  BaseAction.getAction(storeShippingAndRecivingName('shipping'), StoreShippingAndReceivingActions, 'shipping').reset();
}

export const useShipToStores = (): [StoreShippingAndReceivingState, StoreShippingAndReceivingDataManager, StoreShippingAndReceivingActions] => {
  const [state, actions] = BaseAction.getAction(storeShippingAndRecivingName('shipping'), StoreShippingAndReceivingActions, 'shipping').useStore();
  return [state, state._manager, actions];
}

export const resetReceiveFromStores = () =>{
  BaseAction.getAction(storeShippingAndRecivingName('receiving'), StoreShippingAndReceivingActions, 'receiving').reset();
}

export const useReceiveFromStores = (): [StoreShippingAndReceivingState, StoreShippingAndReceivingDataManager, StoreShippingAndReceivingActions] => {
  const [state, actions] = BaseAction.getAction(storeShippingAndRecivingName('receiving'), StoreShippingAndReceivingActions, 'receiving').useStore();
  return [state, state._manager, actions];
}

export const updateShippingItemCount = (shippingUpdate: ShippingAndReceivingItemUpdate): Promise<any> => {
  return fetch(storeShippingItemUpdateCountURL, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${loginToken}`
    },
    body: JSON.stringify(shippingUpdate)
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
          .then((update: ShippingAndReceivingItemUpdate) => {
            return update;
          })
          .catch((e) => {
            return Promise.reject({ message: "Invalid response body" })
          })
      } else {
        return Promise.reject({ message: "Response not ok" })
      }
    })
    .catch((e) => {
      return Promise.reject({ message: "Communication failure" })
    })
}

export const updateShippingItemStatus = (pending: ShippingAndReceivingItemUpdate): Promise<any> => {
  return fetch(storeShippingItemUpdateStatusURL, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${loginToken}`
    },
    body: JSON.stringify(pending)
  })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      } else {
        return Promise.reject({ message: "Response not ok" });
      }
    })
    .catch((e) => {
      return Promise.reject({ message: "Communication failure" })
    })
}

export const downgradeShippingItemStatus = (pending: ShippingAndReceivingItemUpdate): Promise<any> => {
  return fetch(storeShippingItemDowngradeStatusURL, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${loginToken}`
    },
    body: JSON.stringify(pending)
  })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      } else {
        return Promise.reject({ message: "Response not ok" });
      }
    })
    .catch((e) => {
      return Promise.reject({ message: "Communication failure" })
    })
}

export const updateReceivingItemCount = (receivingUpdate: ShippingAndReceivingItemUpdate): Promise<any> => {
  return fetch(storeReceivingItemUpdateCountURL, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${loginToken}`
    },
    body: JSON.stringify(receivingUpdate)
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
          .then((update: ShippingAndReceivingItemUpdate) => {
            return update;
          })
          .catch((e) => {
            return Promise.reject({ message: "Invalid response body" })
          })
      } else {
        return Promise.reject({ message: "Response not ok" })
      }
    })
    .catch((e) => {
      return Promise.reject({ message: "Communication failure" })
    })
}

export const updateReceivingItemStatus = (pending: ShippingAndReceivingItemUpdate): Promise<ShippingAndReceivingItemUpdate> => {
  return fetch(storeReceivingItemUpdateStatusURL, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${loginToken}`
    },
    body: JSON.stringify(pending)
  })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      } else {
        return Promise.reject({ message: "Response not ok" });
      }
    })
    .catch((e) => {
      return Promise.reject({ message: "Communication failure" })
    })
}

export const downgradeReceivingItemStatus = (pending: ShippingAndReceivingItemUpdate): Promise<ShippingAndReceivingItemUpdate> => {
  return fetch(storeReceivingItemDowngradeStatusURL, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${loginToken}`
    },
    body: JSON.stringify(pending)
  })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      } else {
        return Promise.reject({ message: "Response not ok" });
      }
    })
    .catch((e) => {
      return Promise.reject({ message: "Communication failure" })
    })
}

