import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { Grid, Button } from '@material-ui/core';
import { DataGridList } from '../common/DataGridList';
import { Error } from '../common/ErrorComponent';
import { Store } from './Store';
import { useStores } from './StoreHandler';
import { Loading } from '../common/LoadingComponent';

export interface StoreListProps {
  onSelectStore: (store: Store) => void;
}

export function StoreList(props: StoreListProps) {
  const [{ error, loading, success, stores }, actions] = useStores('StoreList');

  useEffect(()=>{
    if(!(error || loading || success))
      actions.getStores();
  }, [error, loading, success]);

  const handleSelectStore = (store: Store) => {
    props.onSelectStore(store);
  }

  const ShowStoreList = () => {
    return (
      <Grid item xs={12}>
        <Grid container direction="column" spacing={0}>
          {
            stores && stores.length
              ? <DataGridList dataList={stores} label="Select a store" onSelectItem={handleSelectStore} />
              : <Grid item xs={12}><Error message="No stores found" /></Grid>
          }
        </Grid>
      </Grid>
    )
  }

  if (error) return <Error message={error.message} />
  if (loading) return <Loading />
  if (stores) return <ShowStoreList />
  return null;
}

