export class Store {
  constructor(source?: Store | any){
    if (source){
      Object.assign(this,source);
    }
  }
  storeID: number;
  storeName: string;
  shortName: string;
  // constructor(id: number, name: string, shortName: string) {
  //   this.storeID = id;
  //   this.storeName = name;
  //   this.shortName = shortName;
  // }

  key(){
    return this.storeID;
  }
  
  toString(){
    return this.storeName;
  }
}