export class UserData {
  constructor(source?: any) {
    if ( source){
      Object.assign(this, source);
    }
  }

  userID: number;
  userName: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  pin: string;
  authorizations: string;
  companies: string;
  userConfig: string;
  deviceID: number;

  key () {
    return this.userID;
  }

  toString() {
    return this.userName;
  }
}

export class userAuthorizationKey{
  key: string;
  label:string;
}

export const userAuthorizationKeys: Array<userAuthorizationKey> = [
  { key: "admin", label: "Admin" },
  { key: "api", label: "Api" },
  { key: "company", label: "Companies" },
  { key: "customer", label: "Customers" },
  { key: "file", label: "File Uploads" },
  { key: "inventory", label: "Inventory/Counts" },
  { key: "inventoryTransfer", label: "Inventory Transfer" },
  { key: "order", label: "Pre-Ordering" },
  { key: "purchaseOrder", label: "Purchase Orders" },
  { key: "stockroom", label: "Stockroom" },
  { key: "storeReceiving", label: "Store Receiving" },
  { key: "storeShipping", label: "Store Shipping" },
  { key: "reports", label: "Reports" },
  { key: "register", label: "Registers" },
  { key: "device", label: "Devices" },
  { key: "mendel", label: "Mendel" },
  { key: "transaction", label: "Transactions" },
  { key: "pendingTransaction", label: "Pending Transactions" },
  { key: "system", label: "System Dashboard" },
]
