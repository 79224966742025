import React, { useState, useEffect, createRef, useRef, useCallback, useLayoutEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, Button, CssBaseline, Paper, Typography, FormGroup, FormLabel, TextField, Divider, Switch, FormControlLabel, Grid, Input, Tabs, Tab } from '@material-ui/core';
import { SalesDialog } from '../fileUpload/SalesUploadDialog';
import { ReceivingComponent } from '../purchaseOrder/ReceivingComponent';
import PurchaseOrderManager from '../purchaseOrder/PurchaseOrderManager';
import { processLogin, processDeviceLogin } from '../login/LoginHandler';
import { setGlobalLoginToken, setGlobalLoginUser, loginToken, loginUser, getJSONHeaders } from '../utils/WireUtils';
import { Loading } from './LoadingComponent';
import { UserData } from '../wireData/UserData';
import { CustomerData } from '../wireData/CustomerData';
import { DeviceData } from '../wireData/DeviceData';
import { InventoryCostContainer } from '../InventoryCosts/InventoryCostsContainer';
import { TransactionData } from '../wireData/TransactionData';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { useRegisters } from '../register/registerHandler';
import { Error } from './ErrorComponent';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    height: 500,
    width: "100%",
  },
  appBar: {
    // flex: 1,
    display: 'flex',
    flexDirection: "row",
    // justifyContent:"space-between",
    width: '100%',
    height: 80,
    backgroundColor: "blue",
  },
  appBarLeft: {
    flex: 0,
    backgroundColor: "Red",
  },
  appBarRight: {
    flex: 0,
    backgroundColor: "green",
  },
  appBarCenter: {
    flexShrink: 1,
    // width:400,
  },
  appBarFlex2: {
    display: "flex",
    flexDirection: "column",
  },
}));

function BaseActionsTest() {
  const classes = useStyles({});
  const [{error, loading, success, registers}, actions] = useRegisters('Testing');
  console.log(registers);

  useEffect(() =>{
    if(!(error||loading||success))
      actions.getRegistersForStore(1);
  }, [error,  loading, success]);

  if (error) return <Error message={error.message} />
  if (loading) return <Loading />
  if(success)
    return (
      <div>
        {registers.map((register) => <Typography variant="body1">{register.registerName}</Typography>)}
      </div>
    )
    return null;
}

const requiresLogin = true;

export interface TestComponentProps {
  defaultUserName?: string;
  defaultPassword?: string;
  loginStatus: boolean;
  setLoginStatusCallback: (status: boolean, token: string, user: UserData, message: string) => void;
}

export function TestComponent(props: TestComponentProps) {
  if (requiresLogin && !props.loginStatus)
    processLogin(props.defaultUserName, props.defaultPassword, props.setLoginStatusCallback);

  if (!requiresLogin || props.loginStatus)
    return <BaseActionsTest />

  return <Loading />
}

function DateTest() {
  const [inputDate, setInputDate] = useState<Moment>(moment());
  const [textDate, setTextDate] = useState("");

  const handleDateChange = (value: Moment) => {
    console.log(value.toISOString(true));
    setTextDate(value.toString());
    setInputDate(value);
    console.log(new Date(value.toISOString()).toString());
  }

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {/* <Input type="date" defaultValue={inputDate} onChange={handleDateChange} /> */}
        <DatePicker
          label="Test Date"
          value={inputDate}
          onChange={handleDateChange}
          autoOk
          format="MM/DD/YYYY" />
        <Typography variant="body1">Date: {inputDate.toString()}</Typography>
        <Typography variant="body1">Text Date: {textDate}</Typography>
      </MuiPickersUtilsProvider>
    </div>
  )
}

function WebSocketsTest() {

  const AUTHENTICATE: string = 'authenticate';
  const UPDATE_TOKEN: string = 'updateToken';
  const GET_USER: string = 'getUser'; // data: incomplete customer record
  const COMMIT_TRANSACTION: string = 'commitTransaction'; // data: Transaction object
  const CUSTOMER_LOGIN: string = 'customerLogin'; //data: customer record
  const CUSTOMER_UPDATE: string = 'customerUpdate'; //data: complete customer record
  const CREATE_CUSTOMER: string = 'createCustomer';  //data: incomplete customer record
  const ERROR: string = 'error'; // data: error object including [bool reset]
  const GET_CUSTOMER: string = 'getCustomer'; // data: incomplete customer record

  interface DeviceConnectionProps {
    deviceLogin: string;
    devicePassword: string;
  }

  const DeviceConnection = (props: DeviceConnectionProps) => {
    const [loginToken, setLoginToken] = useState("");
    const [message, setMessage] = useState("");
    const [clerkWebSocket, setClerkWebSocket] = useState(null);
    const [customer, setCustomer] = useState<CustomerData>(new CustomerData());
    const [transaction, setTransaction] = useState<TransactionData>(new TransactionData());
    const [command, setCommand] = useState("getCustomer");
    const [showCustomer, setShowCustomer] = useState(true);

    const startDevice = () => {
      const ws = new WebSocket("ws://localhost:3001");
      setClerkWebSocket(ws);

      ws.onopen = (event) => {
        setMessage("Socket opened");
        ws.send(JSON.stringify({ command: AUTHENTICATE, token: loginToken, data: {} }))
      }

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        switch (data.command) {
          case GET_CUSTOMER:
            setCommand(data.command);
            setMessage(JSON.stringify(data));
            //            console.log("data: %s", data)
            if (data.data)
              setCustomer(data.data);
            else
              setCustomer(new CustomerData());
            setShowCustomer(true);
            break;
          case CREATE_CUSTOMER:
            setCommand(data.command);
            setMessage(JSON.stringify(data));
            setCustomer(data.data);
            setShowCustomer(true);
            break;
          case COMMIT_TRANSACTION:
            setCommand(data.command);
            setMessage(JSON.stringify(data));
            setTransaction(data.data);
            setShowCustomer(false);
            break;
        }
        setMessage(JSON.stringify(data));
      }

      ws.onclose = (event) => {
        setClerkWebSocket(null);
        setMessage("Socket Closed");
      }
    }

    const login = () => {
      processDeviceLogin(props.deviceLogin, props.devicePassword, (status: boolean, token: string, device: DeviceData, message: string) => {
        if (status) {
          setLoginToken(token);
          setGlobalLoginToken(token);
        }
        setMessage(message);
      })
    }

    const stopDevice = () => {
      if (!!clerkWebSocket) {
        clerkWebSocket.close();
      }
    }

    const send = () => {
      clerkWebSocket.send(JSON.stringify({
        command: command,
        token: loginToken,
        data: showCustomer ? customer : transaction
      }))
    }

    const updateCustomerField = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCustomer({ ...customer, [key]: event.target.value })
    }

    const updateTransactionField = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setTransaction({ ...transaction, [key]: event.target.value })
    }

    const handleChangeShowCustomer = (event, checked: boolean) => {
      setShowCustomer(!showCustomer);
    }

    return (
      <div>
        {showCustomer
          ?
          <Grid container direction="row">
            <Grid item xs={2}>
              <TextField fullWidth label="Customer ID" value={customer.customerID} onChange={updateCustomerField("customerID")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Phone Number" value={customer.phoneNumber} onChange={updateCustomerField("phoneNumber")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Card Number" value={customer.cardNumber} onChange={updateCustomerField("cardNumber")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="First Name" value={customer.firstName} onChange={updateCustomerField("firstName")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Last Name" value={customer.lastName} onChange={updateCustomerField("lastName")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Birthdate" value={customer.birthdate} onChange={updateCustomerField("birthdate")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="eMail" value={customer.email} onChange={updateCustomerField("email")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Login Name" value={customer.loginName} onChange={updateCustomerField("loginName")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Password" value={customer.password} onChange={updateCustomerField("password")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Permissions" value={customer.permissions} onChange={updateCustomerField("permissions")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Authorizations" value={customer.authorizations} onChange={updateCustomerField("authorizations")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Comments" value={customer.comments} onChange={updateCustomerField("comments")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Credits" value={customer.credits} onChange={updateCustomerField("credits")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Lifetime Child Credits" value={customer.lifetimeChildCredits} onChange={updateCustomerField("lifetimeChildCredits")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Lifetime Earned Credits" value={customer.lifetimeEarnedCredits} onChange={updateCustomerField("lifetimeEarnedCredits")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Taken" value={customer.taken} onChange={updateCustomerField("taken")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Taken On" value={customer.takenOn} onChange={updateCustomerField("takenOn")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Last Visit" value={customer.lastVisit} onChange={updateCustomerField("lastVisit")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Last Login" value={customer.lastLogin} onChange={updateCustomerField("lastLogin")} />
            </Grid>
          </Grid>
          :
          <Grid container direction="row">
            <Grid item xs={2}>
              <TextField fullWidth label="Transaction ID" value={transaction.transactionID} onChange={updateTransactionField("transactionID")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Transaction Type" value={transaction.transactionType} onChange={updateTransactionField("transactionType")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Date" type="date" value={transaction.date} onChange={updateTransactionField("date")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Origin Transaction" value={transaction.originTransaction} onChange={updateTransactionField("originTransaction")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="customer ID" value={transaction.customerID} onChange={updateTransactionField("customerID")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Company ID" value={transaction.companyID} onChange={updateTransactionField("companyID")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Location ID" value={transaction.locationID} onChange={updateTransactionField("locationID")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Register ID" value={transaction.registerID} onChange={updateTransactionField("registerID")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Associate ID" value={transaction.associateID} onChange={updateTransactionField("associateID")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Purchase Amount" value={transaction.purchaseAmount} onChange={updateTransactionField("purchaseAmount")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Credit Change" value={transaction.creditChange} onChange={updateTransactionField("creditChange")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Credit Percent" value={transaction.creditPercent} onChange={updateTransactionField("creditPercent")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Parent Percent" value={transaction.parentPercent} onChange={updateTransactionField("parentPercent")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Level Division" value={transaction.levelDivision} onChange={updateTransactionField("levelDivision")} />
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Level Number" value={transaction.levelNumber} onChange={updateTransactionField("levelNumber")} />
            </Grid>
          </Grid>

        }
        <Grid container direction="row">
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Switch checked={showCustomer} onChange={handleChangeShowCustomer} />
              }
              label={showCustomer ? "Show Customer" : "ShowTransaction"}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField label="Command" value={command} onChange={(event) => setCommand(event.target.value)} />
          </Grid>
          <Grid item xs={2}>
            <Button onClick={login} disabled={loginToken.length != 0}>Login</Button>
            <Button onClick={startDevice} disabled={!!clerkWebSocket || loginToken.length == 0}>Start</Button>
            <Button onClick={stopDevice} disabled={!clerkWebSocket}>Stop</Button>
            <Button onClick={send} disabled={!clerkWebSocket}>Send</Button>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography>{message}</Typography>
          </Grid>
        </Grid>
      </div>
    )
  }

  const handleProcessTransactions = () => {
    fetch("http://10.20.2.37:3001/transaction/processonetransaction", {
      method: "GET",
      headers: getJSONHeaders()
    })
      .then((response) => {
        if (response.ok) {
          response.json()
            .then((body) => {
              alert(body.message)
            })
        }
        else {
          alert(response.statusText)
        }
      })

  }

  return (
    <div>
      <Typography variant="h6">Customer Test</Typography>
      <DeviceConnection deviceLogin="Test 5" devicePassword="test5" />
      <Divider />
      <Typography variant="h6">Clerk Test</Typography>
      <DeviceConnection deviceLogin="Test 7" devicePassword="test7" />
      <Divider />
      <Button variant="contained" color="primary" onClick={handleProcessTransactions}>Process Transactions</Button>
    </div>
  )
}
