import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, Button, CssBaseline, Typography, IconButton, Icon } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { updateItem, useInventoryManager } from './InventoryHandler';
import { CountEntryQueryOrUpdate } from '../wireData/inventoryCountData';
import { InventoryManager } from './InventoryManager';
import { localISODateString } from '../utils/Convert';
import { NumPad } from '../common/NumPad';

// const manager = InventoryManager.Instance;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up(600)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  topPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%'
  },
  itemReadout: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    alignItems: 'center',
  },
  itemReadoutText: {
    padding: 0,
  },
  itemNotCountedIcon: {
    opacity: 0.5,
    color: 'white'
  },
  itemCountedIcon: {
    color: theme.palette.secondary.main
  },
  countReadout: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '50%',
    paddingTop: 20
  },
  countItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonPanel: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    minHeight: 100,
    touchAction: 'manipulation',
  },
  navButton: {
    flexGrow: 1,
  },
  numRoot: {
    bottom: 0,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 'auto',
    height: '40%',
    minWidth: 300,
    minHeight: 250,
    [theme.breakpoints.up(600 + theme.spacing(4))]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

export interface InventoryPagerProps {
  onBackClick: () => void;
}

export function InventoryPager(props: InventoryPagerProps) {

  const [{ entryState, countState }, manager, actions] = useInventoryManager();
  // const [entryState, setEntryState] = useState({ category: manager.category, item: manager.item, variation: manager.variant })
  // const [countState, setCountState] = useState({ oldCount: '-', newCount: '-', completed: false })
  // const [shouldClear, setShouldClear] = useState(true);

  const classes = useStyles({});

  useEffect(() => {
    updateCounts();
  }, []);

  // if (value >= 0) {
  //   newCount = (countState.newCount == '-' || shouldClear ? value.toString() : countState.newCount.length < 4 ? countState.newCount + value.toString() : countState.newCount);
  // } else if (value == -1) {
  //   const newOnHand = countState.newCount.substring(0, newCount.length - 1);
  //   newCount = newOnHand.length > 0 ? newOnHand : '-';
  // } else if (value == -2) {
  //   newCount = '-';
  // }

  const handleNumClick = (value: number) => {
    actions.updateInventoryCountState({
      newCount: (countState.newCount == '-' || countState.shouldClear ? value.toString() : (countState.newCount.length < 4 ? countState.newCount + value.toString() : countState.newCount)),
      shouldClear: false
    });
  }

  const handleNumBackspaceClick = () => {
    const newOnHand = countState.newCount.substring(0, countState.newCount.length - 1);
    actions.updateInventoryCountState({
      newCount: newOnHand.length > 0 ? newOnHand : '-',
      shouldClear: false
    });
  }

  const handleNumClearClick = () => {
    actions.updateInventoryCountState({
      newCount: '-',
      shouldClear: false
    });
  }

  enum NavLevel {
    VARIANT,
    ITEM,
    CATEGORY
  }

  function saveCount() {
    let n = parseInt(countState.newCount)
    n = isNaN(n) ? null : n;

    if (!countState.shouldClear) {
      const update = new CountEntryQueryOrUpdate();

      update.categoryID = manager.category.categoryID;
      update.itemID = manager.item.itemID;
      if(manager.variant){
        update.variationID = manager.variant.variationID;
        update.cost = manager.variant.cost;
      }
      else{
        update.variationID = 0;
        update.cost = manager.item.cost;
      }
      update.count = n;
      update.storeID = manager.store.storeID;
      update.date = localISODateString(manager.date);

      updateItem(update).then(confirmation => {
        manager.updateEntry(confirmation);
      });
    }
  }

  function handleNav(forward: boolean, level: NavLevel) {
    saveCount();

    if (forward) {
      switch (level) {
        case NavLevel.VARIANT:
          manager.nextVariant();
          break;
        case NavLevel.ITEM:
          manager.nextItem();
          break;
        case NavLevel.CATEGORY:
          manager.nextCategory();
      }
    } else {
      switch (level) {
        case NavLevel.VARIANT:
          manager.previousVariant();
          break;
        case NavLevel.ITEM:
          manager.previousItem();
          break;
        case NavLevel.CATEGORY:
          manager.previousCategory();
      }
    }
    updateCounts();
  }

  function updateCounts() {
    const oldCount = manager.variant && manager.variant.oldCount != null
      ? manager.variant.oldCount.toString()
      : manager.item && manager.item.oldCount != null
        ? manager.item.oldCount.toString()
        : '-'
    const newCount = manager.variant && manager.variant.newCount != null
      ? manager.variant.newCount.toString()
      : manager.item && manager.item.newCount != null
        ? manager.item.newCount.toString()
        : '-'
    actions.updateInventoryEntryState({
      category: manager.category,
      item: manager.item,
      variation: manager.variant
    })
    actions.updateInventoryCountState({
      oldCount: oldCount,
      newCount: newCount,
      completed: (newCount != '-'),
      shouldClear: true
    })
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    console.log(e.key);
    const digits = "0123456789";
    const navigation = ["ArrowLeft", "ArrowRight", "Backspace", "Delete", "Enter", "Tab"];
    if (digits.indexOf(e.key) >= 0) {
      handleNumClick(digits.indexOf(e.key));
    }
    else {
      switch (navigation.indexOf(e.key)) {
        case 0:
          if (e.ctrlKey)
            handleNav(false, NavLevel.CATEGORY);
          else
            if (e.shiftKey)
              handleNav(false, NavLevel.ITEM);
            else
              handleNav(false, NavLevel.VARIANT);
          break;
        case 1:
          if (e.ctrlKey)
            handleNav(true, NavLevel.CATEGORY);
          else
            if (e.shiftKey)
              handleNav(true, NavLevel.ITEM);
            else
              handleNav(true, NavLevel.VARIANT);
          break;
        case 2:
          handleNumClick(-1);
          break;
        case 3:
          handleNumClick(-2);
          break;
        case 4:
          handleNav(!e.shiftKey, NavLevel.VARIANT);
          break;
        case 5:
          handleNav(!e.shiftKey, NavLevel.VARIANT);
          break;
        default:
          break;
      }
    }
  }

  const handleBackClick = () => {
    saveCount();
    props.onBackClick();
  }

  return (
    <div className={classes.root} onKeyDown={handleKeyDown} tabIndex={0}>
      <div className={classes.topPanel}>
        <IconButton onClick={handleBackClick}><ArrowBackIcon /></IconButton>
        <div className={classes.itemReadout}>
          <Typography className={classes.itemReadoutText} variant='h6'>
            {entryState.category && entryState.category.categoryName}
          </Typography>
          <Typography className={classes.itemReadoutText} variant='h6'>
            {entryState.item && entryState.item.itemName}
          </Typography>
          <Typography className={classes.itemReadoutText} variant='h6'>
            {entryState.variation && entryState.variation.variationDescription}
          </Typography>
        </div>
        <Icon><CheckCircleIcon className={countState.completed ? classes.itemCountedIcon : classes.itemNotCountedIcon} /></Icon>
      </div>
      <div className={classes.countReadout}>
        <div className={classes.countItem}>
          <Typography variant='body1'>Last Week</Typography>
          <Typography variant='h5'>{countState.oldCount}</Typography>
        </div>
        <div className={classes.countItem}>
          <Typography variant='body1'>This Week</Typography>
          <Typography variant='h5'>{countState.newCount}</Typography>
        </div>
      </div>
      <div className={classes.buttonPanel}>
        <Button variant='contained'
          className={classes.navButton}
          color='primary'
          onClick={(e) => {
            e.preventDefault();
            handleNav(false, NavLevel.VARIANT);
          }}>
          <NavigateBeforeIcon />
          <Typography variant='button'>Prev</Typography>
        </Button>
        <Button variant='contained'
          className={classes.navButton}
          color='primary'
          onClick={(e) => {
            e.preventDefault();
            handleNav(true, NavLevel.VARIANT);
          }}>
          <Typography variant='button'>Next</Typography>
          <NavigateNextIcon />
        </Button>
      </div>
      <NumPad
        onNumClick={handleNumClick}
        onBackClick={handleNumBackspaceClick}
        onClearClick={handleNumClearClick}
        onKeyDown={handleKeyDown}
        rootClassname={classes.numRoot}
      />
    </div >
  )
}