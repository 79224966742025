import { loginToken, getDevicesForStoreURL, updateDeviceURL, removeDeviceURL, getJSONHeaders } from '../utils/WireUtils';
import { DeviceData } from '../wireData/DeviceData';
import { useEffect, useState } from 'react';
import { BaseState, BaseAction, getOrCreateEditStore } from '../common/BaseHandler';
import { Store } from '../store/Store';
import { useStore } from 'react-hookstore';

export const deviceEditStoreName = "deviceEditStore";

export const getDeviceEditStore = (device?: DeviceData) => getOrCreateEditStore<DeviceData>(deviceEditStoreName, device); 
export const useDeviceEditStore = () => useStore<DeviceData>(deviceEditStoreName); 

const deviceStoreName = "deviceStore";

class DeviceState extends BaseState {
  currentStore: Store;
  currentDevice: DeviceData;
  devices: Array<DeviceData>;
}

export class DeviceActions extends BaseAction<DeviceState> {

  get initialState() : DeviceState {
    return {
      error: null,
      loading: false,
      success: false,
      currentStore: null,
      currentDevice: null,
      devices: [],
      _actions: this,
    };
  }

  mapDevices = (body: any) => {
    return { devices: body.map((device) => new DeviceData(device)) }
  }

  getDevicesForStore = (storeID: number) => {
    this.updateState({ devices: [] });
    this.getData(`${getDevicesForStoreURL}/${storeID}`, this.mapDevices)
  }

  setCurrentStore = ( store: Store) =>{
    this.updateState({currentStore: store});
  }

  setCurrentDevice = ( device: DeviceData) =>{
    this.updateState({currentDevice: device});
  }
}

export function resetDeviceStore() {
  BaseAction.getAction(deviceStoreName, DeviceActions).reset();
}

export function useDevices(): [DeviceState, DeviceActions] {
  return BaseAction.getAction(deviceStoreName, DeviceActions).useStore<DeviceActions>();
}

export const useUpdateDevices = (deviceToUpdate: DeviceData): {
  error: any,
  updating: boolean,
  device: DeviceData
} => {
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [device, setDevice] = useState<DeviceData>(null);

  useEffect(() => {
    setError(null);
    setUpdating(false);
    setDevice(null);
    if (deviceToUpdate) {
      setUpdating(true);
      fetch(updateDeviceURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(deviceToUpdate)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((device) => {
                setDevice(new DeviceData(device));
                setUpdating(false);
              })
              .catch((e) => {
                setError(e);
                setUpdating(false);
              })
          } else {
            setError(new Error(response.statusText));
            setUpdating(false);
          }
        })
        .catch((e) => {
          setError(e);
          setUpdating(false);
        })
    }
  }, [deviceToUpdate]);

  return { error, updating, device };
}

export const useRemoveDevices = (deviceToRemove: DeviceData): {
  error: any,
  removing: boolean,
  device: DeviceData
} => {
  const [error, setError] = useState(null);
  const [removing, setRemoving] = useState(false);
  const [device, setDevice] = useState<DeviceData>(null);

  useEffect(() => {
    setError(null);
    setRemoving(false);
    setDevice(null);
    if (deviceToRemove) {
      setRemoving(true);
      fetch(removeDeviceURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(deviceToRemove)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((device) => {
                setDevice(device);
                setRemoving(false);
              })
              .catch((e) => {
                setError(e);
                setRemoving(false);
              })
          } else {
            setError(new Error(response.statusText));
            setRemoving(false);
          }
        })
        .catch((e) => {
          setError(e);
          setRemoving(false);
        })
    }
  }, [deviceToRemove]);

  return { error, removing, device };
}

export async function createDevice(device: DeviceData) {
  return fetch(updateDeviceURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginToken}`
    },
    body: JSON.stringify(device)
  }).then((response) => {
    if (response.ok) {
      response.json()
        .then((device) => {
          return (device);
        })
    } else {
      return response.status + ": " + response.statusText;
    }
  }).catch(e => {
    throw e;
  })
}

export async function removeDevice(device: DeviceData) {
  return fetch(`${removeDeviceURL}/${device.deviceID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginToken}`
    },
  }).then((response) => {
    if (response.ok) {
      return "Success";
    } else {
      return response.status + ": " + response.statusText;
    }
  }).catch(e => {
    throw e;
  })
}
