import React from "react";
import { useStyles } from "./styles";
import { useInventoryTransfersForDateRange, resetInventoryTransfersForDateRangeStore } from "./InventoryTransferHandler";
import { Error } from "../common/ErrorComponent";
import { Loading } from "../common/LoadingComponent";
import { InventoryTransferSummaryHeader } from "./InventoryTransferSummaryHeader";
import { ShowInventoryTransferSummaryTable } from "./InventoryTransferSummary";

export const resetInventoryTransferHistoryContainer = () =>{
  resetInventoryTransfersForDateRangeStore ();
}

export interface InventoryTransferHistoryContainerProps {
  onClose: () => void;
}

export function InventoryTransferSummaryContainer(props: InventoryTransferHistoryContainerProps) {

  const classes = useStyles({});

  const [{ error, loading, success, worksheet }, actions] = useInventoryTransfersForDateRange();

  const handleRefresh = () => {
    actions.getTransfers();
  }

  const WorksheetOrStatus = () => {
    if (error) return <Error message={error.message} />;
    if (loading) return <Loading />;
    if (success) return <ShowInventoryTransferSummaryTable worksheet={worksheet} />
    return null;
  }

  return (
    <div className={classes.root}>
      <InventoryTransferSummaryHeader refresh={handleRefresh} />
      <WorksheetOrStatus />
    </div>
  )
}