import { usersURL, getJSONHeaders, updateUserURL, removeUserURL, getFilteredUsersURL } from '../utils/WireUtils';
import { UserData } from '../wireData/UserData';
import { useState, useEffect } from 'react';
import { createStore, useStore, ReducerStoreInterface, getStoreByName, StateStoreInterface } from 'react-hookstore';
import { getOrCreateEditStore, BaseAction, BaseState } from '../common/BaseHandler';

export const userEditStoreName = "userEditStore";
export const getUserEditStore = (user: UserData) => getOrCreateEditStore<UserData>(userEditStoreName, user); 
export const useUserEditStore = () => useStore<UserData>(userEditStoreName); 

const userStoreName = "userStore";

class UserState extends BaseState{
  currentUser: UserData;
  users: Array<UserData>;
  _actions: UserActions;
}

class UserActions extends BaseAction<UserState>{

  get initialState(): UserState {
    return {
      error: null,
      loading: false,
      success: false,
      currentUser: null,
      users: [],
      _actions: this,
    }
  }

  private mapUsers(body: any): Partial<UserState> {
    const users = body.map((user) => new UserData(user));
    return { users: users };
  }

  getUsers = async() =>{
    return this.getData(usersURL, this.mapUsers);
  }

  setCurrentUser = (user: UserData) => {
    this.updateState({ currentUser: user });
  }
}

export const resetUserStore = () =>{
  BaseAction.getAction(userStoreName, UserActions).reset();
}

export const useUsers = (): [UserState, UserActions] => {
  return BaseAction.getAction(userStoreName, UserActions).useStore();
}

export async function getUsers(): Promise<Array<UserData>> {
  try {
    const response = await fetch(usersURL, {
      method: "GET",
      headers: getJSONHeaders(),
    });
    if (response.ok) {
      const users = await response.json();
      return users.map((user: UserData) => new UserData(user));
    } else {
      const body = await response.json();
      throw (body);
    }
  }
  catch (e) {
    throw (e);
  }
}

export const useUpdateUser = (userToUpdate: UserData): {
  error: any,
  updating: boolean,
  user: UserData
} => {
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [user, setUser] = useState<UserData>(null);

  useEffect(() => {
    setError(null);
    setUpdating(false);
    setUser(null);
    if (userToUpdate) {
      setUpdating(true);
      fetch(updateUserURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(userToUpdate)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((user) => {
                setUser(new UserData(user));
                setUpdating(false);
              })
              .catch((e) => {
                setError(e);
                setUpdating(false);
              })
          } else {
            setError(new Error(response.statusText));
            setUpdating(false);
          }
        })
        .catch((e) => {
          setError(e);
          setUpdating(false);
        })
    }
  }, [userToUpdate]);

  return { error, updating, user };
}

export const useRemoveUser = (userToRemove: UserData): {
  error: any,
  removing: boolean,
  user: UserData
} => {
  const [error, setError] = useState(null);
  const [removing, setRemoving] = useState(false);
  const [user, setUser] = useState<UserData>(null);

  useEffect(() => {
    setError(null);
    setRemoving(false);
    setUser(null);
    if (userToRemove) {
      setRemoving(true);
      fetch(removeUserURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(userToRemove)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((user) => {
                setUser(user);
                setRemoving(false);
              })
              .catch((e) => {
                setError(e);
                setRemoving(false);
              })
          } else {
            setError(new Error(response.statusText));
            setRemoving(false);
          }
        })
        .catch((e) => {
          setError(e);
          setRemoving(false);
        })
    }
  }, [userToRemove]);

  return { error, removing, user };
}

export async function createUser(user: UserData) {
  return fetch(updateUserURL, {
    method: "POST",
    headers: getJSONHeaders(),
    body: JSON.stringify(user)
  }).then((response) => {
    if (response.ok) {
      return "Success";
    } else {
      response.json().then(body => {
        throw new Error(body.message);
      })
    }
  }).catch(e => {
    throw e;
  })
}

export const lookupUsersByUserName = async (searchKey: string, max: number = 10): Promise<Array<UserData>> => {
  let query = "";

  if (searchKey)
    query = `userName=${searchKey}&max=${max}`;
  else
    query = `max=${max}`;

  query += "&sortField=userName";

  try {
    const response = await fetch(`${getFilteredUsersURL}?${query}`, {
      method: 'GET',
      headers: getJSONHeaders(),
    })
    if (response.ok) {
      const users = await response.json();
      return users;
    }
    else
      throw ("User lookup error")
  }
  catch (e) {
    throw ("User lookup error")
  }
}