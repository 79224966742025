import React, { useState } from 'react';
import { CustomerData } from '../wireData/CustomerData';
import { useStyles } from './styles';
import { Paper, Table, TableBody } from '@material-ui/core';
import { CustomerRow } from './CustomerRow';
import { CustomerTableHeader, CustomerTableHeaderColumn } from './CustomerTableHeader';

export interface CustomerTableProps {
  headers: Array<CustomerTableHeaderColumn>;
  // fields: Array<string>;
  customers: Array<CustomerData>;
  filters: {};
  onFilterUpdate: (filters: {}) => void;
  onSelectCustomer: (customer: CustomerData) => void;
}

export function CustomerTable(props: CustomerTableProps) {
  const classes = useStyles({});

  const [customerCount, setCustomerCount] = useState(props.customers.length)

  const handleRemoveCustomer = (customerRemoved: CustomerData) => {
    const index = props.customers.findIndex((customer) => customer.customerID == customerRemoved.customerID);
    if (index >= 0) {
      props.customers.splice(index, 1);
      setCustomerCount(props.customers.length)
    }
  }

  const fields = props.headers.map((header) => header.field);

  return (
    <div className={classes.tableRoot}>
      <Paper>
        <Table>
          <CustomerTableHeader
            headers={props.headers}
            filters={props.filters}
            onFilterUpdate={props.onFilterUpdate}
          />
          <TableBody>
            {
              props.customers.map((customer) => (
                <CustomerRow
                  key={customer.customerID}
                  fields={fields}
                  row={customer}
                  onSelectCustomer={props.onSelectCustomer}
                />
              ))
            }
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}