import { CircularProgress, TableCell } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Vendor } from '../vendor/Vendor';
import { VendorSelect } from '../vendor/VendorSelect';
import { updateItemVendors } from './PreOrderingHandler';
import { PendingOrderItem, PendingOrderItemUpdate } from './StoreOrderingData';
import { useStyles } from './styles';

export interface VendorCellProps {
  item: PendingOrderItem;
}

export function VendorCell(props: VendorCellProps) {
  const [vendor, setVendor] = useState(props.item.vendorName);
  const [anchor, setAnchor] = useState(null);
  const [pending, setPending] = useState(false);
  const classes = useStyles({});
  useEffect(() => {
    setVendor(props.item.vendorName)
  }, [props.item.vendorName]);

  const handleOnClick = (event: React.MouseEvent) => {
    setAnchor(anchor ? null : event.currentTarget);
  }

  const handleVendorSelected = async (vendor: Vendor) => {
    setAnchor(null);
    setPending(true);
    const update = {
      categoryID: props.item.categoryID,
      itemID: props.item.itemID,
      variationID: props.item.variationID,
      vendorID: vendor.vendorID,
    }
    updateItemVendors(update as PendingOrderItemUpdate)
      .then((result) => {
        props.item.vendorID = vendor.vendorID;
        props.item.vendorName = vendor.vendorName;
        setPending(false);
      })
      .catch((result) => {
        props.item.vendorID = 0;
        props.item.vendorName = result.message;
        setPending(false);
      })
  }

  const handleVendorCleared = () => {
    setPending(true);
    const update = {
      categoryID: props.item.categoryID,
      itemID: props.item.itemID,
      variationID: props.item.variationID,
      vendorID: 0,
    }
    updateItemVendors(update as PendingOrderItemUpdate)
      .then((result) => {
        props.item.vendorID = 0;
        props.item.vendorName = "No Vendor";
        setPending(false);
      })
      .catch((result) => {
        props.item.vendorID = 0;
        props.item.vendorName = result.message;
        setPending(false);
      })
  }

  return <TableCell className={`${classes.tableCell} ${classes.pointerCell}`} style={Boolean(anchor) ? { backgroundColor: '#222' } : null} onClick={handleOnClick}>
    {anchor
      ?
      <VendorSelect anchor={anchor} onVendorSelected={handleVendorSelected} onVendorCleared={handleVendorCleared} />
      :
      pending
        ?
        <CircularProgress />
        :
        null}
    {vendor ? vendor : "No Vendor"}
  </TableCell>
}
