import React, { useState } from 'react';
import { useGetUserCompanies } from "../company/CompanyHandler";
import { useSetUserCompany } from '../login/LoginHandler';
import { Loading } from '../common/LoadingComponent';
import { Error } from '../common/ErrorComponent';
import { UserCompanyData } from '../wireData/UserCompanyData';
import { Button, Typography, Theme, List, ListItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    maxHeight: "100%",
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginLeft: 'auto',
    //   marginRight: 'auto',
    //   maxHeight: "auto",
    // },
  },
  paper: {
    width: 500,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
  },
  listBox: {
    width: "100%",
    flexBasis: 'auto',
    flex: '1 1',
    overflowY: "visible",
    overflowX: "hidden",
    // height: "50vh",
  },
  gridCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
}));

export interface SelectCompanyProps {
  onSetCompany: (company: UserCompanyData, token: string) => void;
  onCancel: () => void;
}

export const SelectCompanyContainer = (props: SelectCompanyProps) => {
  const {
    error,
    loading,
    companies
  } = useGetUserCompanies();

  const classes = useStyles();

  interface SetUserCompanyProps {
    company: UserCompanyData;
    onCompanySet: (token: string) => void;
  }

  const SetUserCompany = (props: SetUserCompanyProps) => {
    const {
      error,
      loading,
      success,
      token
    } = useSetUserCompany(props.company.companyID);

    if (error) return <Error message={error.message} />
    if (loading) return <Loading />
    if (success) {
      props.onCompanySet(token);
    }
    return null;
  }

  interface SelectUserCompanyProps {
    companies: Array<UserCompanyData>;
    onCompanySelect: (company: UserCompanyData, token: string) => void;
  }

  const SelectUserCompany = (props: SelectUserCompanyProps) => {
    const [company, setCompany] = useState<UserCompanyData>(null);

    const handleToken = (token: string) => {
      props.onCompanySelect(company, token);
    }

    const handleCompanySelect = (company: UserCompanyData) => {
      setCompany(company);
    }

    const CompanyList = () => {
      return (
        <div className={classes.listBox}>
          <List>
            {companies.map((company) => (
              <ListItem key={company.companyID}>
                <Button
                  variant='contained'
                  fullWidth
                  color="primary"
                  onClick={(e) => handleCompanySelect(company)}>
                  <Typography noWrap>
                    {company.companyName}
                  </Typography>
                </Button>
              </ListItem>
            ))}
          </List>
        </div>
      );
    }

    if (company)
      return <SetUserCompany company={company} onCompanySet={handleToken} />;
    else
      return <CompanyList />
  }

  const NoCompanies = () => {
    return (
      <Typography>
        No companies assigned to user.
      </Typography>
    )
  }

  interface CompanyErrorProps {
    message: string;
    onCancel: () => void;
  }

  const CompanyError = (props: CompanyErrorProps) => {
    return (
      <div>
        <Typography>
          There was an error retrieving company information.
        </Typography>
        <Typography>
          {props.message}
        </Typography>
      </div>
    )

  }

  const DialogBody = () => {
    if (error) return <CompanyError onCancel={props.onCancel} message={error.message} />
    if (loading) return <Loading />
    if (companies) {
      switch (companies.length) {
        case 0:
          return <NoCompanies />
        case 1:
          return <SetUserCompany company={companies[0]} onCompanySet={handleSetCompany(companies[0])} />
        default:
          return <SelectUserCompany companies={companies} onCompanySelect={handleSetCompanyAndToken} />
      }
    }
    return null;
  }

  const handleSetCompanyAndToken = (company: UserCompanyData, token: string) => {
    props.onSetCompany(company, token);
  }

  const handleSetCompany = (company: UserCompanyData) => (token: string) => {
    handleSetCompanyAndToken(company, token);
  }

  return (
    <Dialog
      classes={{
        root: classes.root,
        paper: classes.paper
      }}
      open={true}
      aria-labelledby="form-dialog-title">
      <DialogTitle>
      <Grid container justify="space-around">
            <Grid item xs={12} className={classes.gridCell}>
              <Typography noWrap variant="h6">Select Company</Typography>
            </Grid>
          </Grid>

      </DialogTitle>
      <DialogContent>
        <DialogBody />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="secondary">
          Cancel
          </Button>
      </DialogActions>
    </Dialog>
  )
}
