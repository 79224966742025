import { StoreShippingAndReceivingDataTable, VendorItems, CategoryItems } from "./ShippingData";
import { ShippingAndReceivingItemUpdate } from "../wireData/storeShippingAndReceivingData";

export class StoreShippingAndReceivingDataManager {

  private _shippingAndReceivingData: StoreShippingAndReceivingDataTable;

  set ShippingAndReceivingData(data: StoreShippingAndReceivingDataTable) {
    this._shippingAndReceivingData = data;
  }

  get items() {
    if (this._shippingAndReceivingData)
      return this._shippingAndReceivingData.items;
    return [];
  }

  get vendors() {
    if (this._shippingAndReceivingData) {
      if (!this._shippingAndReceivingData.vendors)
        this.buildVendorItems();
      return this._shippingAndReceivingData.vendors;
    }
    return [];
  }

  get categories() {
    if (this._shippingAndReceivingData) {
      if (!this._shippingAndReceivingData.categories)
        this.buildCategoryItems();
      return this._shippingAndReceivingData.categories;
    }
    return [];
  }

  private buildVendorItems() {
    const data = this._shippingAndReceivingData;
    data.vendors = [];
    data.items.forEach((item) => {
      var vendor: VendorItems = data.vendors.find((vendor) => vendor.vendorName == item.vendorName);
      if (vendor == null)
        data.vendors.push(vendor = new VendorItems(item.vendorName));
      vendor.items.push(item);
    })
  }

  private buildCategoryItems() {
    const data = this._shippingAndReceivingData;
    data.categories = [];
    data.items.forEach((item) => {
      var category: CategoryItems = data.categories.find((category) => category.categoryName == item.categoryName);
      if (category == null)
        data.categories.push(category = new CategoryItems(item.categoryName));
      category.items.push(item);
    })
  }

  public updateStatus(shippingItemUpdate: ShippingAndReceivingItemUpdate) {
    const item = this.items.find((item) => item.orderItemID == shippingItemUpdate.orderItemID);
    if (item)
      item.shippingStatus = shippingItemUpdate.itemStatus;
  }

  public updateShippedCount(shippingItemUpdate: ShippingAndReceivingItemUpdate) {
    const item = this.items.find((item) => item.orderItemID == shippingItemUpdate.orderItemID);
    if (item)
      item.quantityShipped = shippingItemUpdate.quantityShipped;
  }

  public updateReceivedCount(shippingItemUpdate: ShippingAndReceivingItemUpdate) {
    const item = this.items.find((item) => item.orderItemID == shippingItemUpdate.orderItemID);
    if (item)
      item.quantityReceived = shippingItemUpdate.quantityReceived;
  }
}
