import React, { useState } from 'react';
import { TransactionData } from '../wireData/TransactionData';
import { useStyles } from './styles';
import { Paper, Table, TableBody } from '@material-ui/core';
import { TransactionRow } from './TransactionRow';
import { TransactionTableHeader, TransactionTableHeaderColumn } from './TransactionTableHeader';

export interface TransactionTableProps {
  headers: Array<TransactionTableHeaderColumn>;
  transactions: Array<TransactionData>;
  filters: {};
  onFilterUpdate: (filters: {}) => void;
  onSelectTransaction: (transaction: TransactionData) => void;
}

export function TransactionTable(props: TransactionTableProps) {
  const classes = useStyles({});

  const fields = props.headers.map((header) => header.field);

  return (
    <div className={classes.tableRoot}>
      <Paper>
        <Table>
          <TransactionTableHeader
            headers={props.headers}
            filters={props.filters}
            onFilterUpdate={props.onFilterUpdate}
          />
          <TableBody>
            {
              props.transactions.map((transaction) => (
                <TransactionRow
                  key={transaction.transactionID}
                  fields={fields}
                  row={transaction}
                  onSelectTransaction={props.onSelectTransaction}
                />
              ))
            }
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}