import React, { useState } from 'react';
import { CategoryData } from '../wireData/CategoryData';
import { DataGridList } from '../common/DataGridList';
import { Dialog, DialogContent, Theme, DialogActions, Button, Typography, Paper, Tabs, Tab } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { CategoriesAndDateDialog } from './CategoriesAndDateDialog';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
    position: "relative",
    top: 0,
    display: "inline"
  },
}))


export interface CategoryListProps {
  categories: Array<CategoryData>;
  // selectedCategories: Array<CategoryData>;
  selectedCategory: number;
  categoriesLabel?:string;
  onChangeCategories: () => void;
  onCategorySelected: (index: number) => void;
}

export function CategoryList(props: CategoryListProps) {

  const classes = useStyles({});

    const handleTabClick = (index: number) => (event) => {
    if (index == props.selectedCategory) {
      props.onCategorySelected(-1);
    }
    else {
      props.onCategorySelected(index);
    }
  }

  return (
    <div className={classes.root}>
      <Paper square>
        <Tabs variant='scrollable' indicatorColor="primary" value={props.selectedCategory + 1} scrollButtons="auto">
          <Tab key={0} label={<Typography variant='caption'>{props.categoriesLabel || "Categories:"}</Typography>} onClick={props.onChangeCategories} />
          {props.categories.map((cat, index) => <Tab key={cat.categoryID} label={<Typography variant='caption'> {cat.categoryName}</Typography>} onClick={handleTabClick(index)} />)}
        </Tabs>
      </Paper>
    </div>
  )

}