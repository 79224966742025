export class ShippingAndReceivingItemUpdate {
  orderItemID: number;
  itemStatus: string;
  quantityShipped: number;
  quantityReceived: number;
}

export class StoreShippingAndReceivingItem {
  storeReceivingID: number;
  orderItemID: number;
  vendorID: number;
  vendorName: string;
  storeID: number;
  storeName: string;
  categoryID: number;
  categoryName: string;
  itemID: number;
  itemName: string;
  variationID: number;
  variationDescription: string;
  quantityShipped: number; //quantity for store
  quantityReceived: number; //changable confirmation
  shipDate: number;
  receiptDate: number;
  shippingStatus: string;
}
