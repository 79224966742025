import React from 'react';
import { Paper, Typography, CssBaseline } from '@material-ui/core';

interface ErrorProps {
  message?: string
}

export function Error(props: ErrorProps) {
  const message = props.message ? props.message : "Something went wrong";

  return (
    <div>
      <Typography align="center" variant='h5'>{message}</Typography>
    </div>
  )
}