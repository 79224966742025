import React, { useState, useEffect } from 'react';
import { Category, Item } from '../catalog/Catalog';
import { InventoryExpander } from './InventoryExpander';
import { Loading } from '../common/LoadingComponent';
import { InventoryPager } from './InventoryPager';
import { StoreDateSelect } from './StoreDateSelect';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { InventoryManager } from './InventoryManager';
import { Store } from '../store/Store';
import { useInventoryManager, resetInventoryManager } from './InventoryHandler';
import { Error } from '../common/ErrorComponent';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',
    height: '100%',
    boxSizing: 'border-box'
  }
}))

// const manager = InventoryManager.Instance;

export const resetInventoryComponent = () =>{
  resetInventoryManager ();
}

interface InventoryContextWrapperProps {
  onInventoryDone: () => void
}
export function InventoryContextWrapper(props: InventoryContextWrapperProps) {
  const [{ store, date, dateAndStoreSelected }, , actions] = useInventoryManager();
  // const [store, setStore] = useState(null);
  // const [date, setDate] = useState(null);

  const handleSelectSubmit = () => {
    actions.updateInventoryState({ dateAndStoreSelected: true })
  }

  const handleBackClick = () =>{
    actions.reset();
  }

  const handleInventoryDone = () =>{
    actions.updateInventoryState({store:null, date: null});
    props.onInventoryDone();
  }

  if (dateAndStoreSelected) {
    return <InventoryContainer onInventoryDone={handleBackClick} store={store} date={date} />
  } else {
    return <StoreDateSelect onSubmit={handleSelectSubmit} onCancel={handleInventoryDone} />
  }
}

interface InventoryContainerProps {
  onInventoryDone: () => void
  store: Store
  date: Date
}

export function InventoryContainer(props: InventoryContainerProps) {
  const classes = useStyles({});
  const [{ error, loading, success, showList }, manager, actions] = useInventoryManager();

  useEffect(() => {
    if (!(loading || success || error))
      actions.getInventoryData(props.store, props.date);
  }, [error, loading, success]);

  function handleCategorySelect(category: Category) {
    manager.selectCategory(category);
    actions.updateInventoryState({ showList: false });
  }

  function handleItemSelect(category: Category, item: Item) {
    manager.selectItem(category, item);
    actions.updateInventoryState({ showList: false });
  }

  function handleBackClick() {
    actions.updateInventoryState({ showList: true });
  }


  if (error) return <Error message={error.message} />
  if (loading) return <Loading />;
  if (success) {
    return (
      <div className={classes.root}>
        {showList
          ?
          <InventoryExpander onBackClick={props.onInventoryDone} onCategorySelect={handleCategorySelect} onItemSelect={handleItemSelect} />
          :
          <InventoryPager onBackClick={handleBackClick} />}
      </div>
    )
  }
  return null;

}
