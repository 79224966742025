import React, { useState, useMemo } from 'react';
import { Typography, Grid, TextField, Button, Switch, Input, InputLabel } from '@material-ui/core';
import { CompanyData } from '../wireData/CompanyData';
import NumberFormat from 'react-number-format';
import { useStyles } from './styles';
import { getOrCreateEditCompanyStore, companyEditStoreName } from './CompanyHandler';
import { useField } from '../common/useField';
import { getStoreByName } from 'react-hookstore';

interface EditCompanyProps {
  company: CompanyData;
  onUpdate: (company: CompanyData) => void;
  onRemove: (company: CompanyData) => void;
  onCancel: () => void;
}

export function EditCompany(props: EditCompanyProps) {
  const classes = useStyles({});
  const store = getStoreByName<CompanyData>(companyEditStoreName);
  const [companyID, setCompanyID] = useField(store, 'companyID');
  const [companyName, setCompanyName] = useField(store, 'companyName');
  const [companyActive, setCompanyActive] = useField(store, 'companyActive');
  const [companyEmail, setCompanyEmail] = useField(store, 'companyEmail');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useField(store, 'companyPhoneNumber');
  const [creditPercent, setCreditPercent] = useField(store, 'creditPercent');
  const [levelDivision, setLevelDivision] = useField(store, 'levelDivision');
  const [parentPercent, setParentPercent] = useField(store, 'parentPercent');

  const label = useMemo(() => (
    Number(props.company.companyID)
      ? "Update"
      : "Add"),
    [props.company.companyID]);

  const handleUpdate = async () => {
    const company = new CompanyData({});
    company.companyID = companyID;
    company.companyName = companyName;
    company.companyActive = companyActive;
    company.companyEmail = companyEmail;
    company.companyPhoneNumber = companyPhoneNumber;
    company.creditPercent = creditPercent;
    company.levelDivision = levelDivision;
    company.parentPercent = parentPercent;
    props.onUpdate(company);
  }

  const handleRemove = async () => {
    props.onRemove(props.company);
  }

  const handleCancel = () => {
    props.onCancel();
  }

  const checkFieldsAreComplete = () => {
    return companyName && companyName.trim().length &&
      companyPhoneNumber && companyPhoneNumber.trim().length &&
      companyEmail && companyEmail.trim().length &&
      creditPercent && Number(creditPercent) &&
      levelDivision && Number(levelDivision) &&
      parentPercent && Number(parentPercent);
  }

  return (
    <Grid container spacing={8} >
      <Grid item xs={12}>
        <Typography variant='subtitle2' align='center' gutterBottom>
          {label + " company"}
        </Typography>

      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='companyName'
          name='companyName'
          label='Company Name'
          fullWidth
          value={companyName || ""}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel htmlFor="companyActive" className={classes.inputLabel}>Company Active *</InputLabel>
        <Switch
          required
          id='companyActive'
          name='companyActive'
          checked={Boolean(companyActive)}
          onChange={(e) => setCompanyActive(e.target.checked)}
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel htmlFor="companyPhoneNumber" className={classes.inputLabel}>Phone Number *</InputLabel>
        <NumberFormat
          required
          id='companyPhoneNumber'
          name='companyPhoneNumber'
          format="(###) ###-####"
          mask="_"
          customInput={Input}
          value={companyPhoneNumber || ""}
          onValueChange={(values) => setCompanyPhoneNumber(values.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='companyEmail'
          name='companyEmail'
          label='Company eMail'
          fullWidth
          value={companyEmail || ""}
          onChange={(e) => setCompanyEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <InputLabel htmlFor="creditPercent" className={classes.inputLabel}>Credit Percent *</InputLabel>
        <NumberFormat
          required
          id='creditPercent'
          name='creditPercent'
          value={creditPercent}
          format="##.##"
          customInput={Input}
          onValueChange={(values) => setCreditPercent(values.formattedValue)}
        />
      </Grid>
      <Grid item xs={4}>
        <InputLabel htmlFor="levelDivision" className={classes.inputLabel}>Level Division *</InputLabel>
        <NumberFormat
          required
          id='levelDivision'
          name='levelDivision'
          format="##.##"
          customInput={Input}
          value={levelDivision}
          onChange={(e) => setLevelDivision(e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <InputLabel htmlFor="parentPercent" className={classes.inputLabel}>Parent Percent *</InputLabel>
        <NumberFormat
          required
          id='parentPercent'
          name='parentPercent'
          format="##.##"
          customInput={Input}
          value={parentPercent}
          onChange={(e) => setParentPercent(e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <Button variant='contained' fullWidth onClick={handleCancel}>Cancel</Button>
      </Grid>
      <Grid item xs={4}>
        <Button variant='contained' disabled={!props.company.companyID} fullWidth onClick={handleRemove}>Delete</Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant='contained'
          fullWidth
          disabled={!checkFieldsAreComplete()}
          onClick={handleUpdate}
        >{label}
        </Button>
      </Grid>
    </Grid>
  )
}
