import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { Paper, Typography, Grid, Button, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Switch, FormControlLabel } from '@material-ui/core';
import { DataSelect } from '../common/SelectData';
import { Error } from '../common/ErrorComponent';
import { CategoryData } from '../wireData/CategoryData';
import { ItemData } from '../wireData/ItemData';
import { VariationData } from '../wireData/VariationData';
import { LinearLoading, Loading } from '../common/LoadingComponent';
import { NumPad } from '../common/NumPad';
import { numberToDollarString } from '../utils/Convert';
import { useItemCostStore, ItemCostState, resetItemCostStore } from './ItemCostHandler';

interface StatusDialogProps {
  message: string;
  open: boolean;
}

function StatusDialog(props: StatusDialogProps) {

  return (
    <Dialog open={props.open} fullWidth maxWidth="xs">
      <DialogContent>
        <LinearLoading message={props.message} />
      </DialogContent>
    </Dialog>
  )
}

interface ErrorDialogProps {
  error: any;
  onClose: () => void;
}

function ErrorDialog(props: ErrorDialogProps) {
  return (
    <Dialog open={true}>
      <DialogTitle>
        Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.error ? props.error.message || props.error : "Unknown Error"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export const resetItemCostContainer = () =>{
  resetItemCostStore ();
}

export interface ItemCostContainerProps {
  onClose: () => void;
}

export function ItemCostContainer(props: ItemCostContainerProps) {
  const classes = useStyles({});
  const [{
    currentCategory,
    currentItem,
    currentVariation,
    value,
    clearOnFirst,
    updatePreordering,
    error,
    loading,
    itemCostError,
    itemCostLoading,
    itemCostSuccess,
    itemCost,
    itemCostUpdating,
    itemCostUpdateError
  }, catalogManager, itemCostActions] = useItemCostStore();

  const updateState = (update: Partial<ItemCostState>) => {
    itemCostActions.updateState(update);
  }

  useEffect(() => {
    if (!catalogManager) {
      itemCostActions.getCatalog();
    }
    else if (!currentCategory)
      nextVariation();
  }, [catalogManager]);

  const ShowCategoryList = () => {

    const handleUpdateCategory = (category: CategoryData) => {
      catalogManager.selectCategory(category.categoryID);
      itemCostActions.updateCatalogEntry();
    }

    if (error) return <Error message={error.message} />
    return (
      <DataSelect
        dataList={catalogManager.categories as any}
        label="Category"
        disableClearIcon
        currentSelection={currentCategory}
        onSelectItem={handleUpdateCategory}
      />
    )
  }

  const ShowItemList = () => {

    const handleSelectItem = (item: ItemData) => {
      catalogManager.selectItem(item.itemID);
      itemCostActions.updateCatalogEntry();
    }

    return (
      <DataSelect
        dataList={catalogManager.category.items as any}
        label="Item"
        disableClearIcon
        currentSelection={currentItem}
        onSelectItem={handleSelectItem}
      />
    )
  }

  const ShowVariationList = () => {

    const handleUpdateVariation = (variation: VariationData) => {
      catalogManager.selectVariant(variation.variationID);
      itemCostActions.updateCatalogEntry();
    }

    return (
      <DataSelect
        dataList={catalogManager.item.variations as any}
        label="Variation"
        disableClearIcon
        currentSelection={currentVariation}
        onSelectItem={handleUpdateVariation}
      />
    )
  }

  const ShowItemCost = () => {
    if (itemCostError) return <Error message={itemCostError.message} />
    if (itemCostLoading) return <LinearLoading noLabel />
    if (itemCostSuccess)
      return (
        <Typography variant="h5">{numberToDollarString(value)}</Typography>
      )
    return <Typography variant="h5"></Typography>
  }

  const nextVariation = () => {
    catalogManager.nextVariant();
    itemCostActions.updateCatalogEntry();
  }

  const previousVariation = () => {
    catalogManager.previousVariant();
    itemCostActions.updateCatalogEntry();
  }

  const handleNext = () => {
    nextVariation();
  }

  const handlePrevious = () => {
    previousVariation();
  }

  const updateValue = (input: number) => {
    const localValue = clearOnFirst ? 0 : value;
    if (localValue < 1000)
      updateState({ value: (localValue * 1000 + input) / 100, clearOnFirst: false });
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        updateValue(Number(event.key));
        break;
      case "Backspace":
        updateState({ value: Math.trunc(value * 10) / 100 });
        break;
      case "Enter":
        if (itemCost && itemCost.cost != value)
          handleUpdate();
        break;
      case "Escape":
        props.onClose();
        break;
    }
    event.preventDefault();
  }

  const handleNumClick = (inputValue: number) => {
    updateValue(inputValue);
  }

  const handleClearClick = () => {
    updateState({ value: 0, clearOnFirst: false });
  }

  const handleBackClick = () => {
    updateState({ value: Math.trunc(value * 10) / 100, clearOnFirst: false });
  }

  const handleUpdate = () => {
    itemCostActions.updateCost(value);
  }

  const handleCloseErrorDialog = () => {
    updateState({ itemCostUpdateError: null });
  }

  const updateDisabled = (): boolean => {
    if (updatePreordering)
      return false;
    if (itemCost && itemCost.cost != value)
      return false;
    return true;

  }

  if (loading || !catalogManager || !catalogManager.catalog) return <Loading />
  return (
    <div className={classes.layout}>
      <StatusDialog open={itemCostUpdating} message="Updating item cost" />
      {itemCostUpdateError ? <ErrorDialog error={itemCostUpdateError} onClose={handleCloseErrorDialog} /> : null}
      <Paper className={classes.paper}>
        <Typography variant='h6' align='center' gutterBottom>Update current item cost</Typography>
        <Grid container direction="column" spacing={0}>
          <Grid item xs={12}>
            <ShowCategoryList />
          </Grid>
          <Grid item xs={12}>
            <ShowItemList />
          </Grid>
          <Grid item xs={12}>
            <ShowVariationList />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              onClick={handlePrevious}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleNext}
            >
              Next
            </Button>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
            <Grid item xs={4} sm={2}>
              <Typography variant="h5" >Cost</Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <ShowItemCost />
            </Grid>
            <Grid item xs={4} sm={2}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleUpdate}
                disabled={updateDisabled()}
              >
                Update
            </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" spacing={2}>
            <NumPad
              onNumClick={handleNumClick}
              onBackClick={handleBackClick}
              onClearClick={handleClearClick}
              onKeyDown={handleKeyDown}
              rootClassname={classes.numRoot}
            />
          </Grid>
          <Grid container direction="row" justify="center">
            <FormControlLabel
              control={<Switch checked={updatePreordering} onChange={(e, checked) => updateState({ updatePreordering: checked })} />}
              label="Also update preorder"
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}