import { InventoryTransferQuantityAndStatusUpdate } from '../wireData/InventoryTransferData';
import { StorePullInventoryDataTable, CategoryItems } from "./StorePullInventoryData";

export class PullInventoryDataManager {

  // private static _instance: PullInventoryDataManager = null;

  // public static get Instance() {
  //   if (this._instance == null) {
  //     this._instance = new PullInventoryDataManager();
  //   }
  //   return this._instance;
  // }
  private _inventoryPullData: StorePullInventoryDataTable;

  set InventoryPullData(data: StorePullInventoryDataTable) {
    this._inventoryPullData = data;
  }

  get transfers() {
    return this._inventoryPullData.transfers;
  }

  get categories() {
    if (!this._inventoryPullData.categories)
      this.buildCategoryItems();
    return this._inventoryPullData.categories;
  }

  private buildCategoryItems() {
    const data = this._inventoryPullData;
    data.categories = [];
    data.transfers.forEach((item) => {
      var category: CategoryItems = data.categories.find((category) => category.categoryName == item.categoryName);
      if (category == null)
        data.categories.push(category = new CategoryItems(item.categoryName));
      category.items.push(item);
    })
  }

  public updateStatus(inventoryPullItem: InventoryTransferQuantityAndStatusUpdate) {
    const item = this.transfers.find((item) => item.inventoryTransferID == inventoryPullItem.inventoryTransferID);
    if (item)
      item.status = inventoryPullItem.status;
  }

  public updateCount(inventoryPullItem: InventoryTransferQuantityAndStatusUpdate) {
    const item = this.transfers.find((item) => item.inventoryTransferID == inventoryPullItem.inventoryTransferID);
    if (item)
      item.quantityShipped = inventoryPullItem.quantity;
  }
}
