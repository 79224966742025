import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    maxHeight: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.up(600 + 280 + theme.spacing(3 * 2))]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxHeight: "auto",
      overflowY: "hidden",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + 280 + theme.spacing(3 * 2))]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  listBox: {
    width: "100%",
    flexBasis: 'auto',
    flex: '1 1',
    overflowY: "auto",
    overflowX: "hidden",
  },
  editBox: {
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  formControl: {
    width: "100%",
  },
  userList: {
    maxHeight: "50vh",
  },
  attributeList: {
    [theme.breakpoints.up(600 + 280 + theme.spacing(3) * 2)]: {
      maxHeight: "35vh",
    },
  },
  button: {
    marginTop: theme.spacing(1),
  },
  iconLeft: {
    marginRight: theme.spacing(1),
  },
}));

