import React, { memo, useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { OrderHeader, OrderLineItem, OrderReconcilingUpdate, OrderLineItemReceivingUpdate } from './PurchaseOrderData';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Grid, Theme, TableRow, TableCell, Table, TableHead, TableBody, Checkbox, TextField, CircularProgress, Icon, FormControlLabel, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { updatePurchaseOrderItemReceived, updatePurchaseOrderItemStatus, downgradePurchaseOrderItemStatus, PurchaseOrderStatus, updatePurchaseOrderStatus, downgradePurchaseOrderStatus, usePendingPurchaseOrderWorkbook, resetOpenPurchaseOrderStore } from './PurchaseOrderHandler';

const fieldWeight = 4;
export function resetReceivingComponent() {
  resetOpenPurchaseOrderStore();
}


export function ReceivingComponent() {
  const [{
    error,
    loading,
    success,
  }, manager, purchaseOrderActions] = usePendingPurchaseOrderWorkbook();

  useEffect(() => {
    if (!manager) {
      purchaseOrderActions.getOrders();
    }
  }, [manager]);

  if (error) return <Error message={error.message} />
  if (loading) return <Loading />
  if (manager && success) return <OrderList />
  return null;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    boxSizing: 'border-box',
  },
  lineItemContainer: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxSizing: 'border-box',
  },
  summaryCommentContainer: {
    width: '100%',
    display: 'flex-column',
  },
  commentText: {
    maxWidth: "65vw",
  },
  summaryRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  summaryText: {
    flexBasis: '40%',
    flexGrow: 0,
    flexShrink: 0
  },
  summaryDate: {
    flex: 1,
  },
  summaryInvoice: {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  summaryInvoiceLabel: {
    marginRight: 20,
  },
  checkIcon: {
    marginRight: 10,
  },
  tableCell: {
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'left',
  },
}));

interface ExpandedItem {
  id: number;
  expandedLines: Array<number>;
}

const OrderList = memo(() => {
  const classes = useStyles({});
  const [{ expandedItems, scrolling }, manager] = usePendingPurchaseOrderWorkbook();

  const scrollingRef = React.useRef(null);

  useLayoutEffect(() => {
    if (scrollingRef.current)
      scrollingRef.current.scrollTop = scrolling.top;
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrolling.top = event.currentTarget.scrollTop;
  }

  return (
    <div className={classes.root} ref={scrollingRef} onScroll={handleScroll}>
      {manager.orders.map((header, index) => (
        <OrderExpander
          expandedItems={expandedItems}
          key={header.orderID}
          order={header}
        />
      ))}
    </div>
  )
});

interface OrderExpanderProps {
  expandedItems: Array<ExpandedItem>;
  order: OrderHeader;
}

const OrderExpander = memo((props: OrderExpanderProps) => {
  const classes = useStyles({});
  const [{ }, manager] = usePendingPurchaseOrderWorkbook();
  const date = useMemo(() => new Date(props.order.orderDate).toDateString(), [props.order])
  const lineItemArray = useMemo(() => manager.sortItemsFromHeader(props.order.orderID), [props.order.orderID])
  const [expanded, setExpanded] = useState(props.expandedItems.find((expandedItem) => expandedItem.id == props.order.orderID));
  const [allComplete, setAllComplete] = useState(props.order.items.every((item) => item.itemStatus != "pending"));
  const [checked, setChecked] = useState(props.order.orderStatus != 'pending');

  const handleChange = (event, isExpanded) => {
    if (isExpanded) {
      const item: ExpandedItem = { id: props.order.orderID, expandedLines: [] };
      setExpanded(item);
      props.expandedItems.push(item);
    }
    else {
      setExpanded(null);
      const index = props.expandedItems.findIndex((item) => item.id == props.order.orderID);
      if (index >= 0)
        props.expandedItems.splice(index, 1);
    }
  }

  const handleStatusChange = () => {
    setAllComplete(props.order.items.every((item) => item.itemStatus != "pending"));
  }

  const getCheckColor = () => {
    if (props.order.comment) {
      return 'orange'
    }
    return allComplete ? 'Chartreuse' : 'lightgray'
  }

  const handleToggle = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const update = new OrderReconcilingUpdate();
    update.orderStatus = props.order.orderStatus;
    update.orderID = props.order.orderID;
    let operation: (update: OrderReconcilingUpdate) => Promise<any>;
    if (checked) {
      operation = downgradePurchaseOrderStatus;
    } else {
      operation = updatePurchaseOrderStatus;
    }
    await operation(update).then(order => {
      manager.updateOrder(order);
      setChecked(order.orderStatus != 'pending');
    }).catch(e => {
      alert(e.message || e);
    });
  }

  return (
    <ExpansionPanel expanded={Boolean(expanded)} onChange={handleChange} >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.summaryCommentContainer}>
          <div className={classes.summaryRoot}>
            <Checkbox disabled={!allComplete} checked={checked} onClick={handleToggle} />
            <div className={classes.checkIcon}>
              <Icon><CheckCircleIcon style={{ color: getCheckColor() }} /></Icon>
            </div>
            <Typography className={classes.summaryText} variant='h6'>{props.order.vendorName}</Typography>
            <div className={classes.summaryInvoice}>
              <Typography align='right' variant='body2' className={classes.summaryInvoiceLabel}>Invoice  </Typography><Typography align='center' variant='h6'>{props.order.invoiceNumber}</Typography>
            </div>
            <Typography className={classes.summaryDate} variant='body1'>{date}</Typography>
          </div>
          {
            props.order.comment
              ?
              <Typography className={classes.commentText} noWrap variant='subtitle1'>{props.order.comment}</Typography>
              :
              null
          }
        </div>
      </ExpansionPanelSummary>
      {expanded
        ?
        <ExpansionPanelDetails>
          <div className={classes.lineItemContainer}>
            {lineItemArray.map((lineItems, index) =>
              <LineItemExpander
                key={`${props.order.orderID}${index}`}
                items={lineItems}
                expandedLines={expanded.expandedLines}
                onStatusChange={handleStatusChange}
              />)}
          </div>
        </ExpansionPanelDetails>
        :
        null
      }
    </ExpansionPanel>
  )
});

interface LineItemExpanderProps {
  items: Array<OrderLineItem>
  expandedLines: Array<number>;
  onStatusChange: () => void;
}

const LineItemExpander = memo((props: LineItemExpanderProps) => {
  const classes = useStyles({});
  const [{ }, manager] = usePendingPurchaseOrderWorkbook();

  const expectedQuantity = useMemo(() => {
    return props.items.map(item => item.itemQuantity).reduce((prev, curr) => prev + curr);
  }, props.items)

  const currentRecieved = useMemo(() => {
    return props.items.map(item => item.quantityReceived != null ? item.quantityReceived : item.itemQuantity).reduce((prev, curr) => prev + curr);
  }, props.items)

  const itemsComplete = useMemo(() => props.items.every((item) => item.itemStatus != 'pending'), props.items);
  const initialCheckedLines = useMemo(() => props.items.map(item => item.itemStatus != 'pending'), props.items);

  const [actualReceived, setActualReceived] = useState(currentRecieved);
  const [completed, setCompleted] = useState(itemsComplete);
  const [checkedLines, setCheckedLines] = useState(initialCheckedLines);
  const [expanded, setExpanded] = useState(props.expandedLines.indexOf(props.items[0].orderItemID) >= 0);

  const handleTotalUpdate = (newTotal: number) => {
    const total = props.items.map(item => item.quantityReceived != null ? item.quantityReceived : item.itemQuantity).reduce((prev, curr) => prev + curr);
    setActualReceived(total);
  }

  const handleReceiveAllCheck = (event, checked) => {
    const operation = checked ? updatePurchaseOrderItemStatus : downgradePurchaseOrderItemStatus;
    const relevantStatus = checked ? 'pending' : 'received';

    const filteredItems = props.items.filter(item => item.itemStatus == relevantStatus);
    if (filteredItems.length != 0) {
      const ops = filteredItems.map(item => {
        const update = new OrderLineItemReceivingUpdate();
        update.itemStatus = item.itemStatus;
        update.orderItemID = item.orderItemID;
        update.receivedQuantity = checked ? item.quantityReceived != null ? item.quantityReceived : item.itemQuantity : null;
        return operation(update)
          .then(confirm => {
            manager.updateItem(item.orderID, confirm);
          });
      });

      return Promise.all(ops).then(() => {
        setCompleted(checked);
        setCheckedLines(props.items.map(item => item.itemStatus != 'pending'));
        props.onStatusChange();
      }).catch(e => {
        alert(e.message || e);
      });
    }
  }

  const handleLineToggle = (lineIndex: number, item: OrderLineItem, checked: boolean) => {
    if (item.itemStatus != 'pending' && item.itemStatus != 'received') {
      return;
    }
    const update = new OrderLineItemReceivingUpdate();
    update.itemStatus = item.itemStatus;
    update.orderItemID = item.orderItemID;
    update.receivedQuantity = checked ? item.quantityReceived != null ? item.quantityReceived : item.itemQuantity : null;
    let operation: (item: OrderLineItemReceivingUpdate) => Promise<any>;
    if (checked && item.itemStatus == 'pending') {
      operation = updatePurchaseOrderItemStatus;
    } else if (!checked && item.itemStatus == 'received') {
      operation = downgradePurchaseOrderItemStatus;
    }
    if (operation) {
      return operation(update)
        .then(confirm => {
          manager.updateItem(item.orderID, confirm);
          setCheckedLines(props.items.map(item => item.itemStatus != 'pending'));
          setCompleted(props.items.every(item => item.itemStatus != 'pending'));
          props.onStatusChange();
        })
        .catch(e => {
          alert(e.message || e);
        });
    }
  }

  const handleChange = (event, isExpanded: boolean) => {
    if (isExpanded) {
      setExpanded(true);
      props.expandedLines.push(props.items[0].orderItemID);
    }
    else {
      setExpanded(false);
      const index = props.expandedLines.indexOf(props.items[0].orderID);
      if (index >= 0)
        props.expandedLines.splice(index, 1);
    }
  }

  return (
    <ExpansionPanel expanded={expanded} onChange={handleChange}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.summaryRoot}>
          <Icon><CheckCircleIcon style={{ color: completed ? 'Chartreuse' : 'lightgray', transform: "scale(0.65)" }} /></Icon>
          <Typography>
            {props.items[0].categoryName} | {props.items[0].itemName} | {props.items[0].variationDescription || '-'}
          </Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={4}>
          <GridItemLabel label='Expected Total Quantity' display={expectedQuantity.toString()} />
          <GridItemLabel label='Distributed Total Quantity' display={actualReceived.toString()} />
          <Grid item xs={fieldWeight}>
            <FormControlLabel label='Receive All' control={<Checkbox checked={completed} onChange={handleReceiveAllCheck} />} />
          </Grid>
          <Grid item xs={12}>
            <DistributionTable checked={checkedLines} onLineToggle={handleLineToggle} items={props.items} onReceivedTotalChange={handleTotalUpdate} />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
});

interface GridItemLabelProps {
  label: string;
  display: string;
}

const GridItemLabel = memo((props: GridItemLabelProps) => {

  return (
    <Grid item xs={fieldWeight}>
      <div>
        <Typography align='center' variant='caption'>{props.label}</Typography>
        <Typography align='center' variant='body1'>{props.display}</Typography>
      </div>
    </Grid>
  )
}, (prev, next) => (prev.display == next.display))

interface DistributionTableProps {
  items: Array<OrderLineItem>
  checked: Array<boolean>;
  onReceivedTotalChange: (newTotal: number) => void;
  onLineToggle: (index: number, item: OrderLineItem, checked: boolean) => void;
}

const DistributionTable = memo((props: DistributionTableProps) => {

  const handleQuantityChanged = (newQuantity: number) => {
    props.onReceivedTotalChange(newQuantity);
  }

  const handleCheck = (index: number, item: OrderLineItem) => (checked: boolean) => {
    props.onLineToggle(index, item, checked);
  }

  const classes = useStyles({});

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell}>Store</TableCell>
          <TableCell className={classes.tableCell}>Expected Quantity</TableCell>
          <TableCell className={classes.tableCell}>Distributed Quantity</TableCell>
          <TableCell className={classes.tableCell}>Received</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.items.map((item, index) => (
          <DistributionRow
            item={item}
            key={`${item.orderItemID}`}
            onQuantityChanged={handleQuantityChanged}
            onToggle={handleCheck(index, item)}
            checked={props.checked[index]}
          />
        ))}
      </TableBody>
    </Table>
  );
}, (prev, next) => (prev.checked.every((t, i) => t == next[i])
  && prev.items.length == next.items.length
  && next.items.every((item, index) => item.quantityReceived == prev.items[index].quantityReceived)))

interface DistributionRowProps {
  item: OrderLineItem
  onQuantityChanged: (newQuantity: number) => void;
  checked: boolean;
  onToggle: (checked: boolean) => void;
}

const DistributionRow = memo((props: DistributionRowProps) => {

  const handleQuantityChanged = (quantity: number) => {
    props.onQuantityChanged(quantity);
  }

  const handleToggle = (checked: boolean) => {
    props.onToggle(checked);
  }

  const classes = useStyles({});

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>{props.item.storeName}</TableCell>
      <TableCell className={classes.tableCell}>{props.item.itemQuantity}</TableCell>
      <DistroCell
        item={props.item}
        onReceivedChange={handleQuantityChanged}
      />
      <CheckCell
        item={props.item}
        checked={props.checked}
        onToggle={handleToggle}
      />
    </TableRow>
  );
}, (prev, next) => (prev.checked == next.checked && prev.item.quantityReceived == next.item.quantityReceived))

interface DistroCellProps {
  item: OrderLineItem;
  onReceivedChange: (newRecieved: number) => void;
}

function DistroCell(props: DistroCellProps) {
  const [{ }, manager] = usePendingPurchaseOrderWorkbook();
  const [isInput, setIsInput] = useState(false);
  const [displayed, setDisplayed] = useState('');
  const [pending, setPending] = useState(false);
  const classes = useStyles({});

  useEffect(() => {
    let displayed: string;
    if (props.item.quantityReceived != null) {
      displayed = props.item.quantityReceived.toString();
    } else {
      displayed = props.item.itemQuantity.toString();
    }
    setDisplayed(displayed);
  }, [props.item.quantityReceived]);

  const handleFocus = (e) => {
    e.target.select();
  }
  const handleChange = (e) => {
    setDisplayed(e.currentTarget.value);
  }
  const handleBlur = (e) => {
    setIsInput(false);
    if (Number(displayed) != props.item.quantityReceived)
      submitChange();
  }
  const submitChange = () => {
    const quantity = parseInt(displayed);
    if (isNaN(quantity)) {
      setDisplayed(props.item.quantityReceived ? props.item.quantityReceived.toString() : props.item.itemQuantity.toString())
      return;
    }
    setPending(true);
    const update = new OrderLineItemReceivingUpdate();
    update.itemStatus = props.item.itemStatus;
    update.orderItemID = props.item.orderItemID;
    update.receivedQuantity = Number(displayed);
    updatePurchaseOrderItemReceived(update).then(item => {
      manager.updateItem(props.item.orderID, item);
      props.onReceivedChange(item.receivedQuantity)
      setPending(false);
    }).catch(e => {
      setPending(false);
      alert(e.message || e);
    });
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "Enter":
        setIsInput(false)
        submitChange();
        break;
      default:
        break;
    }
  }

  const handleClick = (event) => setIsInput(true);

  return (
    <TableCell onClick={handleClick} className={classes.tableCell}>
      {
        pending
          ?
          <CircularProgress style={{ maxWidth: 25 }} />
          :
          isInput
            ?
            <TextField
              style={{ maxWidth: 35 }}
              type='text'
              autoFocus
              value={displayed}
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyPress}
            />
            :
            <span>{displayed}</span>
      }
    </TableCell>
  )
}

interface CheckCellProps {
  item: OrderLineItem;
  checked: boolean;
  onToggle: (checked: boolean) => void;
}

const CheckCell = memo((props: CheckCellProps) => {
  const classes = useStyles({});

  const handleToggle = (event, checked: boolean) => {
    props.onToggle(checked);
  }

  return (
    <TableCell className={classes.tableCell}>
      {(props.item.itemStatus != 'pending' && props.item.itemStatus != 'received') ?
        <Tooltip title={`Cannot change status: ${props.item.itemStatus}`}>
          <Checkbox
            checked={props.checked}
            onChange={handleToggle}
          />
        </Tooltip>
        :
        <Checkbox
          checked={props.checked}
          onChange={handleToggle}
        />
      }
    </TableCell>
  )
}, (prev, next) => (prev.checked == next.checked))