import { InventoryTransferUpdate } from "../wireData/InventoryTransferData";
import { updateOrAddInventoryTransferURL, loginToken, getInventoryTransfersForDateRangeURL, getInventoryTransferDetailsURL } from "../utils/WireUtils";
import { Moment } from "moment";
import { InventoryTransferSummaryWorksheet, InventoryTransferDetailData, InventoryTransferSummaryData } from "../wireData/InventoryTransferSummaryData";
import { localISODateString } from "../utils/Convert";
import { getStoreByName } from "react-hookstore";
import { BaseState, BaseAction } from "../common/BaseHandler";

export const addOrUpdateInventoryTransfer = (inventoryTransfer: InventoryTransferUpdate): Promise<any> => {
  return fetch(updateOrAddInventoryTransferURL, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${loginToken}`
    },
    body: JSON.stringify(inventoryTransfer)
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
          .then((update: InventoryTransferUpdate) => {
            return update;
          })
          .catch((e) => {
            return Promise.reject({ message: "Invalid response body" })
          })
      } else {
        return Promise.reject({ message: "Response not ok" })
      }
    })
    .catch((e) => {
      return Promise.reject({ message: "Communication failure" })
    })
}

export const InventoryTransfersForDateRangeStoreName = 'inventoryTrasfersForDateRange';
export const inventoryTransferForDateRangeStore = () => getStoreByName<InventoryTransfersForDateRangeState>(InventoryTransfersForDateRangeStoreName);

export class InventoryTransfersForDateRangeState extends BaseState {
  startDate: Moment;
  endDate: Moment;
  worksheet: InventoryTransferSummaryWorksheet;
}

export class InventoryTransfersForDateRangeActions extends BaseAction<InventoryTransfersForDateRangeState> {

  get initialState(): InventoryTransfersForDateRangeState {
    return {
      error: null,
      loading: false,
      success: false,
      startDate: null,
      endDate: null,
      worksheet: null,
      _actions: this,
    }
  }

  private mapTransfers = (body: any): Partial<InventoryTransfersForDateRangeState> => {
    return {worksheet: body}
  }

  getTransfers = () => {
    console.log (this.state);
    if (this.state.startDate && this.state.endDate) {
      const startDate = localISODateString(this.state.startDate.toDate());
      const endDate = localISODateString(this.state.endDate.toDate());
      this.getData(`${getInventoryTransfersForDateRangeURL}/${startDate}/${endDate}`, this.mapTransfers);
    }
  }
}

export const resetInventoryTransfersForDateRangeStore = () => {
  BaseAction.getAction(InventoryTransfersForDateRangeStoreName, InventoryTransfersForDateRangeActions).reset();
}

export const useInventoryTransfersForDateRange = (): [InventoryTransfersForDateRangeState, InventoryTransfersForDateRangeActions] => {
  return BaseAction.getAction(InventoryTransfersForDateRangeStoreName, InventoryTransfersForDateRangeActions).useStore();
}

export const InventoryTransferDetailsStoreName = 'inventoryTransfersDetails';
export const inventoryTransferDetailsStore = () => getStoreByName<InventoryTransferDetailsState>(InventoryTransferDetailsStoreName);

export class InventoryTransferDetailsState extends BaseState {
  summaryRow: InventoryTransferSummaryData;
  details: Array<InventoryTransferDetailData>;
}

export class InventoryTransferDetailActions extends BaseAction<InventoryTransferDetailsState> {

  get initialState(): InventoryTransferDetailsState {
    return {
      error: null,
      loading: false,
      success: false,
      summaryRow: null,
      details: null,
      _actions: this,
    }
  }

  private mapDetails = (body: any): Partial<InventoryTransferDetailsState> => {
    return {details: body}
  }

  getDetails = () => {
    const summaryRow = this.state.summaryRow;
    if (summaryRow) {
      this.getData(`${getInventoryTransferDetailsURL}/${summaryRow.categoryID}/${summaryRow.itemID}/${summaryRow.variationID}/${summaryRow.dateRequested}`, this.mapDetails);
    }
  }
}

export const resetInventoryTransferDetailsStore = () => {
  BaseAction.getAction(InventoryTransferDetailsStoreName, InventoryTransferDetailActions).reset();
}

export const useInventoryTransferDetails = (): [InventoryTransferDetailsState, InventoryTransferDetailActions] => {
  return BaseAction.getAction(InventoryTransferDetailsStoreName, InventoryTransferDetailActions).useStore();
}
