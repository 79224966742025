import React from 'react';
import { StateStoreInterface, getStoreByName, createStore, useStore } from 'react-hookstore';
import { getJSONHeaders } from '../utils/WireUtils';

export function getOrCreateEditStore<TData>(storeName: string, data?: TData): StateStoreInterface<TData> {
  try {
    const store = getStoreByName<TData>(storeName);
    if (data)
      store.setState(data);
    return store;
  }
  catch (e) {
    return createStore<TData>(storeName, data || {} as TData);
  }
}

export class BaseState {
  error: any;
  loading: boolean;
  success: boolean;
  _actions: BaseAction<BaseState>;
}

export interface StatusFields<TState> {
  error: keyof TState;
  processing: keyof TState;
  success: keyof TState;
}

export class BasePostResponse {
  error: any;
  success: boolean;
  data: any;
}

export abstract class BaseAction<TState extends BaseState>{

  static getAction<TActions extends BaseAction<BaseState>>(storeName: string, 
    actionsType: new (storeType: any) => TActions, storeType?: any): TActions {
    try {
      const store = getStoreByName<BaseState>(storeName);
      const actions = store.getState()._actions;
      if (actions && actions instanceof actionsType)
        return actions;
      return null;
    }
    catch (e) {
      const actions = new actionsType(storeType);
      actions.createStore(storeName);

      return actions;
    }
  }

  createStore(storeName: string) {
    this._store = createStore<TState>(storeName, this.initialState)
  }

  useStore<TActions>(): [TState, TActions] {
    const [state] = useStore<TState>(this._store);
    return [state, this as unknown as TActions];
  }

  get state() {
    return this._store.getState();
  }

  abstract get initialState(): TState;

  protected _store: StateStoreInterface<TState>;

  get store(): StateStoreInterface<TState> {
    return this._store;
  }

  updateState(update: Partial<TState>) {
    this.store.setState({ ...this.state, ...update });
  }

  defaultStatusFields: StatusFields<BaseState> = {
    error: "error", 
    processing: "loading", 
    success: "success"
  }

  protected getData = async (url: string,
    mapData: (body: any) => Partial<TState>,
    statusFields: StatusFields<TState> = this.defaultStatusFields) => {
    try {
      this.updateState({ [statusFields.error]: null, [statusFields.processing]: true, [statusFields.success]: false } as any);
      const response = await fetch(url, {
        method: 'GET',
        headers: getJSONHeaders(),
      });
      if (response.ok) {
        const body = await response.json();
        this.updateState({ [statusFields.processing]: false, [statusFields.success]: true, ...mapData(body) })
      }
      else {
        this.updateState({ [statusFields.error]: { message: response.statusText }, [statusFields.processing]: false, [statusFields.success]: false } as any);
      }
    }
    catch (e) {
      this.updateState({ [statusFields.error]: e, [statusFields.processing]: false, [statusFields.success]: false } as any);
    }
  }

  protected postData = async (url: string, data: any, mapResult: (body: any) => Partial<TState>,
    statusFields?: StatusFields<TState>): Promise<Partial<BasePostResponse>> => {
    try {
      if (statusFields)
        this.updateState({ [statusFields.error]: null, [statusFields.processing]: true, [statusFields.success]: false } as any);
      const response = await fetch(url, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const body = await response.json();
        const result = mapResult(body);
        if (statusFields)
          this.updateState({ [statusFields.error]: null, [statusFields.processing]: false, [statusFields.success]: true, ...result });
        return { success: true, ...result }
      }
      else {
        if (statusFields)
          this.updateState({ [statusFields.error]: { message: response.statusText }, [statusFields.processing]: false, [statusFields.success]: false } as any);
        return { error: { message: response.statusText } };
      }
    }
    catch (e) {
      if (statusFields)
        this.updateState({ [statusFields.error]: e, [statusFields.processing]: false, [statusFields.success]: false } as any);
      return { error: e };
    }
  }

  reset = () => {
    this.updateState(this.initialState);
  }
}
/*
export const xxxxxEditStoreName = "xxxxxEditStore";

export const getOrCreateEditXxxxx = (initialState: XxxxxData): StateStoreInterface<XxxxxData> => {
  try {
    const store = getStoreByName<XxxxxData>(xxxxxEditStoreName);
    store.setState(initialState);
    return store;
  }
  catch (e) {
    return createStore<XxxxxData>(xxxxxEditStoreName, initialState);
  }
}

*/

/* Template
const xxxxxStoreName = "xxxxxStore";

class XxxxxState extends BaseState {
  currentStore: Store;
  currentXxxxx: XxxxxData;
  xxxxxs: Array<XxxxxData>;
}

export class XxxxxActions extends BaseAction<XxxxxState> {
  constructor(storeName: string) {
    super(storeName);
  }

  get initialState() : XxxxxState {
    return {
      error: null,
      loading: false,
      success: false,
      currentStore: null,
      currentXxxxx: null,
      xxxxxs: [],
      _actions: this,
    };
  }

  mapXxxxxs = (body: any) => {
    return { xxxxxs: body.map((xxxxx) => new XxxxxData(xxxxx)) }
  }

  getXxxxxsForStore = (storeID: number) => {
    this.updateState({ xxxxxs: [] });
    this.getData(`${getXxxxxsForStoreURL}/${storeID}`, this.mapXxxxxs)
  }

  setCurrentStore = ( store: Store) =>{
    this.updateState({currentStore: store});
  }

  setCurrentXxxxx = ( xxxxx: XxxxxData) =>{
    this.updateState({currentXxxxx: xxxxx});
  }
}

export function useXxxxxs(): [XxxxxState, XxxxxActions] {
  return BaseAction.getAction(xxxxxStoreName, XxxxxActions).useStore<XxxxxActions>();
}

*/