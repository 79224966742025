import React from 'react';
import { Store } from '../store/Store';
import { useStyles } from './styles';
import { TableRow, TableCell } from '@material-ui/core';
import classNames from 'classnames';

export interface InventorySummaryTableHeaderProps {
  stores: Array<Store>;
}

export const InventorySummaryTableHeader = (props: InventorySummaryTableHeaderProps) => {
  const classes = useStyles({});
  return (
    <TableRow>
      <TableCell className={classNames(classes.tableCell, classes.headerCell)}>
        Category
      </TableCell>
      <TableCell className={classNames(classes.tableCell, classes.headerCell)}>
        Item
      </TableCell>
      <TableCell className={classNames(classes.tableCell, classes.headerCell)}>
        Variation
      </TableCell>
      <TableCell className={classNames(classes.tableCell, classes.headerCell)}>
        Request Date
      </TableCell>
      {props.stores.map((store) =>
        <TableCell key={store.storeID} className={classNames(classes.tableCell, classes.headerCell)}>
          {store.shortName}
        </TableCell>
      )}
      <TableCell className={classNames(classes.tableCell, classes.headerCell)}>
        Cost
      </TableCell>
    </TableRow>
  )
}

