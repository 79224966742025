import {salesUploadURL, getFormDataHeaders} from '../utils/WireUtils';

export function uploadSales(storeID: number, weekEnding: number, data: FormData): Promise<any> {
  const url = `${salesUploadURL}/${storeID}/${weekEnding}`;

  return fetch(url,{
    method: 'POST',
    headers: getFormDataHeaders(),
    body: data
  }).then(response => {
    if (response.ok) {
      return({message: 'Success!'})
    } else {
      response.json().then(error => {
        return Promise.reject({message: error.message || 'Failed with no message'})
      })
    }
  }).catch(e => {
    return Promise.reject({message: `Failed with error: ${e}`})
  })
} 