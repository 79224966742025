import { UserData } from "../wireData/UserData";
import { UserCompanyData } from "../wireData/UserCompanyData";

console.log(process.env)
export const rootURL = process.env.REACT_APP_DEFAULT_ROOT_URL;

export const loginURL = `${rootURL}/login`;
export const deviceloginURL = `${rootURL}/devicelogin`;
export const refreshTokenURL = `${rootURL}/refresh`;

export const inventoryBaseURL = `${rootURL}/inventory`;
  export const storeURL = `${inventoryBaseURL}/stores`; // GET
  export const updateCountURL = `${inventoryBaseURL}/updateCount`;// POST body: CountEntryQueryOrUpdate
  export const getCountURL = `${inventoryBaseURL}/getCount`;// POST body: CountEntryQueryOrUpdate
  export const countsByStoreForDate = `${inventoryBaseURL}/countsByStoreForDate`;// GET /localISODateString
  export const allInventoryCatalogURL = `${inventoryBaseURL}/catalog/all`; // GET
  export const activeInventoryCatalogURL = `${inventoryBaseURL}/catalog/active`; // GET
  export const datesAndStoresURL = `${inventoryBaseURL}/datesandstores`; // GET
  export const storeCountsForDateURL = `${inventoryBaseURL}/counts`; // GET
  export const inventoryCostsWorkbookURL = `${inventoryBaseURL}/costsbystore`; // GET?categories=[]

export const apiURL = `${rootURL}/api`;
  export const getUserCompaniesURL = `${apiURL}/getusercompanies`; // GET
  export const setUserCompanyURL = `${apiURL}/setusercompany`; // GET /companyID
  export const testURL = `${apiURL}/test"`;
  export const allCatalogURL = `${apiURL}/catalog/all`; // GET
  export const activeCatalogURL = `${apiURL}/catalog/active`; // GET
  export const categoriesURL = `${apiURL}/categories`; // GET/active
  export const itemsURL = `${apiURL}/items`; // GET/categoryID/active
  export const variationsURL = `${apiURL}/variations`; // GET/itemID/active
  export const getitemcostURL = `${apiURL}/itemcost`  // GET/categoryID/itemID/variationID
  export const updateitemcostURL = `${apiURL}/updateitemcost`  // POST body: ItemCostData
  export const updatepreorderitemcostURL = `${apiURL}/updatepreorderitemcost`  // POST body: ItemCostData

  export const structuredCatalogURL = `${apiURL}/structuredCatalog`;
    export const allStructuredCatalogURL = `${structuredCatalogURL}/all`;
    export const activeStructuredCatalogURL = `${structuredCatalogURL}/active`;

  export const vendorURL = `${apiURL}/vendors`;
    export const addVendorURL = `${apiURL}/vendor/add`; // POST /vendor name

  export const fileURL = `${apiURL}/file`;
    export const salesUploadURL = `${fileURL}/sales`; // POST body: FormData

  export const orderingURL = `${apiURL}/pendingOrderItem`;
//    export const orderBookURL = `${orderingURL}/PendingOrdersAndItems`; // GET
    export const pricingWorkbookURL = `${orderingURL}/pendingItemSummary`; // GET
    export const orderingWorkbookURL = `${orderingURL}/workbook`;// GET query: ?storeIDs=Array of numbers&date=adjusted millis date
    export const orderingWorksheetURL = `${orderingURL}/worksheet`; // GET /store ID/adjusted millis date
    export const pendingOrderVendorsURL = `${orderingURL}/pendingVendors`; // GET
    export const pendingOrderHeadersURL = `${orderingURL}/pendingOrders`; // GET
    export const movePendingForVendorURL = `${orderingURL}/movePendingForVendor`; // GET /vendor id
    export const downloadPendingOrderURL = `${orderingURL}/pendingConsolidatedOrders`; // GET /order id
    export const pendingOrderItemUpdateURL = `${orderingURL}/pendingItem`; // POST body: PendingOrderItemUpdate
    export const downloadOrderSummarySheetURL = `${orderingURL}/orderSheet`; // GET file
    export const pendingOrderItemUpdateCostURL = `${orderingURL}/updatePendingItemsCost`; // POST body: PendingOrderItemUpdate
    export const pendingOrderItemUpdateVendorURL = `${orderingURL}/updatePendingItemsVendor`; // POST body: PendingOrderItemUpdate

  export const purchaseOrderURL = `${apiURL}/purchaseOrder`;
    export const purchaseOrderWorkbookURL = `${purchaseOrderURL}/{status}OrdersAndItems`; // GET
    export const getPurchaseOrderCountURL = `${purchaseOrderURL}/{status}OrderCount`; // GET
    export const pendingPurchaseOrderWorkbookURL = `${purchaseOrderURL}/pendingOrdersAndItems`; // GET
    export const openPurchaseOrderWorkbookURL = `${purchaseOrderURL}/openOrdersAndItems`; // GET
    export const closedPurchaseOrderWorkbookURL = `${purchaseOrderURL}/closedOrdersAndItems`; // GET
    export const purchaseOrderHeadersURL = `${purchaseOrderURL}/{status}Orders`; // GET
    export const downloadPurchaseOrderReceivingSheetURL = `${purchaseOrderURL}/pendingConsolidatedOrderSheet`; // GET /order id
    export const purchaseOrderItemUpdateURL = `${purchaseOrderURL}/updatePurchaseOrderItemCost`; // POST body: OrderLineItemUpdate
    export const purchaseOrderItemUpdateStatusURL = `${purchaseOrderURL}/updatePurchaseOrderItemStatus` // POST body: OrderItemUpdate
    export const purchaseOrderItemDowngradeStatusURL = `${purchaseOrderURL}/downgradePurchaseOrderItemStatus` // POST body: OrderItemUpdate
    export const purchaseOrderItemUpdateReceivedURL = `${purchaseOrderURL}/updatePurchaseOrderItemQuantity` // POST body: OrderItemUpdate
    export const purchaseOrderUpdateStatusURL = `${purchaseOrderURL}/updatePurchaseOrder`;
    export const purchaseOrderDowngradeStatusURL = `${purchaseOrderURL}/downgradePurchaseOrder`;
    export const purchaseOrderUpdateShippingURL = `${purchaseOrderURL}/updatePurchaseOrderShipping`;
    export const purchaseOrderUpdateAdjustmentsURL = `${purchaseOrderURL}/updatePurchaseOrderAdjustment`;
    export const purchaseOrderUpdateDiscountURL = `${purchaseOrderURL}/updatePurchaseOrderDiscount`;
    export const purchaseOrderUpdateItemCostURL = `${purchaseOrderURL}/updatePurchaseOrderItemCost`; // POST body: ItemCostUpdate
    export const purchaseOrderUpdateInvoiceNumberURL = `${purchaseOrderURL}/updatePurchaseOrderInvoiceNumber`;
    
  export const storeShippingURL = `${apiURL}/storeshipping`;
    export const storeShippingItemsToShipURL = `${storeShippingURL}/itemstoship`;
    export const storeShippingItemUpdateCountURL = `${storeShippingURL}/updatecount`;
    export const storeShippingItemUpdateStatusURL = `${storeShippingURL}/updatestatus`;
    export const storeShippingItemDowngradeStatusURL = `${storeShippingURL}/downgradestatus`;

  export const storeReceivingURL = `${apiURL}/storereceiving`;
    export const storeReceivingItemsToShipURL = `${storeReceivingURL}/itemstoreceive`;
    export const storeReceivingItemUpdateCountURL = `${storeReceivingURL}/updatecount`;
    export const storeReceivingItemUpdateStatusURL = `${storeReceivingURL}/updatestatus`;
    export const storeReceivingItemDowngradeStatusURL = `${storeReceivingURL}/downgradestatus`;

  export const inventoryTransferURL = `${apiURL}/inventorytransfer`;
    export const updateOrAddInventoryTransferURL = `${inventoryTransferURL}/updateOrAddTransfer`;
    export const storePullInventoryToShipURL = `${inventoryTransferURL}/itemstopull`;
    export const storePullInventoryUpdateCountURL = `${inventoryTransferURL}/updateshippedcount`;
    export const storePullInventoryUpdateStatusURL = `${inventoryTransferURL}/updatestatus`;
    export const storePullInventoryDowngradeStatusURL = `${inventoryTransferURL}/downgradestatus`;
    export const storeReceiveTransfersURL = `${inventoryTransferURL}/itemstoreceive`;
    export const storeReceiveTransferUpdateCountURL = `${inventoryTransferURL}/updatereceivedcount`;
    export const storeReceiveTransferUpdateStatusURL = `${inventoryTransferURL}/updatestatus`;
    export const storeReceiveTransferDowngradeStatusURL = `${inventoryTransferURL}/downgradestatus`;
    export const getInventoryTransfersForDateRangeURL = `${inventoryTransferURL}/fordaterange`;  //GET /:startDate/:endDate
    export const getInventoryTransferDetailsURL = `${inventoryTransferURL}/detailsfordate`;  //GET /:categoryID/:itemID/:variationID/:date

  export const stockTransferURL = `${apiURL}/stockroom`;
    export const transferStoreInventoryURL = `${stockTransferURL}/itemsto{direction}`;
    // export const receiveStoreInventoryURL = `${stockTransferURL}/itemstoreceive`;
    // export const sendStoreInventoryURL = `${stockTransferURL}/itemstosend`;
    export const receiveStoreInventoryUpdateCountURL = `${stockTransferURL}/updatereceivedcount`;
    export const sendStoreInventoryUpdateCountURL = `${stockTransferURL}/updateshippedcount`;
    export const receiveStoreInventoryUpdateStatusURL = `${stockTransferURL}/updatestatus`;
    export const receiveStoreInventoryDowngradeStatusURL = `${stockTransferURL}/downgradestatus`;

  export const adminURL = `${apiURL}/admin`;
    export const usersURL = `${adminURL}/users`;
    export const updateUserURL = `${adminURL}/user`;
    export const removeUserURL = `${adminURL}/removeuser`;
    export const getFilteredUsersURL = `${adminURL}/filteredusers`;  // GET ?filterName=filterValue
    export const companiesURL = `${adminURL}/company/all`;
    export const updateCompanyURL = `${adminURL}/company/update`;
    export const removeCompanyURL = `${adminURL}/company/remove`;
    export const addCompanyToUserURL = `${adminURL}/company/addtouser`;
    export const removeCompanyFromUserURL = `${adminURL}/company/removefromuser`;
    export const getFilteredCompaniesURL = `${adminURL}/company/filteredcompanies`;  // GET ?filterName=filterValue
    export const getFilteredStoresURL = `${adminURL}/filteredstores`;  // GET ?filterName=filterValue
    export const activeToggleURL = `${adminURL}/catalog/changeactive`;//'category', 'item', or 'variation'/ id / active boolean
    export const setInventoryClassURL = `${adminURL}/catalog/changeInventoryClass` // category id / 'hardware' or 'liquid'
    export const downloadDatabaseBackupURL = `${adminURL}/databaseBackup`;

  export const registerURL = `${adminURL}/register`;
    export const getRegistersForStoreURL = `${registerURL}/all`; // GET storeID
    export const updateRegisterURL = `${registerURL}/update`; // POST body: register
    export const removeRegisterURL = `${registerURL}/remove`; // POST body: register
    export const getFilteredRegistersURL = `${registerURL}/filtered`; // GET ?filterName=filterValue
    
  export const deviceURL = `${adminURL}/device`;
    export const getDevicesForStoreURL = `${deviceURL}/forstore`; // GET storeID
    export const updateDeviceURL = `${deviceURL}/update`; // POST body: device
    export const removeDeviceURL = `${deviceURL}/remove`; // GET deviceID
    
  export const mendelURL = `${rootURL}/mendel`
  export const customerURL = `${mendelURL}/customer`;
    export const getCustomersURL = `${customerURL}/all`;
    export const getFilteredCustomersURL = `${customerURL}/filtered`; // GET ?filterName=filterValue
    export const getFilteredCustomerCountURL = `${customerURL}/filteredcount`; // GET ?filterName=filterValue
    export const updateCustomerURL = `${customerURL}/update`; // POST body: customer
    export const removeCustomerURL = `${customerURL}/remove`; // GET customerID
    export const customerNameLookupURL = `${customerURL}/namelookup`; //GET ?searchKey=partialName

  export const transactionURL = `${mendelURL}/transaction`;
    export const getTransactionsURL = `${transactionURL}/all`;
    export const getFilteredTransactionsURL = `${transactionURL}/filtered`; // GET ?filterName=filterValue
    export const getFilteredTransactionCountURL = `${transactionURL}/filteredcount`; // GET ?filterName=filterValue
    export const updateTransactionURL = `${transactionURL}/update`; // POST body: transaction
    export const removeTransactionURL = `${transactionURL}/remove`; // GET transactionID

  export const pendingTransactionURL = `${mendelURL}/pendingtransaction`;
    export const getPendingTransactionsURL = `${pendingTransactionURL}/all`;
    export const getFilteredPendingTransactionsURL = `${pendingTransactionURL}/filtered`; // GET ?filterName=filterValue
    export const getFilteredPendingTransactionCountURL = `${pendingTransactionURL}/filteredcount`; // GET ?filterName=filterValue
    export const updatePendingTransactionURL = `${pendingTransactionURL}/update`; // POST body: transaction
    export const removePendingTransactionURL = `${pendingTransactionURL}/remove`; // GET transactionID
    export const pendingTransactionServerStartURL = `${pendingTransactionURL}/start`;
    export const pendingTransactionServerStopURL = `${pendingTransactionURL}/stop`;
    export const pendingTransactionServerProcessOneURL = `${pendingTransactionURL}/processonetransaction`;

  export const systemURL = `${rootURL}/system`;
  export const loadCustomersFromFirestoreURL = `${systemURL}/importmendelcustomersfromfirebase`; // GET
  export const loadTransactionsFromFirestoreURL = `${systemURL}/importmendeltransactionsfromfirebase`; // GET

export var loginToken: string;
export var loginUser: UserData;
export var loginAuthorizations: Array<string> = [];
export let loginCompany: UserCompanyData = null; 

export const setGlobalLoginToken = (token: string) => {
  loginToken = token;
}

export const setGlobalLoginUser = (user: UserData) =>{
  loginUser = user;
  if ( user && user.authorizations)
    loginAuthorizations = user.authorizations.split(",");
  else
    loginAuthorizations = [];
}

export function getFormDataHeaders() {
  return {
    'Authorization': `Bearer ${loginToken}`
  }
}

export function setGlobalUserCompany(userCompany: UserCompanyData){
  loginCompany = userCompany;
}

export function getJSONHeaders() {
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${loginToken}`
  }
}

export const buildQueryFromFilters = (filters: {}): string => {
  let query = "";
  if (filters) {
    const keys = Object.keys(filters);
    let i = keys.length;
    while (i--) {
      const key = keys[i];
      const keyValue = filters[key]
      if (keyValue) {
        query += (query ? "&" : "?") + key + "=" + keyValue;
      }
    }
  }
  return query;
}
