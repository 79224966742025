import React, { useState, useLayoutEffect } from 'react';
import { Theme, ListItem, List, ListItemText, ListSubheader, ListItemSecondaryAction, ListItemIcon, Button, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle'
import { InventoryTransferItem, InventoryTransferQuantityAndStatusUpdate } from '../wireData/InventoryTransferData'
import { CategoryItems } from './StoreReceiveTransfersData';
import { TypographyProps } from '@material-ui/core/Typography';
import { useReceiveStoreTransfers } from './ReceiveStoreTransfersHandler';
import { ChangeTransferCountDialog } from '../common/ChangeTransferCountDialog';
import { UpdateError, UpdateStatus } from '../common/TransferUpdateDialogs';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  categoryList: {
    flex: 1,
    overflowY: 'auto',
  },
  categorySection: {
    backgroundColor: "inherit",
  },
  categoryHeader: {
    backgroundColor: theme.palette.grey[900],
    padding: 0,
  },
  listItem: {
    listStyleType: "none",
  },
  itemName: {
    variant: "h6",
  },
}));

export class StoreReceiveTransferListProps {
  storeName: string;
}

export function StoreReceiveTransferList(props: StoreReceiveTransferListProps) {
  const classes = useStyles({});
  const [{ scrolling, transferToUpdate, updateError, updating }, manager, actions] = useReceiveStoreTransfers();

  const scrollinfRef = React.useRef(null);

  useLayoutEffect(() => {
    scrollinfRef.current.scrollTop = scrolling.top;
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrolling.top = event.currentTarget.scrollTop;
  }

  class SubHeaderProps {
    category: CategoryItems;
  }

  const SubHeader = (props: SubHeaderProps) => {
    class TransferItemProps {
      transferItem: InventoryTransferItem;
    }

    const primaryProps: TypographyProps = {
      variant: "h5"
    }

    const secondaryProps: TypographyProps = {
      variant: "h6"
    }

    const TransferItem = (props: TransferItemProps) => {
      const checked = props.transferItem.status == "delivered";

      const handleClick = async (transferItem: InventoryTransferItem) => {
        if (transferItem.quantityReceived == null) {
          actions.setTransferToUpdate(transferItem);
          return;
        }
        const update = new InventoryTransferQuantityAndStatusUpdate();
        update.status = transferItem.status;
        update.quantity = transferItem.quantityReceived;
        update.inventoryTransferID = transferItem.inventoryTransferID;

        if (checked) {
          await actions.downgradeReceiveTransferItemStatus(update);
        } else {
          await actions.updateReceiveTransferItemStatus(update);
        }
        if (actions.state.updateSuccess) {
          const updatedItem = actions.state.updatedItem;
          manager.updateStatus(updatedItem);
          manager.updateCount(updatedItem);
        }
        actions.clearUpdate();
      }

      const handleCountButtonClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        actions.setTransferToUpdate(props.transferItem);
        if (event.ctrlKey && props.transferItem.quantityReceived == null)
          props.transferItem.quantityReceived = props.transferItem.quantityShipped;
      }

      const handleChangeCountDialogClose = () => {
        actions.setTransferToUpdate(null);
      }

      const handleCountUpdate = async (newValue: number) => {
        const update = new InventoryTransferQuantityAndStatusUpdate();
        update.quantity = newValue;
        update.inventoryTransferID = transferToUpdate.inventoryTransferID;

        actions.setTransferToUpdate(null);
        await actions.updateReceiveTransferItemCount(update);
        if (actions.state.updateSuccess) {
          manager.updateCount(actions.state.updatedItem);
        }
        actions.clearUpdate();
      }

      const CountDialog = () => {
        if (transferToUpdate)
          return (
            <ChangeTransferCountDialog
              open
              initialValue={transferToUpdate.quantityReceived}
              transferItem={transferToUpdate}
              onClose={handleChangeCountDialogClose}
              onUpdate={handleCountUpdate}
            />

          )
        return null;
      }

      const ButtonOrProgress = () => {
        if (transferToUpdate == props.transferItem)
          return (
            <div>
              <CircularProgress />
              <CountDialog />
            </div>
          )
        return (
          <Button onClick={handleCountButtonClick}>
            <Typography variant="h4">{props.transferItem.quantityReceived == null ? "--" : props.transferItem.quantityReceived}</Typography>
          </Button>
        )
      }

      return (
        <ListItem button onClick={() => handleClick(props.transferItem)} key={props.transferItem.inventoryTransferID}>
          <ListItemIcon>
            <ButtonOrProgress />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={primaryProps}
            secondaryTypographyProps={secondaryProps}
            primary={props.transferItem.itemName}
            secondary={props.transferItem.variationDescription ? props.transferItem.variationDescription : null} />
          <ListItemSecondaryAction>
            <CheckIcon style={{ color: checked ? "Chartreuse" : "lightgray" }} />
          </ListItemSecondaryAction>
        </ListItem>
      )
    }

    return (
      <li className={classes.categorySection}>
        <ul className={classes.listItem}>
          <ListSubheader className={classes.categoryHeader}><Typography variant="h4">{props.category.categoryName}</Typography></ListSubheader>
          {
            props.category.items.map((transferItem) =>
              <TransferItem key={transferItem.inventoryTransferID} transferItem={transferItem} />
            )
          }
        </ul>
      </li>
    )
  }

  const handleErrorClose = () => {
    actions.clearUpdate();
  }

  return (
    <div className={classes.root}>
      <UpdateError open={Boolean(updateError)} message={updateError && updateError.message} onClose={handleErrorClose} />
      <UpdateStatus open={updating} message="Updating..." />
      <Typography variant="h6" align="center" gutterBottom>Receive Transfers for {props.storeName}</Typography>
      <div className={classes.categoryList} ref={scrollinfRef} onScroll={handleScroll}>
        <List>
          {manager.categories.map((category) => (
            <SubHeader category={category} key={category.categoryName} />
          ))}
        </List>
      </div>
    </div>
  )
}