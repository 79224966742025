import React, { useState, useEffect } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import { useUsers, getUserEditStore, resetUserStore } from './UserHandler';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { UserData } from '../wireData/UserData';
import { useStyles } from './styles';
import { UserList } from './UserList';
import { EditUserContainer } from './EditUserContainer';
import { loginCompany } from '../utils/WireUtils';

export const resetUserComponent = () =>{
  resetUserStore ();
}
class UserContainerProps {
  onClose: () => void;
}

export function UserContainer(props: UserContainerProps) {
  const classes = useStyles({});
  // const [currentUser, setCurrentUser] = useState<UserData>(null);
  const [{ error, loading, success, currentUser, users }, actions] = useUsers();

  useEffect(() => {
    if (!error && !loading && !success)
      actions.getUsers();
  }, [users]);

  const handleUserListClose = () => {
    props.onClose();
  }

  const handleEditUserClose = () => {
    actions.setCurrentUser(null);
  }

  const handleUpdateUser = (update: UserData) => {
    const user = users.find((user) => user.userID == update.userID);

    if (user) {
      Object.assign(user, update);
      if (user.companies.split(',').map((companyID) => Number(companyID)).indexOf(loginCompany.companyID) < 0)
        handleRemoveUser(user);
    }
    else
      users.unshift(update);

    actions.setCurrentUser(null);
  }

  const handleRemoveUser = (userRemoved: UserData) => {
    const user = users.find((user) => user.userID == userRemoved.userID);
    if (user) {
      const index = users.indexOf(user);
      if (index >= 0)
        users.splice(index, 1);
    }
    actions.setCurrentUser(null);
  }

  const handleSelectUser = (user: UserData) =>{
    actions.setCurrentUser(user);
    getUserEditStore(new UserData(user));
  }

  const ShowUserList = () => {
    if (loading) return <Loading />
    if (error) return <Error message={error.message} />
    if (success)
      return (
        <Grid container spacing={1} >
          <UserList
            users={users}
            onSelectUser={handleSelectUser}
            onClose={handleUserListClose} />
        </Grid>
      )
    return null;
  }

  const ShowListOrEditor = () => {
    if (currentUser)
      return <EditUserContainer
        user={currentUser}
        onUpdate={handleUpdateUser}
        onRemove={handleRemoveUser}
        onCancel={handleEditUserClose} />;
    return <ShowUserList />
  }

  return (
    <div className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography variant='h6' align='center' gutterBottom>Manage Users</Typography>
        <ShowListOrEditor />
      </Paper>
    </div >
  )
}