import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/styles";
import { Theme, createStyles, Grid, Typography, List, ListItem, Button } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles((theme: Theme) => createStyles({
  listBox: {
    width: "100%",
    // flexBasis: 'auto',
    // flex: '1',
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "40vh",
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

export interface ListData {
  key: () => any;
  toString: () => string;
}

export interface DataGridListProps {
  dataList: Array<ListData>;
  label?: string;
  classes?: any;
  multiple?: boolean;
  currentSelection?: ListData | Array<ListData>;
  onSelectItem: (listItem: ListData | Array<ListData>) => void;
}

export function DataGridList(props: DataGridListProps) {
  const classes = props.classes || useStyles({});

  const [currentSelection, setCurrentSelection] = useState<ListData | Array<ListData>>(null);
  const [update, setUpdate] = useState(false);

  const label = props.label || "Select";

  useEffect(() => {
    setCurrentSelection(props.currentSelection);
  }, [props.currentSelection]);

  const onClick = (item: ListData) => (event) => {
    if (props.multiple) {
      const index = (currentSelection as Array<ListData>).indexOf(item);
      if (index >= 0)
        (currentSelection as Array<ListData>).splice(index, 1);
      else
        (currentSelection as Array<ListData>).push(item);
      setUpdate(!update);
    }
    props.onSelectItem(item);
  }

  const ShowSelected = (selected: ListData) => {
    if (props.multiple) {
      if (currentSelection && (currentSelection as Array<ListData>).find((item) => item.key() == selected.key()))
        return <CheckIcon color="secondary" />
      else
        return <CheckIcon color="primary" />
    }
    else
      return null;
  }

  return (
    <Grid item xs={12}>
      <Typography variant='subtitle1' align='center'>{label}</Typography>
      <div className={classes.listBox}>
        <List>
          {props.dataList.map((item) =>
            <ListItem key={item.key()} >
              {ShowSelected(item)}
              <Button
                variant='contained'
                color="primary"
                fullWidth
                className={classes.button}
                onClick={onClick(item)}
              >
                <Typography noWrap >{item.toString()}</Typography>
              </Button>
            </ListItem>
          )}
        </List>
      </div>
    </Grid>
  )
}

