import { PriceSummary, PricingWorkbook } from './PricingData';
import { PendingOrderItemUpdate } from '../preordering/StoreOrderingData';

export class PricingWorkbookManager {

  // private static _instance: PricingWorkbookManager = null;
  // private constructor() { };
  // public static get Instance() {
  //   if (this._instance == null) {
  //     this._instance = new PricingWorkbookManager();
  //   }
  //   return this._instance;
  // }

  private _workbook: PricingWorkbook;
  private _filteredWorkbook: PricingWorkbook;
  currentFilter: string = null;
  set workbook(book: PricingWorkbook) {
    this._workbook = book;
  }

  get workbook() {
    if (this._filteredWorkbook) return this._filteredWorkbook;
    return this._workbook;
  }

  getVendors(): Array<string> {
    const vendors = new Array<string>();
    this._workbook.forEach(summary => {
      if (vendors.indexOf(summary.vendorName) < 0) {
        vendors.push(summary.vendorName);
      }
    })
    return vendors;
  }

  filterVendors(vendor: string) {
    this.currentFilter = vendor;
    this._filteredWorkbook = this._workbook.filter(summary => summary.vendorName == vendor);
  }

  clearFilter() {
    this.currentFilter = null;
    this._filteredWorkbook = null;
  }

  getColumns() {
    return [
      'Category',
      'Item',
      'Variation',
      'To Order',
      'Latest Cost',
      'Cost Per',
      'Total Cost',
      'Latest Vendor',
      'Vendor',
    ]
  }

  updateWithPending(itemUpdate: PendingOrderItemUpdate) {
    const summaryToUpdate = this._workbook.find(summary => (
      summary.categoryID == itemUpdate.categoryID &&
      summary.itemID == itemUpdate.itemID &&
      summary.variationID == itemUpdate.variationID &&
      summary.vendorID == itemUpdate.vendorID
    ));

    if (summaryToUpdate) {
      summaryToUpdate.itemCost = itemUpdate.itemCost;
      summaryToUpdate.itemTotal = itemUpdate.pendingTotal;
    }
  }

  generateWorkbook() {
    const book = new Array<PriceSummary>();
    let itemCount = 0;
    let vendorID = 1;
    for (let i = 0; i < 100; i++) {
      if (itemCount >= 10) {
        vendorID += 1;
        itemCount = 0;
      }
      const toOrder = Math.floor(Math.random() * 10)
      book.push(new PriceSummary(
        vendorID,
        `Vendor ${vendorID}`,
        i,
        `Category${i}`,
        i,
        `Item ${i}`,
        i,
        `Variation ${i}`,
        toOrder,
        2,
        toOrder * 2
      ))
      itemCount++;
    }
    this._workbook = book;
  }
}