import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Paper, TextField, Tabs, Tab, Typography, TableHead, TableRow, TableCell, CircularProgress, TableBody, Table, Theme } from '@material-ui/core';
import { PriceSummary, PricingWorkbook } from './PricingData';
import { updateCost, usePricingWorkbook, PricingWorkbookState, PricingWorkbookStoreName, resetPricingWorkbookState } from './PricingHandler';
import { Vendor } from '../vendor/Vendor';
import { updateItemVendors } from '../preordering/PreOrderingHandler';
import { VendorSelect } from '../vendor/VendorSelect';
import { PendingOrderItemUpdate } from '../preordering/StoreOrderingData';
import { numberToDollarString } from '../utils/Convert';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { useStore } from 'react-hookstore';

export function resetPricingWorkbookComponent(){
  resetPricingWorkbookState();
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    // padding: theme.spacing(2),
    overflowY: 'hidden',
    overflowX: 'auto',
    height: 'auto',
    maxHeight: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tabScrollButton: {
    color: 'white',
  },
  tabs: {
    maxHeight: 48,
    flexBasis: 'auto',
    flex: '0 0',
  },
  table: {
    flex: 1,
    overflowY: 'auto',
  },
  headerCell: {
    position: 'sticky',
    top: 0,
    background: theme.palette.primary[800],
  },
  tableCell: {
    fontSize: 18,
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: 10,
    // textAlign: 'center',
  },
  withPointer: {
    cursor: 'pointer',
  },
}))

interface VendorTabProps {
  vendors: Array<string>;
  vendor: string;
  onTabClick: (vendor: string) => void;
}

function VendorTabs(props: VendorTabProps) {
  const classes = useStyles({});
  const [value, setValue] = useState(props.vendors.indexOf(props.vendor));
  const handleChange = (event, value) => {
    props.onTabClick(props.vendors[value]);
    setValue(value);
  }
  return (
    <Paper square>
      <Tabs value={value} onChange={handleChange} variant='scrollable' scrollButtons="auto" >
        {props.vendors.map(ven => {
          ven = ven ? ven : "No Vendor";
          return <Tab key={ven} label={
            <Typography>{ven}</Typography>
          } />
        })}
      </Tabs>
    </Paper>
  )
}

interface PricingTableHeadProps {
  columns: Array<string>;
}

const PricingTableHead = React.memo((props: PricingTableHeadProps) => {
  const classes = useStyles({});
  return (
    <TableHead>
      <TableRow>
        {props.columns.map(col => <TableCell className={`${classes.headerCell} ${classes.tableCell}`} key={col}>{col}</TableCell>)}
      </TableRow>
    </TableHead>
  )
})

interface PricingTableRowProps {
  item: PriceSummary;
  focused: boolean;
  index: number;
  onNavUp: () => void;
  onNavDown: () => void;
  onClick: () => void;
}

const PricingTableRow = React.memo((props: PricingTableRowProps) => {
  const [total, setTotal] = useState(props.item.itemTotal);
  const [newCost, setNewCost] = useState(props.item.itemCost);
  const [newVendor, setNewVendor] = useState<Vendor>({ vendorID: props.item.vendorID, vendorName: props.item.vendorName })
  const classes = useStyles({});

  useEffect(() => {
    setTotal(props.item.itemTotal);
  }, [props.item.itemTotal]);

  const handleTotalChange = (total: number) => {
    //    console.log("New Total: " + total);
    setTotal(total);
  }

  const forceCostUpdate = (cost: number) => (event) => {
    if (cost != props.item.itemCost) {
      if (cost == newCost) {
        setNewCost(0);
        setTimeout(
          () => setNewCost(newCost),
          250
        )
      }
      else
        setNewCost(cost)
    }
  }

  const forceVendorUpdate = (event) => {
    //console.log("Prior Vendor Clicked");
    if (props.item.priorVendorID && props.item.priorVendorID != props.item.vendorID) {
      setNewVendor({ vendorID: props.item.priorVendorID, vendorName: props.item.priorVendorName });
    }
  }

  const pointer = { cursor: "pointer" };

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>{props.item.categoryName}</TableCell>
      <TableCell className={classes.tableCell}>{props.item.itemName}</TableCell>
      <TableCell className={classes.tableCell}>{props.item.variationDescription}</TableCell>
      <TableCell className={classes.tableCell}>{props.item.toOrder}</TableCell>
      <TableCell className={classes.tableCell} style={pointer} onClick={forceCostUpdate(props.item.latestItemCost)}>{numberToDollarString(props.item.latestItemCost)}</TableCell>
      <PriceCell
        vendorID={props.item.vendorID}
        categoryID={props.item.categoryID}
        itemID={props.item.itemID}
        variationID={props.item.variationID}
        itemCost={props.item.itemCost}
        focused={props.focused}
        onNavDown={props.onNavDown}
        onNavUp={props.onNavUp}
        onClick={props.onClick}
        onTotalChange={handleTotalChange}
        newCost={newCost}
      />
      <TableCell className={classes.tableCell}>{numberToDollarString(total)}</TableCell>
      <TableCell className={classes.tableCell} style={pointer} onClick={forceVendorUpdate}>{props.item.priorVendorName}</TableCell>
      <VendorCell item={props.item} newVendor={newVendor} />
    </TableRow>
  )
}, (oldProps: PricingTableRowProps, newProps: PricingTableRowProps) => (
  oldProps.focused == newProps.focused
  && oldProps.item.vendorID == newProps.item.vendorID
))

interface PriceCellProps {
  vendorID: number;
  categoryID: number;
  itemID: number;
  variationID: number;
  itemCost: number;
  focused: boolean;
  newCost: number;
  onNavDown: () => void;
  onNavUp: () => void;
  onClick: () => void;
  onTotalChange?: (newTotal: number) => void;
}

function PriceCell(props: PriceCellProps) {
  const [{ }, manager] = usePricingWorkbook();
  const [isInput, setIsInput] = useState(false);
  const [displayed, setDisplayed] = useState('0');
  const [pending, setPending] = useState(false);
  const [cost, setCost] = useState(props.itemCost);
  const classes = useStyles({});

  useEffect(() => {
    setIsInput(props.focused);
    setDisplayed(String(cost));
  }, [props.focused])

  useEffect(() => {
    setCost(props.itemCost);
  }, [props.itemCost])

  useLayoutEffect(() => {
    if (props.newCost != cost) {
      handleSubmit(props.newCost)
    }
  }, [props.newCost])

  const handleClick = () => {
    setDisplayed(String(cost));
    props.onClick();
  }

  const handleChange = (e) => {
    setDisplayed(e.currentTarget.value);
  }

  const handleFocus = (e) => {
    e.target.select()
  }

  const handleBlur = (e) => {
    setIsInput(false);
    if (Number(displayed) != cost) {
      handleSubmit(Number(displayed));
    }
  }

  const handleSubmit = (newCost: number) => {
    if (newCost == cost) {
      return;
    }
    setPending(true);
    const pendingItem = new PendingOrderItemUpdate();
    pendingItem.vendorID = props.vendorID;
    pendingItem.categoryID = props.categoryID;
    pendingItem.itemID = props.itemID;
    pendingItem.variationID = props.variationID;
    pendingItem.itemCost = newCost;

    updateCost(pendingItem)
      .then((update: PendingOrderItemUpdate) => {
        manager.updateWithPending(update);
        setCost(update.itemCost);
        setPending(false);
        if (props.onTotalChange)
          props.onTotalChange(update.pendingTotal);
      })
      .catch((e) => {
        alert(e.message);
        setPending(false);
      })
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "Enter":
      case "Tab":
      case "ArrowDown":
        event.preventDefault();
        props.onNavDown();
        handleSubmit(Number(displayed));
        break;
      case "ArrowUp":
        event.preventDefault();
        props.onNavUp();
        handleSubmit(Number(displayed));
        break;
      case "0": case "1": case "2": case "3": case "4": case "5": case "6": case "7": case "8": case "9":
      case "Backspace":
      case "Delete":
        break;
      case ".":
        if (displayed.length && displayed.indexOf(".") >= 0)
          event.preventDefault();
        break;
      case "Escape":
        setIsInput(false);
        setDisplayed(String(props.itemCost));
        event.preventDefault();
        break;
      default:
        event.preventDefault();
        break;
    }
  }

  const cellStyle = { maxWidth: 35 };
  const circleStyle = { maxWidth: 25 };
  const pointerStyle = { cursor: "pointer" };

  return <TableCell className={classes.tableCell} style={pointerStyle} onClick={handleClick}>{
    pending
      ?
      <CircularProgress style={circleStyle} />
      :
      isInput
        ?
        <span>$
        <TextField
            style={cellStyle}
            type='text'
            autoFocus
            value={displayed}
            onKeyDown={handleKeyPress}
            onFocus={handleFocus}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </span>
        :
        numberToDollarString(cost)
  }</TableCell>
}

interface VendorCellProps {
  item: PriceSummary;
  newVendor: {
    vendorID: number;
    vendorName: string;
  }
}

function VendorCell(props: VendorCellProps) {
  const [vendor, setVendor] = useState(props.item.vendorName);
  const [anchor, setAnchor] = useState(null);
  const [pending, setPending] = useState(false);
  const classes = useStyles({});

  useEffect(() => {
    setVendor(props.item.vendorName)
  }, [props.item.vendorName]);

  useEffect(() => {
    if (props.newVendor.vendorID != props.item.vendorID)
      handleVendorSelected(props.newVendor);
  }, [props.newVendor])

  const handleOnClick = (event: React.MouseEvent) => {
    setAnchor(anchor ? null : event.currentTarget);
  }

  const handleVendorSelected = async (vendor: Vendor) => {
    setAnchor(null);
    setPending(true);
    const update = {
      categoryID: props.item.categoryID,
      itemID: props.item.itemID,
      variationID: props.item.variationID,
      vendorID: vendor.vendorID,
    }
    updateItemVendors(update as PendingOrderItemUpdate)
      .then((result) => {
        props.item.vendorID = vendor.vendorID;
        props.item.vendorName = vendor.vendorName;
        setPending(false);
      })
      .catch((result) => {
        props.item.vendorID = 0;
        props.item.vendorName = result.message;
        setPending(false);
      })
  }

  const handleVendorCleared = () => {
    setVendor(null);
    setPending(true);
    const update = {
      categoryID: props.item.categoryID,
      itemID: props.item.itemID,
      variationID: props.item.variationID,
      vendorID: 0,
    }
    updateItemVendors(update as PendingOrderItemUpdate)
      .then((result) => {
        props.item.vendorID = 0;
        props.item.vendorName = "No Vendor";
        setPending(false);
      })
      .catch((result) => {
        props.item.vendorID = 0;
        props.item.vendorName = result.message;
        setPending(false);
      });
  }

  return <TableCell className={`${classes.tableCell} ${classes.withPointer}`} style={Boolean(anchor) ? { backgroundColor: '#222' } : null} onClick={handleOnClick}>
    {anchor
      ?
      <VendorSelect anchor={anchor} onVendorSelected={handleVendorSelected} onVendorCleared={handleVendorCleared} />
      :
      pending
        ?
        <CircularProgress />
        :
        null}
    {vendor || "No Vendor"}
  </TableCell>
}

interface PricingTableBodyProps {
  book: PricingWorkbook
}

const PricingTableBody = React.memo((props: PricingTableBodyProps) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleNavUp = (index: number) => () => {
    if (index > 0) {
      setActiveIndex(index - 1);
    } else {
      setActiveIndex(0);
    }
  }
  const handleNavDown = (index: number) => () => {
    if (index < props.book.length - 1) {
      setActiveIndex(index + 1);
    }
  }
  const handleRowClick = (index: number) => () => {
    setActiveIndex(index);
  }

  return (
    <TableBody>
      {props.book.map(
        (item, index) =>
          <PricingTableRow
            key={index}
            item={item}
            focused={index == activeIndex}
            onNavUp={handleNavUp(index)}
            onNavDown={handleNavDown(index)}
            index={index}
            onClick={handleRowClick(index)}
          />)}
    </TableBody>
  )
})

function PricingTable() {
  const classes = useStyles({});
  const [{ displayedBook, vendor, columns, vendors, scrolling }, , pricingActions] = usePricingWorkbook();

  const scrollinfRef = React.useRef(null);

  useLayoutEffect(() => {
    scrollinfRef.current.scrollTop = scrolling.top;
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrolling.top = event.currentTarget.scrollTop;
  }

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        <VendorTabs vendors={vendors} vendor={vendor} onTabClick={pricingActions.setVendor} />
      </div>
      <div className={classes.table} ref={scrollinfRef} onScroll={handleScroll}>
        <Paper>
          <Table>
            <PricingTableHead columns={columns} />
            <PricingTableBody book={displayedBook} />
          </Table>
        </Paper>
      </div>
    </div>
  )
}

export function PricingComponent() {
  const [{ error, loading, success }, manager, pricingActions] = usePricingWorkbook();

  useEffect(() => {
    if (!(error || loading || success))
      pricingActions.getPricingWorkbook();
  }, [error, loading, success])

  if (error) return <Error message={error.message} />
  if (loading) return <Loading />
  if (success) return <PricingTable />
  return null;
}
