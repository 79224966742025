
import { loginURL, setUserCompanyURL, loginToken, getJSONHeaders, deviceloginURL } from '../utils/WireUtils';
import { sha256 } from 'js-sha256';
import { useEffect, useState } from 'react';
import { UserData } from '../wireData/UserData';
import { DeviceData } from '../wireData/DeviceData';

export async function processLogin(userName: string, password: string,
  setLoginStatus: (status: boolean, token: string, user:UserData, message: string) => void): Promise<any> {

  return fetch(loginURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  }).then(async function (response) {
    if (response.status >= 200 && response.status < 300) {
      const requestBody = await response.json();
      const responseBody = { userName: userName, password: sha256.hex(requestBody.nonce + password), token: requestBody.token };
      fetch(loginURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(responseBody)
      }).then(async function (response) {
        if (response.status >= 200 && response.status < 300) {
          const body = await response.json();
          //          console.log(body);
          setLoginStatus(true, body.token, body.user, "Login Successful");
        } else {
          const message = await response.json();
          setLoginStatus(false, "", null, "Login Failed: " + message.message);
        }
      }).catch(function (reason) {
        setLoginStatus(false, "", null, "Login Error: " + reason);
      })
    } else {
      const message = await response.json();
      setLoginStatus(false, "", null, "Login Failed: " + message.message);
    }
  }).catch(function (reason) {
    setLoginStatus(false, "", null, "Login Error: " + reason);
  })
}

export async function processDeviceLogin(deviceName: string, password: string,
  setLoginStatus: (status: boolean, token: string, device:DeviceData, message: string) => void): Promise<any> {

  return fetch(deviceloginURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  }).then(async function (response) {
    if (response.status >= 200 && response.status < 300) {
      const requestBody = await response.json();
      const responseBody = { deviceName: deviceName, password: sha256.hex(requestBody.nonce + password), token: requestBody.token };
      fetch(deviceloginURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(responseBody)
      }).then(async function (response) {
        if (response.status >= 200 && response.status < 300) {
          const body = await response.json();
          //          console.log(body);
          setLoginStatus(true, body.token, body.device, "Login Successful");
        } else {
          const message = await response.json();
          setLoginStatus(false, "", null, "Login Failed: " + message.message);
        }
      }).catch(function (reason) {
        setLoginStatus(false, "", null, "Login Error: " + reason);
      })
    } else {
      const message = await response.json();
      setLoginStatus(false, "", null, "Login Failed: " + message.message);
    }
  }).catch(function (reason) {
    setLoginStatus(false, "", null, "Login Error: " + reason);
  })
}

export const useSetUserCompany = (companyID: number): {
  error: any,
  loading: boolean,
  success: boolean,
  token: string
} => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [token, setToken] = useState<string>(null);

  useEffect(() => {
    setLoading(true);
    fetch(`${setUserCompanyURL}/${companyID}`, {
      method: 'GET',
      headers: getJSONHeaders()
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          response.json()
            .then((body) => {
              setToken(body.token);
              setSuccess(true);
            })
            .catch((e) => {
              setError(e);
            })
        } else {
          setError(new Error(response.statusText));
        }
      })
      .catch((e) => {
        setError(e);
      })
  }, [companyID]);

  return { error, loading, success, token };
}