import { FlatCatalog } from './Catalog'

export class FlatCatalogManager {
  private _catalog: FlatCatalog;
  private _filteredCatalog: FlatCatalog;

  get catalog(): FlatCatalog {
    if (this._filteredCatalog != null) {
      return this._filteredCatalog;
    }
    return this._catalog;
  }

  set catalog(catalog: FlatCatalog) {
    this._catalog = catalog;
  }

  parseFilterItems(): {vendors: Array<string>, categories: Array<string>} {
    const categories = new Array<string>();
    const vendors = new Array<string>();
    this._catalog.forEach(entry => {
      if (categories.indexOf(entry.categoryName) < 0) {
        categories.push(entry.categoryName);
      }
      let vendor = entry.variationID ? entry.variationVendor : entry.itemVendor
      if (vendors.indexOf(vendor) < 0) {
        vendors.push(vendor);
      }
    })
    return {vendors: vendors, categories: categories}
  }
  
  filterByVendor(vendor: string) {
    if (this._catalog == null ) return;
    this._filteredCatalog = this._catalog.filter (entry => {
      const itemVendor = entry.variationVendor ? entry.variationVendor : entry.itemVendor;
      return itemVendor == vendor;
    })
  }

  filterByCategory(category: string) {
    if (this._catalog == null) return;
    this._filteredCatalog = this._catalog.filter(entry => {
      return entry.categoryName == category;
    })
  }

  clearFilters() {
    this._filteredCatalog = this._catalog;
  }

  filterActive() {
    this._filteredCatalog = this._catalog.filter(entry => {
      return (entry.variationActive && entry.itemActive && entry.categoryActive);
    })
  }
}