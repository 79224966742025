import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useUpdateUser, useRemoveUser } from './UserHandler';
import { LinearLoading } from '../common/LoadingComponent';
import { UserData } from '../wireData/UserData';
import { EditUser } from './EditUser';
import { useGetUserCompanies } from '../company/CompanyHandler';

interface StatusDialogProps {
  message: string;
  open: boolean;
}

function StatusDialog(props: StatusDialogProps) {

  return (
    <Dialog open={props.open} fullWidth maxWidth="xs">
      <DialogContent>
        <LinearLoading message={props.message} />
      </DialogContent>
    </Dialog>
  )
}

interface ErrorDialogProps {
  error: any;
  open: boolean;
  onClose: () => void;
}

function ErrorDialog(props: ErrorDialogProps) {

  return (
    <Dialog open={props.open && Boolean(props.error)}>
      <DialogTitle>
        Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.error ? props.error.message || props.error : "Unknown Error"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export interface EditUserContainerProps {
  user: UserData;
//  currentStore: Store;
  onUpdate: (user: UserData) => void;
  onRemove: (user: UserData) => void;
  onCancel: () => void;
}

export function EditUserContainer(props: EditUserContainerProps) {

  const [userToUpdate, setUserToUpdate] = useState<null | UserData>(null);
  const [showErrors, setShowErrors] = useState(false);
  const [userToRemove, setUserToRemove] = useState<null | UserData>(null);
  const { error: companiesError, loading: companiesLoading, companies } = useGetUserCompanies();

  const { error: userUpdateError, updating, user: updatedUser } = useUpdateUser(userToUpdate);
  const { error: userRemoveError, removing, user: removedUser } = useRemoveUser(userToRemove);

  useEffect(() => {
    if (updatedUser && !updating) {
      props.onUpdate(updatedUser);
      setUserToUpdate(null);
    }
  }, [updatedUser, updating])

  useEffect(() => {
    if (removedUser && !removing) {
      props.onRemove(userToRemove);
      setUserToRemove(null);
    }
  }, [removedUser, removing])

  const handleUpdate = async (user: UserData) => {
    setUserToUpdate(user);
    setShowErrors(true);
  }

  const handleCancel = () => {
    props.onCancel();
  }

  const handleRemove = async () => {
    setUserToRemove(props.user);
    setShowErrors(true);
  }

  const handleCloseErrorDialog = () => {
    setUserToUpdate(null);
    setUserToRemove(null);
    setShowErrors(false);
  }

  return (
    <div>
      <StatusDialog open={companiesLoading} message="Loading companies" />
      <ErrorDialog open={showErrors} error={companiesError} onClose={handleCloseErrorDialog} />
      <StatusDialog open={updating || removing} message={updating ? "Updating user":"removing User"} />
      <ErrorDialog open={showErrors && Boolean(userToUpdate || userToRemove)} error={userUpdateError || userRemoveError} onClose={handleCloseErrorDialog} />
      <EditUser
        user={props.user}
        companies={companies}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
        onCancel={handleCancel} 
      />
    </div>
  )
}
