import React, { useState, useMemo, useEffect, HTMLAttributes, CSSProperties } from 'react';
import { TransactionData } from '../wireData/TransactionData';
import { useStyles } from './styles';
import { Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, Typography, Button, MenuItem, Paper, useTheme, Select, Input, FormControl, InputLabel, Switch, FormControlLabel } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { penniesToDecimalString, decimalStringToPennies } from '../utils/Convert';
import { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import AsyncSelect from 'react-select/async';
import { CustomerData } from '../wireData/CustomerData';
import { ValueContainerProps } from 'react-select';
import { ControlProps } from 'react-select';
import { MenuProps, NoticeProps } from 'react-select';
import { OptionProps } from 'react-select';
import { PlaceholderProps } from 'react-select';
import { SingleValueProps } from 'react-select';
import PropTypes from 'prop-types';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import { UserData } from '../wireData/UserData';
import { CompanyData } from '../wireData/CompanyData';
import { Store } from '../store/Store';
import { RegisterData } from '../wireData/RegisterData';

interface NumberFormatProps {
  name: string;
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string, value: string } }) => void;
}

function NumberFormatDollar(props: NumberFormatProps) {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
            name: name
          },
        });
      }}
      isNumericString
      thousandSeparator
      prefix="$"
      decimalScale={2}
      fixedDecimalScale
      defaultValue="0.00"
    />
  );
}

function NumberFormatPercent(props: NumberFormatProps) {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
            name: name
          },
        });
      }}
      isNumericString
      decimalScale={2}
      fixedDecimalScale
      defaultValue="0.00"
    />
  );
}

function NumberFormatInteger(props: NumberFormatProps) {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
            name: name
          },
        });
      }}
      isNumericString
      decimalScale={0}
    />
  );
}

interface OptionType {
  label: string;
  value: string;
}

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
} as any;

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
} as any;

function Option(props: OptionProps<OptionType>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
} as any;

function Placeholder(props: PlaceholderProps<OptionType>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
} as any;

function Menu(props: MenuProps<OptionType>) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
} as any;

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

export interface EditTransactionProps {
  transaction: TransactionData;
  onCancel: () => void;
  onCompanyLookup: (inputValue: string) => Promise<Array<CompanyData>>;
  onStoreLookup: (inputValue: string) => Promise<Array<Store>>;
  onRegisterLookup: (storeID: number, inputValue: string) => Promise<Array<RegisterData>>;
  onCustomerLookup: (inputValue: string) => Promise<Array<CustomerData>>;
  onClerkLookup: (inputValue: string) => Promise<Array<UserData>>;
  onUpdateTransaction: (transaction: TransactionData) => void;
  onRemoveTransaction?: (transaction: TransactionData) => void;
}

export function EditTransaction(props: EditTransactionProps) {
  const classes = useStyles({});
  const theme = useTheme();

  const [transaction, setTransaction] = useState<TransactionData>(props.transaction)
  const [company, setCompany] = useState<CompanyData>(null);
  const [store, setStore] = useState<Store>(null);
  const [register, setRegister] = useState<RegisterData>(null);
  const [customer, setCustomer] = useState<CustomerData>(null);
  const [clerk, setClerk] = useState<UserData>(null);

  useEffect(() => {
    setTransaction({
      ...props.transaction,
      creditChange: penniesToDecimalString(props.transaction.creditChange),
      purchaseAmount: penniesToDecimalString(props.transaction.purchaseAmount),
    })
  }, [props.transaction])

  const addOrUpdate = useMemo(() => props.transaction.transactionID ? "Update" : "Add", [props.transaction.transactionID]);

  const handleDialogClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    props.onCancel();
  }

  const handleTransactionRemove = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    props.onRemoveTransaction(transaction);
  }

  const handleTransactionUpdate = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    props.onUpdateTransaction({
      ...transaction,
      creditChange: decimalStringToPennies(transaction.creditChange),
      purchaseAmount: decimalStringToPennies(transaction.purchaseAmount),
      date: transaction.date && transaction.date.valueOf(),
    });
  }

  const updateField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTransaction({ ...transaction, [event.target.name]: event.target.value })
  }

  const updateDate = (field: string) => (date: Moment) => {
    setTransaction({ ...transaction, [field]: date })
  }

  const updateBoolean = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setTransaction({ ...transaction, [event.target.name]: checked })
  }

  const checkFieldsAreComplete = () => {
    if (!transaction.associateID || !transaction.locationID || !transaction.registerID || !transaction.customerID || !transaction.companyID)
      return false;
    if (transaction.transactionType == null || transaction.transactionType.trim().length != 4)
      return false;

    return true;
  }

  const getCompanyLabel = (company: CompanyData) => {
    return company.companyName;
  }

  const handleCompanyChange = (company: CompanyData, action: any) => {
    setCompany(company);
    transaction.companyID = company && company.companyID;
    transaction.companyName = company && company.companyName;
  };

  const companyPlaceholder = (): string => {
    return transaction.companyName || "Select..."
  }

  const getStoreLabel = (store: Store) => {
    return store.storeName;
  }

  const handleStoreChange = (store: Store, action: any) => {
    if (!store || transaction.locationID != store.storeID) {
      transaction.registerID = null;
      transaction.registerName = null;
      setRegister(null);
    }
    setStore(store);
    transaction.locationID = store && store.storeID;
    transaction.storeName = store && store.storeName;
  };

  const storePlaceholder = (): string => {
    return transaction.storeName || "Select..."
  }

  const getRegisterLabel = (register: RegisterData) => {
    return register.registerName;
  }

  const handleRegisterChange = (register: RegisterData, action: any) => {
    setRegister(register);
    transaction.registerID = register && register.registerID;
    transaction.registerName = register && register.registerName;
  };

  const registerPlaceholder = (): string => {
    return transaction.registerName || "Select..."
  }

  const handleRegisterLookup = (inputValue: string): Promise<Array<RegisterData>> => {
    if (transaction.locationID)
      return props.onRegisterLookup(transaction.locationID, inputValue);
    return Promise.resolve([]);
  }

  const getCustomerLabel = (customer: CustomerData) => {
    return customer.firstName + " " + customer.lastName;
  }

  const handleCustomerChange = (customer: CustomerData, action: any) => {
    setCustomer(customer);
    transaction.customerID = customer && customer.customerID;
    transaction.customerFirstName = customer && customer.firstName;
    transaction.customerLastName = customer && customer.lastName;
  };

  const customerPlaceholder = (): string => {
    if (transaction.customerFirstName || transaction.customerLastName)
      return transaction.customerFirstName + " " + transaction.customerLastName
    return "Select..."
  }

  const getClerkLabel = (clerk: UserData) => {
    return clerk.userName;
  }

  const handleClerkChange = (clerk: UserData, action: any) => {
    setClerk(clerk);
    transaction.associateID = clerk && clerk.userID;
    transaction.userName = clerk && clerk.userName;
  };

  const clerkPlaceholder = (): string => {
    return transaction.userName || "Select...";
  }

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <Dialog open onBackdropClick={handleDialogClose} maxWidth='sm' fullWidth>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6" align="center">Edit Transaction</Typography>
        <Typography variant="body2" align="center">{addOrUpdate} transaction</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContents}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container direction="row">
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <AsyncSelect
                classes={classes}
                styles={selectStyles}
                inputId="select-company"
                TextFieldProps={{
                  label: "Company",
                  InputLabelProps: {
                    htmlFor: "select-company",
                    shrink: true,
                  },
                }}
                fullWidth
                isClearable
                defaultOptions
                maxMenuHeight="20vh"
                placeholder={companyPlaceholder()}
                components={components}
                loadOptions={props.onCompanyLookup}
                getOptionLabel={getCompanyLabel}
                onChange={handleCompanyChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <DatePicker
                label="Date"
                value={transaction.date}
                onChange={updateDate("date")}
                autoOk
                fullWidth
                format="MM/DD/YYYY" />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <AsyncSelect
                classes={classes}
                styles={selectStyles}
                inputId="select-store"
                TextFieldProps={{
                  label: "Store",
                  InputLabelProps: {
                    htmlFor: "select-store",
                    shrink: true,
                  },
                }}
                fullWidth
                isClearable
                defaultOptions
                maxMenuHeight="20vh"
                placeholder={storePlaceholder()}
                components={components}
                loadOptions={props.onStoreLookup}
                getOptionLabel={getStoreLabel}
                onChange={handleStoreChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <AsyncSelect
                key={transaction.locationID}
                classes={classes}
                styles={selectStyles}
                inputId="select-register"
                TextFieldProps={{
                  label: "Register",
                  InputLabelProps: {
                    htmlFor: "select-register",
                    shrink: true,
                  },
                }}
                fullWidth
                isClearable
                maxMenuHeight="20vh"
                defaultOptions
                placeholder={registerPlaceholder()}
                components={components}
                loadOptions={handleRegisterLookup}
                getOptionLabel={getRegisterLabel}
                onChange={handleRegisterChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <AsyncSelect
                classes={classes}
                styles={selectStyles}
                inputId="select-customer"
                TextFieldProps={{
                  label: "Customer name",
                  InputLabelProps: {
                    htmlFor: "select-customer",
                    shrink: true,
                  },
                }}
                fullWidth
                isClearable
                defaultOptions
                maxMenuHeight="20vh"
                placeholder={customerPlaceholder()}
                components={components}
                loadOptions={props.onCustomerLookup}
                getOptionLabel={getCustomerLabel}
                onChange={handleCustomerChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <AsyncSelect
                classes={classes}
                styles={selectStyles}
                inputId="select-clerk"
                TextFieldProps={{
                  label: "Clerk",
                  InputLabelProps: {
                    htmlFor: "select-clerk",
                    shrink: true,
                  },
                }}
                fullWidth
                isClearable
                defaultOptions
                maxMenuHeight="20vh"
                menuPlacement="top"
                placeholder={clerkPlaceholder()}
                components={components}
                loadOptions={props.onClerkLookup}
                getOptionLabel={getClerkLabel}
                onChange={handleClerkChange}
              />
            </Grid>
            <Grid item xs={6} sm={3} className={classes.gridCell}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-transactionType">Transaction type</InputLabel>
                <Select
                  value={transaction.transactionType}
                  fullWidth
                  name="transactionType"
                  onChange={updateField}
                  input={<Input id="select-transactionType" />}
                >
                  <MenuItem value="SALE">Sale</MenuItem>
                  <MenuItem value="DEEM">Redeem</MenuItem>
                  <MenuItem value="CHLD">Child</MenuItem>
                  <MenuItem value="RVRT">Revert</MenuItem>
                  <MenuItem value="CHRV">Revert Child</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.gridCell}>
              <TextField
                label="Origin Transaction"
                value={transaction.originTransaction}
                fullWidth
                name="originTransaction"
                onChange={updateField}
                InputProps={{ inputComponent: NumberFormatInteger, name: "originTransaction" }}
              />
            </Grid>
            <Grid item xs={6} sm={3} className={classes.gridCell}>
              <TextField
                label="Purchase amount"
                value={transaction.purchaseAmount}
                fullWidth
                name="purchaseAmount"
                onChange={updateField}
                InputProps={{ inputComponent: NumberFormatDollar, name: "purchaseAmount" }}
              />
            </Grid>
            <Grid item xs={6} sm={3} className={classes.gridCell}>
              <TextField
                label="Credit change"
                value={transaction.creditChange}
                fullWidth
                name="creditChange"
                onChange={updateField}
                InputProps={{ inputComponent: NumberFormatDollar, name: "creditChange" }}
              />
            </Grid>
            <Grid item xs={6} sm={4} className={classes.gridCell}>
              <TextField
                label="Credit %"
                value={transaction.creditPercent}
                fullWidth
                name="creditPercent"
                onChange={updateField}
                InputProps={{ inputComponent: NumberFormatPercent, name: "creditPercent" }}
              />
            </Grid>
            <Grid item xs={6} sm={4} className={classes.gridCell}>
              <TextField
                label="Parent %"
                value={transaction.parentPercent}
                fullWidth
                name="parentPercent"
                onChange={updateField}
                InputProps={{ inputComponent: NumberFormatPercent, name: "parentPercent" }}
              />
            </Grid>
            <Grid item xs={6} sm={4} className={classes.gridCell}>
              <TextField
                label="Level /"
                value={transaction.levelDivision}
                fullWidth
                name="levelDivision"
                onChange={updateField}
                InputProps={{ inputComponent: NumberFormatPercent, name: "levelDivision" }}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridCell}>
              <FormControlLabel
                label="Reverted"
                control={<Switch
                  checked={Boolean(transaction.reverted)}
                  name="reverted"
                  onChange={updateBoolean}
                />}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container direction="row">
          <Grid item xs={12} sm={4} className={classes.gridCell}>
            <Button fullWidth variant="contained" onClick={handleDialogClose}>Cancel</Button>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridCell}>
            <Button disabled={!props.onRemoveTransaction || !transaction.transactionID} fullWidth variant="contained" onClick={handleTransactionRemove}>Remove</Button>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridCell}>
            <Button disabled={!checkFieldsAreComplete()} fullWidth variant="contained" onClick={handleTransactionUpdate}>{addOrUpdate}</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}