import { useState, useEffect } from 'react';
import {
  getJSONHeaders,
  purchaseOrderItemUpdateStatusURL,
  purchaseOrderItemDowngradeStatusURL,
  purchaseOrderItemUpdateReceivedURL,
  purchaseOrderUpdateAdjustmentsURL,
  purchaseOrderUpdateDiscountURL,
  purchaseOrderUpdateShippingURL,
  purchaseOrderWorkbookURL,
  purchaseOrderHeadersURL,
  downloadPurchaseOrderReceivingSheetURL,
  purchaseOrderUpdateStatusURL,
  purchaseOrderDowngradeStatusURL,
  purchaseOrderUpdateItemCostURL,
  purchaseOrderUpdateInvoiceNumberURL,
  buildQueryFromFilters,
  getPurchaseOrderCountURL
} from '../utils/WireUtils';
import PurchaseOrderManager from './PurchaseOrderManager';
import { OrderLineItemReceivingUpdate, OrderHeader, OrderReconcilingUpdate, ItemCostUpdate } from './PurchaseOrderData';
import FileSaver from 'file-saver';
import { BaseState, BaseAction, StatusFields } from '../common/BaseHandler';

export enum PurchaseOrderStatus {
  PENDING,
  OPEN,
  CLOSED
}

const prefix = [
  "pending",
  "open",
  "closed"
];

const PurchaseOrderStoreName = (status: PurchaseOrderStatus) => 'purchaseOrderStore' + status.toString();

export class PurchaseOrderState extends BaseState {
  countError: any;
  countLoading: boolean;
  countSuccess: boolean;
  updateError: any;
  updating: boolean;
  updateSuccess: boolean;
  updatedItem: OrderLineItemReceivingUpdate | OrderReconcilingUpdate;
  orderCount: number;
  pageSize: number;
  currentOffset: number;
  pagingFilters: {};
  expandedItems: Array<{ id: number, expandedLines: Array<number> }>;
  scrolling: { top: number };
  _manager: PurchaseOrderManager;
}

class PurchaseOrderActions extends BaseAction<PurchaseOrderState>{
  private _status: PurchaseOrderStatus;

  constructor(status: PurchaseOrderStatus) {
    super();
    this._status = status;
  }

  get initialState(): PurchaseOrderState {
    return {
      error: null,
      loading: false,
      success: false,
      countError: null,
      countLoading: false,
      countSuccess: false,
      updateError: null,
      updating: false,
      updateSuccess: false,
      updatedItem: null,
      orderCount: 0,
      pageSize: 15,
      currentOffset: 0,
      pagingFilters: null,
      expandedItems: [],
      scrolling: { top: 0 },
      _manager: null,
      _actions: this,
    }
  }

  private mapOrders = (body: any): Partial<PurchaseOrderState> => {
    const manager: PurchaseOrderManager = new PurchaseOrderManager();
    manager.book = body;
    return {
      error: null,
      loading: false,
      success: true,
      expandedItems: [],
      scrolling: { top: 0 },
      _manager: manager,
    };
  }

  getOrders = async () => {
    return this.getData(purchaseOrderWorkbookURL.replace("{status}", prefix[this._status]) +
      buildQueryFromFilters(this.state.pagingFilters), this.mapOrders);
  }

  private mapOrderCount = (body: any): Partial<PurchaseOrderState> => {
    return body;
  }

  getOrderCount() {
    this.updateState({ orderCount: 0 });
    this.getData(getPurchaseOrderCountURL.replace("{status}", prefix[this._status]) +
    buildQueryFromFilters(this.state.pagingFilters), this.mapOrderCount,
      { error: "countError", processing: "countLoading", success: "countSuccess" });
  }

  updateStatusFields: StatusFields<PurchaseOrderState> = {
    error: 'updateError',
    processing: 'updating',
    success: 'updateSuccess'
  };

  private mapUpdate(body: any): Partial<PurchaseOrderState> {
    return { updatedItem: body }
  }

  updatePurchaseOrderItemStatus = async (pending: OrderLineItemReceivingUpdate) => {
    return this.postData(purchaseOrderItemUpdateStatusURL, pending, this.mapUpdate);//, this.updateStatusFields);
  }

}

export const resetOpenPurchaseOrderStore = () => {
  BaseAction.getAction(PurchaseOrderStoreName(PurchaseOrderStatus.OPEN), PurchaseOrderActions, PurchaseOrderStatus.OPEN).reset();
}

export const resetPendingPurchaseOrderStore = () => {
  BaseAction.getAction(PurchaseOrderStoreName(PurchaseOrderStatus.PENDING), PurchaseOrderActions, PurchaseOrderStatus.PENDING).reset();
}

export const resetClosedPurchaseOrderStore = () => {
  BaseAction.getAction(PurchaseOrderStoreName(PurchaseOrderStatus.CLOSED), PurchaseOrderActions, PurchaseOrderStatus.CLOSED).reset();
}

export const useOpenPurchaseOrderWorkbook = (): [PurchaseOrderState, PurchaseOrderManager, PurchaseOrderActions] => {
  const [state, actions] = BaseAction.getAction(PurchaseOrderStoreName(PurchaseOrderStatus.OPEN), PurchaseOrderActions, PurchaseOrderStatus.OPEN).useStore();
  return [state, state._manager, actions];
}

export const usePendingPurchaseOrderWorkbook = (): [PurchaseOrderState, PurchaseOrderManager, PurchaseOrderActions] => {
  const [state, actions] = BaseAction.getAction(PurchaseOrderStoreName(PurchaseOrderStatus.PENDING), PurchaseOrderActions, PurchaseOrderStatus.PENDING).useStore();
  return [state, state._manager, actions];
}

export const useClosedPurchaseOrderWorkbook = (): [PurchaseOrderState, PurchaseOrderManager, PurchaseOrderActions] => {
  const [state, actions] = BaseAction.getAction(PurchaseOrderStoreName(PurchaseOrderStatus.CLOSED), PurchaseOrderActions, PurchaseOrderStatus.CLOSED).useStore();
  return [state, state._manager, actions];
}

export const updatePurchaseOrderItemStatus = (pending: OrderLineItemReceivingUpdate) => updatePurchaseOrderItem(pending, purchaseOrderItemUpdateStatusURL)
export const updatePurchaseOrderItemReceived = (pending: OrderLineItemReceivingUpdate) => updatePurchaseOrderItem(pending, purchaseOrderItemUpdateReceivedURL)
export const downgradePurchaseOrderItemStatus = (pending: OrderLineItemReceivingUpdate) => updatePurchaseOrderItem(pending, purchaseOrderItemDowngradeStatusURL)

const updatePurchaseOrderItem = (pending: OrderLineItemReceivingUpdate, url: string): Promise<any> => {
  return fetch(url, {
    method: "POST",
    headers: getJSONHeaders(),
    body: JSON.stringify(pending)
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
          .then((update: OrderLineItemReceivingUpdate) => {
            return update;
          })
          .catch((e) => {
            return Promise.reject({ message: "Invalid response body" })
          })
      } else {
        return Promise.reject({ message: "Response not ok" })
      }
    })
    .catch((e) => {
      return Promise.reject({ message: "Communication failure" })
    })
}

export const updatePurchaseOrderStatus = (pending: OrderReconcilingUpdate) => updatePurchaseOrder(pending, purchaseOrderUpdateStatusURL)
export const downgradePurchaseOrderStatus = (pending: OrderReconcilingUpdate) => updatePurchaseOrder(pending, purchaseOrderDowngradeStatusURL)
export const updatePurchaseOrderShipping = (pending: OrderReconcilingUpdate) => updatePurchaseOrder(pending, purchaseOrderUpdateShippingURL)
export const updatePurchaseOrderAdjustments = (pending: OrderReconcilingUpdate) => updatePurchaseOrder(pending, purchaseOrderUpdateAdjustmentsURL)
export const updatePurchaseOrderDiscount = (pending: OrderReconcilingUpdate) => updatePurchaseOrder(pending, purchaseOrderUpdateDiscountURL)

const updatePurchaseOrder = (pending: OrderReconcilingUpdate, url: string): Promise<any> => {
  return fetch(url, {
    method: "POST",
    headers: getJSONHeaders(),
    body: JSON.stringify(pending)
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
          .then((update: OrderReconcilingUpdate) => {
            return update;
          })
          .catch((e) => {
            return Promise.reject({ message: "Invalid response body" })
          });
      } else {
        return Promise.reject({ message: "Response not ok" })
      }
    })
    .catch((e) => {
      return Promise.reject({ message: "Communication failure" })
    });
}

export const updateItemCost = (pending: ItemCostUpdate): Promise<any> => {
  return fetch(purchaseOrderUpdateItemCostURL, {
    method: "POST",
    headers: getJSONHeaders(),
    body: JSON.stringify(pending)
  }).then((response) => {
    if (response.ok) {
      return response.json().then((update: ItemCostUpdate) => {
        return update;
      })
        .catch((e) => {
          return Promise.reject({ message: "Invalid response body" })
        });
    } else {
      return Promise.reject({ message: "Response not ok" })
    }
  })
    .catch((e) => {
      return Promise.reject({ message: "Communication failure" })
    });
}

export const updateInvoiceNumber = (orderID: number, invoiceNumber: string): Promise<string> => {
  return fetch(purchaseOrderUpdateInvoiceNumberURL, {
    method: "POST",
    headers: getJSONHeaders(),
    body: JSON.stringify({ orderID: orderID, invoiceNumber: invoiceNumber })
  }).then((response) => {
    if (response.ok) {
      return response.json().then((invoiceNumber: string) => {
        return invoiceNumber;
      })
        .catch((e) => {
          return Promise.reject({ message: "Invalid response body" })
        });
    } else {
      return Promise.reject({ message: "Response not ok" })
    }
  })
    .catch((e) => {
      return Promise.reject({ message: "Communication failure" })
    });
}

export const useOrderHeaders = (status: PurchaseOrderStatus): {
  error: any,
  loading: boolean,
  success: boolean,
  orderHeaders: Array<OrderHeader>
} => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [orderHeaders, setOrderHeaders] = useState<Array<OrderHeader>>([]);

  useEffect(() => {
    console.log("Loading Order Headers");
    setLoading(true);
    fetch(purchaseOrderHeadersURL.replace("{status}", prefix[status]), {
      method: 'GET',
      headers: getJSONHeaders()
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          response.json()
            .then((orderHeaders) => {
              setOrderHeaders(orderHeaders);
              setSuccess(true);
            })
            .catch((e) => {
              setError(e);
            });
        } else {
          setError(new Error(response.statusText));
        }
      })
      .catch((e) => {
        setError(e);
      });
  }, [status]);

  return { error, loading, success, orderHeaders };
}

export const downloadPurchaseOrderReceivingSheet = async (): Promise<any> => {
  return fetch(downloadPurchaseOrderReceivingSheetURL, {
    method: "GET",
    headers: getJSONHeaders()
  })
    .then((response) => response.blob())
    .then((blob) => FileSaver.saveAs(blob, 'receivingsheet.csv'))
}
