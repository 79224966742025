import { createMuiTheme } from '@material-ui/core/styles';
import { blueGrey, lime, red } from '@material-ui/core/colors';

export const getTheme = () => 
   createMuiTheme({
    palette: {
      primary: blueGrey,
      secondary: lime,
      error: red,
      type: 'dark'
    },
    typography: {
//      useNextVariants: true,
    },
    
  })

  // mixins: {
  //   toolbar: {
  //     minHeight: 64,
  //     '@media (min-width: 0px and (orientation: landscape)': {
  //       minHeight: 64
  //     },
  //     '@media (min-width: 600px)': {
  //       minHeight: 64
  //     },
  //   },
  // },