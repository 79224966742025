import React, { useState, useEffect } from 'react';
import { TableCell, Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, Grid, Paper, LinearProgress } from "@material-ui/core";
import { useStyles } from './styles';
import { NumPad } from '../common/NumPad';
import { OrderingWorkbookManager } from './PreOrderingWorkbookManager';
import { StoreOrderingCount, PendingOrderItem } from './StoreOrderingData';
import { InventoryTransferUpdate } from '../wireData/InventoryTransferData';
import { addOrUpdateInventoryTransfer } from '../inventoryTransfer/InventoryTransferHandler';
import Dreaggable from 'react-draggable';
import { useGetCount } from '../inventory/InventoryHandler';
import { getLastSundayISODateString } from '../utils/Convert';
import { stockroomID } from '../wireData/StockroomData';
import { usePreOrderingWorkbook } from './PreOrderingHandler';

// const Manager = OrderingWorkbookManager.Instance;

export class TransferCellProps {
  item: StoreOrderingCount | PendingOrderItem;
  onUpdated: (transfer: number) => void;
  manager: OrderingWorkbookManager;
}

export const TransferCell = (props: TransferCellProps) => {
  const classes = useStyles({});
  const [transferCount, setTransferCount] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if ((props.item as StoreOrderingCount).transfer)
      setTransferCount((props.item as StoreOrderingCount).transfer);
    else
      setTransferCount((props.item as PendingOrderItem).stockCount);
  }, [props.item])

  const handleClick = () => {
    setShowDialog(true);
  }

  const handleClose = () => {
    setShowDialog(false);
    props.onUpdated(transferCount);
  }

  const handleSetTransferCount = async (count: number) => {
    setTransferCount(count);
  }

  return (
    <TableCell className={`${classes.tableCell} ${classes.pointerCell} ${classes.selectableCell}`} onClick={handleClick}>
      {showDialog
        ?
        <TransferDialog
          templateItem={props.item}
          manager={props.manager}
          setTemplateCount={handleSetTransferCount}
          onClose={handleClose}
        />
        : null
      }
      {transferCount}
    </TableCell>
  )
}

class StockroomCountProps {
  templateItem: (StoreOrderingCount | PendingOrderItem);
}

const StockroomCount = (props: StockroomCountProps) => {
  const classes = useStyles({});

  const { error, loading, success, count } =
    useGetCount({
      storeID: stockroomID,
      categoryID: props.templateItem.categoryID,
      itemID: props.templateItem.itemID,
      variationID: props.templateItem.variationID,
      date: getLastSundayISODateString(),
      count: 0,
      cost: 0
    });
  if (error)
    return (
      <Typography align="right" noWrap className={classes.listFooter} variant="body1">
        Error
      </Typography>
    )
  if (loading)
    return <div className={classes.transferProgress}><LinearProgress /></div>;
  if (success)
    return (
      <Typography align="right" noWrap className={classes.listFooter} variant="body1">
        {count}
      </Typography>
    )
  return null;
}

export class TransferDialogProps {
  //  open: boolean;
  templateItem: (StoreOrderingCount | PendingOrderItem);
  manager: OrderingWorkbookManager;
  setTemplateCount: (count: number) => void;
  onClose: () => void;
}

export function TransferDialog(props: TransferDialogProps) {

  class TransferCount {
    storeID: number;
    storeName: string;
    count: StoreOrderingCount;
  }

  const classes = useStyles({});
  // const [{}, manager] = usePreOrderingWorkbook();

  const Contents = () => {
    const [value, setValue] = useState(0);
    const [plusMinus, setPlusMinus] = useState(1);
    const [transfers, setTransfers] = useState<Array<TransferCount>>([]);
    const [selectedTransfer, setSelectedTransfer] = useState<TransferCount>(null);
    const [templatedValue, setTemplateValue] = useState(0);
    const [update, setUpdate] = useState(false);
    const [clearBeforeEntry, setClearBeforeEntry] = useState(true);

    const updateValue = (value: number) => {
      if (value) {
        setPlusMinus(value < 0 ? -1 : 1);
        setValue(Math.abs(value));
      }
      else {
        setValue(0);
        setPlusMinus(1);
      }
      setClearBeforeEntry(true);
    }

    useEffect(() => {
      const storeCounts: Array<TransferCount> = [];
      props.manager.getStores().forEach((store) => {
        const transfer = {
          storeID: store.storeID,
          storeName: store.storeName,
          count: props.manager.retrieveCountForStore(store.storeID, props.templateItem)
        }
        storeCounts.push(transfer);
        if (store.storeID == props.manager.currentStore.storeID) {
          setSelectedTransfer(transfer);
          updateValue(transfer.count.transfer);
          setTemplateValue(transfer.count.transfer);
        }
      })
      setTransfers(storeCounts);
    }, [props.templateItem])

    const handleCloseClick = (event: React.MouseEvent) => {
      event && event.stopPropagation();
      props.setTemplateCount(templatedValue);
      props.onClose();
    }

    const updateSelectedTransfer = async () => {
      if (selectedTransfer &&
        (selectedTransfer.count.transfer != null || value != 0) &&
        selectedTransfer.count.transfer != value * plusMinus) {
        const inventoryTransfer = new InventoryTransferUpdate();
        inventoryTransfer.categoryID = selectedTransfer.count.categoryID;
        inventoryTransfer.itemID = selectedTransfer.count.itemID;
        inventoryTransfer.variationID = selectedTransfer.count.variationID;
        inventoryTransfer.storeID = selectedTransfer.storeID;
        inventoryTransfer.quantityRequested = (value * plusMinus);
        inventoryTransfer.quantityShipped = 0;
        inventoryTransfer.itemCost = 0;
        inventoryTransfer.status = "pending";

        try {
          await addOrUpdateInventoryTransfer(inventoryTransfer);
          selectedTransfer.count.transfer = (value * plusMinus);
          if (selectedTransfer.count == props.templateItem)
            setTemplateValue(value * plusMinus);
        }
        catch (e) {
          alert(e.message);
        }

        setUpdate(!update);
      }
    }

    const handleUpdateClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      updateSelectedTransfer();
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
      var startingValue = value;
      if (clearBeforeEntry) {
        startingValue = 0;
        setPlusMinus(1);
        setClearBeforeEntry(false);
      }
      switch (event.key) {
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
          if (startingValue < 1000)
            setValue(startingValue * 10 + Number(event.key));
          break;
        case "-":
          setPlusMinus(-plusMinus);
          break;
        case "Backspace":
          setValue(Math.trunc(value / 10));
          break;
        case "Enter":
          if (selectedTransfer)
            selectedTransfer.count.transfer = (value * plusMinus)
          break;
        case "Escape":
          handleCloseClick(null);
          break;
      }
      event.preventDefault();
    }
    const handleNumClick = (inputValue: number) => {
      var startingValue = value;
      if (clearBeforeEntry) {
        startingValue = 0;
        setPlusMinus(1);
        setClearBeforeEntry(false);
      }
      if (startingValue < 1000)
        setValue(startingValue * 10 + Number(inputValue));
    }

    const handleClearClick = () => {
      setPlusMinus(-plusMinus);
    }

    const handleBackClick = () => {
      setValue(Math.trunc(value / 10));
    }

    const handleTransferSelect = (transfer: TransferCount) => (event) => {
      updateSelectedTransfer();
      setSelectedTransfer(transfer);
      updateValue(transfer.count.transfer);
    }

    const availableStock = (transfer: TransferCount) => {
      if (transfer == null)
        return "--";
      const weights = [0.5, 0.75, 1.25, 1.5];
      const available = transfer.count.lastCount + transfer.count.onOrder;
      const sales = transfer.count.sales.reduce((subtotal, sale, index) => (subtotal + sale * weights[index]), 0);
      return Math.trunc(available - sales);
    }

    const transferTotal = () => {
      if (transfers && transfers.length)
        return transfers.map(transfer => transfer.count.transfer).reduce((sum, transfer) => sum + transfer);
      return 0;
    }

    return (
      <>
        <DialogContent
          onKeyDown={handleKeyDown}
        >
          <Grid container direction="row" >
            <Grid item xs={12} sm={5}>
              <Grid container direction="row" justify="center">
                <Grid container direction="row" justify="center">
                  <Grid className={classes.gridCell} item xs={6} >
                    <Typography align="center" className={classes.listHeader} variant="h6">Transfer:</Typography>
                  </Grid>
                  <Grid className={classes.gridCell} item xs={3} >
                    <Typography align="center" className={classes.listHeader} variant="h6">{value * plusMinus}</Typography>
                  </Grid>
                </Grid>
                <Grid className={classes.gridCell} item xs={12}>
                  <NumPad
                    onNumClick={handleNumClick}
                    onBackClick={handleBackClick}
                    onClearClick={handleClearClick}
                    onKeyDown={handleKeyDown}
                    rootClassname={classes.numRoot}
                    plusMinus={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid container direction="row" >
                <Grid key="titles" container direction="row" justify="center">
                  <Grid item xs={6} className={classes.gridListCell}>
                    <Typography noWrap className={classes.listHeader} gutterBottom variant="h6">Store</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.gridListCell}>
                    <Typography align="right" className={classes.listHeader} gutterBottom variant="h6">Avail.</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.gridListCell}>
                    <Typography align="right" className={classes.listHeader} gutterBottom variant="h6">Xfer</Typography>
                  </Grid>
                </Grid>
                {transfers.map((transfer) => (
                  <Grid
                    key={transfer.storeID}
                    className={selectedTransfer == transfer ? classes.selectedTransfer : null}
                    container
                    direction="row"
                    justify="flex-start"
                    onClick={handleTransferSelect(transfer)}
                  >
                    <Grid item xs={6} className={classes.gridListCell}>
                      <Typography noWrap className={classes.listItem} variant="body1">{transfer.storeName}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.gridListCell}>
                      <Typography align="right" noWrap className={classes.listItem} variant="body1">
                        {availableStock(transfer)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.gridListCell}>
                      <Typography align="right" noWrap className={classes.listItem} variant="body1">
                        {transfer.count.transfer}
                      </Typography>
                    </Grid>
                  </Grid>
                )
                )}
                <Grid
                  key="Stockroom"
                  container
                  direction="row"
                  justify="flex-start"
                  className={classes.borderTop}
                >
                  <Grid item xs={6} className={classes.gridListCell}>
                    <Typography noWrap className={classes.listFooter} variant="body1">Stockroom/Total</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.gridListCell}>
                    <StockroomCount templateItem={props.templateItem} />
                  </Grid>
                  <Grid item xs={3} className={classes.gridListCell}>
                    <Typography align="right" noWrap className={classes.listFooter} variant="body1">
                      {transferTotal()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateClick} color="secondary">
            update
          </Button>
          <Button onClick={handleCloseClick} color="secondary">
            close
          </Button>
        </DialogActions>
      </>
    )
  }

  const DraggablePaper = (props) => {
    return (
      <Dreaggable>
        <Paper {...props} />
      </Dreaggable>
    )
  }

  return (
    props.templateItem
      ?
      <Dialog
        onClick={(e) => e.stopPropagation()}
        disableBackdropClick
        disableEscapeKeyDown
        open={true}
        PaperComponent={DraggablePaper}
      >
        <DialogTitle disableTypography>
          <Grid container>
            <Grid className={classes.gridCell} item xs={12}>
              <Typography variant="h6">Transfer Requests</Typography>
            </Grid>
            <Grid className={classes.gridCell} item xs={12}>
              <Typography variant="h6">{props.templateItem.category}</Typography>
            </Grid>
            <Grid className={classes.gridCell} item xs={12}>
              <Typography variant="h6">{props.templateItem.item}</Typography>
            </Grid>
            {props.templateItem.variation
              ?
              <Grid className={classes.gridCell} item xs={12}>
                <Typography variant="h6">{props.templateItem.variation}</Typography>
              </Grid>
              : null
            }
          </Grid>
        </DialogTitle>
        <Contents />

      </Dialog>
      : null
  )
}