export const stockroomID = -1;

export class StockroomItem {
  stockID: number;
  categoryID: number;
  categoryName: string;
  itemID: number;
  itemName: string;
  variationID: number;
  variationDescription: string;
  quantityShipped: number; //changable 
  quantityReceived: number; //changable 
  itemCost: number;
  shippedDate: number;
  receivedDate: number;
  status: string;
}

export class StockroomUpdate {
  stockID: number;
  categoryID: number;
  categoryName: string;
  itemID: number;
  itemName: string;
  variationID: number;
  variationDescription: string;
  quantityShipped: number; //+ or -
  quantityReceived: number; //+ or -
  itemCost: number;
  status: string;
}

