import React, { useState } from 'react';
import { FlatCatalogComponent } from './FlatCatalogComponent';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel, FormGroup, Switch, Theme, Grid } from '@material-ui/core';
import { StructuredCatalogExpander } from './CatalogExpander';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    overflowY: 'hidden',
    overflowX: 'auto',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  controls: {
    maxHeight: 54,
    flexBasis: 'auto',
    flexGrow: 0,
  },
  content: {
    flex: 1,
    overflowY: 'hidden',
  }
}))

export const CatalogContainer = (props) => {
  const [source, setSource] = useState("Active");
  const [display, setDisplay] = useState("Expander");

  const classes = useStyles({});

  const changeSource = () => {
    setSource(source == "Active" ? "All" : "Active");
  }

  const changeDisplay = () => {
    setDisplay(display == "Table" ? "Expander" : "Table");
  }

  if (!props.hidden)
    return (
      <div className={classes.root} >
        <div className={classes.controls}>
          <Grid container justify="center">
            <Grid item>
              <FormGroup row >
                <Typography variant="h4" component="h2">Products</Typography>
                <FormControlLabel label={"Read from: " + source} labelPlacement="start" control=
                  {
                    <Switch checked={source == "Active"} onChange={(e) => changeSource()} value={source} />
                  }
                />
                <FormControlLabel label={"Show as: " + display} labelPlacement="start" control=
                  {
                    <Switch checked={display == "Table"} onChange={(e) => changeDisplay()} value={display} />
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </div>
        <div className={classes.content}>
          {
            display == "Table"
              ?
              <FlatCatalogComponent activeOnly={source == "Active"} />
              :
              <StructuredCatalogExpander active={source == "Active"} />
          }
        </div>
      </div>
    )
  else
    return null;
}
