import { StockroomDataManager } from "./StockroomDataManager";
import { loginToken, transferStoreInventoryURL, receiveStoreInventoryUpdateCountURL, receiveStoreInventoryUpdateStatusURL, receiveStoreInventoryDowngradeStatusURL, sendStoreInventoryUpdateCountURL, getJSONHeaders } from "../utils/WireUtils";
import { Store } from "../store/Store";
import { InventoryTransferQuantityAndStatusUpdate, InventoryTransferItem } from "../wireData/InventoryTransferData";
import { BaseState, BaseAction, StatusFields } from "../common/BaseHandler";

export const StockroomTransferReceive = 'receive';
export const StockroomTransferSend = 'send';

const StockroomTransferSendStoreName = 'StockroomTransferSendStore';
const StockroomTransferReceiveStoreName = 'StockroomTransferReceiveStore';

export class StockroomTransferState extends BaseState {
  transferToUpdate: InventoryTransferItem;
  updateError: any;
  updating: boolean;
  updateSuccess: boolean;
  updatedItem: InventoryTransferQuantityAndStatusUpdate;
  store: Store;
  scrolling: { top: number };
  _manager: StockroomDataManager;
}

const statusFields: StatusFields<StockroomTransferState> = {
  error: "updateError",
  processing: "updating",
  success: "updateSuccess"
};

class StockroomTransferActions extends BaseAction<StockroomTransferState> {
  private _direction: string;
  constructor(direction: string) {
    super();
    this._direction = direction;
  }

  get initialState(): StockroomTransferState {
    return {
      error: null,
      loading: false,
      success: false,
      transferToUpdate: null,
      updateError: null,
      updating: false,
      updateSuccess: false,
      updatedItem: null,
      store: null,
      scrolling: { top: 0 },
      _manager:null,
      _actions: this,
    }
  }

  private mapTransfers = (body: any): Partial<StockroomTransferState> => {
    const manager = new StockroomDataManager();
    manager.StockroomData = body;
    return {
      error: null,
      loading: false,
      success: true,
      scrolling: { top: 0 },
      _manager: manager,
    };
  }

  getTransfers = async (store: Store) => {
    this.state.store = store;
    console.log("getData");
    return this.getData(`${transferStoreInventoryURL.replace('{direction}', this._direction)}/${store.storeID}`, this.mapTransfers)
  }

  private mapUpdate = (body: any): Partial<StockroomTransferState> => {
    return { updatedItem: body as InventoryTransferQuantityAndStatusUpdate }
  }

  updateStockroomReceivedCount = async (update: InventoryTransferQuantityAndStatusUpdate) => {
    return this.postData(receiveStoreInventoryUpdateCountURL, update, this.mapUpdate, statusFields);
  }

  updateStockroomShippedCount = async (update: InventoryTransferQuantityAndStatusUpdate) => {
    return this.postData(sendStoreInventoryUpdateCountURL, update, this.mapUpdate, statusFields);
  }

  updateStockroomStatus = async (update: InventoryTransferQuantityAndStatusUpdate) => {
    return this.postData(receiveStoreInventoryUpdateStatusURL, update, this.mapUpdate, statusFields);
  }

  downgradeStockroomStatus = async (update: InventoryTransferQuantityAndStatusUpdate) => {
    return this.postData(receiveStoreInventoryDowngradeStatusURL, update, this.mapUpdate, statusFields);
  }

  setStore = (store:Store) =>{
    this.updateState({store:store});
  }

  setTransferToUpdate = (transfer: InventoryTransferItem) => {
    this.updateState({ transferToUpdate: transfer })
  }

  clearUpdate = () => {
    this.updateState({ transferToUpdate: null, updatedItem: null, updateError: null, updating: false, updateSuccess: false });
  }
}

export const resetReceiveFromStoresStore = () =>{
  BaseAction.getAction(StockroomTransferReceiveStoreName, StockroomTransferActions, StockroomTransferReceive).reset();
}

export const useReceiveFromStores = (): [StockroomTransferState, StockroomDataManager, StockroomTransferActions] => {
  const [state, actions] = BaseAction.getAction(StockroomTransferReceiveStoreName, StockroomTransferActions, StockroomTransferReceive).useStore();
  return [state, state._manager, actions];
}

export const resetShipToStoresStore = () =>{
  BaseAction.getAction(StockroomTransferSendStoreName, StockroomTransferActions, StockroomTransferSend).reset();
}

export const useShipToStores = (): [StockroomTransferState, StockroomDataManager, StockroomTransferActions] => {
  const [state, actions] = BaseAction.getAction(StockroomTransferSendStoreName, StockroomTransferActions, StockroomTransferSend).useStore();
  return [state, state._manager, actions];
}

// export const updateStockroomReceivedCount = (stockroomUpdate: InventoryTransferQuantityAndStatusUpdate): Promise<InventoryTransferQuantityAndStatusUpdate> => {
//   return fetch(receiveStoreInventoryUpdateCountURL, {
//     method: "POST",
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${loginToken}`
//     },
//     body: JSON.stringify(stockroomUpdate)
//   })
//     .then((response) => {
//       if (response.ok) {
//         return response.json()
//           .then((update: InventoryTransferQuantityAndStatusUpdate) => {
//             return update;
//           })
//           .catch((e) => {
//             return Promise.reject({ message: "Invalid response body" })
//           })
//       } else {
//         return Promise.reject({ message: "Response not ok" })
//       }
//     })
//     .catch((e) => {
//       return Promise.reject({ message: "Communication failure" })
//     })
// }

// export const updateStockroomShippedCount = (stockroomUpdate: InventoryTransferQuantityAndStatusUpdate): Promise<InventoryTransferQuantityAndStatusUpdate> => {
//   return fetch(sendStoreInventoryUpdateCountURL, {
//     method: "POST",
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${loginToken}`
//     },
//     body: JSON.stringify(stockroomUpdate)
//   })
//     .then((response) => {
//       if (response.ok) {
//         return response.json()
//           .then((update: InventoryTransferQuantityAndStatusUpdate) => {
//             return update;
//           })
//           .catch((e) => {
//             return Promise.reject({ message: "Invalid response body" })
//           })
//       } else {
//         return Promise.reject({ message: "Response not ok" })
//       }
//     })
//     .catch((e) => {
//       return Promise.reject({ message: "Communication failure" })
//     })
// }

// export const updateStockroomStatus = (stockroomUpdate: InventoryTransferQuantityAndStatusUpdate): Promise<InventoryTransferQuantityAndStatusUpdate> => {
//   return fetch(receiveStoreInventoryUpdateStatusURL, {
//     method: "POST",
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${loginToken}`
//     },
//     body: JSON.stringify(stockroomUpdate)
//   })
//     .then((response) => {
//       if (response.ok) {
//         return Promise.resolve(response.json());
//       } else {
//         return Promise.reject({ message: "Response not ok" });
//       }
//     })
//     .catch((e) => {
//       return Promise.reject({ message: "Communication failure" })
//     })
// }

// export const downgradeStockroomStatus = (stockroomUpdate: InventoryTransferQuantityAndStatusUpdate): Promise<InventoryTransferQuantityAndStatusUpdate> => {
//   return fetch(receiveStoreInventoryDowngradeStatusURL, {
//     method: "POST",
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${loginToken}`
//     },
//     body: JSON.stringify(stockroomUpdate)
//   })
//     .then((response) => {
//       if (response.ok) {
//         return Promise.resolve(response.json());
//       } else {
//         return Promise.reject({ message: "Response not ok" });
//       }
//     })
//     .catch((e) => {
//       return Promise.reject({ message: "Communication failure" })
//     })
// }

