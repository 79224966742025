import { Variation, Item, Category, StructuredCatalog } from '../catalog/Catalog';
import { Store } from '../store/Store';
import { CountEntryQueryOrUpdate } from '../wireData/inventoryCountData';

export class InventoryManager {

  // private static _instance: InventoryManager = null;
  // private constructor() { }
  // public static get Instance() {
  //   if (this._instance == null) {
  //     this._instance = new InventoryManager();
  //   }
  //   return this._instance;
  // }

  store: Store;
  date: Date;
  private _catalog: StructuredCatalog;
  category: Category;
  item: Item;
  variant: Variation;
  private catIndex = 0;
  private itemIndex = 0;
  private varIndex = 0;

  get catalog(): StructuredCatalog {
    return this._catalog;
  }

  set catalog(catalog: StructuredCatalog) {
    this._catalog = catalog;
    if (catalog)
      this.firstCategory();
  }

  updateEntry(update: CountEntryQueryOrUpdate): boolean {
    if (update.storeID != this.store.storeID) {
      return;
    }
    let item: any =
      this.catalog.find(category => category.categoryID == update.categoryID)
        .items.find(item => item.itemID == update.itemID);
    if (item && update.variationID) {
      item = item.variations.find((v: Variation) => v.variationID == update.variationID);
    }
    if (item) {
      item.newCount = update.count;
      return true;
    }
    return false;
  }

  isComplete(item: Item): boolean {
    if (!this.hasVariations(item)) {
      return item.newCount != null;
    }
    return !item.variations.find(v => v.newCount == null);
  }

  hasVariations(item: Item): boolean {
    return (item && item.variations && item.variations.length > 0)
  }

  hasItems(category: Category): boolean {
    return (category && category.items && category.items.length > 0);
  }

  firstCategory() {
    this.category = this._catalog[this.catIndex = 0];
    this.firstItem();
  }

  firstItem() {
    this.itemIndex = 0
    this.item = this.hasItems(this.category) ? this.category.items[0] : null;
    this.firstVariant();
  }

  lastItem() {
    this.itemIndex = 0
    this.item = this.hasItems(this.category) ? this.category.items[this.itemIndex = this.category.items.length - 1] : null;
    this.lastVariant();
  }

  firstVariant() {
    this.varIndex = 0
    this.variant = this.hasVariations(this.item) ? this.item.variations[0] : null;
  }

  lastVariant() {
    this.varIndex = 0
    this.variant = this.hasVariations(this.item) ? this.item.variations[this.varIndex = this.item.variations.length - 1] : null;
  }

  nextVariant() {
    if (this.hasVariations(this.item) && this.varIndex < this.item.variations.length - 1) {
      this.variant = this.item.variations[++this.varIndex];
      return;
    }
    return this.nextItem();
  }

  nextItem() {
    if (this.item && this.itemIndex < this.category.items.length - 1) {
      this.item = this.category.items[++this.itemIndex];
      this.firstVariant();
      return;
    }
    return this.nextCategory();
  }

  nextCategory() {
    if (this.catIndex < this._catalog.length - 1) {
      this.category = this._catalog[++this.catIndex];
      this.firstItem();
    }
    return;
  }

  previousVariant() {
    if (this.hasVariations(this.item) && this.varIndex > 0) {
      this.variant = this.item.variations[--this.varIndex];
      return;
    }
    return this.previousItem();
  }

  previousItem() {
    if (this.item && this.itemIndex > 0) {
      this.item = this.category.items[--this.itemIndex];
      this.lastVariant();
      return;
    }
    return this.previousCategory();
  }

  previousCategory() {
    if (this.catIndex > 0) {
      this.category = this._catalog[--this.catIndex];
      this.lastItem();
    }
    return;
  }

  selectCategory(c: Category) {
    this.category = c;
    this.catIndex = this._catalog.indexOf(c);
    this.firstItem();
  }

  selectItem(c: Category, i: Item) {
    this.category = c;
    this.catIndex = this._catalog.indexOf(c);
    this.item = i;
    this.itemIndex = this.category.items.indexOf(i);
    this.firstVariant();
  }

  selectVariant(c: Category, i: Item, v: Variation) {
    this.category = c;
    this.catIndex = this._catalog.indexOf(c);
    this.item = i;
    this.itemIndex = this.category.items.indexOf(i);
    this.variant = v;
    this.varIndex = this.item.variations.indexOf(v);
  }
}