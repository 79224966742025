import React, { useState, useMemo, useEffect } from 'react';
import { Typography, Grid, TextField, Button } from '@material-ui/core';
import { UserData } from '../wireData/UserData';
import { UserCompanyData } from '../wireData/UserCompanyData';
import { useStyles } from './styles';
import { UserCompaniesSelection } from './UserCompanySelectionList';
import { UserAuthorizations } from './UserAuthorizations';
import { UserConfiguration } from './UserConfiguration';
import { getStoreByName } from 'react-hookstore';
import { useField } from '../common/useField';
import { userEditStoreName } from './UserHandler';

export interface EditUserProps {
  user: UserData;
  companies: Array<UserCompanyData>;
  onUpdate: (user: UserData) => void;
  onRemove: (user: UserData) => void;
  onCancel: () => void;
}

export function EditUser(props: EditUserProps) {
  const classes = useStyles({});
  const store = getStoreByName<UserData>(userEditStoreName);
  const [userID, setUserID] = useState(props.user.userID);
  const [userName, setUserName] = useField(store, 'userName');
  const [firstName, setFirstName] = useField(store, 'firstName');
  const [lastName, setLastName] = useField(store, 'lastName');
  const [phoneNumber, setPhoneNumber] = useField(store, 'phoneNumber');
  const [pin, setPIN] = useField(store, 'pin');
  const [password, setPassword] = useField(store, 'password');
  const [userConfig, setUserConfig] = useField(store, 'userConfig');
  const [confirmationPassword, setConfirmationPassword] = useState(props.user.password);
  const [authorizations, setAuthorizations] = useField(store, 'authorizations');
  const [companies, setCompanies] = useField(store, 'companies')
  const [authArray, setAuthArray] = useState(authorizations ? authorizations.split(/[ ,]+/) : []);
  const [companyArray, setCompanyArray] = useState(companies ? companies.split(/[ ,]+/).map((company) => Number(company)) : []);

  const [statusMessage, setStatusMessage] = useState(null);

  const handleClearStatusMessage = () => {
    if (statusMessage == "Success") {
      props.onCancel();
    }
    setStatusMessage(null);
  }

  const label = useMemo(() => (
    props.user.userID != null
      ? "Update user"
      : "Add User"),
    [props.user.userID]);

  const handleConfigSelect = (value: string) => {
    setUserConfig(value);
  }

  const handleAuthItemSelect = (value: string, checked: boolean) => {
    if (checked && authorizations.indexOf(value) == -1) {
      const newAuth = [...authArray, value]
      setAuthArray(newAuth);
      setAuthorizations(newAuth.join(","));
    }
    else if (!checked) {
      const newAuth = authArray.filter(a => a != value);
      setAuthArray(newAuth);
      setAuthorizations(newAuth.join(","));
    }
  }

  const handleCompanySelect = (id: number, checked: boolean) => {
    if (checked && companyArray.indexOf(id) == -1) {
      const newCompanies = [...companyArray, id]
      setCompanyArray(newCompanies);
      setCompanies(newCompanies.join(",") );
    } 
    else if (!checked) {
      const newCompanies = companyArray.filter(a => a != id);
      setCompanyArray(newCompanies);
      setCompanies(newCompanies.join(","));
    }
  }

  const handleUpdate = () => {
    const user = new UserData();
    user.userID = userID;
    user.userName = userName;
    user.password = password;
    user.firstName = firstName;
    user.lastName = lastName;
    user.phoneNumber = phoneNumber;
    user.pin = pin;
    user.authorizations = authorizations;
    user.companies = companies;
    user.userConfig = userConfig;
    props.onUpdate(user);
  }

  const handleCancel = () => {
    setStatusMessage(null);
    props.onCancel();
  }

  const handleRemove = () => {
    props.onRemove(props.user);
  }

  const checkFieldsAreComplete = () => {
    if (userName == null || userName.trim().length == 0)
      return false;
    if (userID == null) {
      if (password == null || password.trim().length < 8)
        return false;
    }
    if (confirmationPassword != password)
      return false;
    if (userConfig == null)
      return false;

    return true;
  }

  return (
    <Grid container spacing={1} >
      <Grid item xs={12}>
        <Typography variant='subtitle2' align='center' gutterBottom>
          {label}
        </Typography>

      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='userName'
          name='userName'
          label='User Email'
          fullWidth
          value={userName || ""}
          onChange={(e) => setUserName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required={props.user.userID == null}
          id='password'
          name='password'
          label='Password'
          fullWidth
          value={password || ""}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required={props.user.userID == null}
          id='confirmPassword'
          name='confirmPassword'
          label='Confirm Password'
          fullWidth
          value={confirmationPassword || ""}
          onChange={(e) => setConfirmationPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id='firstName'
          name='firstName'
          label='First Name'
          fullWidth
          value={firstName || ""}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id='lastName'
          name='lastName'
          label='Last Name'
          fullWidth
          value={lastName || ""}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id='phoneNumber'
          name='phoneNumber'
          label='Phone Number'
          fullWidth
          value={phoneNumber || ""}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id='pin'
          name='pin'
          label='PIN'
          fullWidth
          value={pin || ""}
          onChange={(e) => setPIN(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} >
        <UserConfiguration onItemSelected={handleConfigSelect} currentConfig={userConfig} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <UserAuthorizations authorizations={authArray} onItemChanged={handleAuthItemSelect} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <UserCompaniesSelection companies={props.companies} userCompanies={companyArray} onItemChanged={handleCompanySelect} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button variant='contained' fullWidth onClick={handleCancel}>Cancel</Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant='contained'
          fullWidth
          disabled={!props.user.userID}
          onClick={handleRemove}
        >
          Remove
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant='contained'
          fullWidth
          disabled={!checkFieldsAreComplete()}
          onClick={handleUpdate}
        >{label}
        </Button>
      </Grid>
    </Grid>
  )
}

