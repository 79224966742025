import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, FormControl, InputLabel, IconButton, Select, Input, MenuItem } from '@material-ui/core';
import { Error } from '../common/ErrorComponent';
import { LinearLoading } from '../common/LoadingComponent';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    width: "100%",
    margin: theme.spacing(1),
  },
}));

export interface SelectData {
  key: () => any;
  toString: () => string;
}

export interface DataSelectProps {
  error?: any;
  loading?: boolean;
  multiple?: boolean;
  dataList: Array<SelectData>;
  currentSelection: SelectData | Array<SelectData>;
  insertClear?: boolean;
  disableClearIcon?: boolean;
  label?: string;
  classes?: any;
  onSelectItem: (item: SelectData | Array<SelectData>) => void
}

const clearSelection: SelectData = {
  key: () => "*none*",
  toString: () => "<clear>",
}

export const DataSelect = (props: DataSelectProps) => {

  const classes = props.classes || useStyles();
  const [currentItem, setCurrentItem] = useState<string>("");
  const [currentItems, setCurrentItems] = useState<Array<string>>([]);

  const label = props.label || "Select";
  const emptyEntry = {
    key: () => 0,
    toString: () => "None"
  }

  useEffect(() => {
    if (props.currentSelection) {
      if (!!props.multiple)
        setCurrentItems((props.currentSelection as Array<SelectData>).map((item) => item.toString()));
      else
        setCurrentItem((props.currentSelection as SelectData).toString());
    }
    else{
      setCurrentItems([]);
      setCurrentItem("");
    }
  }, [props.currentSelection]);

  const handleSelectItem = (event: React.ChangeEvent<{ value: unknown }>) => {

    if (props.multiple) {
      const values: Array<string> = event.target.value as Array<string>;
      const items: Array<SelectData> = [];
      for (const value of values)
        items.push(props.dataList.find((item) => item.toString() == value));

      setCurrentItems(values);
      props.onSelectItem(items);
    }
    else {
      setCurrentItem(event.target.value as string);
      props.onSelectItem(props.dataList.find((item) => item.toString() == event.target.value as string));
    }
  }

  const handleClearItem = () => {
    setCurrentItem(null);
    setCurrentItems([]);
    if (!!props.multiple)
      props.onSelectItem([]);
    else
      props.onSelectItem(null);
  }

  const dataList = () => {
    if (props.insertClear && !props.multiple) {
      const list: Array<SelectData> = props.dataList ? [...props.dataList] : [];
      list.unshift(clearSelection);
      return list;
    }
    return props.dataList || [emptyEntry];
  }

  if (props.error) return <Error message={props.error.message || props.error} />
  if (props.loading) return <LinearLoading message="Loading..." />
  if (props.dataList) return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="select-item">
        {label}
        {
          props.disableClearIcon
            ? null
            :
            <IconButton disabled={currentItem == ""} size="small" onClick={handleClearItem}>
              <ClearIcon />
            </IconButton>
        }
      </InputLabel>
      <Select
        value={props.multiple ? currentItems : currentItem}
        onChange={handleSelectItem}
        fullWidth
        multiple={!!props.multiple}
        disabled={!props.dataList || !props.dataList.length}
        input={<Input id="select-item" />}
      >
        {dataList().map((item: SelectData) => (
          <MenuItem key={item.key()} value={item.toString()}>{item.toString()}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
  return null;
}
