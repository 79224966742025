import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useUpdateTransaction, useRemoveTransaction } from './TransactionHandler';
import { LinearLoading } from '../common/LoadingComponent';
import { TransactionData } from '../wireData/TransactionData';
import { EditTransaction } from './EditTransaction';
import { CustomerData } from '../wireData/CustomerData';
import { lookupCustomersByName } from '../customer/CustomerHandler';
import { lookupUsersByUserName } from '../user/UserHandler';
import { UserData } from '../wireData/UserData';
import { CompanyData } from '../wireData/CompanyData';
import { lookupCompaniesByName } from '../company/CompanyHandler';
import { Store } from '../store/Store';
import { lookupStoresByName } from '../store/StoreHandler';
import { RegisterData } from '../wireData/RegisterData';
import { lookupRegistersByName } from '../register/registerHandler';

interface StatusDialogProps {
  message: string;
  open: boolean;
}

function StatusDialog(props: StatusDialogProps) {

  return (
    <Dialog open={props.open} fullWidth maxWidth="xs">
      <DialogContent>
        <LinearLoading message={props.message} />
      </DialogContent>
    </Dialog>
  )
}

interface ErrorDialogProps {
  error: any;
  open: boolean;
  onClose: () => void;
}

function ErrorDialog(props: ErrorDialogProps) {

  return (
    <Dialog open={props.open && Boolean(props.error)}>
      <DialogTitle>
        Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.error ? props.error.message || props.error : "Unknown Error"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export interface EditTransactionContainerProps {
  transaction: TransactionData;
  onUpdateTransaction: (transaction: TransactionData) => void;
  onRemoveTransaction?: (transaction: TransactionData) => void;
  onCancel: () => void;
}

export function EditTransactionContainer(props: EditTransactionContainerProps) {

  const [showErrors, setShowErrors] = useState(false);
  const [transactionToUpdate, setTransactionToUpdate] = useState<null | TransactionData>(null);
  const [transactionToRemove, setTransactionToRemove] = useState<null | TransactionData>(null);

  const { error: transactionUpdateError, updating, transaction: updatedTransaction } = useUpdateTransaction(transactionToUpdate);
  const { error: transactionRemoveError, removing, transaction: removedTransaction } = useRemoveTransaction(transactionToRemove);

  useEffect(() => {
    if (updatedTransaction && !updating) {
      props.onUpdateTransaction(updatedTransaction);
      setTransactionToUpdate(null);
    }
  }, [updatedTransaction, updating])

  useEffect(() => {
    if (removedTransaction && !removing) {
      props.onRemoveTransaction(transactionToRemove);
      setTransactionToRemove(null);
    }
  }, [removedTransaction, removing])

  const handleUpdate = (transaction: TransactionData) => {
    setTransactionToUpdate(transaction);
    setShowErrors(true);
  }

  const handleCancel = () => {
    props.onCancel();
  }

  const handleRemove = (transaction: TransactionData) => {
    setTransactionToRemove(transaction);
    setShowErrors(true);
  }

  const handleCloseErrorDialog = () => {
    setShowErrors(false);
  }

  const handleCompanyLookup = async (inputValue: string) : Promise<Array<CompanyData>> =>{
    return lookupCompaniesByName (inputValue, 8);
  }

  const handleStoreLookup = async (inputValue: string) : Promise<Array<Store>> =>{
    return lookupStoresByName (inputValue, 8);
  }

  const handleRegisterLookup = async (storeID: number, inputValue: string) : Promise<Array<RegisterData>> =>{
    return lookupRegistersByName (storeID, inputValue, 8);
  }

  const handleCustomerLookup = async (inputValue: string) : Promise<Array<CustomerData>> =>{
    return lookupCustomersByName (inputValue, 8);
  }

  const handleClerkLookup = async (inputValue: string) : Promise<Array<UserData>> =>{
    return lookupUsersByUserName (inputValue, 8);
  }

  return (
    <div>
      <StatusDialog open={updating || removing} message={updating ? "Updating Transaction":"Removing Transaction"} />
      <ErrorDialog open={showErrors && Boolean(transactionToUpdate || transactionToRemove)} error={transactionUpdateError || transactionRemoveError} onClose={handleCloseErrorDialog} />
      <EditTransaction
        transaction={props.transaction}
        onUpdateTransaction={handleUpdate}
        onRemoveTransaction={handleRemove}
        onCancel={handleCancel} 
        onCompanyLookup={handleCompanyLookup}
        onStoreLookup={handleStoreLookup}
        onRegisterLookup={handleRegisterLookup}
        onCustomerLookup={handleCustomerLookup}
        onClerkLookup={handleClerkLookup}
      />
    </div>
  )
}
