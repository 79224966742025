import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, IconButton, Button, Paper, Typography, createStyles } from '@material-ui/core';
import BackspaceIcon from '@material-ui/icons/Backspace';
import ClearIcon from '@material-ui/icons/Clear';
import ExposureIcon from '@material-ui/icons/Exposure';
import DecimalIcon from '@material-ui/icons/Adjust';

const useNumStyles = makeStyles((theme: Theme) => createStyles({
  touchFix: {
    touchAction: 'manipulation',
  },
  numRoot: {
    bottom: 0,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 'auto',
    height: '40%',
    minWidth: 350,
    minHeight: 250,
    [theme.breakpoints.up(600 + theme.spacing(4))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  numColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '100%',
  },
  numRow: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
  },
  numButton: {
    flexGrow: 1,
  },
}))

interface NumPadProps {
  onNumClick: (value: number) => void;
  onClearClick: () => void;
  onBackClick: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
  rootClassname?: string;
  plusMinus?: boolean;
  decimal?:boolean;
}

export function NumPad(props: NumPadProps) {
  const numClasses = useNumStyles({});
  const numPadRef = useRef(null);
  const root = props.rootClassname ? props.rootClassname : numClasses.numRoot;

  const row1 = [1, 2, 3]
  const row2 = [4, 5, 6]
  const row3 = [7, 8, 9]
  const row4 = [-1, 0, -2]

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const clicked = parseInt(e.currentTarget.value);
    switch (clicked) {
      case -2: props.onClearClick(); break;
      case -1: props.onBackClick(); break;
      default: props.onNumClick(clicked);
    }
    if (numPadRef && numPadRef.current)
      numPadRef.current.focus();
  }

  const handleKeyDown = (event) => {
    event.stopPropagation();
    props.onKeyDown(event);
    if (numPadRef && numPadRef.current)
      numPadRef.current.focus();
  }

  interface NumButtonProps {
    c: number;
    special?: boolean;
    plusMinus?: boolean;
    decimal?:boolean;
  }

  function NumButton(props: NumButtonProps) {
    if (props.special) {
      const icon = props.c == -1 ? <BackspaceIcon /> : props.plusMinus ? <ExposureIcon /> : props.decimal? <DecimalIcon /> :<ClearIcon />;
      return (<IconButton key={props.c} className={numClasses.numButton} onClick={handleButtonClick} value={props.c}>{icon}</IconButton>)
    }
    return (
      <Button
        disableTouchRipple
        size='large'
        key={props.c}
        variant='text'
        className={numClasses.numButton}
        onClick={handleButtonClick}
        value={props.c}
      >
        <Typography variant='h5'>{props.c.toString()}</Typography>
      </Button>
    )
  }
  var key = 1;
  return (
    <Paper className={`${numClasses.touchFix} ${root}`} >
      <div className={numClasses.numColumns} onKeyDown={handleKeyDown} ref={numPadRef} tabIndex={1}>
        <div className={numClasses.numRow} >
          {row1.map(c => <NumButton key={key++} c={c} />)}
        </div>
        <div className={numClasses.numRow}>
          {row2.map(c => <NumButton key={key++} c={c} />)}
        </div>
        <div className={numClasses.numRow}>
          {row3.map(c => <NumButton key={key++} c={c} />)}
        </div>
        <div className={numClasses.numRow}>
          {row4.map(c => <NumButton key={key++} c={c} special={c != 0} plusMinus={props.plusMinus} decimal={props.decimal}/>)}
        </div>
      </div>
    </Paper>
  )
}