import { StoreOrderingWorkbook, StoreOrderingWorksheet, TotalOrderingWorksheet, PendingOrderItem, PendingOrderItemUpdate, StoreOrderingCount } from "./StoreOrderingData";
import { number } from "prop-types";
import { Store } from "../store/Store";

export class WorkbookTotals {
  workbookTotal: number;
  categoryTotal: number;
  worksheetTotal: number;
  worksheetCategoryTotal: number;
  category: string;
  store: string;
}

export class OrderingWorkbookManager {


  // private static _instance: OrderingWorkbookManager = null;
  private _totalsSubscriber: (totals: WorkbookTotals) => void = null;
  private _totals: WorkbookTotals = new WorkbookTotals();

  // private constructor() {

  // }

  // public static get Instance() {
  //   if (this._instance == null) {
  //     this._instance = new OrderingWorkbookManager();
  //   }
  //   return this._instance;
  // }

  private _workbook: StoreOrderingWorkbook;
  displayedWorksheet: StoreOrderingWorksheet;
  totalSheet: TotalOrderingWorksheet;
  currentCategoryFilter: string = null;
  currentSheetIndex = 0;
  currentStore: Store;

  set workbook(book: StoreOrderingWorkbook) {
    this._workbook = book;
    if (book.worksheets.length > 0) {
      this.selectSheet(0);
    }
    this.totalSheet = book.totalSheet;
  }

  get numSheets(): number {
    return this._workbook.worksheets.length;
  }

  get totals(): TotalOrderingWorksheet {
    return this.totalSheet;
  }

  getStores(): Array<Store> {
    return this._workbook.totalSheet.stores;
  }

  getStoreNames(): Array<string> {
    return this._workbook.totalSheet.stores.map(store => store.storeName)
  }

  getStoreIDs(): Array<number> {
    return this._workbook.totalSheet.stores.map(store => store.storeID);
  }

  getColumns(totals: boolean): Array<string> {
    const columns = new Array<string>();
    if (totals) {
      columns.push('Item');
      columns.push('Variation');
      columns.push("Stock");
      this._workbook.totalSheet.stores.forEach(store => columns.push(store.shortName))
      columns.push('Total');
      columns.push('Latest Cost')
      columns.push('Total Cost')
      columns.push("Vendor");
    } else {
      columns.push('Item');
      columns.push('Variation');
      this._workbook.worksheets[0].dates.forEach(date => columns.push(date));
      columns.push('Last Count');
      columns.push('On Order');
      columns.push("Transfers");
      columns.push('To Order');
      columns.push('Run Days');
      columns.push('Latest Cost')
      columns.push('Total Cost')
    }

    return columns;
  }

  selectSheet(sheetIndex: number) {
    this.currentSheetIndex = sheetIndex;
    this._totals.store = (this.currentStore = this._workbook.totalSheet.stores[sheetIndex]).storeName;
    if (this.currentCategoryFilter) {
      this.filterWorksheet();
      this.filterTotalSheet();
    }
    else {
      this.displayedWorksheet = this._workbook.worksheets[this.currentSheetIndex];
      this.totalSheet = this._workbook.totalSheet;
    }
    this.calculateTotals();
  }

  selectCategory(category: string) {
    this.currentCategoryFilter = this._totals.category = category;
    this.selectSheet(this.currentSheetIndex);
  }

  findSheetIndexForStore(storeID: number): number {
    return this._workbook.worksheets.findIndex(sheet => sheet.storeID == storeID)
  }

  filterWorksheet() {
    const worksheet = this._workbook.worksheets[this.currentSheetIndex];
    this.displayedWorksheet = {
      ...worksheet,
      counts: worksheet.counts.filter(count => count.category == this.currentCategoryFilter)
    };
  }

  filterTotalSheet() {
    this.totalSheet = {
      ...this._workbook.totalSheet,
      items: this._workbook.totalSheet.items.filter(item => item.category == this.currentCategoryFilter)
    };
  }

  clearFilters() {
    this.currentCategoryFilter = null;
    this.selectSheet(this.currentSheetIndex);
  }

  getCategories(): Array<string> {
    const categories = new Array<string>();

    this._workbook.worksheets[0].counts.forEach(count => {
      if (categories.indexOf(count.category) < 0) {
        categories.push(count.category);
      }
    })

    return categories;
  }

  updatePending(updateItem: PendingOrderItemUpdate) {
    const workSheetUpdate = this._workbook.worksheets
      .find(sheet => sheet.storeID == updateItem.storeID)
      .counts
      .find(count => (
        count.categoryID == updateItem.categoryID
        && count.itemID == updateItem.itemID
        && count.variationID == updateItem.variationID));

    if (workSheetUpdate) {
      const update = (updateItem.pending - workSheetUpdate.toOrder) * workSheetUpdate.latestItemCost;
      this._totals.worksheetTotal += update;
      this._totals.worksheetCategoryTotal += update;
      workSheetUpdate.toOrder = updateItem.pending;
    }

    const totalsUpdate = this._workbook.totalSheet.items
      .find(totalsItem => (
        totalsItem.categoryID == updateItem.categoryID
        && totalsItem.itemID == updateItem.itemID
        && totalsItem.variationID == updateItem.variationID))

    if (totalsUpdate) {
      const update = (updateItem.pendingTotal - totalsUpdate.pendingTotal) * totalsUpdate.latestItemCost;
      this._totals.workbookTotal += update;
      this._totals.categoryTotal += update;

      totalsUpdate.pendingTotal = updateItem.pendingTotal;

      const pendingIndex = updateItem.storeIDs.indexOf(updateItem.storeID);

      totalsUpdate.pending[pendingIndex] = updateItem.pending;
    }
    if (this._totalsSubscriber) {
      this._totalsSubscriber(this._totals)
    }
  }

  calculateTotals() {
    this._totals.workbookTotal = this._workbook.totalSheet.items.map(
      (item) => (item.pendingTotal * item.latestItemCost)).reduce((subTotal, value) => (subTotal + value), 0);
    this._totals.categoryTotal = this.totalSheet.items.map(
      (item) => (item.pendingTotal * item.latestItemCost)).reduce((subTotal, value) => (subTotal + value), 0);

    this._totals.worksheetTotal = this._workbook.worksheets[this.currentSheetIndex].counts.map(
      (item) => (item.toOrder * item.latestItemCost)).reduce((subTotal, value) => (subTotal + value), 0);
    this._totals.worksheetCategoryTotal = this.displayedWorksheet.counts.map(
      (item) => (item.toOrder * item.latestItemCost)).reduce((subTotal, value) => (subTotal + value), 0);

    if (this._totalsSubscriber) {
      this._totalsSubscriber(this._totals)
    }
  }

  retrieveCountForStore(storeID: number, template: StoreOrderingCount | PendingOrderItem): StoreOrderingCount{
    const sheetIndex = this.findSheetIndexForStore(storeID);
    const match = this._workbook.worksheets[sheetIndex].counts.find((count) => 
      count.categoryID == template.categoryID &&
      count.itemID == template.itemID &&
      count.variationID == template.variationID
      )
    return match;
  }

  subscribeToTotals(callback: (totals: WorkbookTotals) => void) {
    this._totalsSubscriber = callback;
    if (callback)
      callback(this._totals);
  }

  unsubscribeFromTotals() {
    this._totalsSubscriber = null;
  }
}
