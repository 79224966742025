import React, { useState } from 'react';
import { Error } from '../common/ErrorComponent';
import { useStructuredCatalogManager } from '../catalog/CatalogHandler';
import { Loading } from '../common/LoadingComponent';
import Typography from '@material-ui/core/Typography';
import { StructuredCatalogManager } from '../catalog/StructuredCatalogManager';

class PointOfSaleProps{
  catalogManager: StructuredCatalogManager;
}

function PointOfSale(props: PointOfSaleProps){
const[activeCashier, setActiveCasahier] = useState(null);

if (!activeCashier)
  return <div>Select Cashier</div>
  return <div>Start new Ticket</div>
}

export class PointOfSaleContainerProps {
  onClose: () => void;
}

export function PointOfSaleContainer(props: PointOfSaleContainerProps) {
  const { error, loading, catalogManager } = useStructuredCatalogManager(true);

  if (error) return <Error message={error.message || error} />;
  if (loading) return <Loading />;
  if (catalogManager) return <PointOfSale catalogManager={catalogManager} />
  return null;
}