import { StockroomDataTable, CategoryItems } from "./StockroomData";
import { InventoryTransferQuantityAndStatusUpdate } from '../wireData/InventoryTransferData';

export class StockroomDataManager {

  // private static _instances = {};

  // public static Instance(direction: string) {
  //   if (this._instances[direction] == null) {
  //     this._instances[direction] = new StockroomDataManager();
  //   }
  //   return this._instances[direction];
  // }

  private _stockroomData: StockroomDataTable;

  set StockroomData(data: StockroomDataTable) {
    this._stockroomData = data;
  }

  get transfers() {
    if (!this._stockroomData)
      return [];
    return this._stockroomData.transfers;
  }

  get categories() {
    if (!this._stockroomData)
      return [];
    if (!this._stockroomData.categories)
      this.buildCategoryItems();
    return this._stockroomData.categories;
  }

  private buildCategoryItems() {
    const data = this._stockroomData;
    data.categories = [];
    data.transfers.forEach((item) => {
      var category: CategoryItems = data.categories.find((category) => category.categoryName == item.categoryName);
      if (category == null)
        data.categories.push(category = new CategoryItems(item.categoryName));
      category.items.push(item);
    })
  }

  public updateStatus(inventoryTransferItem: InventoryTransferQuantityAndStatusUpdate) {
    const item = this.transfers.find((item) => item.inventoryTransferID == inventoryTransferItem.inventoryTransferID);
    if (item)
      item.status = inventoryTransferItem.status;
  }

  public updateReceivedCount(inventoryTransferItem: InventoryTransferQuantityAndStatusUpdate) {
    const item = this.transfers.find((item) => item.inventoryTransferID == inventoryTransferItem.inventoryTransferID);
    if (item)
      item.quantityReceived = inventoryTransferItem.quantity;
  }

  public updateShippedCount(inventoryTransferItem: InventoryTransferQuantityAndStatusUpdate) {
    const item = this.transfers.find((item) => item.inventoryTransferID == inventoryTransferItem.inventoryTransferID);
    if (item)
      item.quantityShipped = inventoryTransferItem.quantity;
  }
}
