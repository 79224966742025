export class InventoryTransferUpdate {
  inventoryTransferID: number;
  categoryID: number;
  categoryName: string;
  itemID: number;
  itemName: string;
  variationID: number;
  variationDescription: string;
  storeID: number;
  quantityRequested: number; //+ or -
  quantityShipped: number; //+ or -
  quantityReceived: number; //+ or -
  itemCost: number;
  status: string;
}

export class InventoryTransferItem {
  inventoryTransferID: number;
  storeID: number;
  storeName: string;
  categoryID: number;
  categoryName: string;
  itemID: number;
  itemName: string;
  variationID: number;
  variationDescription: string;
  quantityRequested: number; //changable 
  quantityShipped: number; //changable 
  quantityReceived: number;
  dateRequested: number;
  dateShipped: number;
  dateReceived: number;
  status: string;
}

export class InventoryTransferQuantityAndStatusUpdate {
  inventoryTransferID: number;
  status: string;
  quantity: number;
}
