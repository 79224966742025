import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Category, Item, Variation } from './Catalog';
import { ExpansionPanelSummary, ExpansionPanel, Typography, ExpansionPanelDetails, CssBaseline, Switch, FormGroup, FormControl, RadioGroup, Radio, FormControlLabel, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Theme } from '@material-ui/core';
import { changeActive, useStructuredCatalogManager, setClass } from './CatalogHandler';
import { Loading } from '../common/LoadingComponent';
import { Error } from '../common/ErrorComponent';

const useStyles = makeStyles((theme: Theme) => createStyles({
  outerDiv: {
    flex: 1,
    position: 'relative',
    overflowX: 'hidden',
    maxHeight: '100%',
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + 280 + theme.spacing(2 * 2))]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
  },
  root: {
    background: theme.palette.background.default,
    maxHeight: '100%',
    // overflowY: 'auto',
  },
  fab: {
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 10,
    right: 50,
    position: 'fixed',
    zIndex: 999,
  },
  expander: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    background: theme.palette.primary.dark,
  },
  itemButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    background: theme.palette.primary.light,
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  startButton: {

  },
  variationLayout: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: '100%'
  },
  nameAndSwitch: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexBasis: '40%',
    flex: '0 0',
    alignItems: 'center',
  },
  radioGroup: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexBasis: '40%',
    flex: '0 0',
  },
}));

export class StructuredCatalogExpanderProps {
  active: boolean;
}

export function StructuredCatalogExpander(props: StructuredCatalogExpanderProps) {
  const classes = useStyles({});
  const { error, loading, catalogManager } = useStructuredCatalogManager(props.active);
  const [changedItem, setChangedItem] = useState(false);
  const [categoryExpanded, setCategoryExpanded] = useState(null);
  const [itemExpanded, setItemExpanded] = useState(null);

  const handleInventoryClassChange = (c: Category, newClass: string) => (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    event.stopPropagation();
    setClass(c.categoryID, newClass).then(() => {
      c.inventoryClass = newClass;
      setChangedItem(!changedItem);
    })
  }

  const handleCategoryActiveToggle = (c: Category) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    changeActive('category', c.categoryID, !c.categoryActive).then(() => {
      c.categoryActive = !c.categoryActive;
      setChangedItem(!changedItem);
    }).catch(e => {
      console.log(e);
    })
  }

  const handleItemActiveToggle = (i: Item) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    changeActive('item', i.itemID, !i.itemActive).then(() => {
      i.itemActive = !i.itemActive;
      setChangedItem(!changedItem);
    }).catch(e => {
      console.log(e);
    })
  }

  const handleVariationActiveToggle = (v: Variation) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    changeActive('variation', v.variationID, !v.variationActive).then(() => {
      v.variationActive = !v.variationActive;
      setChangedItem(!changedItem);
    }).catch(e => {
      console.log(e);
    })
  }

  const handleCategoryExpand = (category: Category) => {
    if (catalogManager.hasItems(category)) {
      setItemExpanded(null);
      if (category == categoryExpanded) {
        setCategoryExpanded(null);
      }
      else {
        setCategoryExpanded(category);
      }
    }
  }

  const handleItemExpand = (item: Item) => {
    if (catalogManager.hasVariations(item)) {
      if (item == itemExpanded) {
        setItemExpanded(null);
      }
      else {
        setItemExpanded(item);
      }
    }
  }

  const expandedItems = (category: Category) => {
    if (category != categoryExpanded || !catalogManager.hasItems(category))
      return null;
    return (
      <ExpansionPanelDetails className={classes.expander}>
        {
          category.items.map(item => (
            <ExpansionPanel expanded={itemExpanded == item} key={`I${item.itemID}`}>
              <ExpansionPanelSummary
                onClick={() => handleItemExpand(item)}
                expandIcon={
                  catalogManager.hasVariations(item)
                    ?
                    <ExpandMoreIcon />
                    :
                    null}
              >
                <div className={classes.variationLayout} >
                  <Switch checked={Boolean(item.itemActive)} onClick={handleItemActiveToggle(item)} />
                  <Typography>{item.itemName}</Typography>
                </div>
              </ExpansionPanelSummary>
              {expandedVariations(item)}
            </ExpansionPanel>
          ))
        }
      </ExpansionPanelDetails>
    )
  }

  const expandedVariations = (item: Item) => {
    if (item != itemExpanded || !catalogManager.hasVariations(item))
      return null;
    return (
      <ExpansionPanelDetails className={classes.expander}>
        {
          item.variations.map(variation => (
            <ExpansionPanel expanded={false} key={`V${variation.variationID}`}>
              <ExpansionPanelSummary >
                <div className={classes.variationLayout} >
                  <Switch checked={Boolean(variation.variationActive)} onClick={handleVariationActiveToggle(variation)} />
                  <Typography>{variation.variationDescription}</Typography>
                </div>
              </ExpansionPanelSummary>
            </ExpansionPanel>
          ))
        }
      </ExpansionPanelDetails>
    )
  }

  if (error)
    return <Error message={error.message || error} />
  if (loading)
    return <Loading />
  if (catalogManager) {
    const log = catalogManager.catalog;
    return (
      <div className={classes.outerDiv}>
        <Paper className={classes.root}>
          {log.map(cat => (
            <ExpansionPanel expanded={categoryExpanded == cat} key={`C${cat.categoryID}`}>
              <ExpansionPanelSummary
                onClick={() => handleCategoryExpand(cat)}
                expandIcon={
                  catalogManager.hasItems(cat)
                    ?
                    <ExpandMoreIcon />
                    :
                    null}
              >
                <div className={classes.variationLayout} >
                  <div className={classes.nameAndSwitch}>
                    <Switch checked={Boolean(cat.categoryActive)} onClick={handleCategoryActiveToggle(cat)} />
                    <Typography>{cat.categoryName}</Typography>
                  </div>
                  <div className={classes.radioGroup}>
                    <FormControl>
                      <RadioGroup
                        row
                        name='class'
                        value={cat.inventoryClass}
                      >
                        <FormControlLabel value="hardware" onClick={handleInventoryClassChange(cat, "hardware")} control={<Radio />} label='Hardware' />
                        <FormControlLabel value="liquid" onClick={handleInventoryClassChange(cat, "liquid")} control={<Radio />} label='Liquid' />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </ExpansionPanelSummary>
              {expandedItems(cat)}
            </ExpansionPanel>
          ))}
        </Paper>

      </div>
    )
  }
  else
    return null;
}