import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',
    height: '90vh',
    boxSizing: 'border-box'
  },
  list: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  categoryList: {
    flex: 1,
    overflowY: 'auto',
  },
  listItem:{
    listStyleType: "none",
  },
  categorySection: {
    backgroundColor: "inherit",
  },
  categoryHeader: {
    backgroundColor: theme.palette.grey[900],
    padding: 0,
  },
  itemName: {
    variant: "h6",
  },
  fab: {
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 10,
    right: 50,
    position: 'fixed',
    zIndex: 999,
  },

}));
