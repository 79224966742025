import { OrderBook, OrderHeader, OrderLineItem, OrderLineItemReceivingUpdate, OrderReconcilingUpdate, TotalsBreakdown, ItemCostUpdate } from './PurchaseOrderData';
import { Store } from '../store/Store';
import { bankersRound } from '../utils/Convert';

export default class PurchaseOrderManager {
  // private static _instances = {};

  // private constructor() { }

  // public static Instance(status:number) {
  //   if (this._instances[status] == null) {
  //     this._instances[status] = new PurchaseOrderManager();
  //   }
  //   return this._instances[status];
  // }

  private _book: OrderBook;
  private _orders: Array<OrderHeader>;
  private _stores: Array<Store>;

  set book(newBook: OrderBook) {
    this._book = newBook;
    if(newBook){
    this._orders = newBook.orders;
    this._stores = newBook.stores;
    }
    else{
      this._orders = null;
      this._stores = null;
    }
  }
  get book() {
    return this._book;
  }
  get orders() {
    return this._orders;
  }
  get stores() {
    return this._stores;
  }

  updateItem(headerID: number, update: OrderLineItemReceivingUpdate) {
    const toUpdate = this._orders.find(header => header.orderID == headerID).items.find(item => item.orderItemID == update.orderItemID)
    toUpdate.quantityReceived = update.receivedQuantity;
    toUpdate.itemStatus = update.itemStatus;
  }

  updateOrder(update: OrderReconcilingUpdate) {
    const toUpdate = this._orders.find(header => header.orderID == update.orderID);
    if (toUpdate) {
      toUpdate.orderStatus = update.orderStatus;
      toUpdate.orderAdjustment = update.orderAdjustment;
      toUpdate.orderDiscount = update.orderDiscount;
      toUpdate.orderShipping = update.orderShipping;
    }
  }

  applyCostUpdate(update: ItemCostUpdate) {
    const order = this._orders.find(order => order.orderID == update.orderID);
    order.items.forEach(item => {
      if (item.categoryID == update.categoryID && item.itemID == update.itemID && item.variationID == update.variationID) {
        item.itemCost = update.itemCost;
        item.receivedTotal = item.quantityReceived * update.itemCost;
        item.itemTotal = update.totalCost;
      }
    });
  }

  getRealOrderSubtotal(order: OrderHeader) {
    return order.items.map(item => item.receivedTotal).reduce((t1, t2) => t1 + t2)
  }

  sortItemsFromHeader(orderID: number): Array<Array<OrderLineItem>> {
    const items = this._orders.find(head => head.orderID == orderID).items;
    const sortedOrders = new Array<Array<OrderLineItem>>();
    for (const item of items){
      const homeArray = sortedOrders.find(list => {
        return list.length
          && list[0].categoryID == item.categoryID
          && list[0].itemID == item.itemID
          && list[0].variationID == item.variationID
      });
      if (homeArray) {
        homeArray.push(item);
      } else {
        sortedOrders.push([item]);
      }
    }
    sortedOrders.forEach(arr => {
      arr.sort((a: OrderLineItem, b: OrderLineItem) => {
        if ( a == b ) 
          alert("Duplicate entries");
        if (a.storeName < b.storeName) return -1;
        if (a.storeName > b.storeName) return 1;
        return 0;
      });
    });

    return sortedOrders;
  }

  sortStoresFromHeader(orderID: number): Array<Store> {
    const header = this._orders.find(h => h.orderID == orderID);
    const stores = new Array<Store>();
    header.items.forEach(item => {
      if (stores.find(store => store.storeID == item.storeID) == null) {
        stores.push(this._stores.find(s => s.storeID == item.storeID));
      }
    })
    return stores.sort((a: Store, b: Store) => {
      if (a.storeName < b.storeName) return -1;
      if (a.storeName > b.storeName) return 1;
      return 0;
    });
  }

  getTotalOrderCostForStore(store: Store, order: OrderHeader, type: string = 'all'): number {
    const items = order.items.filter(item => item.storeID == store.storeID);
    if (items.length == 0) {
      return 0;
    }
    const filtered = items.filter(item => (type == 'all' || item.inventoryClass == type));
    if (filtered.length == 0) {
      return 0;
    }
    return filtered.map(filteredItem => filteredItem.receivedTotal).reduce((t1, t2) => t1 + t2);
  }

  generateSubtotalBreakdown(orderID: number, type: string = 'all'): TotalsBreakdown {
    const order = this._orders.find(order => order.orderID == orderID);
    return this._stores.map(store => {
      return { store: store.storeName, total: this.getTotalOrderCostForStore(store, order, type) }
    })
  }

  getStoreMultipliers(orderID: number): Array<{ store: Store, multiplier: number }> {
    const order = this._orders.find(order => order.orderID == orderID);
    const stores = this.sortStoresFromHeader(orderID);
    return stores.map(store => {
      const totalForStore = this.getTotalOrderCostForStore(store, order);
      const multiplier = (totalForStore / this.getRealOrderSubtotal(order));
      return { store: store, multiplier: multiplier }
    });
  }

  generateStoreCostBreakdown(orderID: number, cost: number): TotalsBreakdown {
    const unadjusted = this.getStoreMultipliers(orderID).map(storeMult => {
      return { store: storeMult.store.storeName, total: bankersRound((cost * storeMult.multiplier), 2) }
    })
    const sum = unadjusted.map(entry => entry.total).reduce((t1, t2) => t1 + t2);
    if (sum != cost) {
      unadjusted[0].total += (cost - sum);
    }
    return unadjusted;
  }

  getBreakdownTotal(breakdown: TotalsBreakdown) {
    return breakdown.map(entry => entry.total).reduce((t1, t2) => t1 + t2);
  }

  generateOrder(): OrderHeader {
    const header = new OrderHeader();

    const itemIDs = [1, 2, 3, 4, 5];

    header.orderID = 1;
    header.orderDate = new Date().valueOf();
    header.receivedDate = new Date().valueOf();
    header.clearedDate = null;
    header.vendorID = 1;
    header.vendorName = 'Real Fake Doors';
    header.orderStatus = 'Pending';
    header.orderShipping = 2.24;
    header.orderTax = 5.30;
    header.orderAdjustment = 1;
    header.orderTotal = 10.43;
    header.items = itemIDs.map(id => {
      const item = new OrderLineItem();
      item.orderItemID = id;
      item.orderID = id;
      item.lineNumber = id;
      item.vendorID = 1;
      item.vendorName = 'Real Fake Doors';
      item.storeID = id;
      item.storeName = `Store ${id}`;
      item.categoryID = 1;
      item.categoryName = `Category ${1}`;
      item.itemID = 1;
      item.itemName = `Item ${1}`;
      item.variationID = 1;
      item.variationDescription = `Variation ${1}`;
      item.itemCost = 1.43;
      item.itemQuantity = 3;
      item.quantityReceived = null;
      item.itemTotal = 1.43 * 3;
      item.receivedTotal = null;
      item.itemStatus = 'Pending';
      return item;
    });
    this._orders = [header];
    return header;
  }
}