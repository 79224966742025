import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider, jssPreset, StylesProvider } from '@material-ui/styles';
//import { blueGrey, lime, red, } from '@material-ui/core/colors';
import { create as createJss } from 'jss'
//import { JssProvider } from 'react-jss'
import { getTheme } from './themes/maintheme';
import CssBaseline from '@material-ui/core/CssBaseline';

//const jss = createJss({...jssPreset(), insertionPoint: document.getElementById('jss-insertion-point')});

const theme = getTheme();

const toRender = (
  <ThemeProvider theme={theme}>
    <StylesProvider injectFirst >
      <CssBaseline />
      <App />
    </StylesProvider>
  </ThemeProvider>
)

ReactDOM.render(toRender, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();