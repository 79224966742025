export class VariationData {
  constructor (source? : any){
    if (source)
      Object.assign(this, source);
  }

  variationID: number;
  itemID: number;
  variationDescription: string;

  key(){
    return this.variationID;
  }

  toString(){
    return this.variationDescription;
  }
}