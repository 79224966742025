import React from 'react';
import { useStyles} from './styles';
import { Typography, List, ListItem, Button, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle'
import { userAuthorizationKeys } from '../wireData/UserData';

export interface UserAuthorizationsProps {
  onItemChanged(value: string, checked: boolean);
  authorizations: Array<string>;
}

export function UserAuthorizations(props: UserAuthorizationsProps) {
  const classes = useStyles({});

  const checkedColor = (key: string): "inherit" | "secondary" => {
    if (props.authorizations.indexOf(key) >= 0)
      return "secondary"
    return "inherit"
  }

  const handleAuthorizationToggle = (key: string) => {
    props.onItemChanged(key, props.authorizations.indexOf(key) < 0);
  }

  return (
    <div>
      <Typography variant='subtitle1' align='center' gutterBottom>Authorizations</Typography>
      <div className={classes.listBox}>
        <List className={classes.attributeList}>
          {userAuthorizationKeys.map((authorization) => (
            <ListItem key={authorization.key}>
              <Button
                variant='contained'
                fullWidth
                color="primary"
                onClick={(e) => handleAuthorizationToggle(authorization.key)}>
                <Grid container direction="row" spacing={0} alignContent="center" alignItems="center" wrap="nowrap">
                  <Grid item xs={2}>
                    <CheckIcon color={checkedColor(authorization.key)} />
                  </Grid>
                  <Grid item xs={10}>
                    {authorization.label}
                  </Grid>
                </Grid>
              </Button>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  )
}

