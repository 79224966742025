import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { DeviceData } from '../wireData/DeviceData';
import { useStyles } from './styles';
import { DataGridList } from '../common/DataGridList';
import { loginCompany } from '../utils/WireUtils';

export interface DeviceListProps {
  devices: Array<DeviceData>;
  storeID: number;
  onSelectDevice: (device: DeviceData) => void;
  onClose: () => void;
}

export function DeviceList(props: DeviceListProps) {
  const classes = useStyles({});

  const handleSelectDevice = (device: DeviceData) => {
    props.onSelectDevice(device);
  }

  const handleClose = () => {
    props.onClose();
  }

  const handleAddDevice = () => {
    const device = new DeviceData();
    device.deviceName = "";
    device.companyID = loginCompany.companyID;
    device.storeID = props.storeID;
    props.onSelectDevice(device);
  }

  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={1}>
        {
          props.devices && props.devices.length
            ? <DataGridList dataList={props.devices} label="Select a device" onSelectItem={handleSelectDevice} />
            : <Grid item xs={12}><Typography align="center">No devices found</Typography></Grid>
        }
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <Button
              variant='contained'
              fullWidth
              className={classes.button}
              onClick={handleClose}
            >
              Close
          </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              fullWidth
              disabled={props.storeID == 0}
              className={classes.button}
              onClick={handleAddDevice}
            >
              Add Device
          </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
