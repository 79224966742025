import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useUpdateRegisters, useRemoveRegisters } from './registerHandler';
import { LinearLoading } from '../common/LoadingComponent';
import { Store } from '../store/Store';
import { RegisterData } from '../wireData/RegisterData';
import { EditRegister } from './EditRegister';

interface StatusDialogProps {
  message: string;
  open: boolean;
}

function StatusDialog(props: StatusDialogProps) {

  return (
    <Dialog open={props.open} fullWidth maxWidth="xs">
      <DialogContent>
        <LinearLoading message={props.message} />
      </DialogContent>
    </Dialog>
  )
}

interface ErrorDialogProps {
  error: any;
  open: boolean;
  onClose: () => void;
}

function ErrorDialog(props: ErrorDialogProps) {

  return (
    <Dialog open={props.open && Boolean(props.error)}>
      <DialogTitle>
        Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.error ? props.error.message || props.error : "Unknown Error"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export interface EditRegisterContainerProps {
  register: RegisterData;
  currentStore: Store;
  onUpdate: (register: RegisterData) => void;
  onRemove: (register: RegisterData) => void;
  onCancel: () => void;
}

export function EditRegisterContainer(props: EditRegisterContainerProps) {

  const [registerToUpdate, setRegisterToUpdate] = useState<null | RegisterData>(null);
  const [showErrors, setShowErrors] = useState(false);
  const [registerToRemove, setRegisterToRemove] = useState<null | RegisterData>(null);

  const { error: registerUpdateError, updating, register: updatedRegister } = useUpdateRegisters(registerToUpdate);
  const { error: registerRemoveError, removing, register: removedRegister } = useRemoveRegisters(registerToRemove);

  useEffect(() => {
    if (updatedRegister && !updating) {
      props.onUpdate(updatedRegister);
      setRegisterToUpdate(null);
    }
  }, [updatedRegister, updating])

  useEffect(() => {
    if (removedRegister && !removing) {
      props.onRemove(registerToRemove);
      setRegisterToRemove(null);
    }
  }, [removedRegister, removing])

  const handleUpdate = async (register: RegisterData) => {
    setRegisterToUpdate(register);
    setShowErrors(true);
  }

  const handleCancel = () => {
    props.onCancel();
  }

  const handleRemove = async () => {
    setRegisterToRemove(props.register);
    setShowErrors(true);
  }

  const handleCloseErrorDialog = () => {
    setShowErrors(false);
  }

  return (
    <div>
      <StatusDialog open={updating || removing} message="Updating register" />
      <ErrorDialog open={showErrors} error={registerUpdateError || registerRemoveError} onClose={handleCloseErrorDialog} />
      <EditRegister
        store={props.currentStore}
        register={props.register}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
        onCancel={handleCancel} 
      />
    </div>
  )
}
