import React from 'react';
import { Typography, CircularProgress, Theme, LinearProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
//import { NotificationEventAvailable } from 'material-ui/svg-icons';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
   alignItems: 'center',
  },
  rootRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
  },
  message: {
    flex: 0,
  },
  progress: {
    flex: 0,
  },
  linearMessage: {
    flex: 1,
    padding:"15px 0 0 20px",
  },
  linearProgress: {
    flex: 1,
    padding:"15px 0 0 20px",
  },
}));

interface LoadingProps {
  message?: string;
  noLabel?: boolean;
}

export function Loading(props: LoadingProps) {

  const classes = useStyles({});
  const message = props.message ? props.message : "Loading";

  return (
    <div className={classes.root} >
      {props.noLabel
        ? null
        : <Typography variant='h5' className={classes.message}>{message}</Typography>
      }
      <CircularProgress className={classes.progress} />
    </div >
  )
}

export function LinearLoading(props: LoadingProps) {

  const classes = useStyles({});
  const message = props.message ? props.message : "Loading";

  return (
    <div className={classes.rootRow} >
      {props.noLabel
        ? null
        : <Typography variant='body1' noWrap display="inline" className={classes.linearMessage}>{message}</Typography>
      }
      <LinearProgress className={classes.linearProgress} />
    </div>
  )
}