import { StoreReceiveTransferDataTable, CategoryItems } from "./StoreReceiveTransfersData";
import { InventoryTransferQuantityAndStatusUpdate } from '../wireData/InventoryTransferData';

export class ReceiveTransferDataManager {

  private _receiveTransferData: StoreReceiveTransferDataTable;

  set ReceiveTransferData(data: StoreReceiveTransferDataTable) {
    this._receiveTransferData = data;
  }

  get transfers() {
    return this._receiveTransferData.transfers;
  }

  get categories() {
    if (!this._receiveTransferData.categories)
      this.buildCategoryItems();
    return this._receiveTransferData.categories;
  }

  private buildCategoryItems() {
    const data = this._receiveTransferData;
    data.categories = [];
    data.transfers.forEach((item) => {
      var category: CategoryItems = data.categories.find((category) => category.categoryName == item.categoryName);
      if (category == null)
        data.categories.push(category = new CategoryItems(item.categoryName));
      category.items.push(item);
    })
  }

  public updateStatus(receiveTransferItem: InventoryTransferQuantityAndStatusUpdate) {
    const item = this.transfers.find((item) => item.inventoryTransferID == receiveTransferItem.inventoryTransferID);
    if (item)
      item.status = receiveTransferItem.status;
  }

  public updateCount(receiveTransferItem: InventoryTransferQuantityAndStatusUpdate) {
    const item = this.transfers.find((item) => item.inventoryTransferID == receiveTransferItem.inventoryTransferID);
    if (item)
      item.quantityReceived = receiveTransferItem.quantity;
  }
}
