import { getJSONHeaders, getitemcostURL, updateitemcostURL, updatepreorderitemcostURL, activeStructuredCatalogURL, allStructuredCatalogURL } from '../utils/WireUtils';
import { ItemCostData } from '../wireData/ItemCostData';
import { CategoryData } from '../wireData/CategoryData';
import { ItemData } from '../wireData/ItemData';
import { VariationData } from '../wireData/VariationData';
import { StructuredCatalogManager } from '../catalog/StructuredCatalogManager';
import { BaseState, BaseAction } from '../common/BaseHandler';

export const ItemCostStoreName = 'itemCostStore';

export class ItemCostState extends BaseState {
  currentCategory: CategoryData;
  currentItem: ItemData;
  currentVariation: VariationData;
  value: number;
  clearOnFirst: boolean;
  updatePreordering: boolean;
  error: any;
  loading: boolean;
  _manager: StructuredCatalogManager;
  itemCostError: any;
  itemCostLoading: boolean;
  itemCostSuccess: boolean;
  itemCostUpdateSuccess: boolean;
  itemCost: ItemCostData;
  itemCostUpdating: boolean;
  itemCostUpdateError: any;
  _actions: ItemCostActions;
}

export class ItemCostActions extends BaseAction<ItemCostState> {

  get initialState(): ItemCostState {
    return {
      error: null,
      loading: false,
      success: false,
      currentCategory: null,
      currentItem: null,
      currentVariation: null,
      value: 0,
      clearOnFirst: true,
      updatePreordering: false,
      itemCostError: null,
      itemCostLoading: false,
      itemCostSuccess: false,
      itemCostUpdateSuccess: false,
      itemCost: null,
      itemCostUpdating: false,
      itemCostUpdateError: null,
      _manager: null,
      _actions: this,
    }
  }

  private mapCatalog = (body: any): Partial<ItemCostState> => {
    return {
      _manager: new StructuredCatalogManager(body)
    }
  }

  getCatalog = async () => {
    return this.getData(allStructuredCatalogURL, this.mapCatalog);
  }

  private mapItemCost = (body: any): Partial<ItemCostState> => {
    const itemCost: ItemCostData = body;

    return {
      clearOnFirst: true,
      value: itemCost.cost,
      itemCost: itemCost
    }
  }

  updateCatalogEntry = async () => {
    const catalogManager = this.state._manager;

    this.updateState({
      currentCategory: catalogManager.category ? new CategoryData(catalogManager.category) : null,
      currentItem: catalogManager.item ? new ItemData(catalogManager.item) : null,
      currentVariation: catalogManager.variant ? new VariationData(catalogManager.variant) : null,
      clearOnFirst: true, itemCostError: null, itemCostLoading: true
    });

    const categoryID = catalogManager.category ? catalogManager.category.categoryID : 0;
    const itemID = catalogManager.item ? catalogManager.item.itemID : 0;
    const variationID = catalogManager.variant ? catalogManager.variant.variationID : 0;

    return this.getData(`${getitemcostURL}/${categoryID}/${itemID}/${variationID}`, this.mapItemCost,
      { error: "itemCostError", processing: "itemCostLoading", success: "itemCostSuccess" })
  }

  private mapItemCostUpdate = (body: any): Partial<ItemCostState> => {
    const itemCost: ItemCostData = body;
    return { value: itemCost.cost, itemCost: itemCost };
  }

  updateCost = async (cost: number) => {
    const { itemCost, updatePreordering } = this._store.getState();
    itemCost.cost = cost;
    return this.postData(updatePreordering ? updatepreorderitemcostURL : updateitemcostURL,
      itemCost, this.mapItemCostUpdate)
  }
}

export function resetItemCostStore() {
  BaseAction.getAction(ItemCostStoreName, ItemCostActions).reset();
}

export const useItemCostStore = (): [ItemCostState, StructuredCatalogManager, ItemCostActions] => {
  const [state, actions] = BaseAction.getAction(ItemCostStoreName, ItemCostActions).useStore();
  return [state, state._manager, actions];
}
