import React, { useState, ReactElement } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListIcon from "@material-ui/icons/List";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import BarChartIcon from "@material-ui/icons/BarChart";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SwapHorizontalIcon from "@material-ui/icons/SwapHoriz";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import HistoryIcon from "@material-ui/icons/History";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DownloadFileIcon from "@material-ui/icons/CloudDownload";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import Divider from "@material-ui/core/Divider";
import { Theme, Tooltip, Collapse } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";
import BusinessIcon from "@material-ui/icons/Business";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Store from "@material-ui/icons/Store";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { loginUser, loginAuthorizations } from "../utils/WireUtils";
import RegisterIcon from "@material-ui/icons/LocalAtm";
import DeviceIcon from '@material-ui/icons/Devices'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import CheckCosts from '@material-ui/icons/PlaylistAddCheck';
import Timer from '@material-ui/icons/Timer';
import Receipt from '@material-ui/icons/Receipt';
import Desktop from '@material-ui/icons/DesktopWindowsOutlined';

export enum DisplayState {
  EMPTY,
  DOWNLOAD_ORDER_SHEET,
  GENERATE_ORDERS_FROM_PREORDERS,
  DOWNLOAD_DATABASE_BACKUP,
  SALES_UPLOAD,
  CATALOG_UPLOAD,
  CATALOG,
  INVENTORY_COUNT_TABLE,
  TAKE_INVENTORY,
  INVENTORY_COUNTS,
  MANAGE_USERS,
  ORDERING,
  PRICING,
  RECEIVING_ORDERS,
  RECONCILE_ORDERS,
  ORDER_HISTORY,
  SHIP_TO_STORES,
  RECEIVE_AT_STORES,
  PULL_INVENTORY,
  RECEIVE_FROM_STORES,
  TRANSFER_TO_STORES,
  RECEIVE_TRANSFERS_AT_STORES,
  POINT_OF_SALE,
  MANAGE_COMPANIES,
  MANAGE_REGISTERS,
  MANAGE_DEVICES,
  ITEM_COSTS,
  INVENTORY_COSTS,
  TRANSFER_HISTORY,
  CUSTOMERS,
  TRANSACTIONS,
  PENDING_TRANSACTIONS,
  SYSTEM_DASHBOARD,
}

interface MainListItemsProps {
  onSelect: (displayState: DisplayState) => void;
  disableTooltips: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  tooltip: {
    ...theme.typography.body1,
    background: theme.palette.background.default
  },
  popper: {
    opacity: 1
  },
  flipIcon: {
    transform: "scaleX(-1)"
  },
  nestedList: {
    paddingLeft: theme.spacing(4)
  }
}));

export function MainListItems(props: MainListItemsProps) {
  const classes = useStyles({});
  const [subMenu, setSubMenu] = useState(null);

  const handleSelect = (displayState: DisplayState) => (event) => {
    props.onSelect(displayState);
  }

  interface TipProps {
    content: string;
    children?: any;
  }

  const Tip = (tipProps: TipProps) => {
    return (
      <Tooltip
        disableTouchListener
        disableFocusListener
        disableHoverListener={props.disableTooltips}
        placement="right"
        classes={{ tooltip: classes.tooltip, popper: classes.popper }}
        title={tipProps.content}
        TransitionProps={{ timeout: 100 }}
      >
        {tipProps.children}
      </Tooltip>
    );
  };

  const showSubMenu = (menu: string) => event => {
    event.stopPropagation();
    if (subMenu == menu)
      setSubMenu(null);
    else setSubMenu(menu);
  };

  const isSubMenuIsActive = (menu: string) => {
    return subMenu == menu;
  };

  const isAuthorized = (key: string): boolean => {
    if (key && key.length)
      return loginAuthorizations.indexOf(key) >= 0;
    return true;
  }

  interface ListSectionProps {
    sectionName: string;
    icon: ReactElement<any>;
    authorization?: string;
    sectionItems: Array<SectionItem>;
  }

  const ListSection = (props: ListSectionProps) => {
    if (isAuthorized(props.authorization))
      return (
        <>
          <Tip content={props.sectionName}>
            <ListItem button onClick={showSubMenu(props.sectionName)}>
              <ListItemIcon>
                {props.icon}
              </ListItemIcon>
              <ListItemText primary={props.sectionName} />
              {isSubMenuIsActive(props.sectionName) ? (
                <ExpandLess color="primary" />
              ) : (
                  <ExpandMore color="primary" />
                )}
            </ListItem>
          </Tip>
          <CollapsableSection sectionName={props.sectionName} sectionItems={props.sectionItems} />
          {/* <Divider /> */}
        </>
      )
    return null;
  }

  class SectionItem {
    listItemText: string;
    icon?: ReactElement<any>;
    authorization?: string;
    displayState: DisplayState;
  }

  interface CollapsableSectionProps {
    sectionName: string;
    sectionItems: Array<SectionItem>;
  }

  const CollapsableSection = (props: CollapsableSectionProps) => {
    return (
      <Collapse in={isSubMenuIsActive(props.sectionName)} timeout="auto">
        {
          props.sectionItems.map((sectionItem) => {
            if (isAuthorized(sectionItem.authorization))
              return (
                <Tip key={sectionItem.listItemText} content={sectionItem.listItemText}>
                  <ListItem
                    button
                    className={classes.nestedList}
                    onClick={handleSelect(sectionItem.displayState)}
                  >
                    <ListItemIcon>
                      {sectionItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={sectionItem.listItemText} />
                  </ListItem>
                </Tip>
              )
            return null;
          })
        }
      </Collapse>
    )
  }

  const preOrderingItems: Array<SectionItem> = [
    {
      listItemText: "Pre-Ordering",
      icon: <AllInboxIcon />,
      displayState: DisplayState.ORDERING,
      authorization: "",
    },
    {
      listItemText: "Pre-Order Pricing",
      icon: <AttachMoney />,
      displayState: DisplayState.PRICING,
      authorization: "",
    },
    {
      listItemText: "Download Pre-Order Sheet",
      icon: <DownloadFileIcon />,
      displayState: DisplayState.DOWNLOAD_ORDER_SHEET,
      authorization: "",
    },
    {
      listItemText: "Generate Purchase Orders",
      icon: <AssignmentReturnIcon />,
      displayState: DisplayState.GENERATE_ORDERS_FROM_PREORDERS,
      authorization: "",
    },
  ];

  const stockroomItems: Array<SectionItem> = [
    {
      listItemText: "Receive Purchases",
      icon: <ReceiptIcon />,
      displayState: DisplayState.RECEIVING_ORDERS,
      authorization: "",
    },
    {
      listItemText: "Receive From Stores",
      icon: <SwapHorizontalIcon />,
      displayState: DisplayState.RECEIVE_FROM_STORES,
      authorization: "",
    },
    {
      listItemText: "Send Store Transfers",
      icon: <SwapHorizontalIcon />,
      displayState: DisplayState.TRANSFER_TO_STORES,
      authorization: "",
    },
    {
      listItemText: "Ship To Stores",
      icon: <LocalShippingIcon className={classes.flipIcon} />,
      displayState: DisplayState.SHIP_TO_STORES,
      authorization: "",
    },
  ];

  const accountingItems: Array<SectionItem> = [
    {
      listItemText: "Reconcile Orders",
      icon: <AssignmentTurnedInIcon />,
      displayState: DisplayState.RECONCILE_ORDERS,
      authorization: "",
    },
    {
      listItemText: "Order History",
      icon: <HistoryIcon />,
      displayState: DisplayState.ORDER_HISTORY,
      authorization: "",
    },
    {
      listItemText: "Check Item Costs",
      icon: <CheckCosts />,
      displayState: DisplayState.ITEM_COSTS,
      authorization: "",
    }
  ];

  const retailItems: Array<SectionItem> = [
    {
      listItemText: "Receive New Inventory",
      icon: <LocalShippingIcon />,
      displayState: DisplayState.RECEIVE_AT_STORES,
      authorization: "storeReceiving",
    },
    {
      listItemText: "Pull Transfers",
      icon: <ShoppingBasket />,
      displayState: DisplayState.PULL_INVENTORY,
      authorization: "inventoryTransfer",
    },
    {
      listItemText: "Receive Transfers",
      icon: <SwapHorizontalIcon />,
      displayState: DisplayState.RECEIVE_TRANSFERS_AT_STORES,
      authorization: "inventoryTransfer",
    },
    {
      listItemText: "Take Inventory",
      icon: <AssignmentIcon />,
      displayState: DisplayState.TAKE_INVENTORY,
      authorization: "inventory",
    },
    {
      listItemText: "Inventory Counts",
      icon: <AssignmentIcon />,
      displayState: DisplayState.INVENTORY_COUNTS,
      authorization: "inventory",
    },
    {
      listItemText: "Point of Sale",
      icon: <AttachMoney />,
      displayState: DisplayState.POINT_OF_SALE,
      authorization: "POS",
    }
  ];

  const administrationItems: Array<SectionItem> = [
    {
      listItemText: "Catalog",
      icon: <ListIcon />,
      displayState: DisplayState.CATALOG,
      authorization: "admin"
    },
    {
      listItemText: "Manage Users",
      icon: <PersonAddIcon />,
      displayState: DisplayState.MANAGE_USERS,
      authorization: "admin"
    },
    {
      listItemText: "Manage Companies",
      icon: <BusinessIcon />,
      displayState: DisplayState.MANAGE_COMPANIES,
      authorization: "company"
    },
    {
      listItemText: "Manage Registers",
      icon: <RegisterIcon />,
      displayState: DisplayState.MANAGE_REGISTERS,
      authorization: "register"
    },
    {
      listItemText: "Manage Devices",
      icon: <DeviceIcon />,
      displayState: DisplayState.MANAGE_DEVICES,
      authorization: "device"
    },
    {
      listItemText: "Database Backup",
      icon: <DownloadFileIcon />,
      displayState: DisplayState.DOWNLOAD_DATABASE_BACKUP,
      authorization: "admin"
    },
    {
      listItemText: "Upload Sales",
      icon: <CloudUploadIcon />,
      displayState: DisplayState.SALES_UPLOAD,
      authorization: "admin"
    },
    {
      listItemText: "Upload Catalog",
      icon: <CloudUploadIcon />,
      displayState: DisplayState.CATALOG_UPLOAD,
      authorization: "admin"
    },
  ];

  const reportItems: Array<SectionItem> = [
    {
      listItemText: "Inventory Counts",
      icon: <BarChartIcon />,
      displayState: DisplayState.INVENTORY_COUNT_TABLE,
      authorization: "reports"
    },
    {
      listItemText: "Inventory Costs",
      icon: <BarChartIcon />,
      displayState: DisplayState.INVENTORY_COSTS,
      authorization: "reports"
    },
    {
      listItemText: "Transfer History",
      icon: <BarChartIcon />,
      displayState: DisplayState.TRANSFER_HISTORY,
      authorization: "reports"
    },
  ];

  const mendelItems: Array<SectionItem> = [
    {
      listItemText: "Customers",
      icon: <SupervisorAccount />,
      displayState: DisplayState.CUSTOMERS,
      authorization: "customer"
    },
    {
      listItemText: "Transactions",
      icon: <AttachMoney />,
      displayState: DisplayState.TRANSACTIONS,
      authorization: "transaction"
    },
    {
      listItemText: "Pending Transactions",
      icon: <Timer />,
      displayState: DisplayState.PENDING_TRANSACTIONS,
      authorization: "transaction"
    },
  ];
  const systemItems: Array<SectionItem> = [
    {
      listItemText: "Dashboard",
      icon: <Desktop />,
      displayState: DisplayState.SYSTEM_DASHBOARD,
      authorization: "system"
    },
  ];

  return (
    <div>
      <ListSection
        sectionName="Ordering"
        icon={<AddShoppingCart />}
        sectionItems={preOrderingItems}
        authorization="order"
      />
      <ListSection
        sectionName="Stockroom"
        icon={<BusinessIcon />}
        sectionItems={stockroomItems}
        authorization="stockroom"
      />
      <ListSection
        sectionName="Accounting"
        icon={<AttachMoney />}
        sectionItems={accountingItems}
        authorization="purchaseOrder"
      />
      <ListSection
        sectionName="Retail"
        icon={<Store />}
        sectionItems={retailItems}
        authorization=""
      />
      <ListSection
        sectionName="Administration"
        icon={<SupervisorAccount />}
        sectionItems={administrationItems}
        authorization="admin"
      />
      <ListSection
        sectionName="Reports"
        icon={<BarChartIcon />}
        sectionItems={reportItems}
        authorization="reports"
      />
      <ListSection
        sectionName="Mendel"
        icon={<Receipt />}
        sectionItems={mendelItems}
        authorization="mendel"
      />
      <ListSection
        sectionName="System"
        icon={<Desktop />}
        sectionItems={systemItems}
        authorization="system"
      />
    </div>
  );
}
