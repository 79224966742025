import { Store } from "../store/Store";

export class OrderBook {
  orders: Array<OrderHeader>;
  stores: Array<Store>;
}

export class OrderHeader {
  orderID: number;
  orderDate: number;
  receivedDate: number;
  clearedDate: number;
  vendorID: number;
  vendorName: string;
  orderStatus: string;
  orderShipping: number;
  orderTax: number;
  orderAdjustment: number;
  orderDiscount: number; // ADDED
  orderTotal: number;
  items: Array<OrderLineItem>;
  invoiceNumber: string;
  comment: string;
}

export class OrderLineItem {
  orderItemID: number;
  orderID: number;
  lineNumber: number;
  vendorID: number;
  vendorName: string;
  storeID: number;
  storeName: string;
  categoryID: number;
  categoryName: string;
  itemID: number;
  itemName: string;
  variationID: number;
  variationDescription: string;
  itemCost: number;
  itemQuantity: number; //quantity for store
  quantityReceived: number; //changable confirmation
  itemTotal: number;
  receivedTotal: number;
  itemStatus: string;
  inventoryClass: string;
}

export class OrderLineItemReceivingUpdate {
  orderItemID: number;
  receivedQuantity: number;
  itemStatus: string;
}

export class OrderReconcilingUpdate { //NEW
  orderID: number;
  orderStatus: string;
  orderShipping: number;
  orderAdjustment: number;
  orderDiscount: number;
  comment: string;
}

export class OrderLineItemReconcilingUpdate {
  
}

export class ItemCostUpdate {
  orderID: number;
  categoryID: number;
  itemID: number;
  variationID: number;
  itemCost: number;
  totalCost: number;
}

export type TotalsBreakdown = Array<{ store: string, total: number }>;