import React from 'react';
import { CompanyData } from "../wireData/CompanyData";
import { useStyles } from './styles';
import { Grid, Button } from '@material-ui/core';
import { DataGridList } from '../common/DataGridList';
import { Error } from '../common/ErrorComponent';

export interface CompanyListProps {
  companies: Array<CompanyData>;
  onSelectCompany: (company: CompanyData) => void;
  onClose: () => void;
}

export function CompanyList(props: CompanyListProps) {
  const classes = useStyles({});

  const handleSelectCompany = (company: CompanyData) => {
    props.onSelectCompany(company);
  }

  const handleClose = () => {
    props.onClose();
  }

  const handleAddCompany = () => {
    const company = new CompanyData({
      companyName: "",
      companyEmail: "",
      companyPhoneNumber: "",
      creditPercent: "00.00",
      levelDivision: "00.00",
      parentPercent: "00.00"
          
    });
    props.onSelectCompany(company);
  }

  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={1}>
        {
          props.companies && props.companies.length
            ? <DataGridList dataList={props.companies} label="Select a company" onSelectItem={handleSelectCompany} />
            : <Grid item xs={12}><Error message="No companies found" /></Grid>
        }
        <Grid item xs={6}>
          <Button
            variant='contained'
            fullWidth
            className={classes.button}
            onClick={handleClose}
          >
            Close
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant='contained'
            fullWidth
            className={classes.button}
            onClick={handleAddCompany}
          >
            Add Company
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

