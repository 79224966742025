import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme} from "@material-ui/core";

export const footerHeight = 32;
export const useStyles = makeStyles((theme: Theme) => createStyles({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary[800],
    height: footerHeight,
    flexBasis: 'auto',
    flex: '0 0',
    width: '100%',
  },
  footerItem:{
    marginLeft: 1,
    marginRight: 1,
    [theme.breakpoints.up(600)]: {
      marginLeft: 5,
      marginRight: 5,
      },
  },
  footerSelect:{
    font: "caption",
  },
}));
