import { activeCatalogURL, allCatalogURL, loginToken, activeInventoryCatalogURL, allInventoryCatalogURL, activeToggleURL, activeStructuredCatalogURL, allStructuredCatalogURL, setInventoryClassURL, getJSONHeaders, categoriesURL, itemsURL, variationsURL } from '../utils/WireUtils';
import { FlatCatalogEntry } from './Catalog';
import { StructuredCatalogManager } from './StructuredCatalogManager';
import { useEffect, useState } from 'react';
import { FlatCatalogManager } from './FlatCatalogManager';
import { CategoryData } from '../wireData/CategoryData';
import { ItemData } from '../wireData/ItemData';
import { VariationData } from '../wireData/VariationData';

export async function getCatalog(active: boolean, setResult: (status: string, catalog: FlatCatalogEntry[]) => void): Promise<any> {
  setResult("Fetching", []);
  return fetch(active ? activeStructuredCatalogURL : allStructuredCatalogURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginToken}`
    },
  }).then(async function (response) {
    if (response.status >= 200 && response.status < 300) {
      const body = await response.json();
      //console.log(body);
      setResult("Success", body);
    } else {
      const body = await response.json();
      setResult(body.message, []);
    }
  }).catch(function (reason) {
    setResult(JSON.stringify(reason), []);
  })
}

export function changeActive(level: string, id: number, active: boolean) {
  const url = `${activeToggleURL}/${level}/${id}/${active}`
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginToken}`
    },
  }).then(async function (response) {
    if (response.status >= 200 && response.status < 300) {
      const body = await response.json();
      //console.log(body);
    } else {
      const body = await response.json();
    }
  }).catch(function (reason) {

  })
}

export const setClass = (categoryID: number, inventoryClass: string): Promise<any> => {
  const url = `${setInventoryClassURL}/${categoryID}/${inventoryClass}`;
  return fetch(url, {
    method: 'POST',
    headers: getJSONHeaders()
  }).then(response => {
    if (response.ok) {
      return response.json().then(confirm => {
        return confirm;
      }).catch((e) => {
        return Promise.reject({ message: "Invalid response body" })
      });
    } else {
      return Promise.reject({ message: "Response not ok" })
    }
  }).catch((e) => {
    return Promise.reject({ message: "Communication failure" })
  });
}

export const useStructuredCatalogManager = (active: boolean): {
  error: any,
  loading: boolean,
  catalogManager: StructuredCatalogManager
} => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [catalogManager, setCatalogManager] = useState<StructuredCatalogManager>(null);
  //  const [useActive, sertUseActive] = useState(active);

  useEffect(() => {
    console.log(`Loading ${active ? "Active" : "Entire"} Catalog`);
    setLoading(true);
    fetch(active ? activeStructuredCatalogURL : allStructuredCatalogURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginToken}`
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          response.json()
            .then((catalog) => {
              const manager = new StructuredCatalogManager();
              manager.catalog = catalog;
              setCatalogManager(manager);
            })
            .catch((e) => {
              setError(e);
            })
        } else {
          setError(new Error(response.statusText));
        }
      })
      .catch((e) => {
        setError(e);
      })
  }, [active]);

  return { error, loading, catalogManager };
}

export const useFlatCatalogManager = (): {
  error: any,
  loading: boolean,
  catalogManager: FlatCatalogManager
} => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [catalogManager, setCatalogManager] = useState<FlatCatalogManager>(null);

  useEffect(() => {
    if (!!catalogManager) {
      return;
    }
    // console.log("Loading Catalog");
    setLoading(true);
    fetch(allCatalogURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginToken}`
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          response.json()
            .then((catalog) => {
              const manager = new FlatCatalogManager();
              manager.catalog = catalog;
              setCatalogManager(manager);
            })
            .catch((e) => {
              setError(e);
            })
        } else {
          setError(new Error(response.statusText));
        }
      })
      .catch((e) => {
        setError(e);
      })
  }, []);

  return { error, loading, catalogManager };

}

export const useCategories = (active: boolean): {
  error: any,
  loading: boolean,
  categories: Array<CategoryData>
} => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<Array<CategoryData>>([]);

  useEffect(() => {
    // console.log("Loading Categories");
    setLoading(true);
    fetch(`${categoriesURL}/${active}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginToken}`
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          response.json()
            .then((categories) => {
              setCategories(categories.map((category) => new CategoryData(category)));
            })
            .catch((e) => {
              setError(e);
            })
        } else {
          setError(new Error(response.statusText));
        }
      })
      .catch((e) => {
        setError(e);
      })
  }, [active]);

  return { error, loading, categories };

}

export const useItems = (categoryID: number, active: boolean): {
  error: any,
  loading: boolean,
  items: Array<ItemData>
} => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Array<ItemData>>(null);

  useEffect(() => {
    if (categoryID) {
      // console.log("Loading Items");
      setLoading(true);
      fetch(`${itemsURL}/${categoryID}/${active}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${loginToken}`
        },
      })
        .then((response) => {
          setLoading(false);
          if (response.ok) {
            response.json()
              .then((items) => {
                setItems(items.map((item) => new ItemData(item)));
              })
              .catch((e) => {
                setError(e);
              })
          } else {
            setError(new Error(response.statusText));
          }
        })
        .catch((e) => {
          setError(e);
        })
    }
    else
      setItems(null);
  }, [categoryID, active]);

  return { error, loading, items };

}

export const useVariations = (itemID: number, active: boolean): {
  error: any,
  loading: boolean,
  variations: Array<VariationData>
} => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [variations, setVariations] = useState<Array<VariationData>>(null);

  useEffect(() => {
    // console.log("Loading Variations");
    setLoading(true);
    fetch(`${variationsURL}/${itemID}/${active}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginToken}`
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          response.json()
            .then((variations) => {
              setVariations(variations.map((variation) => new VariationData(variation)));
            })
            .catch((e) => {
              setError(e);
            })
        } else {
          setError(new Error(response.statusText));
        }
      })
      .catch((e) => {
        setError(e);
      })
  }, [itemID, active]);

  return { error, loading, variations };

}
