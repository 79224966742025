import React, { useState, useMemo, useEffect, useLayoutEffect } from "react";
import { Typography, Paper, Tabs, Tab, TableRow, TableCell, TableHead, Table, TableBody, Fab } from "@material-ui/core";
import { useDatesAndStoreCounts } from "./CountHandler";
import { Error } from '../common/ErrorComponent';
import { Loading } from "../common/LoadingComponent";
import { useStyles } from './CountStyles'
import { CategoryCountData, CategoryCountDataItem } from "./CountManager";
import { localISODateString } from "../utils/Convert";
import classNames from 'classnames';
import ExitIcon from '@material-ui/icons/ExitToApp';

// const countsManager = CountManager.Instance;

export interface CountViewTableProps {
  // stores: string;
  // date: Date;
  onClose: () => void;
}

export function CountViewTable(props: CountViewTableProps) {
  const classes = useStyles({});
  const [{ error, loading, success, stores, date, category, scrolling }, manager, actions] = useDatesAndStoreCounts();

  useEffect(() => {
    if (!loading && !success)
      actions.getDataForStores(stores, localISODateString(date));
  }, [loading, success]);

  const scrollingRef = React.useRef(null);

  useLayoutEffect(() => {
    if (scrollingRef.current)
      scrollingRef.current.scrollTop = scrolling.top;
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrolling.top = event.currentTarget.scrollTop;
  }

  interface CategoryTabsProps {
    categories: Array<string>;
    category: string;
    onTabClick: (value: string) => void;
  }

  function CategoryTabs(props: CategoryTabsProps) {
    const handleChange = (event, value) => {
      props.onTabClick(props.categories[value]);
    }

    return (
      <Paper square>
        <Tabs variant='scrollable' value={props.categories.indexOf(props.category)} onChange={handleChange} scrollButtons="auto">
          {props.categories.map(cat => <Tab key={cat} label={<Typography variant='caption'>{cat}</Typography>} />)}
        </Tabs>
      </Paper>
    )
  }

  interface CountsTableHeadProps {
    columns: Array<string>;
  }

  function CountsTableHead(props: CountsTableHeadProps) {
    return (
      <TableHead>
        <TableRow>
          {props.columns.map(column =>
            <TableCell className={classNames(classes.tableCell, classes.headerCell)} key={column}>{column}</TableCell>
          )}
        </TableRow>
      </TableHead>)
  }

  interface CountsTableRowProps {
    categoryItem: CategoryCountDataItem;
  }

  function CountsTableRow(props: CountsTableRowProps) {
    const { itemName, variationDescription, countsByStore } = { ...props.categoryItem }
    return (
      <TableRow>
        <TableCell key={1} className={classes.tableCell}>{itemName}</TableCell>
        <TableCell key={2} className={classes.tableCell}>{variationDescription}</TableCell>
        {countsByStore.map((count, index) => (
          <TableCell key={index + 3} className={classNames(classes.tableCell, typeof count == "string" ? classes.noCountCell : null)}>{count}</TableCell>
        ))}
        <TableCell key={100} className={classes.tableCell}>{countsByStore.reduce((subTotal, count) => typeof count == "string" ? subTotal : subTotal + count, 0)}</TableCell>
      </TableRow>
    )
  }

  interface CountsTableBodyProps {
    categoryItems: Array<CategoryCountDataItem>;
  }

  function CountsBody(props: CountsTableBodyProps) {
    var key = 0;
    return (
      <TableBody>
        {props.categoryItems.map((categoryItem) =>
          <CountsTableRow key={key++} categoryItem={categoryItem} />
        )}
      </TableBody>
    )
  }

  interface CountsTableProps {
    categoryData: CategoryCountData;
  }

  function CountsTable(props: CountsTableProps) {
    return (
      <Table>
        <CountsTableHead columns={manager.columnHeadings} />
        <CountsBody categoryItems={props.categoryData.items} />
      </Table>
    )
  }

  function ShowTable() {
    const categoryData = useMemo(() => manager.getCategoryData(category), [category]);

    const handleTabClick = (categoryName: string) => {
      actions.setCategory(categoryName);
    }

    return (
      <div className={classes.root}>
        <CategoryTabs categories={manager.categories} category={category} onTabClick={handleTabClick} />
        <div className={classes.tableRoot} ref={scrollingRef} onScroll={handleScroll}>
          <CountsTable categoryData={categoryData} />
        </div>
      </div>
    )
  }

  const handleListClose = () => {
    actions.setStores(null);
  }

  if (error) return <Error message={error.message} />
  if (loading) return <Loading />
  if (success) return (
    <div className={classes.root}>
      <ShowTable />
      <Fab onClick={handleListClose} className={classes.fab} ><ExitIcon /></Fab>
    </div>
  )
  return null;
}