import React, { useState } from 'react';
import { setGlobalLoginToken, setGlobalLoginUser } from './utils/WireUtils';
import { SignIn } from './login/LoginComponent';
import { Dashboard } from './dashboard/DashboardComponent';
import { UserName, Password } from './defaultCredentials';
import { TestComponent } from './common/TestComponent';
import { StoreDashboardContainer } from './dashboard/StoreDashboard';
import { UserData } from './wireData/UserData';
import { createStore, useStore } from 'react-hookstore';

createStore('loginState', {
  status: false,
  token: "",
  message: "Not Logged In",
  dashboard: ""
});

function App() {
  const userName = UserName;
  const password = Password;
  const [loginState, setLoginState] = useStore('loginState');
  const useForDebug = false;

  //  console.log('Called APP');

  const setLoginStatusCallback = async (status: boolean, token: string, user: UserData, message: string) => {
    setGlobalLoginToken(token);
    setGlobalLoginUser(user);
    setLoginState({
      status: status,
      token: token,
      message: message,
      dashboard: user ? user.userConfig : ""
    })
  }

  function handleClose() {
    setGlobalLoginToken("");
    setGlobalLoginUser(null);
    setLoginState({ dashboard: "", token: "", message: "Not Logged In", status: false });
  }

  if (useForDebug) {
    return (
      <TestComponent setLoginStatusCallback={setLoginStatusCallback}
        defaultUserName={userName}
        defaultPassword={password}
        loginStatus={loginState.status} />
    )
  }

  function getRender() {
    //    console.log(loginState);
    switch (loginState.dashboard) {
      case "all":
        return <Dashboard onExit={handleClose} />
      case "inventory":
        return <StoreDashboardContainer onClose={handleClose} />
      default:
        return <SignIn setLoginStatusCallback={setLoginStatusCallback}
          defaultUserName={userName}
          defaultPassword={password}
          message={loginState.message} />
    }
  }

  return (
    <div>
      {getRender()}
    </div>
  )
}

export default App;
