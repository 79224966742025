import React, { useState } from 'react';
import { useStyles } from './styles';
import { TableRow, TableCell, TableHead, TextField, IconButton, Grid, InputAdornment } from '@material-ui/core';
import SortUpDown from '@material-ui/icons/UnfoldMore';
import SortASC from '@material-ui/icons/ExpandMore';
import SortDESC from '@material-ui/icons/ExpandLess';

export class CustomerTableHeaderColumn {
  field: string;
  header: string;
  isSearchable: boolean;
}

export interface CustomerTableHeaderProps {
  headers: Array<CustomerTableHeaderColumn>;
  filters: any;
  onFilterUpdate: (filters: any) => void;
}

export function CustomerTableHeader(props: CustomerTableHeaderProps) {
  const classes = useStyles({});

  const [filters, setFilters] = useState(props.filters);

  const updateField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = { ...filters, [event.target.name]: event.target.value };
    setFilters(newFilters);
    props.onFilterUpdate(newFilters);
  }

  const handleSort = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const sortField = filters.sortField;
    const sortDirection = filters.sortDirection;
    const fieldName = event.currentTarget.name;
    let newFilters;

    if (fieldName == sortField) {
      if (sortDirection == "ASC")
        newFilters = { ...filters, sortDirection: "DESC" };
      else
        newFilters = { ...filters, sortField: null, sortDirection: "ASC" };
    }
    else {
      newFilters ={ ...filters, sortField: fieldName, sortDirection: "ASC" };
    }
    setFilters(newFilters);
    props.onFilterUpdate(newFilters);
  }

  return (
    <TableHead>
      <TableRow>
        {
          props.headers.map((header, index) => (
            <TableCell key={header.field} className={classes.headerCell}>
              <Grid container direction="row">
                <Grid item xs={10}>
              <TextField
                label={header.header}
                value={filters[header.field] || ""}
                name={header.field}
                onChange={updateField}
                InputProps={{
                  disabled: !header.isSearchable,
                }}
              />
                </Grid>
                <Grid item xs={2}>
                  <IconButton size="small" edge="start" name={header.field} className={classes.sortButton} onClick={handleSort}>
                    {filters.sortField == header.field
                      ? filters.sortDirection == "ASC"
                        ? <SortASC />
                        : <SortDESC />
                      : <SortUpDown />
                    }
                  </IconButton>

                </Grid>
              </Grid>
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  )
}