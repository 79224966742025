import React from 'react';
import { ReceiveFromStoresList } from './ReceiveFromStoresList';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { Fab } from '@material-ui/core';
import { Store } from '../store/Store';
import { StoreSelect } from '../store/StoreSelect';
import ExitIcon from '@material-ui/icons/ExitToApp';
import { useReceiveFromStores, resetReceiveFromStoresStore } from './StockroomHandler';
import { useStyles } from './styles';

export const resetReceiveFromStoresContainer = () =>{
  resetReceiveFromStoresStore();
}

interface ReceiveFromStoresContainerProps {
  onClose: () => void;
}

export function ReceiveFromStoresContainer(props: ReceiveFromStoresContainerProps) {
  const classes = useStyles({});
  const [{ store, error, loading, success }, manager, actions] = useReceiveFromStores();

  const handleSelectStore = (store: Store) => {
    actions.setStore(store);
    actions.getTransfers(store);
  }

  const handleListClose = () => {
    actions.setStore(null);
  }

  if (error) return <Error message={error.message} />;
  if (loading) return <Loading />;

  const message = store != null && manager.categories.length == 0  ? "No items found to receive": null;

  if (store == null || message)
    return (
      <div className={classes.root}>
        <StoreSelect message={message} onStoreSelected={handleSelectStore} onClose={props.onClose} />
      </div>
    )
  if (success)
    return (
      <div className={classes.root}>
        <ReceiveFromStoresList storeName={store.storeName} />
        <Fab onClick={handleListClose} className={classes.fab} ><ExitIcon /></Fab>
      </div>
    )

  return null;
}
