import { loginToken, vendorURL, addVendorURL } from '../utils/WireUtils';
import { Vendor } from './Vendor';
import { BaseState, BaseAction, getOrCreateEditStore } from '../common/BaseHandler';
import { useStore } from 'react-hookstore';
import { Store } from '../store/Store';

export const vendorEditStoreName = "vendorEditStore";

export const getVendorEditStore = (vendor: Vendor) => getOrCreateEditStore<Vendor>(vendorEditStoreName, vendor); 
export const useVendorEditStore = () => useStore<Vendor>(vendorEditStoreName); 

const vendorStoreName = "vendorStore";

class VendorState extends BaseState {
  currentStore: Store;
  currentVendor: Vendor;
  vendors: Array<Vendor>;
}

export class VendorActions extends BaseAction<VendorState> {

  get initialState() : VendorState {
    return {
      error: null,
      loading: false,
      success: false,
      currentStore: null,
      currentVendor: null,
      vendors: [],
      _actions: this,
    };
  }

  mapVendors = (body: any) => {
    return { vendors: body }
  }

  getVendors = () => {
    this.updateState({ vendors: [] });
    this.getData(vendorURL, this.mapVendors)
  }

  setCurrentVendor = ( vendor: Vendor) =>{
    this.updateState({currentVendor: vendor});
  }
}

export function useVendors(): [VendorState, VendorActions] {
  return BaseAction.getAction(vendorStoreName, VendorActions).useStore<VendorActions>();
}

export async function getVendors(): Promise<Array<Vendor>> {
  return fetch(vendorURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${loginToken}`
    },
  }).then(async (response) => {
    if (response.status >= 200 && response.status < 300) {
      const body = await response.json();
      return body;
    } else {
      const body = await response.json();
      return Promise.reject(body.message);
    }
  }).catch(reason => Promise.reject(reason));
}

export async function addVendor(vendor: Vendor): Promise<Vendor> {
  return fetch(addVendorURL + "/" + vendor.vendorName, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${loginToken}`
    },
  }).then(async (response) => {
    if (response.status >= 200 && response.status < 300) {
      const body = await response.json();
      return body;
    } else {
      const body = await response.json();
      return Promise.reject(body.message);
    }
  }).catch(reason => Promise.reject(reason));
}

// // : { error: any, loading: boolean, vendors: Array<Vendor> }
// export const useVendors = (): {
//   error: any,
//   loading: boolean,
//   vendors: Array<Vendor>
// } => {
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [vendors, setVendors] = useState<Array<Vendor>>([]);

//   useEffect(() => {
//     setLoading(true);
//     fetch(vendorURL, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${loginToken}`
//       },
//     })
//       .then((response) => {
//         setLoading(false);
//         if (response.ok) {
//           response.json()
//             .then((vendors) => {
//               setVendors(vendors);
//             })
//             .catch((e) => {
//               setError({message: `${e}`});
//             })
//         } else {
//           setError({message: response.statusText});
//         }
//       })
//       .catch((e) => {
//         setError({message: `${e}`});
//       })
//   }, []);
//   // getVendors().then(vendors => setVendors(vendors));
//   return { error, loading, vendors };
// }