export class DeviceData {
  constructor(source?: DeviceData | any){
    if (source){
      Object.assign(this,source);
    }
  }
  deviceID: number;
  companyID: number;
  storeID: number;
  registerID: number;
  role: string;
  deviceName: string;
  password: string;
  authorizations: string;

  key() {
    return this.deviceID;
  }

  toString() {
    return this.deviceName;
  }
}

export class deviceAuthorizationKey{
  key: string;
  label:string;
}

export const deviceAuthorizationKeys: Array<deviceAuthorizationKey> = [
  { key: "deviceproxy", label: "Device Proxy" },
  { key: "customer", label: "Customer" },
  { key: "clerk", label: "Clerk" },
  { key: "sales", label: "Sales" },
]
