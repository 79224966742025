import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, TableCell } from "@material-ui/core";
import { drawerWidth } from "../dashboard/DashboardComponent";

export const footerHeight = 54;
export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {

  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 20,
    background: theme.palette.primary[800]
  },
  dialogTitle:{
    background: theme.palette.primary[900]
  },
  dialogActions:{
  },
  tableRoot: {
    flex: 1,
    overflowY: 'auto',
  },
  headerCell: {
    position: 'sticky',
    top: 0,
    zIndex: 20,
    background: theme.palette.primary[800]
  },
  tableRow:{
    cursor: "pointer",
  },
  tableCell: {
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    fontSize: 18,
  },
  totalTextContainer: {
    display: 'flex-column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  gridCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
}));
