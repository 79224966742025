import React, { useEffect } from 'react';
import { ShippingList } from './ShippingList';
import { Loading } from '../common/LoadingComponent';
import { Fab } from '@material-ui/core';
import { Store } from '../store/Store';
import { useShipToStores, resetShipToStores } from './ShippingHandler';
import { StoreSelect } from '../store/StoreSelect';
import ExitIcon from '@material-ui/icons/ExitToApp';
import { useStyles } from './styles';
import { useStore } from 'react-hookstore';
import { StoreShippingAndReceivingDataManager } from './ShippingAndReceivingDataManager';

interface ShippingContainerProps {
  onClose: () => void;
}

export const resetShippingContainer = () =>{
  resetShipToStores ();
}

export function ShippingContainer(props: ShippingContainerProps) {
  const classes = useStyles({});
  const [{ error, loading, success, store }, manager, actions] = useShipToStores();

  useEffect(() =>{
    if (store && ! manager)
      actions.getShippingOrReceiving(store.storeID);
  }, [manager, store]);

  const handleSelectStore = (store: Store) => {
    actions.setStore(store);
  }

  const handleListClose = () => {
    actions.setStore(null);
  }

  if (error) return (<div>{error.message}</div>)
  if (loading) return (<Loading />)
  const message = manager && manager.items.length == 0 && store != null ? "No items found to ship" : null;
  if (store == null || message)
    return (
      <div className={classes.root}>
        <StoreSelect message={message} onStoreSelected={handleSelectStore} onClose={props.onClose} />
      </div>
    )
  if (success)
    return (
      <div className={classes.root}>
        <ShippingList storeName={store.storeName} />
        <Fab onClick={handleListClose} className={classes.fab} ><ExitIcon /></Fab>
      </div>
    )

  return null;
}
