import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, TableCell } from "@material-ui/core";
import { drawerWidth } from "../dashboard/DashboardComponent";

export const footerHeight = 54;
export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {

  },
  tabs: {
    height: 96,
    flexBasis: 'auto',
    flex: '0 0'
  },
  tableRoot: {
    flex: 1,
    overflowY: 'auto',
  },
  headerCell: {
    position: 'sticky',
    top: 0,
    zIndex: 20,
    background: theme.palette.primary[800]
  },
  tableCell: {
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    fontSize: 18,
  },
  lastCountCell: {
    borderLeft: '2px solid white'
  },
  pointerCell: {
    cursor: 'pointer',
  },
  selectableCell: {
    border: '1px dotted white',
    borderRadius: 0,
  },
  noStockCell: {
    color: "tomato",
  },
  nonZeroOrderCell: {
    color: "#d4e157",
    fontWeight: "bolder"
  },
  tabScrollButton: {
    color: 'white',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: theme.palette.primary[800],
    height: footerHeight,
    flexBasis: 'auto',
    flex: '0 0',
    width: '100%',
  },
  totalTextContainer: {
    display: 'flex-column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  numRoot: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 'auto',
    height: 'auto',
    maxWidth: 350,
    maxHeight: 450,
  },
  gridCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  gridListCell: {
    display: 'flex',
    width: "100%",
    padding: 0,
    justifyContent: 'left',
    flexGrow: 1,
    flexShrink: 1,
  },
  listItem: {
    lineHeight: 1.8,
    width: "100%",
    cursor: "pointer",
  },
  listFooter: {
    lineHeight: 1.8,
    width: "100%",
  },
  listHeader: {
    lineHeight: 1.8,
    width: "100%",
  },
  selectedTransfer: {
    background: '#222',
  },
  borderTop: {
    borderTop: "1px solid white"
  },
  transferProgress: {
    padding:"15px 0 0 20px",
  },
}));
