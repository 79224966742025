import React from "react";
import { InventoryCostsDataManager } from "./InventoryCostsDataManager";
import { inventoryCostsWorkbookURL } from "../utils/WireUtils";
import { CategoryData } from "../wireData/CategoryData";
import { Store } from "../store/Store";
import { localISODateString } from "../utils/Convert";
import { BaseState, BaseAction } from "../common/BaseHandler";
import { getStoreByName } from "react-hookstore";

export const InventoryCostsDataStoreName = 'inventoryCostsStore';
export const inventoryCostsStore = () => getStoreByName<InventoryCostsState>(InventoryCostsDataStoreName);

export class InventoryCostsState extends BaseState {
  date: Date;
  stores: Array<Store>;
  sheetIndex: number;
  categories: Array<CategoryData>;
  categoryFilter: number;
  categoryLabel: string;
  dialogOpen: boolean;
  _manager: InventoryCostsDataManager;
}

export class InventoryCostsActions extends BaseAction<InventoryCostsState> {
  get initialState(): InventoryCostsState {
    return {
      error: null,
      loading: false,
      success: false,
      date: new Date(),
      stores: [],
      sheetIndex: 0,
      categories: [],
      categoryFilter: -1,
      categoryLabel: null,
      dialogOpen: false,
      _manager: new InventoryCostsDataManager(),
      _actions: this,
    }
  }

  private mapCosts = (body: any): Partial<InventoryCostsState> => {
    const manager = this.state._manager;
    manager.workbook = body;
    manager.selectSheet(this.state.sheetIndex);
    if (this.state.categoryFilter >= 0 && this.state.categories.length >= this.state.categoryFilter)
      manager.selectCategory(this.state.categoryFilter, this.state.categories[this.state.categoryFilter].categoryName);
    else
      manager.selectCategory(-1,"");
    return {
      _manager: manager,
    }
  }

  getCosts = () => {
    const stores: string = this.state.stores.map((store) => store.storeID).join();
    const categories: string = this.state.categories.map((category) => category.categoryID).join();
    if (this.state.date && stores.length && categories.length){
      this.getData(`${inventoryCostsWorkbookURL}?date=${localISODateString(this.state.date)}&stores=[${stores}]&categories=[${categories}]`, this.mapCosts);
    }
    else
      this.updateState({...this.mapCosts(null), success:true});
  }

  setStores = (stores: Array<Store>) =>{
    this.updateState({stores: stores});
  }
}

export const resetInventoryCostsStore = () =>{
  BaseAction.getAction(InventoryCostsDataStoreName, InventoryCostsActions).reset();
}

export const useInventoryCosts = (): [InventoryCostsState, InventoryCostsDataManager, InventoryCostsActions] => {
  const [state, actions] = BaseAction.getAction(InventoryCostsDataStoreName, InventoryCostsActions).useStore();
  return [state, state._manager, actions];
}

// export const useInventoryCosts = (date: Date, stores: Array<Store>, categories: Array<CategoryData>): {
//   error: any,
//   loading: boolean,
//   workbook: StoreInventoryCostsWorkbook
// } => {
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [workbook, setWorkbook] = useState<StoreInventoryCostsWorkbook>(null);

//   useEffect(() => {
//     setError(null);
//     InventoryCostsDataManager.Instance.workbook = null;
//     setWorkbook(null);
//     if (stores && stores.length && categories && categories.length) {
//       setLoading(true);
//       fetch(`${inventoryCostsWorkbookURL}?date=${localISODateString(date)}&stores=[${stores.map((store) => store.storeID).join()}]&categories=[${categories.map((category) => category.categoryID).join()}]`,
//         {
//           headers: getJSONHeaders()
//         })
//         .then((response) => {
//           setLoading(false);
//           if (response.ok) {
//             response.json()
//               .then((workbook) => {
//                 InventoryCostsDataManager.Instance.workbook = workbook;
//                 setWorkbook(workbook);
//               })
//               .catch((e) => {
//                 setError(e);
//               })
//           }
//           else
//             setError(response.statusText);
//         })
//         .catch((e) => {
//           setError(e)
//         })
//     }
//   }, [date.valueOf(), stores, categories]);

//   return { error, loading, workbook }
// }