import React, { useState } from 'react';
import { SelectDatesAndStores } from './SelectDatesAndStores';
import { DateAndStore, useDatesAndStoreCounts, resetDateAndStoreCountsState } from './CountHandler';
import { CountViewTable } from './CountViewTable';

export const resetCountViewContainer = () =>{
  resetDateAndStoreCountsState();
}

export interface CountViewContainerProps {
  onClose: () => void;
}

export function CountViewContainer(props: CountViewContainerProps) {
  const [{stores}, , actions] = useDatesAndStoreCounts();

  const handleOnDisplay = (dateAndStores: Array<DateAndStore>) => {
    if (dateAndStores.length) {
      const stores = dateAndStores.map((dateAndStore) => dateAndStore.storeID).join();

      actions.setStores(stores);
      actions.setDate(dateAndStores[0].inventoryDate)
    }
  }

  const handleClose = () =>{
    actions.reset();
    props.onClose();
  }

  const handleClear = () => {
    actions.setStores(null);
    actions.setDate(null);
  }

  if (stores && stores.length)
    return <CountViewTable onClose={handleClear} />

  return <SelectDatesAndStores onClose={handleClose} onDisplay={handleOnDisplay} />

}
