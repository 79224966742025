import React, { useEffect } from 'react';
import { useStores } from '../store/StoreHandler';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { InventoryCostsTable } from './InventoryCostsTable';
import { useInventoryCosts, resetInventoryCostsStore } from './InventoryCostsHandler';

export const resetInventoryCostsContainer = () =>{
  resetInventoryCostsStore();
}

export interface InventoryCostContainerProps {
  onClose: () => void;
}

export function InventoryCostContainer(props: InventoryCostContainerProps) {
  const [{ error: storesError, loading: storesLoading, success: storesSuccess, stores }, storesActions] = useStores('Inventory');
  const [{ error: workbookError, loading: workbookLoading, success: workbookSuccess, stores: workbookStores },, workbookActions] = useInventoryCosts();

  useEffect(() => {
    if (!(storesError || storesLoading || storesSuccess))
      storesActions.getStores();
  }, [storesError, storesLoading, storesSuccess]);

  useEffect (() =>{
    workbookActions.setStores(stores);
  }, [stores, workbookStores]);

  useEffect(() => {
    if(!(workbookError || workbookLoading || workbookSuccess)){
      workbookActions.getCosts();
    }
  }, [workbookError, workbookLoading,workbookSuccess]);

  if (storesError) return <Error message={storesError.message} />;
  if (storesLoading || workbookLoading) return <Loading />;
  if (workbookError) return <Error message={workbookError.message} />;
  if (storesSuccess) return <InventoryCostsTable onClose={props.onClose} />
  return null;
}