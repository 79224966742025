import React, { useState, useEffect } from 'react';
import { PullTransferList } from './PullInventoryList';
import { Loading } from '../common/LoadingComponent';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, Fab } from '@material-ui/core';
import { Store } from '../store/Store';
import { usePullInventory, resetPullInventoryStore } from './PullInventoryHandler';
import { StoreSelect } from '../store/StoreSelect';
import ExitIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',
    height: '90vh',
    boxSizing: 'border-box'
  },
  fab: {
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 10,
    right: 50,
    position: 'fixed',
    zIndex: 999,
  },
}))

export const resetPullInventoryContainer = () =>{
  resetPullInventoryStore();
}
interface PullInventoryContainerProps {
  onClose: () => void;
}

export function PullInventoryContainer(props: PullInventoryContainerProps) {
  const classes = useStyles({});
  const [{ error, loading, success, store }, manager, actions] = usePullInventory();

  useEffect(() =>{
    if (store && ! manager)
      actions.getInventory(store.storeID);
  }, [manager, store]);

  const handleSelectStore = (store: Store) => {
    actions.setStore(store);
  }

  const handleListClose = () =>{
    actions.setStore(null);
  }

  if (error) return (<div>{error.message}</div>)
  if (loading) return (<Loading />)
  const message = manager && manager.transfers.length == 0 && store != null ? "No items found to pull" : null;
  if (store == null || message)
    return (
      <div className={classes.root}>
        <StoreSelect message={message} onStoreSelected={handleSelectStore} onClose={props.onClose} />
      </div>
    )
  if (success)
    return (
      <div className={classes.root}>
        <PullTransferList storeName={store.storeName} />
        <Fab onClick={handleListClose} className={classes.fab} ><ExitIcon /></Fab>
      </div>
    )
  return null;
}
