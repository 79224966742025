import { InventoryTransferItem } from '../wireData/InventoryTransferData';

export class StoreReceiveTransferDataTable {
  transfers: Array<InventoryTransferItem>;
  categories: Array<CategoryItems>;
}

export class CategoryItems{
  constructor ( categoryName ){
    this.categoryName = categoryName;
    this.items = [];
  }
  categoryName: string;
  items:Array<InventoryTransferItem>;
}

