import React, {useState, useEffect} from 'react';
import { InventoryTransferSummaryData } from '../wireData/InventoryTransferSummaryData';
import { useStyles } from './styles';
import { useInventoryTransferDetails, inventoryTransferDetailsStore } from './InventoryTransferHandler';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { Grid, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useField } from '../common/useField';

export interface InventoryTransferDetailsDialogProps {
  row: InventoryTransferSummaryData;
  onClose: () => void;
}

export const InventoryTransferDetailsDialog = (props: InventoryTransferDetailsDialogProps) => {
  const classes = useStyles({});
  const [{ error, loading, success, details }, actions] = useInventoryTransferDetails();

  const dataStore = inventoryTransferDetailsStore();
  const [summaryRow, setSummaryRow] = useField(dataStore, 'summaryRow');

  useEffect(()=>{
    setSummaryRow(props.row);
    actions.getDetails();
  }, [props.row]);
  
  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    props.onClose();
  }

  const ShowContents = () =>{
    return (
      <Grid container direction="row">
        <Grid container justify="space-around" direction="row">
          <Grid item xs={6}>
            <Typography variant="body1">Store</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1">Req.</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1">Ship</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1">Rec.</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">Status</Typography>
          </Grid>
        </Grid>
        {
          details.map((detail) => (
            <Grid key={detail.transferID} justify="space-around" container direction="row">
              <Grid item xs={6}>
                <Typography variant="body1">{detail.storeName}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1">{detail.quantityRequested}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1">{detail.quantityShipped}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1">{detail.quantityReceived}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1">{detail.status}</Typography>
              </Grid>
            </Grid>
          ))
        }
      </Grid>
    )
  }

  const ShowStatusOrContents = () => {
    if (error) return <Error message={error.message} />
    if (loading) return <Loading />
    if (success) return <ShowContents />
    return null;
  }

  return (
    <Dialog open onBackdropClick={handleClose} maxWidth='md'>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="h6" align="center">{props.row.categoryName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">{props.row.itemName}</Typography>
          </Grid>
          {
            props.row.variationID
              ?
              <Grid item xs={12}>
                <Typography variant="body2" align="center">{props.row.variationDescription}</Typography>
              </Grid>
              : null
          }
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ShowStatusOrContents />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="contained" color="primary" onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
