export class StoreShippingAndReceivingDataTable {
  items: Array<StoreShippingAndReceivingItem>;
  vendors: Array<VendorItems>;
  categories: Array<CategoryItems>;
}

export class StoreShippingAndReceivingItem {
  storeReceivingID: number;
  orderItemID: number;
  vendorID: number;
  vendorName: string;
  storeID: number;
  storeName: string;
  categoryID: number;
  categoryName: string;
  itemID: number;
  itemName: string;
  variationID: number;
  variationDescription: string;
  quantityShipped: number; //quantity for store
  quantityReceived: number; //changable confirmation
  shipDate: number;
  receiptDate: number;
  shippingStatus: string;
}

export class VendorItems{
  constructor ( vendorName ){
    this.vendorName = vendorName;
    this.items = [];
  }
  vendorName: string;
  items:Array<StoreShippingAndReceivingItem>;
}

export class CategoryItems{
  constructor ( categoryName ){
    this.categoryName = categoryName;
    this.items = [];
  }
  categoryName: string;
  items:Array<StoreShippingAndReceivingItem>;
}

// export class StoreShippingAndReceivingItemUpdate {
//   orderItemID: number;
//   itemStatus: string;
//   quantityShipped: number;
//   quantityReceived: number;
// }
