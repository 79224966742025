import { datesAndStoresURL, storeCountsForDateURL } from '../utils/WireUtils';
import { CountManager } from './CountManager';
import { BaseState, BaseAction } from '../common/BaseHandler';

export class DateAndStore {
  inventoryDate: Date;
  storeID: number;
  storeName: string;
  checked: boolean;
}

export class StoreCountsForDate {
  inventoryDate: Date | number;
  storeID: number;
  storeName: string;
  categoryID: number;
  categoryName: string;
  itemID: number;
  itemName: string;
  variationID: number;
  variationDescription: string;
  count: number;
  cost: number;
}

const DatesAndStoresStoreName = 'DatesAndStoresStore';

export class DatesAndStoresState extends BaseState {
  storesError: any;
  storesLoading: boolean;
  storesSuccess: boolean;
  error: any;
  loading: boolean;
  success: boolean;
  date: Date;
  stores: string;
  scrolling: { top: number };
  expandedItems: Array<number>;
  category: string;
  _manager: CountManager;
}

class DatesAndStoreCountsActions extends BaseAction<DatesAndStoresState> {
  get initialState(): DatesAndStoresState {
    return {
      storesError: null,
      storesLoading: false,
      storesSuccess: false,
      error: null,
      loading: false,
      success: false,
      stores: null,
      date: null,
      scrolling: { top: 0 },
      expandedItems: [],
      category: null,
      _manager: null,
      _actions: this,
    }
  }

  mapDatesAndStores=(body: any) : Partial<DatesAndStoresState> =>{
    const manager = new CountManager();
    const datesAndStores: Array<DateAndStore> = body;
    datesAndStores.forEach((dateAndStore) => {
      dateAndStore.inventoryDate = new Date(dateAndStore.inventoryDate);
    });
    manager.datesAndStores = datesAndStores;
    return {
      storesError: null,
      storesLoading: false,
      storesSuccess: true,
      scrolling: { top: 0 },
      expandedItems: [],
      _manager: manager,
    };
  }

  mapStoreData = ( body: any) =>{
    const storeCountsForDate: Array<StoreCountsForDate> = body;
    storeCountsForDate.forEach((storeCount) => {
      storeCount.inventoryDate = new Date(storeCount.inventoryDate)
    })
    this.state._manager.storeCountsForDate = storeCountsForDate;
    return {
      error: null,
      loading: false,
      success: true,
      scrolling: { top: 0 },
      category: this.state._manager.categories[0],
    };
  }

  getDateAndStores = async () => {
    this.getData(datesAndStoresURL, this.mapDatesAndStores,
      {error:'storesError', processing:'storesLoading', success: 'storesSuccess'});
  };

  getDataForStores = async (stores: string, date: string) => {
    this.getData (`${storeCountsForDateURL}?storeIDs=${stores}&date=${date}`, this.mapStoreData)
  };

  setStores = (stores: string) => {
    this.updateState({ stores: stores, error: null, success: false });
  }

  setDate = (date: Date) => {
    this.updateState({ date: date, error: null, success: false });
  }

  setCategory = (category: string) => {
    this.updateState({ category: category, scrolling: { top: 0 } });
  }
}

export const resetDateAndStoreCountsState = () =>{
  BaseAction.getAction(DatesAndStoresStoreName, DatesAndStoreCountsActions).reset();
}

export const useDatesAndStoreCounts = (): [DatesAndStoresState, CountManager, DatesAndStoreCountsActions] => {
  const [state, actions] = BaseAction.getAction(DatesAndStoresStoreName, DatesAndStoreCountsActions).useStore();
  return [state, state._manager, actions];
}

