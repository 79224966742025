import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { TransactionTable } from './TransactionTable';
import { usePendingTransactions, stopServer, startServer, serverProcessOne, getTransactionEditStore } from './PendingTransactionHandler';
import { TransactionTableHeaderColumn } from './TransactionTableHeader';
import { DataTableFooter } from '../common/DataTableFooter';
import { EditTransactionContainer } from './EditTransactionContainer';
import { TransactionData } from '../wireData/TransactionData';
import { IconButton, Tooltip } from '@material-ui/core';
import IconStop from '@material-ui/icons/PauseCircleOutline';
import IconStart from '@material-ui/icons/PlayCircleOutline';
import IconOne from '@material-ui/icons/RepeatOneOutlined';

export interface PendingTransactionContainerProps {
  onClose: () => void;
}

const headers: Array<TransactionTableHeaderColumn> = [
  { header: "Date", field: "date", isSearchable: false },
  { header: "Company", field: "companyName", isSearchable: true },
  { header: "Store", field: "storeName", isSearchable: true },
  { header: "Register", field: "registerName", isSearchable: true },
  { header: "Clerk", field: "userName", isSearchable: true },
  { header: "Card #", field: "cardNumber", isSearchable: true },
  { header: "First Name", field: "customerFirstName", isSearchable: true },
  { header: "Last Name", field: "customerLastName", isSearchable: true },
  { header: "Type", field: "transactionType", isSearchable: true },
  { header: "Purchase", field: "purchaseAmount", isSearchable: false },
  { header: "Credit", field: "creditChange", isSearchable: false },
];

let filterTimer = null;

export function PendingTransactionContainer(props: PendingTransactionContainerProps) {
  const classes = useStyles({});

  const [{ error, loading, success, countError, countLoading, countSuccess, newTransaction,
    currentOffset, pageSize, filters, transactionCount, transactions }, actions] = usePendingTransactions();

  let pendingFilters = filters;

  useEffect(() => {
    if (!(countError || countLoading || countSuccess))
      actions.getTransactionCount();
  }, [countError, countLoading, countSuccess]);

  useEffect(() => {
    if (!(error || loading || success))
      actions.getTransactions();
  }, [error, loading, success]);

  const handleFilterUpdate = (filter: {}) => {
    if (filterTimer) {
      clearTimeout(filterTimer);
      filterTimer = null;
    }
    pendingFilters = filter;
    filterTimer = setTimeout(() => {
      handleRefresh();
    }, 2000);
  }

  const handleRefresh = () => {
    if (filterTimer) {
      clearTimeout(filterTimer);
      filterTimer = null;
    }
    actions.updateState({
      success: false,
      countSuccess: false,
      currentOffset: 0,
      filters: { ...pendingFilters },
      pagingFilters: { ...pendingFilters, skip: 0, take: pageSize }
    });
  }

  const handlePageChange = (offset: number, pageSize: number) => {
    actions.updateState({
      success: false,
      pageSize: pageSize,
      currentOffset: offset,
      filters: pendingFilters,
      pagingFilters: { ...pendingFilters, skip: offset, take: pageSize }
    });
  }

  const handleAddTransaction = () => {
    const transaction= new TransactionData();
    getTransactionEditStore(transaction);
    actions.setNewTransaction(transaction);
  }

  const handleDialogClose = () => {
    actions.setNewTransaction(null);
  }

  const handleUpdateTransaction = (transaction: TransactionData) => {
    actions.setNewTransaction(null);
    transactions.unshift(transaction);
  }

  const handleSelectTransaction = (transaction: TransactionData) => {
    getTransactionEditStore(transaction);
    actions.setNewTransaction(transaction);
  }

  const TransactionsOrStatus = () => {
    if (error || countError) return <Error message={(error || countError).message} />;
    if (loading || countLoading) return <Loading />;
    if (success && countSuccess) return (
      <TransactionTable
        transactions={transactions}
        headers={headers}
        filters={pendingFilters}
        onFilterUpdate={handleFilterUpdate}
        onSelectTransaction={handleSelectTransaction}
      />
    )
    return null;
  }

  const DialogOrNull = () => {
    if (newTransaction) return (
      <EditTransactionContainer
        transaction={newTransaction}
        onCancel={handleDialogClose}
        onUpdateTransaction={handleUpdateTransaction}
      />
    )
    return null;
  }

  const handleServerStop = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const message = await stopServer();
    alert(message.message);
  }

  const handleServerStart = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const message = await startServer();
    alert(message.message);
  }

  const handleProcessOne = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const message = await serverProcessOne();
    alert(message.message);
  }

  return (
    <div className={classes.root} >
      <TransactionsOrStatus />
      <DialogOrNull />
      <DataTableFooter
        pageSize={pageSize}
        currentOffset={currentOffset}
        transactionCount={transactionCount}
        onPage={handlePageChange}
        showAdd
        showRefresh
        onAdd={handleAddTransaction}
        onRefresh={handleRefresh}
        additionalButtons={[
          <Tooltip key="stop" title="Stop pending transaction server">
            <IconButton size="small" className={classes.footerItem} onClick={handleServerStop}><IconStop /></IconButton>
          </Tooltip>,
          <Tooltip key="start" title="Start pending transaction server">
            <IconButton size="small" className={classes.footerItem} onClick={handleServerStart}><IconStart /></IconButton>
          </Tooltip>,
          <Tooltip key="one" title="Process one pending transaction">
            <IconButton size="small" className={classes.footerItem} onClick={handleProcessOne}><IconOne /></IconButton>
          </Tooltip>,
        ]}
      />
    </div>
  )
}