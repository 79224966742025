import React from 'react';
import { UserData } from "../wireData/UserData";
import { useStyles } from './styles';
import { Grid, Button, List, ListItem, Typography } from '@material-ui/core';
import { DataGridList } from '../common/DataGridList';
import { Error } from '../common/ErrorComponent';

export interface UserListProps {
  users: Array<UserData>;
  onSelectUser: (user: UserData) => void;
  onClose: () => void;
}

export function UserList(props: UserListProps) {
  const classes = useStyles({});
 
  const handleSelectUser = (user: UserData) => {
    props.onSelectUser(user);
  }

  const handleClose = () => {
    props.onClose();
  }

  const handleAddUser = () => {
    const user = new UserData({
      userName: "",
      companies: "",
      authorizations: ""
    });
    props.onSelectUser(user);
  }

  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={1}>
        {
          props.users && props.users.length
            ? <DataGridList dataList={props.users} label="Select a user" onSelectItem={handleSelectUser} />
            : <Grid item xs={12}><Error message="No users found" /></Grid>
        }
        <Grid item xs={6}>
          <Button
            variant='contained'
            fullWidth
            className={classes.button}
            onClick={handleClose}
          >
            Close
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant='contained'
            fullWidth
            className={classes.button}
            onClick={handleAddUser}
          >
            Add User
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

