import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Paper, Tabs, Tab, Typography, TableRow, TableCell, TableBody, TableHead, Table } from '@material-ui/core';
import { WorkbookTotals, OrderingWorkbookManager } from './PreOrderingWorkbookManager';
import { StoreOrderingWorksheet, StoreOrderingCount, TotalOrderingWorksheet, PendingOrderItem } from './StoreOrderingData';
import { usePreOrderingWorkbook, resetPreOrdingStore } from './PreOrderingHandler';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { useStyles } from './styles';
import { numberToDollarString, getLastSundayISODateString } from '../utils/Convert';
import { VendorCell } from './VendorCell';
import classNames from 'classnames';
import { ToOrderCell } from './ToOrderCell';
import { TransferCell, TransferDialog } from './TransferCellAndDialog';

const stores = [2];//[1, 2, 3, 4, 5, 6, 7,];
const date = getLastSundayISODateString();

export function resetPreOrderingComponent() {
  resetPreOrdingStore();
}

export function PreOrderingComponent() {
  const [{ error, loading, success }, , orderingActions] = usePreOrderingWorkbook();

  useEffect(() => {
    if (!(error || loading || success))
      orderingActions.getWorkbook(stores, date);
  }, [error, loading, success])

  if (error) return <Error message={error.message} />
  if (loading) return <Loading />
  if (success) return <OrderingTable />
  return null;
}

interface OrderingTableTabProps {
  stores: Array<string>;
  selectedStore: number;
  onTabClick: (index: number) => void;
}

function OrderingTableTabs(props: OrderingTableTabProps) {
  const [value, setValue] = useState(props.selectedStore);
  const handleChange = (event, value) => {
    setValue(value);
    props.onTabClick(value);
  }
  return (
    <Paper square>
      <Tabs variant='scrollable' value={value} onChange={handleChange} scrollButtons="auto">
        {props.stores.map(store => <Tab key={store} label={<Typography>{store}</Typography>} />)}
        <Tab label={<Typography>Totals/Vendors</Typography>} />
      </Tabs>
    </Paper>
  )
}

interface CategoryTabsProps {
  categories: Array<string>;
  selectedCategory: string;
  onTabClick: (value: string) => void;
}

function CategoryTabs(props: CategoryTabsProps) {
  const [value, setValue] = useState(props.categories.indexOf(props.selectedCategory));
  const handleChange = (event, value) => {
    setValue(value);
    props.onTabClick(props.categories[value]);
  }
  return (
    <Paper square>
      <Tabs variant='scrollable' value={value} onChange={handleChange} scrollButtons="auto">
        {props.categories.map(cat => <Tab key={cat} label={<Typography variant='caption'>{cat}</Typography>} />)}
      </Tabs>
    </Paper>
  )
}

interface OrderingTableHeadProps {
  columns: Array<string>;
}

function OrderingTableHead(props: OrderingTableHeadProps) {
  const classes = useStyles({});
  return (
    <TableRow>
      {props.columns.map(column =>
        <TableCell className={classNames(classes.tableCell, classes.headerCell)} key={column}>{column}</TableCell>
      )}
    </TableRow>
  )
}

interface StoreRowProps {
  worksheet: StoreOrderingWorksheet;
  index: number;
  focused: boolean;
  editable: boolean;
  manager: OrderingWorkbookManager;
  onNavUp: () => void;
  onNavDown: () => void;
  onOrderCellClick: () => void;
  onRowClick: () => void;
}

const StoreRow = React.memo((props: StoreRowProps) => {
  const count: StoreOrderingCount = props.worksheet.counts[props.index];
  const key = `${count.categoryID}${count.itemID}${count.variationID}`
  const [value, setValue] = useState(count.toOrder);
  const [transfer, setTransfer] = useState(count.transfer);
  const [totalCost, setTotalCost] = useState(count.toOrder * count.latestItemCost);

  useEffect(() => {
    setValue(count.toOrder);
    setTotalCost(count.toOrder * count.latestItemCost);
  }, [count.toOrder]);

  const handleCellClick = () => {
    props.onOrderCellClick();
  }

  const handleRowClick = () => {
    props.onRowClick();
  }

  const handleToOrderUpdated = (toOrder: number) => {
    setValue(toOrder);
  }

  const handleTransferUpdated = (transfer: number) => {
    setTransfer(transfer);
  }

  const classes = useStyles({});

  const OutOfStockDays = () => {
    const weights = [0.5, 0.75, 1.25, 1.5];
    const available = count.lastCount + count.onOrder + count.transfer + count.toOrder;
    if (available == 0)
      return (<TableCell className={`${classes.noStockCell} ${classes.tableCell}`}>No Stock</TableCell>);
    const sales = count.sales.reduce((subtotal, sale, index) => (subtotal + sale * weights[index]), 0);
    if (sales == 0)
      return (<TableCell className={classes.tableCell}>No Sales</TableCell>);
    const salesPerDay = sales / 28;
    return (<TableCell className={classes.tableCell}>{Math.floor(available / salesPerDay).toString()}</TableCell>);
  }

  const focusedStyle = { backgroundColor: '#222' };

  return (
    <TableRow onClick={handleRowClick} style={props.focused ? focusedStyle : null}>
      <TableCell className={classes.tableCell}>{count.item}</TableCell>
      <TableCell className={classes.tableCell}>{count.variation}</TableCell>
      {count.sales.map((sale, index) => <TableCell className={classes.tableCell} key={`${key}${index}`}>{sale}</TableCell>)}
      <TableCell className={`${classes.tableCell} ${classes.lastCountCell}`}>{count.lastCount}</TableCell>
      <TableCell className={classes.tableCell}>{count.onOrder}</TableCell>
      <TransferCell item={count} manager={props.manager} onUpdated={handleTransferUpdated} />
      <ToOrderCell
        storeID={props.worksheet.storeID}
        categoryID={count.categoryID}
        itemID={count.itemID}
        variationID={count.variationID}
        toOrder={count.toOrder}
        focused={props.editable}
        onNavDown={props.onNavDown}
        onNavUp={props.onNavUp}
        onClick={handleCellClick}
        onUpdated={handleToOrderUpdated}
      />
      <OutOfStockDays />
      <TableCell className={classes.tableCell}>{numberToDollarString(count.latestItemCost)}</TableCell>
      <TableCell className={classes.tableCell}>{numberToDollarString(totalCost)}</TableCell>
    </TableRow>
  )
}, (prevProps: StoreRowProps, newProps: StoreRowProps) => (
  prevProps.focused == newProps.focused
  && prevProps.editable == newProps.editable
  && prevProps.worksheet.counts[0].category == newProps.worksheet.counts[0].category
  && prevProps.worksheet.store == newProps.worksheet.store));


interface StoreBodyProps {
  sheet: StoreOrderingWorksheet;
  activeRow: number;
  manager: OrderingWorkbookManager;
  setActiveRow: (row: number) => void;
}

const StoreBody = React.memo((props: StoreBodyProps) => {
  const [activeState, setActiveState] = useState({ focused: null, editable: null })
  const classes = useStyles({});

  useEffect(() => {
    setActiveState(
      { ...activeState, focused: props.activeRow })
  }, [props.activeRow])

  const handleNavUp = (index: number) => () => {
    if (index > 0) {
      setActiveState({ focused: index - 1, editable: index - 1 })
      props.setActiveRow(index - 1);
    }
    else {
      setActiveState({ focused: 0, editable: 0 })
      props.setActiveRow(0);
    }
  }

  const handleNavDown = (index: number) => () => {
    if (index < props.sheet.counts.length - 1) {
      setActiveState({ focused: index + 1, editable: index + 1 })
      props.setActiveRow(index + 1);
    }
  }

  const handleRowClick = (index: number) => () => {
    setActiveState({ focused: index, editable: null })
    props.setActiveRow(index);
  }

  const handleOrderCellClick = (index: number) => () => {
    setActiveState({ focused: index, editable: index });
    props.setActiveRow(index);
  }

  let key = 0;
  if (props.sheet == null)
    return null;
  return (
    <TableBody>{props.sheet.counts.map(
      (count, index) => (
        <StoreRow
          editable={index == activeState.editable}
          onNavUp={handleNavUp(index)}
          onNavDown={handleNavDown(index)}
          focused={index == activeState.focused}
          worksheet={props.sheet}
          index={index}
          key={key++}
          manager={props.manager}
          onOrderCellClick={handleOrderCellClick(index)}
          onRowClick={handleRowClick(index)}
        />)
    )}
    </TableBody>
  )
})

interface TotalsRowProps {
  item: PendingOrderItem;
  focused: boolean;
  columnIndex: number;
  onNavDown: () => void;
  onNavUp: () => void;
  onNavLeft: () => void;
  onNavRight: () => void;
  onRowClick: (column: number) => void;
}

const TotalsRow = React.memo((props: TotalsRowProps) => {
  const [{ }, manager] = usePreOrderingWorkbook();
  const key = `${props.item.categoryID}${props.item.itemID}${props.item.variationID}`
  const [value, setValue] = useState(props.item.pendingTotal);
  const [totalCost, setTotalCost] = useState(props.item.pendingTotal * props.item.latestItemCost);
  const [transferItem, setTransferItem] = useState<PendingOrderItem>(null);
  const classes = useStyles({});

  useEffect(() => {
    setValue(props.item.pendingTotal);
    setTotalCost(props.item.pendingTotal * props.item.latestItemCost);
  }, [props.item.pendingTotal]);

  const onNavUp = () => {
    props.onNavUp();
  }
  const onNavDown = () => {
    props.onNavDown();
  }
  const onNavLeft = () => {
    props.onNavLeft();
  }
  const onNavRight = () => {
    props.onNavRight();
  }

  const handleCellClick = (column: number) => () => {
    // event.stopPropagation();
    props.onRowClick(column);
  }

  const handleTotalsUpdate = (value: number) => {
    setValue(value)
  }

  // const TransferIconCell = () => {

  //   const handleOnClick = (event: React.MouseEvent) => {
  //     setTransferItem(props.item);
  //   }

  //   const handleTransferCount = (count: number) => {

  //   }

  //   const highlightStyle = { color: 'tomato' };

  //   return (
  //     <TableCell
  //       className={`${classes.tableCell} ${classes.pointerCell}`}
  //       style={props.item.stockCount ? highlightStyle : null}
  //       onClick={handleOnClick}>
  //       {transferItem
  //         ? < TransferDialog
  //           onClose={() => setTransferItem(null)}
  //           setTemplateCount={handleTransferCount}
  //           templateItem={transferItem}
  //         />
  //         : null
  //       }
  //       {props.item.stockCount}
  //     </TableCell>
  //   )
  // }

  const handleRowClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onRowClick(-1);
  }

  const focusedStyle = { backgroundColor: '#222' };

  return (
    <TableRow onClick={handleRowClick} style={props.focused ? focusedStyle : null}>
      <TableCell className={classes.tableCell}>{props.item.item}</TableCell>
      <TableCell className={classes.tableCell}>{props.item.variation}</TableCell>
      <TransferCell item={props.item} manager={manager} onUpdated={(count: number) =>{}}/>
      {props.item.pending.map(
        (count, index) => <ToOrderCell
          storeID={manager.getStoreIDs()[index]}
          categoryID={props.item.categoryID}
          itemID={props.item.itemID}
          variationID={props.item.variationID}
          toOrder={count}
          focused={props.focused && index == props.columnIndex}
          onNavDown={onNavDown}
          onNavUp={onNavUp}
          onNavLeft={onNavLeft}
          onNavRight={onNavRight}
          key={`${key}${index}`}
          onClick={handleCellClick(index)}
          onUpdated={handleTotalsUpdate}
        />)}
      <TableCell className={classes.tableCell}>{value}</TableCell>
      <TableCell className={classes.tableCell}>{numberToDollarString(props.item.latestItemCost)}</TableCell>
      <TableCell className={classes.tableCell}>{numberToDollarString(totalCost)}</TableCell>
      <VendorCell item={props.item} />
    </TableRow>
  )
}, (prevProps: TotalsRowProps, newProps: TotalsRowProps) => (
  prevProps.focused == newProps.focused
  && prevProps.columnIndex == newProps.columnIndex
  && prevProps.item.categoryID == newProps.item.categoryID
))

interface TotalsBodyProps {
  totals: TotalOrderingWorksheet;
  activeRow: number;
  setActiveRow: (row: number) => void;
}

function TotalsBody(props: TotalsBodyProps) {
  let key = 0;
  const classes = useStyles({});
  const [{ }, manager] = usePreOrderingWorkbook();
  const [activeRow, setActiveRow] = useState(-1);
  const [activeItemIndex, setActiveItemIndex] = useState(-1);

  useEffect(() => {
    setActiveRow(props.activeRow)
  }, [props.activeRow]);

  const handleNavUp = () => {
    if (activeRow > 0) {
      props.setActiveRow(activeRow - 1);
      setActiveRow(activeRow - 1);
    } else {
      props.setActiveRow(0);
      setActiveRow(0);
    }
  }

  const handleNavDown = () => {
    if (activeRow < props.totals.items.length - 1) {
      props.setActiveRow(activeRow + 1);
      setActiveRow(activeRow + 1);
    }
  }

  const handleNavLeft = () => {
    if (activeItemIndex > 0) {
      setActiveItemIndex(activeItemIndex - 1);
      manager.selectSheet(activeItemIndex - 1);
    } else {
      setActiveItemIndex(0);
    }
  }

  const handleNavRight = () => {
    if (activeItemIndex < props.totals.stores.length - 1) {
      setActiveItemIndex(activeItemIndex + 1);
      manager.selectSheet(activeItemIndex + 1);
    }
  }

  const handleRowClick = (row: number) => (column: number) => {
    setActiveRow(row);
    props.setActiveRow(row);
    if (column >= 0) {
      setActiveItemIndex(column);
      manager.selectSheet(column);
    }
  }

  return (
    <TableBody>
      {props.totals.items.map((item, index) => <TotalsRow item={item} key={key++}
        onNavDown={handleNavDown}
        onNavUp={handleNavUp}
        onNavRight={handleNavRight}
        onNavLeft={handleNavLeft}
        focused={index == activeRow}
        columnIndex={index == activeRow ? activeItemIndex : -1}
        onRowClick={handleRowClick(index)}
      />)}
    </TableBody>
  )
}

interface OrderingFooterProps {

}

function OrderingFooter(props: OrderingFooterProps) {
  const classes = useStyles({});
  const [{ }, manager] = usePreOrderingWorkbook();
  const [workbookTotals, setWorkbookTotals] = useState<WorkbookTotals>({
    workbookTotal: 0,
    worksheetTotal: 0,
    categoryTotal: 0,
    worksheetCategoryTotal: 0,
    category: null,
    store: null
  });


  const handleTotalsUpdate = (totals: WorkbookTotals) => {
    setWorkbookTotals({ ...totals });
  }

  useEffect(() => {
    manager.subscribeToTotals(handleTotalsUpdate);
    return function cleanup() {
      manager.unsubscribeFromTotals();
    }
  }, [])

  return (
    <div className={classes.footer}>
      <div className={classes.totalTextContainer}>
        <Typography variant='caption'>Total for {workbookTotals.store}/{workbookTotals.category}</Typography>
        <Typography variant='body1'>{numberToDollarString(workbookTotals.worksheetCategoryTotal)}</Typography>
      </div>
      <div className={classes.totalTextContainer}>
        <Typography variant='caption'>Total for {workbookTotals.store}</Typography>
        <Typography variant='body1'>{numberToDollarString(workbookTotals.worksheetTotal)}</Typography>
      </div>
      <div className={classes.totalTextContainer}>
        <Typography variant='caption'>Total for {workbookTotals.category}</Typography>
        <Typography variant='body1'>{numberToDollarString(workbookTotals.categoryTotal)}</Typography>
      </div>
      <div className={classes.totalTextContainer}>
        <Typography variant='caption'>Current Order Total:</Typography>
        <Typography variant='body1'>{numberToDollarString(workbookTotals.workbookTotal)}</Typography>
      </div>
    </div>
  )
}

function OrderingTable() {
  const classes = useStyles({});
  const [{ storeIndex, category, displayedWorksheet, showTotals, activeRow, scrolling, columns, stores, categories }, manager, orderingWorkbookActions] = usePreOrderingWorkbook();
  const scrollingRef = React.useRef(null);

  useLayoutEffect(() => {
    if (scrollingRef.current)
      scrollingRef.current.scrollTop = scrolling.top;
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrolling.top = event.currentTarget.scrollTop;
  }

  const handleActiveRow = (row: number) => {
    orderingWorkbookActions.setActiveRow(row);
  }

  const handleStoreTabClick = (value: number) => {
    orderingWorkbookActions.setDisplayedWorksheet(value);
  }

  const handleCategoryFilter = (category: string) => {
    orderingWorkbookActions.setCategoryFilter(storeIndex, category);
  }

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        <OrderingTableTabs stores={stores} selectedStore={storeIndex} onTabClick={handleStoreTabClick} />
        <CategoryTabs onTabClick={handleCategoryFilter} selectedCategory={category} categories={categories} />
      </div>
      <div className={classes.tableRoot} ref={scrollingRef} onScroll={handleScroll}>
        <Paper>
          <Table >
            <TableHead>
              <OrderingTableHead columns={columns} />
            </TableHead>
            {showTotals
              ?
              <TotalsBody totals={displayedWorksheet as TotalOrderingWorksheet} activeRow={activeRow} setActiveRow={handleActiveRow} />
              :
              <StoreBody sheet={displayedWorksheet as StoreOrderingWorksheet} activeRow={activeRow} setActiveRow={handleActiveRow} manager={manager} />
            }
          </Table>
        </Paper>
      </div>
      <OrderingFooter />
    </div>
  )
}