import { loginToken, downloadDatabaseBackupURL } from "../utils/WireUtils";
import FileSaver from "file-saver";

export const downloadDatabaseBackup = async (): Promise<any> => {
  return fetch(downloadDatabaseBackupURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${loginToken}`
    }
  })
    .then(response => response.blob())
    .then(blob => FileSaver.saveAs(blob, "databaseBackup.zip"));
};
