import React from 'react';
import { Store } from "../store/Store";
import { RegisterData } from "../wireData/RegisterData";
import { useStyles } from './styles';
import { Grid, Button, Typography } from '@material-ui/core';
import { DataGridList } from '../common/DataGridList';
import { Error } from '../common/ErrorComponent';

export interface RegisterListProps {
  store: Store;
  registers: Array<RegisterData>;
  onSelectRegister: (register: RegisterData) => void;
  onClose: () => void;
}

export function RegisterList(props: RegisterListProps) {
  const classes = useStyles({});
 
  const handleSelectRegister = (register: RegisterData) => {
    props.onSelectRegister(register);
  }

  const handleClose = () => {
    props.onClose();
  }

  const handleAddRegister = () => {
    const register = new RegisterData();
    register.registerName = "";
    props.onSelectRegister(register);
  }

  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <Typography variant='subtitle1' align='center' gutterBottom>Update Registers for {props.store.storeName}</Typography>
        </Grid>
        {
          props.registers && props.registers.length
            ? <DataGridList dataList={props.registers} label="Select a register" onSelectItem={handleSelectRegister} />
            : <Grid item xs={12}><Error message="No registers found" /></Grid>
        }
        <Grid item xs={6}>
          <Button
            variant='contained'
            fullWidth
            className={classes.button}
            onClick={handleClose}
          >
            Close
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant='contained'
            fullWidth
            className={classes.button}
            onClick={handleAddRegister}
          >
            Add Register
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
  // if (registers && !registers.length) return <Grid item xs={12}><Error message="No registers found" /></Grid>
  // return null;
}

