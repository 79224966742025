import React, { useState, useMemo } from "react";
import { Store } from "../store/Store";
import { RegisterData } from "../wireData/RegisterData";
import { useStyles } from "./styles";
import { Grid, Button,Typography,TextField } from "@material-ui/core";
import { getStoreByName } from "react-hookstore";
import { registerEditStoreName } from "./registerHandler";
import { useField } from "../common/useField";

export interface EditRegisterProps {
  store: Store;
  register: RegisterData;
  onUpdate: (register: RegisterData) => void;
  onRemove: (register: RegisterData) => void;
  onCancel: () => void;
}

export function EditRegister(props: EditRegisterProps) {
  // const classes = useStyles();
  const store = getStoreByName<RegisterData>(registerEditStoreName);
  const [registerName, setRegisterName] = useField(store, 'registerName');

  const label = useMemo(() => (
    props.register.registerID != null
      ? "Update Register"
      : "Add Register"),
    [props.register.registerID]);

  const handleCreate = () => {
    const register = new RegisterData({});
    register.storeID = props.store.storeID;
    register.registerID = props.register.registerID;
    register.registerName = registerName;
    props.onUpdate(register);
  }

  const handleCancel = () => {
    props.onCancel();
  }

  const handleRemove = () => {
    props.onRemove(props.register);
  }

  const checkFieldsAreComplete = () => {
    if (registerName == null || registerName.trim().length == 0)
      return false;
    return true;
  }

  return (
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Typography variant='subtitle1' align='center' gutterBottom>
          {label}
        </Typography>

      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='registerName'
          name='registerName'
          label='Register Name'
          fullWidth
          value={registerName}
          onChange={(e) => setRegisterName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button variant='contained' fullWidth onClick={handleCancel}>Cancel</Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant='contained'
          fullWidth
          disabled={!props.register.registerID}
          onClick={handleRemove}
        >
          Remove
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant='contained'
          fullWidth
          disabled={!checkFieldsAreComplete()}
          onClick={handleCreate}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  )
}

