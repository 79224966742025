import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { CustomerTable } from './CustomerTable';
import { useCustomers, getCustomerEditStore, resetCustomerStore } from './CustomerHandler';
import { CustomerTableHeaderColumn } from './CustomerTableHeader';
import { CustomerData } from '../wireData/CustomerData';
import { EditCustomerContainer } from './EditCustomerContainer';
import { DataTableFooter } from '../common/DataTableFooter';

export interface CustomerContainerProps {
  onClose: () => void;
}

const headers: Array<CustomerTableHeaderColumn> = [
  { header: "Phone Number", field: "phoneNumber", isSearchable: true },
  { header: "Card Number", field: "cardNumber", isSearchable: true },
  { header: "First Name", field: "firstName", isSearchable: true },
  { header: "Last Name", field: "lastName", isSearchable: true },
  { header: "eMail", field: "email", isSearchable: true },
  { header: "Credits", field: "credits", isSearchable: false },
  { header: "Last Visit", field: "lastVisit", isSearchable: false }
];

export const resetCustomerContainer = () => {
  resetCustomerStore();
}

let filterTimer = null;

export function CustomerContainer(props: CustomerContainerProps) {
  const classes = useStyles({});

  const [{ error, loading, success, countError, countLoading, countSuccess, customerCount, currentOffset, pageSize, filters, newCustomer, customers }, actions] = useCustomers();
  // const [newCustomer, setNewCustomer] = useState(null);

  useEffect(() => {
    if (!(error || loading || success))
      actions.getCustomers();
  }, [error, loading, success])

  useEffect(() => {
    if (!(countError || countLoading || countSuccess))
      actions.getCustomerCount();
  }, [countError, countLoading, countSuccess])

  let pendingFilters = filters;

  const handleFilterUpdate = (filter: {}) => {
    if (filterTimer) {
      clearTimeout(filterTimer);
      filterTimer = null;
    }
    pendingFilters = filter;
    filterTimer = setTimeout(() => {
      handleRefresh();
    }, 2000);
  }

  const handleRefresh = () => {
    if (filterTimer) {
      clearTimeout(filterTimer);
      filterTimer = null;
    }
    actions.updateState({
      success: false,
      countSuccess: false,
      currentOffset: 0,
      filters: pendingFilters,
      pagingFilters: { ...pendingFilters, skip: 0, take: pageSize }
    });
  }

  const handlePageChange = (offset: number, pageSize: number) => {
    actions.updateState({
      success: false,
      pageSize: pageSize,
      currentOffset: offset,
      pagingFilters: { ...pendingFilters, skip: offset, take: pageSize }
    });
  }

  const handleAddCustomer = () => {
    const customer = new CustomerData();
    actions.setNewCustomer(customer);
    getCustomerEditStore(customer);
  }

  const handleDialogClose = () => {
    actions.setNewCustomer(null);
  }

  const handleUpdateCustomer = (customer: CustomerData) => {
    actions.setNewCustomer(null);
    customers.unshift(customer);
  }

  const handleSelectCustomer = (customer: CustomerData) =>{
    getCustomerEditStore(customer);
    actions.setNewCustomer(customer);
  }

  const CustomersOrStatus = () => {
    if (error) return <Error message={error.message} />;
    if (loading) return <Loading />;
    if (success) return (
      <CustomerTable
        customers={customers}
        headers={headers}
        filters={pendingFilters}
        onFilterUpdate={handleFilterUpdate}
        onSelectCustomer={handleSelectCustomer}
      />
    )
    return null;
  }

  const DialogOrNull = () => {
    if (newCustomer) return (
      <EditCustomerContainer
        customer={newCustomer}
        onCancel={handleDialogClose}
        onAddCustomer={handleUpdateCustomer}
      />
    )
    return null;
  }

  return (
    <div className={classes.root} >
      <CustomersOrStatus />
      <DialogOrNull />
      <DataTableFooter
        pageSize={pageSize}
        currentOffset={currentOffset}
        transactionCount={customerCount}
        onPage={handlePageChange}
        showAdd
        showRefresh
        onAdd={handleAddCustomer}
        onRefresh={handleRefresh}
      />
    </div>
  )
}