import { loginToken, getRegistersForStoreURL, updateRegisterURL, removeRegisterURL, getJSONHeaders, getFilteredRegistersURL } from '../utils/WireUtils';
import { RegisterData } from '../wireData/RegisterData';
import { useEffect, useState } from 'react';
import { useStore } from 'react-hookstore';
import { Store } from '../store/Store';
import { BaseAction, BaseState, getOrCreateEditStore } from '../common/BaseHandler';

export const registerEditStoreName = "registerEditStore";

export const getRegisterEditStore = (register: RegisterData) => getOrCreateEditStore<RegisterData>(registerEditStoreName, register); 
export const useRegisterEditStore = () => useStore<RegisterData>(registerEditStoreName); 

const registerStoreName = "registerStoreFor-";

class RegisterState extends BaseState {
  currentStore: Store;
  currentRegister: RegisterData;
  registers: Array<RegisterData>;
}

export class RegisterActions extends BaseAction<RegisterState> {

  get initialState() : RegisterState {
    return {
      error: null,
      loading: false,
      success: false,
      currentStore: null,
      currentRegister: null,
      registers: [],
      _actions: this,
    };
  }

  mapRegisters = (body: any) => {
    return { registers: body.map((register) => new RegisterData(register)) }
  }

  getRegistersForStore = (storeID: number) => {
    this.updateState({ registers: [] });
    this.getData(`${getRegistersForStoreURL}/${storeID}`, this.mapRegisters)
  }

  setCurrentStore = ( store: Store) =>{
    this.updateState({currentStore: store});
  }

  setCurrentRegister = ( register: RegisterData) =>{
    this.updateState({currentRegister: register});
  }
}

export const resetRegisterStore = (application:string) =>{
  BaseAction.getAction(registerStoreName + application, RegisterActions).reset();
}

export function useRegisters(application:string): [RegisterState, RegisterActions] {
  return BaseAction.getAction(registerStoreName + application, RegisterActions).useStore<RegisterActions>();
}

export const useUpdateRegisters = (registerToUpdate: RegisterData): {
  error: any,
  updating: boolean,
  register: RegisterData
} => {
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [register, setRegister] = useState<RegisterData>(null);

  useEffect(() => {
    setError(null);
    setUpdating(false);
    setRegister(null);
    if (registerToUpdate) {
      setUpdating(true);
      fetch(updateRegisterURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(registerToUpdate)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((register) => {
                setRegister(new RegisterData(register));
                setUpdating(false);
              })
              .catch((e) => {
                setError(e);
                setUpdating(false);
              })
          } else {
            setError(new Error(response.statusText));
            setUpdating(false);
          }
        })
        .catch((e) => {
          setError(e);
          setUpdating(false);
        })
    }
  }, [registerToUpdate]);

  return { error, updating, register };
}

export const useRemoveRegisters = (registerToRemove: RegisterData): {
  error: any,
  removing: boolean,
  register: RegisterData
} => {
  const [error, setError] = useState(null);
  const [removing, setRemoving] = useState(false);
  const [register, setRegister] = useState<RegisterData>(null);

  useEffect(() => {
    setError(null);
    setRemoving(false);
    setRegister(null);
    if (registerToRemove) {
      setRemoving(true);
      fetch(removeRegisterURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(registerToRemove)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((register) => {
                setRegister(register);
                setRemoving(false);
              })
              .catch((e) => {
                setError(e);
                setRemoving(false);
              })
          } else {
            setError(new Error(response.statusText));
            setRemoving(false);
          }
        })
        .catch((e) => {
          setError(e);
          setRemoving(false);
        })
    }
  }, [registerToRemove]);

  return { error, removing, register };
}

export async function createRegister(register: RegisterData) {
  return fetch(updateRegisterURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginToken}`
    },
    body: JSON.stringify(register)
  }).then((response) => {
    if (response.ok) {
      return "Success";
    } else {
      return response.status + ": " + response.statusText;
    }
  }).catch(e => {
    throw e;
  })
}

export async function removeRegister(register: RegisterData) {
  return fetch(`${removeRegisterURL}/${register.storeID}/${register.registerID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginToken}`
    },
  }).then((response) => {
    if (response.ok) {
      return "Success";
    } else {
      return response.status + ": " + response.statusText;
    }
  }).catch(e => {
    throw e;
  })
}


export const lookupRegistersByName = async (storeID: number, searchKey: string, max: number = 10): Promise<Array<RegisterData>> => {
  let query = "";

  if (searchKey)
    query = `storeID=${storeID}&registerName=${searchKey}&max=${max}`;
  else
    query = `storeID=${storeID}&max=${max}`;

  query += "&sortField=registerName";

  try {
    const response = await fetch(`${getFilteredRegistersURL}?${query}`, {
      method: 'GET',
      headers: getJSONHeaders(),
    })
    if (response.ok) {
      const registers = await response.json();
      return registers;
    }
    else
      throw ("Register lookup error")
  }
  catch (e) {
    throw ("Register lookup error")
  }
}