import { ItemData } from "./ItemData";

export class CategoryData {
  constructor (source? : any){
    if (source)
      Object.assign(this, source);
  }

  categoryID: number;
  categoryName: string;
  categoryActive; boolean;
  inventoryClass: string;
  items: Array<ItemData>;

  key(){
    return this.categoryID;
  }

  toString(){
    return this.categoryName;
  }
}