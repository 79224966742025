import React from 'react';
import { InventoryTransferSummaryWorksheet } from '../wireData/InventoryTransferSummaryData';
import { useStyles } from './styles';
import { Paper, Table, TableHead, TableBody } from '@material-ui/core';
import { InventorySummaryTableHeader } from './InventoryTransferSummaryTableHeader';
import { ShowInventoryTransferRow } from './InventoryTransferSummaryRow';

export interface ShowInventoryTransferSummaryProps {
  worksheet: InventoryTransferSummaryWorksheet;
}

export const ShowInventoryTransferSummaryTable = React.memo((props: ShowInventoryTransferSummaryProps) => {
  const classes = useStyles({});

  return (
    <div className={classes.tableRoot}>
      <Paper>
        <Table>
          <TableHead>
            <InventorySummaryTableHeader stores={props.worksheet.stores} />
          </TableHead>
          <TableBody>
            {
              props.worksheet.transfers.map((row, index) => (
                <ShowInventoryTransferRow key={index} row={row} />
              ))
            }
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}, (prevProps: ShowInventoryTransferSummaryProps, nextProps: ShowInventoryTransferSummaryProps) => (
  prevProps.worksheet == nextProps.worksheet)
);

