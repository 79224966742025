import React, { useState, useEffect } from "react";
import { CategoryData } from "../wireData/CategoryData";
import { Dialog, DialogContent, DialogActions, Button, Theme, DialogTitle, Typography, Grid, Switch, FormControlLabel, TextField } from "@material-ui/core";
import { DataGridList } from "../common/DataGridList";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useCategories } from "../catalog/CatalogHandler";
import { Error } from "../common/ErrorComponent";
import { LinearLoading } from "../common/LoadingComponent";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogTitle: {
    backgroundColor: theme.palette.grey.A400,
  },
  dialogContents: {
    backgroundColor: theme.palette.background.paper,
  },
  categoryList: {
    marginLeft: theme.spacing(1),
    display: "inline",
  },
  listBox: {
    width: "500px",
    flexBasis: 'auto',
    flex: '1 1',
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "50vh",
  }
}))

export interface CategoriesAndDateDialogProps {
  open: boolean;
  // categories: Array<CategoryData>;
  selectedCategories: Array<CategoryData>;
  selectedDate: Date;
  onClose: (selectedCategories: Array<CategoryData>, date: Date) => void;
  onCancel: () => void;
}

export const CategoriesAndDateDialog = (props: CategoriesAndDateDialogProps) => {

  const classes = useStyles({});

  // const [inputDate, setInputDate] = useState(props.selectedDate.toISOString().substr(0, 10));
  const [date, setDate] = useState(moment(props.selectedDate));
  const [selectedCategories, setSelectedCategories] = useState<Array<CategoryData>>(props.selectedCategories);
  const [active, setActive] = useState(true);
  const { error: categoriesError, loading: categoriesLoading, categories } = useCategories(active);

  useEffect(() => {
    setSelectedCategories([...props.selectedCategories])
  }, [props.selectedCategories]);

  useEffect(() => {
    // setInputDate(props.selectedDate.toISOString().substr(0, 10));
    setDate(moment(props.selectedDate));
  }, [props.selectedDate]);

  const handleItemSelect = (item: CategoryData) => {
    const index = selectedCategories.indexOf(item);
    if (index >= 0)
      selectedCategories.splice(index, 1);
    else
      selectedCategories.push(item);
    // setSelectedCategories([...selectedCategories]);
  }
  const handleCancel = () => {
    props.onCancel();
  }

  const handleOk = () => {
    props.onClose(selectedCategories, date.toDate());
  }

  const handleChangeActive = (event, checked: boolean) => {
    setActive(!active);
  }

  const Contents = () => {
    if (categoriesError) return <Error message={categoriesError} />;
    if (categoriesLoading) return <LinearLoading message="Loading categories..." />;
    if (categories) return <DataGridList
      label="SELECT CATEGORIES"
      dataList={categories}
      multiple
      classes={classes}
      currentSelection={props.selectedCategories}
      onSelectItem={handleItemSelect}
    />
    return null;
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} >
      <Dialog open={props.open}>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Grid container direction="row" justify="space-around">
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>Select Date and Categories</Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControlLabel
                control={
                  <Switch checked={active} onChange={handleChangeActive} />
                }
                label={active ? "Active Categories" : "All Categories"}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <DatePicker
                label="Inventory Date"
                value={date}
                onChange={setDate}
                autoOk
                format="MM/DD/YYYY" />
              {/* <TextField type="date" defaultValue={inputDate} onChange={handleDateChange} label='Inventory Date' InputLabelProps={{ shrink: true }} /> */}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContents}>
          <Grid container direction="row">
            <Contents />
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogContents}>
          <Button color="primary" onClick={handleCancel}>
            Cancel
        </Button>
          <Button color="primary" onClick={handleOk}>
            OK
        </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  )
}

