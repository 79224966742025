import React from 'react';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';
import { Fab } from '@material-ui/core';
import { Store } from '../store/Store';
import { StoreSelect } from '../store/StoreSelect';
import ExitIcon from '@material-ui/icons/ExitToApp';
import { useShipToStores, resetShipToStoresStore } from './StockroomHandler';
import { TransferToStoresList } from './SendToStoresList';
import { useStyles } from './styles';

export const resetTransferToStoresContainer = () =>{
  resetShipToStoresStore ();
}

interface TransferToStoresContainerProps {
  onClose: () => void;
}

export function TransferToStoresContainer(props: TransferToStoresContainerProps) {
  const classes = useStyles({});

  const [{error, loading, success, store}, manager, actions] = useShipToStores();

  const handleSelectStore = (store: Store) => {
    actions.setStore(store);
    actions.getTransfers(store);
  }

  const handleListClose = () =>{
    actions.setStore(null);
  }

  if (error) return (<Error message={error.message} />)
  if (loading) return (<Loading />)

  const message = store != null && manager.categories.length == 0 ? "No items found to send": null;

  if (store == null || message)
    return (
      <div className={classes.root}>
        <StoreSelect message={message} onStoreSelected={handleSelectStore} onClose={props.onClose} />
      </div>
    )
  if (success)
    return (
      <div className={classes.root}>
        <TransferToStoresList storeName={store.storeName} />
        <Fab onClick={handleListClose} className={classes.fab} ><ExitIcon /></Fab>
      </div>
    )

  return null;
}
