import React from 'react';
import { TransactionData } from '../wireData/TransactionData';
import { TableRow, TableCell } from '@material-ui/core';
import { useStyles } from './styles';
import { numberToDateString, penniesToDollarString } from '../utils/Convert';
import {  useTransactions } from './TransactionHandler';

export interface TransactionRowProps {
  fields: Array<string>;
  row: TransactionData;
  onSelectTransaction: (transaction: TransactionData) => void;
}

export function TransactionRow(props: TransactionRowProps) {
  const classes = useStyles({});

   const formatField = (field: string): string => {
    switch (field) {
      case "date":
        return numberToDateString(props.row.date);
      case "purchaseAmount":
      case "creditChange":
        return penniesToDollarString(props.row[field]);
      default:
        return props.row[field];
    }
  }

  const onRowClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    props.onSelectTransaction(props.row);
  }

  // const handleDialogClose = () => {
  //   setSelected(null);
  // }

  // const handleUpdateTransaction = (transaction: TransactionData) => {
  //   Object.assign(props.row, transaction);
  //   setSelected(null);
  // }

  // const handleRemoveTransaction = (transaction: TransactionData) => {
  //   setSelected(null);
  //   props.onRemoveTransaction(transaction);
  // }

  // const DialogOrNull = () => {
  //   if (selected) return (
  //     <EditTransactionContainer
  //       transaction={props.row}
  //       onCancel={handleDialogClose}
  //       onUpdateTransaction={handleUpdateTransaction}
  //       onRemoveTransaction={handleRemoveTransaction}
  //     />
  //   )
  //   return null;
  // }

  return (
    <TableRow className={classes.tableRow} onClick={onRowClick}>
      {
        props.fields.map((field, index) => (
          <TableCell key={index} className={classes.tableCell}>
            {formatField(field)}
          </TableCell>
        ))
      }
      {/* <TableCell className={classes.hiddenCell}>
        <DialogOrNull />
      </TableCell> */}

    </TableRow>
  )
}