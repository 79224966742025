import React, { useState, useLayoutEffect } from 'react';
import { Theme, ListItem, List, ListItemText, ListSubheader, ListItemSecondaryAction, ListItemIcon, Button, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle'
import { StoreShippingAndReceivingItem, CategoryItems } from './ShippingData';
import { TypographyProps } from '@material-ui/core/Typography';
import { ChangeTransferCountDialog } from '../common/ChangeTransferCountDialog';
import { ShippingAndReceivingItemUpdate } from '../wireData/storeShippingAndReceivingData';
import { useReceiveFromStores,updateReceivingItemStatus, downgradeReceivingItemStatus, updateReceivingItemCount } from './ShippingHandler';
import { useStyles } from './styles';

// const manager = StoreShippingAndReceivingDataManager.ReceivingInstance;

// const useStyles = makeStyles((theme: Theme) => createStyles({
//   root: {
//     height: '100%',
//     width: '100%',
//     overflow: 'hidden',
//     display: 'flex',
//     flexDirection: 'column',
//   },
//   categoryList: {
//     flex: 1,
//     overflowY: 'auto',
//   },
//   categorySection: {
//     backgroundColor: "inherit",
//   },
//   categoryHeader: {
//     backgroundColor: theme.palette.grey[900],
//     padding: 0,
//   },
//   itemName: {
//     variant: "h6",
//   },
// }));

export class ReceivingListProps {
  storeName: string;
}

export function ReceivingList(props: ReceivingListProps) {
  const classes = useStyles({});
  const [{ scrolling }, manager] = useReceiveFromStores();

  const scrollinfRef = React.useRef(null);

  useLayoutEffect(() => {
    scrollinfRef.current.scrollTop = scrolling.top;
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrolling.top = event.currentTarget.scrollTop;
  }


  class SubHeaderProps {
    category: CategoryItems;
  }

  const SubHeader = (props: SubHeaderProps) => {
    class ReceivingItemProps {
      receivingItem: StoreShippingAndReceivingItem;
    }

    const primaryProps: TypographyProps = {
      variant: "h5"
    }

    const secondaryProps: TypographyProps = {
      variant: "h6"
    }

    const ReceivingItem = (props: ReceivingItemProps) => {
      const [checked, setChecked] = useState(props.receivingItem.shippingStatus == "received")
      const [receivingItemToUpdate, setReceivingItemToUpdate] = useState(null);

      const handleClick = (receivingItem: StoreShippingAndReceivingItem) => (event) => {
        if (receivingItem.quantityReceived == null) {
          setReceivingItemToUpdate(receivingItem);
          return;
        }
        const update = new ShippingAndReceivingItemUpdate();
        update.itemStatus = receivingItem.shippingStatus;
        update.orderItemID = receivingItem.orderItemID;

        let operation: (update: ShippingAndReceivingItemUpdate) => Promise<any>;
        if (checked) {
          operation = downgradeReceivingItemStatus;
        } else {
          operation = updateReceivingItemStatus;
        }
        operation(update).then((result: ShippingAndReceivingItemUpdate) => {
          manager.updateStatus(result);
          setChecked(result.itemStatus == 'received');
        }).catch(e => {
          alert(e.message || e);
        });
      }

      const handleCountButtonClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setReceivingItemToUpdate(props.receivingItem);
        if (event.ctrlKey && props.receivingItem.quantityReceived == null)
          props.receivingItem.quantityReceived = props.receivingItem.quantityShipped;
      }

      const handleChangeCountDialogClose = () => {
        setReceivingItemToUpdate(null);
      }

      const handleCountUpdate = (newValue: number) => {
        console.log(newValue);
        const update = new ShippingAndReceivingItemUpdate();
        update.quantityReceived = newValue;
        update.orderItemID = receivingItemToUpdate.orderItemID;

        updateReceivingItemCount(update).then((result: ShippingAndReceivingItemUpdate) => {
          manager.updateReceivedCount(result);
          setReceivingItemToUpdate(null);
        }).catch(e => {
          alert(e.message || e);
        });
      }

      const CountDialog = () => {
        return (
          <ChangeTransferCountDialog
            open
            initialValue={receivingItemToUpdate.quantityReceived}
            transferItem={receivingItemToUpdate}
            onClose={handleChangeCountDialogClose}
            onUpdate={handleCountUpdate}
          />

        )
      }

      const ButtonOrProgress = () => {
        if (receivingItemToUpdate == props.receivingItem)
          return (
            <div>
              <CircularProgress />
              <CountDialog />
            </div>
          )
        return (
          <Button onClick={handleCountButtonClick}>
            <Typography variant="h4">{props.receivingItem.quantityReceived == null ? "--" : props.receivingItem.quantityReceived}</Typography>
          </Button>
        )
      }

      return (
        <ListItem button onClick={handleClick(props.receivingItem)} key={props.receivingItem.orderItemID}>
          <ListItemIcon>
            <ButtonOrProgress />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={primaryProps}
            secondaryTypographyProps={secondaryProps}
            primary={props.receivingItem.itemName}
            secondary={props.receivingItem.variationDescription ? props.receivingItem.variationDescription : null} />
          <ListItemSecondaryAction>
            <CheckIcon style={{ color: checked ? "Chartreuse" : "lightgray" }} />
          </ListItemSecondaryAction>
        </ListItem>
      )
    }

    return (
      <li className={classes.categorySection}>
        <ul className={classes.listItem}>
          <ListSubheader className={classes.categoryHeader}><Typography variant="h4">{props.category.categoryName}</Typography></ListSubheader>
          {
            props.category.items.map((receivingItem) =>
              <ReceivingItem key={receivingItem.orderItemID} receivingItem={receivingItem} />
            )
          }
        </ul>
      </li>
    )
  }

  return (
    <div className={classes.list}>
      <Typography variant="h6" align="center" gutterBottom>Receive Items for {props.storeName}</Typography>
      <div className={classes.categoryList} ref={scrollinfRef} onScroll={handleScroll}>
        <List>
          {manager.categories.map((category) => (
            <SubHeader category={category} key={category.categoryName} />
          ))}
        </List>
      </div>
    </div>
  )
}