import React, { useEffect } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import { useDevices, getDeviceEditStore, resetDeviceStore } from './deviceHandler';
import { useStores } from '../store/StoreHandler';
import { Store } from '../store/Store';
import { DeviceData } from '../wireData/DeviceData';
import { DataSelect } from '../common/SelectData';
import { useStyles } from './styles';
import { DeviceList } from './DeviceList';
import { EditDeviceContainer } from './EditDeviceContainer';
import { Error } from '../common/ErrorComponent';
import { Loading } from '../common/LoadingComponent';

export const resetDeviceManager = () =>{
  resetDeviceStore();
}

class DeviceManagerProps {
  onClose: () => void;
}

export function DeviceContainer(props: DeviceManagerProps) {

  const classes = useStyles({});
  const [{ error: storeError, loading: storeLoading, success: storeSuccess, stores }, storeActions] = useStores('Device');
  const [{ error: deviceError, loading: deviceLoading, success: deviceSuccess, currentStore, currentDevice, devices }, deviceActions] = useDevices();

  useEffect(() => {
    if (!(storeError || storeLoading || storeSuccess))
      storeActions.getStores();

  }, [storeError, storeLoading, storeSuccess]);

  useEffect(() => {
    if (!(deviceError || deviceLoading || deviceSuccess))
      if (currentStore)
        deviceActions.getDevicesForStore(currentStore.storeID);

  }, [deviceError, deviceLoading, deviceSuccess]);

  const handleEditDeviceCancel = () => {
    deviceActions.setCurrentDevice(null);
  }

  const handleSelectStore = (store: Store) => {
    deviceActions.setCurrentStore(store);
    if (store)
      deviceActions.getDevicesForStore(store.storeID);
    else
      deviceActions.reset();
  }

  const handleDeviceSelect = (device: DeviceData) => {
    getDeviceEditStore(device);
    deviceActions.setCurrentDevice(device);
  }

  const handleDeviceUpdate = (update: DeviceData) => {
    const device = devices.find((device) => device.deviceID == update.deviceID);

    if (device) {
      if (!currentStore || update.storeID == currentStore.storeID)
        Object.assign(device, update);
      else {
        const index = devices.indexOf(device);
        if (index >= 0)
          devices.splice(index, 1);
      }
    }
    else
      devices.unshift(update);

    deviceActions.setCurrentDevice(null);
  }

  const handleDeviceRemove = (deviceRemoved: DeviceData) => {
    const device = devices.find((device) => device.deviceID == deviceRemoved.deviceID);
    if (device) {
      const index = devices.indexOf(device);
      if (index >= 0)
        devices.splice(index, 1);
    }
    deviceActions.setCurrentDevice(null);
  }

  const handleClose = () => {
    props.onClose();
  }

  const ShowDeviceList = () => {
    if (deviceError) return <Error message={deviceError.message} />
    if (deviceLoading) return <Loading />
    if (deviceSuccess)
      return (
        <Grid container spacing={1} >
          <DeviceList
            devices={devices}
            storeID={currentStore.storeID}
            onSelectDevice={handleDeviceSelect}
            onClose={handleClose} />
        </Grid>
      )

    return null;
  }

  interface ShowListOrEditorProps {
    currentDevice: DeviceData;
    currentStore: Store;
  }

  const ShowListOrEditor = (props: ShowListOrEditorProps) => {

    if (props.currentDevice)
      return <EditDeviceContainer
        device={props.currentDevice}
        currentStore={props.currentStore}
        onUpdate={handleDeviceUpdate}
        onRemove={handleDeviceRemove}
        onCancel={handleEditDeviceCancel} />

    return (
      <Grid container direction="row" >
        <Grid item xs={12}>
          <DataSelect
            error={storeError}
            loading={storeLoading}
            dataList={stores}
            insertClear
            disableClearIcon
            multiple={false}
            label="Select store to filter"
            currentSelection={props.currentStore}
            onSelectItem={handleSelectStore}
          />
        </Grid>
        <ShowDeviceList />
      </Grid>
    )
  }

  return (
    <div className={classes.layout}>
      <Paper className={classes.paper}>
        <Grid container direction="row" >
          <Grid item xs={12}>
            <Typography variant='h6' align='center' gutterBottom>Manage Devices</Typography>
          </Grid>
        </Grid>
        <ShowListOrEditor currentDevice={currentDevice} currentStore={currentStore} />
      </Paper>
    </div >
  )
}