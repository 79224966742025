import React, { useState, useMemo, useEffect } from 'react';
import { CustomerData } from '../wireData/CustomerData';
import { useStyles } from './styles';
import { Dialog, DialogTitle, DialogContent, Grid, FormControl, FormControlLabel, TextField, DialogActions, Typography, Button, Input, Checkbox, Switch } from '@material-ui/core';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { penniesToDecimalString, decimalStringToPennies } from '../utils/Convert';
import { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { useCustomerEditStore, customerEditStoreName } from './CustomerHandler';
import { useStore } from 'react-hookstore';

interface NumberFormatCustomProps {
  name: string;
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string, value: string } }) => void;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
            name: name
          },
        });
      }}
      isNumericString
      thousandSeparator
      prefix="$"
      decimalScale={2}
      fixedDecimalScale
      defaultValue="0.00"
    />
  );
}

export interface EditCustomerProps {
  customer: CustomerData;
  onCancel: () => void;
  onAddCustomer: (customer: CustomerData) => void;
  onRemoveCustomer?: (customer: CustomerData) => void;
}

export function EditCustomer(props: EditCustomerProps) {
  const classes = useStyles({});

  const [customer, setCustomer] = useCustomerEditStore();

  const addOrUpdate = useMemo(() => props.customer.customerID ? "Update" : "Add", [props.customer.customerID]);

    useEffect(() => {
    setCustomer({
      ...props.customer,
      credits: penniesToDecimalString(props.customer.credits),
      lifetimeChildCredits: penniesToDecimalString(props.customer.lifetimeChildCredits),
      lifetimeEarnedCredits: penniesToDecimalString(props.customer.lifetimeEarnedCredits),
    })
  }, [])

  const handleDialogClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    props.onCancel();
  }

  const handleCustomerRemove = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    props.onRemoveCustomer(customer);
  }

  const handleCustomerUpdate = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    props.onAddCustomer({
      ...customer,
      phoneNumber: customer.phoneNumber.replace(/\D/g,''),
      credits: decimalStringToPennies(customer.credits),
      lifetimeChildCredits: decimalStringToPennies(customer.lifetimeChildCredits),
      lifetimeEarnedCredits: decimalStringToPennies(customer.lifetimeEarnedCredits),
      birthdate: customer.birthdate && customer.birthdate.valueOf(),
      lastLogin: customer.lastLogin && customer.lastLogin.valueOf(),
      lastVisit: customer.lastVisit && customer.lastVisit.valueOf(),
      takenOn: customer.takenOn && customer.takenOn.valueOf(),
    });
  }

  const updateField = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("updateField: %s, %s", event.target.name, event.target.value)
    setCustomer({ ...customer, [event.target.name]: event.target.value })
  }

  const updateDate = (field: string) => (date: Moment) => {
    // console.log("updateDate: %s, %s", field, date)
    setCustomer({ ...customer, [field]: date })
  }

  const updateBoolean = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    // console.log("updateBoolean: %s, %s", event.target.name, checked)
    setCustomer({ ...customer, [event.target.name]: checked })
  }

  const checkFieldsAreComplete = () => {
    if (customer.phoneNumber == null || customer.phoneNumber.trim().length < 10)
      return false;
    if (customer.cardNumber == null || customer.cardNumber.trim().length == 0)
      return false;
    if (!customer.taken || !customer.takenOn || customer.takenOn.valueOf() == 0)
      return false;

    return true;
  }

  const handleFieldClick = (event: React.MouseEvent<HTMLElement>) =>{
    event.stopPropagation();
  }


  return (
    <Dialog open onBackdropClick={handleDialogClose} maxWidth='sm' fullWidth onClick={handleFieldClick}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6" align="center">Edit Customer</Typography>
        <Typography variant="body2" align="center">{addOrUpdate} customer</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContents}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container direction="row">
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <InputMask
                mask="(999) 999-9999"
                maskChar="_"
                value={customer.phoneNumber || ""}
                onChange={updateField}
                name="phoneNumber"
                label="Phone number"
                fullWidth
              >
                {(props) => <TextField {...props} />}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <TextField
                label="Card number"
                value={customer.cardNumber || ""}
                fullWidth
                name="cardNumber"
                onChange={updateField}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <TextField
                label="First name"
                value={customer.firstName || ""}
                fullWidth
                name="firstName"
                onChange={updateField}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <TextField
                label="Last name"
                value={customer.lastName || ""}
                fullWidth
                name="lastName"
                onChange={updateField}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <DatePicker
                label="Birthdate"
                value={customer.birthdate}
                onChange={updateDate("birthdate")}
                autoOk
                fullWidth
                format="MM/DD/YYYY" />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <TextField
                label="eMail"
                value={customer.email || ""}
                fullWidth
                name="email"
                onChange={updateField}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(customer.validated)}
                    name="validated"
                    onChange={updateBoolean}
                  />}
                label={customer.validated ? "Validated" : "Not Validated"}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <TextField
                label="Credits"
                value={customer.credits}
                fullWidth
                name="credits"
                onChange={updateField}
                InputProps={{ inputComponent: NumberFormatCustom, name: "credits" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <TextField
                label="Lifetime earned credits"
                value={customer.lifetimeEarnedCredits}
                fullWidth
                name="lifetimeEarnedCredits"
                onChange={updateField}
                InputProps={{ inputComponent: NumberFormatCustom, name: "lifetimeEarnedCredits" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <TextField
                label="Lifetime child credits"
                value={customer.lifetimeChildCredits}
                fullWidth
                name="lifetimeChildCredits"
                onChange={updateField}
                InputProps={{ inputComponent: NumberFormatCustom, name: "lifetimeChildCredits" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <FormControlLabel
                control={
                  <Switch
                    checked={customer.taken}
                    name="taken"
                    onChange={updateBoolean}
                  />}
                label={customer.taken ? "Taken" : "Not taken"}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <DatePicker
                label="Taken on"
                value={customer.takenOn}
                onChange={updateDate("takenOn")}
                autoOk
                fullWidth
                clearable
                format="MM/DD/YYYY" />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <DatePicker
                label="Last visit"
                value={customer.lastVisit}
                onChange={updateDate("lastVisit")}
                autoOk
                fullWidth
                format="MM/DD/YYYY" />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <DatePicker
                label="Last login"
                value={customer.lastLogin}
                onChange={updateDate("lastLogin")}
                autoOk
                fullWidth
                format="MM/DD/YYYY" />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <TextField
                label="Comments"
                value={customer.comments || ""}
                fullWidth
                name="comments"
                onChange={updateField}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridCell}>
              <TextField
                label="Authorizations"
                value={customer.authorizations || ""}
                fullWidth
                name="authorizations"
                onChange={updateField}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container direction="row">
          <Grid item xs={12} sm={4} className={classes.gridCell}>
            <Button fullWidth variant="contained" onClick={handleDialogClose}>Cancel</Button>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridCell}>
            <Button disabled={!props.onRemoveCustomer || !customer.customerID} fullWidth variant="contained" onClick={handleCustomerRemove}>Remove</Button>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridCell}>
            <Button disabled={!checkFieldsAreComplete()} fullWidth variant="contained" onClick={handleCustomerUpdate}>{addOrUpdate}</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}