import React, { useState, useEffect } from 'react';
import { Vendor } from './Vendor';
import { IconButton, Theme, Button, Typography, Popover, ListItem, List, TextField, ListItemText } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useVendors, addVendor } from './VendorHandler';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    minWidth: 300,
    maxHeight: 600,
  },
  listRoot: {
    maxHeight: 300,
    overflowY: 'scroll',
  },
  list: {

  },
  inputRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2),
    maxWidth: 200,
  },
  titleText: {
    // flex: 1,
  },
  clearButton: {
    // flex: 1,
  },
  header: {
    padding: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  vendorButton: {
    background: theme.palette.secondary[500],
  }
}));

interface VendorItemsProps {
  onVendorSelected: (vendor: Vendor) => void
}

function VendorItems(props: VendorItemsProps) {
  const [{ error, loading, success, vendors }, actions] = useVendors();

  useEffect (() =>{
    if (!(error || loading || success))
      actions.getVendors();
  }, [error, loading, success]);

  const handleItemClick = (vendor: Vendor) => (e) => {
    e.stopPropagation();
    props.onVendorSelected(vendor);
  }

  if (error) return (<div>{error.message}</div>)
  if (loading) return null;
  if (success) {
    return (
      <List dense>
        {vendors.map(vendor => (
          <ListItem
            button
            key={vendor.vendorID}
            onClick={handleItemClick(vendor)}>
            <ListItemText primary={vendor.vendorName} />
          </ListItem>
        ))}
      </List>
    )
  };
  return null;
}

interface AddVendorInputProps {
  onSubmitClick: (vendorName: string) => void;
  onCancelClick: () => void;
}

function AddVendorInput(props: AddVendorInputProps) {
  const [input, setInput] = useState('');
  const classes = useStyles({});

  const handleTextChange = (event) => {
    setInput(event.currentTarget.value)
  }

  const handleSubmit = (event) => {
    if (input && input != '') {
      props.onSubmitClick(input);
    }
  }

  const handleCancel = event => {
    event.stopPropagation()
    props.onCancelClick();
  }

  return (
    <>
      <TextField value={input}
        helperText='Enter Vendor Name'
        onChange={handleTextChange}
        type='text'
        fullWidth
        autoFocus
        style={{ marginBottom: 16 }}
      />
      <Button variant='contained' className={classes.vendorButton} onClick={handleSubmit}>Add Vendor</Button>
      <Button variant='text' onClick={handleCancel}>Cancel</Button>
    </>
  )
}

interface VendorSelectProps {
  vendor?: Vendor;
  onVendorSelected: (vendor: Vendor) => void;
  onVendorCleared: () => void;
  anchor: EventTarget;
}

export function VendorSelect(props: VendorSelectProps) {
  const classes = useStyles({});
  const [anchor, setAnchor] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [{vendors}] = useVendors();
  const open = Boolean(anchor);

  useEffect(() => {
    setAnchor(props.anchor)
  }, [props.anchor]);

  const handleVendorSelect = (vendor: Vendor) => {
    props.onVendorSelected(vendor);
  }

  const handleAddClick = (event) => {
    event.stopPropagation();
    setShowInput(true);
  }

  const handleSubmit = async (vendorName: string) => {
    try {
      const vendor: Vendor = new Vendor(0, vendorName);
      const newVendor = await addVendor(vendor);
      vendors.unshift(newVendor);
      props.onVendorSelected(newVendor);
    }
    catch (e) {
      alert(e.message);
    }
  }

  const handleClearClick = () => {
    props.onVendorCleared();
    setAnchor(null);
  }

  const handleCancel = () => {
    setShowInput(false);
  }

  return (
    <Popover anchorEl={anchor} classes={{ paper: classes.root }} anchorReference='anchorPosition' anchorPosition={{ top: 250, left: 1450 }} open={open} >
      <div className={classes.header}>
        <Typography className={classes.titleText} gutterBottom align='left' variant='subtitle1'>Vendors</Typography>
        <IconButton className={classes.clearButton} onClick={handleClearClick}><DeleteIcon/></IconButton>
      </div>
      <div className={classes.listRoot}>
        <VendorItems onVendorSelected={handleVendorSelect} />
      </div>
      <div className={classes.inputRoot}>
        {showInput
          ?
          <AddVendorInput onSubmitClick={handleSubmit} onCancelClick={handleCancel} />
          :
          <Button variant='text' onClick={handleAddClick}>Add Vendor</Button>
        }
      </div>
    </Popover>
  )
}