import { CustomerData, customerDataFromWire } from "../wireData/CustomerData";
import { useState, useEffect } from "react";
import { getJSONHeaders, updateCustomerURL, removeCustomerURL, getFilteredCustomersURL, buildQueryFromFilters, getFilteredCustomerCountURL } from "../utils/WireUtils";
import { BaseState, BaseAction, getOrCreateEditStore } from "../common/BaseHandler";
import { useStore } from "react-hookstore";

export const customerEditStoreName = "customerEditStore";

export const getCustomerEditStore = (customer: CustomerData) => getOrCreateEditStore<CustomerData>(customerEditStoreName, customer);
export const useCustomerEditStore = () => useStore<CustomerData>(customerEditStoreName);

const customerStoreName = 'customersStore';

export class CustomerState extends BaseState {
  countError: any;
  countLoading: boolean;
  countSuccess: boolean;
  currentOffset: number;
  currentCustomer: CustomerData;
  customerCount: number;
  pageSize: number;
  filters: {};
  pagingFilters: {};
  newCustomer: CustomerData;
  customers: Array<CustomerData>;
}

export class CustomerActions extends BaseAction<CustomerState>{
  get initialState(): CustomerState {
    return {
      error: null,
      loading: false,
      success: false,
      countError: null,
      countLoading: false,
      countSuccess: false,
      currentCustomer: null,
      currentOffset: 0,
      customerCount: 0,
      pageSize: 15,
      pagingFilters: { take: 15 },
      filters: {},
      newCustomer: null,
      customers: [],
      _actions: this,
    }
  }

  private mapCustomers = (body: any): Partial<CustomerState> => {
    return { customers: body.map((customer) => new CustomerData(customer)) };
  }

  getCustomers() {
    this.updateState({ customers: [] });
    this.getData(getFilteredCustomersURL + buildQueryFromFilters(this.state.pagingFilters), this.mapCustomers);
  }

  private mapCustomerCount = (body: any): Partial<CustomerState> => {
    return body;
  }

  getCustomerCount() {
    this.updateState({ customerCount: 0 });
    this.getData(getFilteredCustomerCountURL + buildQueryFromFilters(this.state.filters), this.mapCustomerCount,
      { error: "countError", processing: "countLoading", success: "countSuccess" });
  }

  setNewCustomer = (customer: CustomerData) =>{
    this.updateState({newCustomer: customer});
  }
  
  setPageSize = (pageSize: number) => {
    this.updateState({ pageSize: pageSize });
  }

  setCurrentOffset = (offset: number) => {
    this.updateState({ currentOffset: offset });
  }

  setFilters = (filters: any) => {
    this.updateState({ filters: filters });
  }

  setPagingFilters = (pagingFilters: {}) => {
    this.updateState({ success: false, pagingFilters: pagingFilters });
  }
}

export const resetCustomerStore = () => {
  BaseAction.getAction(customerStoreName, CustomerActions).reset();
}

export const useCustomers = () => {
  return BaseAction.getAction(customerStoreName, CustomerActions).useStore<CustomerActions>();
}

// const customerCountStoreName = 'customerCountStore';

// export class CustomerCountState extends BaseState {
//   customerCount: number;
// }

// export class CustomerCountActions extends BaseAction<CustomerCountState>{
//   get initialState(): CustomerCountState {
//     return {
//       error: null,
//       loading: false,
//       success: false,
//       customerCount: 0,
//       _actions: this,
//     }
//   }

//   private mapCount = (body: any): Partial<CustomerCountState> => {
//     return (body);
//   }

//   getCount(filters?: any) {
//     this.getData(getFilteredCustomerCountURL + buildQueryFromFilters(filters), this.mapCount);
//   }
// }

// export const resetCustomerCountStore = () => {
//   BaseAction.getAction(customerCountStoreName, CustomerCountActions).reset();
// }

// export const useCustomerCount = () => {
//   return BaseAction.getAction(customerCountStoreName, CustomerCountActions).useStore<CustomerCountActions>();
// }


export const useUpdateCustomer = (customerToUpdate: CustomerData): {
  error: any,
  updating: boolean,
  customer: CustomerData
} => {
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [customer, setCustomer] = useState<CustomerData>(null);

  useEffect(() => {
    setError(null);
    setUpdating(false);
    setCustomer(null);
    if (customerToUpdate) {
      setUpdating(true);
      fetch(updateCustomerURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(customerToUpdate)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((customer) => {
                setCustomer(new CustomerData(customer));
                setUpdating(false);
              })
              .catch((e) => {
                setError(e);
                setUpdating(false);
              })
          } else {
            response.json()
              .then((message) => {
                setError(new Error(message.message));
                setUpdating(false);
              })
              .catch((reason) => {
                setError(new Error(response.statusText));
                setUpdating(false);
              })
          }
        })
        .catch((e) => {
          setError(e);
          setUpdating(false);
        })
    }
  }, [customerToUpdate]);

  return { error, updating, customer };
}

export const useRemoveCustomer = (customerToRemove: CustomerData): {
  error: any,
  removing: boolean,
  customer: CustomerData
} => {
  const [error, setError] = useState(null);
  const [removing, setRemoving] = useState(false);
  const [customer, setCustomer] = useState<CustomerData>(null);

  useEffect(() => {
    setError(null);
    setRemoving(false);
    setCustomer(null);
    if (customerToRemove) {
      setRemoving(true);
      fetch(removeCustomerURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(customerToRemove)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((customer) => {
                setCustomer(customer);
                setRemoving(false);
              })
              .catch((e) => {
                setError(e);
                setRemoving(false);
              })
          } else {
            setError(new Error(response.statusText));
            setRemoving(false);
          }
        })
        .catch((e) => {
          setError(e);
          setRemoving(false);
        })
    }
  }, [customerToRemove]);

  return { error, removing, customer };
}

export const lookupCustomersByName = async (searchKey: string, max: number = 10): Promise<Array<CustomerData>> => {
  const parts = searchKey.split(" ");
  let query = "";

  if (parts.length >= 2)
    query = `firstName=${parts[0]}&lastName=${parts[1]}&max=${max}`;
  else if (parts.length >= 1)
    query = `lastName=${parts[0]}&max=${max}`;
  else
    query = `max=${max}`;

  query += "&sortField=lastName";

  try {
    const response = await fetch(`${getFilteredCustomersURL}?${query}`, {
      method: 'GET',
      headers: getJSONHeaders(),
    })
    if (response.ok) {
      const customers = await response.json();
      for (const customer of customers)
        customerDataFromWire(customer);
      return customers;
    }
    else
      throw ("Customer lookup error")
  }
  catch (e) {
    throw ("Customer lookup error")
  }
}