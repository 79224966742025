import { TransactionData } from "../wireData/TransactionData";
import { useState, useEffect } from "react";
import { getJSONHeaders, getTransactionsURL, updateTransactionURL, removeTransactionURL, getFilteredTransactionsURL, getFilteredTransactionCountURL, buildQueryFromFilters } from "../utils/WireUtils";
import { useStore } from "react-hookstore";
import { getOrCreateEditStore, BaseState, BaseAction } from "../common/BaseHandler";
import { NumberFormatProps } from "react-number-format";
import { useField } from "../common/useField";

export class TransactionFilter {
  filterBy: "registerID" | "customerID" | "sortField" | "sortDirection";
  filterValue: string;
}

const transactionEditStoreName = "transactionEdtiStore";

export const getTransactionEditStore = (transaction: TransactionData) => getOrCreateEditStore<TransactionData>(transactionEditStoreName, transaction);
export const useTransactionEditStore = () => useStore<TransactionData>(transactionEditStoreName);

const transactionStoreName = 'transactionStore';

export class TransactionState extends BaseState {
  countError: any;
  countLoading: boolean;
  countSuccess: boolean;
  currentOffset: number;
  newTransaction: TransactionData;
  pageSize: number;
  filters: {};
  pagingFilters: {};
  transactionCount: number;
  transactions: Array<TransactionData>;
}

export class TransactionActions extends BaseAction<TransactionState> {

  get initialState(): TransactionState {
    return {
      error: null,
      loading: false,
      success: false,
      countError: null,
      countLoading: false,
      countSuccess: false,
      currentOffset: 0,
      newTransaction: null,
      pageSize: 15,
      pagingFilters: { take: 15 },
      filters: {},
      transactionCount: 0,
      transactions: [],
      _actions: this,
    }
  }

  private mapTransactions = (body: any): Partial<TransactionState> => {
    return { transactions: body.map((transaction) => new TransactionData(transaction)) };
  }

  private mapCount = (body: any): Partial<TransactionState> => {
    return body;
  }

  getTransactions() {
    this.updateState({ transactions: [] });
    this.getData(getFilteredTransactionsURL + buildQueryFromFilters(this.state.pagingFilters), this.mapTransactions);
  }

  getTransactionCount() {
    this.updateState({ transactionCount: 0 });
    this.getData(getFilteredTransactionCountURL + buildQueryFromFilters(this.state.filters), this.mapCount,
      { error: "countError", processing: "countLoading", success: "countSuccess" })
  }

  setNewTransaction = (transaction: TransactionData) => {
    this.updateState({ newTransaction: transaction });
  }

  setPageSize = (pageSize: number) => {
    this.updateState({ pageSize: pageSize });
  }

  setCurrentOffset = (offset: number) => {
    this.updateState({ currentOffset: offset });
  }

  setFilters = (filters: any) => {
    this.updateState({ countSuccess: false, filters: filters });
  }

  setPagingFilters = (pagingFilters: {}) => {
    this.updateState({ success: false, pagingFilters: pagingFilters });
  }
}

export const resetTransactionStore = () => {
  BaseAction.getAction(transactionStoreName, TransactionActions).reset();
}

export const useTransactions = () => {
  return BaseAction.getAction(transactionStoreName, TransactionActions).useStore<TransactionActions>();
}

export const useTransactionField = (key: keyof TransactionState) =>{
  return useField(BaseAction.getAction(transactionStoreName, TransactionActions).store, key);
}

// export const useTransactions = (filters?: {}): {
//   error: any,
//   loading: boolean;
//   transactions: Array<TransactionData>
// } => {
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [transactions, setTransactions] = useState<Array<TransactionData>>(null);

//   useEffect(() => {
//     setLoading(true);
//     setError(null);
//     setTransactions(null);
//     const query = buildQueryFromFilters(filters);
//     const URL = getFilteredTransactionsURL + query;
//     fetch(URL,
//       {
//         method: "GET",
//         headers: getJSONHeaders(),
//       })
//       .then((response) => {
//         setLoading(false);
//         if (response.ok) {
//           response.json()
//             .then((transactions: Array<TransactionData>) => {
//               setTransactions(transactions);
//             })
//             .catch((e) => {
//               setError({ message: "Invalid response body" })
//             })
//         } else {
//           setError({ message: "Response not ok" })
//         }
//       })
//       .catch((e) => {
//         setLoading(false);
//         setError({ message: "Communication failure" })
//       })
//   }, [filters]);

//   return { error, loading, transactions };
// }

// export const useTransactionCount = (filters?: {}): {
//   error: any,
//   loading: boolean;
//   transactionCount: number
// } => {
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [transactionCount, setTransactionCount] = useState<number>(0);

//   useEffect(() => {
//     setLoading(true);
//     setError(null);
//     setTransactionCount(0);
//     const query = buildQueryFromFilters(filters);
//     const URL = getFilteredTransactionCountURL + query;
//     fetch(URL,
//       {
//         method: "GET",
//         headers: getJSONHeaders(),
//       })
//       .then((response) => {
//         setLoading(false);
//         if (response.ok) {
//           response.json()
//             .then((transactionCount: { transactionCount: number }) => {
//               setTransactionCount(transactionCount.transactionCount);
//             })
//             .catch((e) => {
//               setError({ message: "Invalid response body" })
//             })
//         } else {
//           setError({ message: "Response not ok" })
//         }
//       })
//       .catch((e) => {
//         setLoading(false);
//         setError({ message: "Communication failure" })
//       })
//   }, [filters]);

//   return { error, loading, transactionCount };
// }

export const useUpdateTransaction = (transactionToUpdate: TransactionData): {
  error: any,
  updating: boolean,
  transaction: TransactionData
} => {
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [transaction, setTransaction] = useState<TransactionData>(null);

  useEffect(() => {
    setError(null);
    setUpdating(false);
    setTransaction(null);
    if (transactionToUpdate) {
      setUpdating(true);
      fetch(updateTransactionURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(transactionToUpdate)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((transaction) => {
                setTransaction(new TransactionData(transaction));
                setUpdating(false);
              })
              .catch((e) => {
                setError(e);
                setUpdating(false);
              })
          } else {
            setError(new Error(response.statusText));
            setUpdating(false);
          }
        })
        .catch((e) => {
          setError(e);
          setUpdating(false);
        })
    }
  }, [transactionToUpdate]);

  return { error, updating, transaction };
}

export const useRemoveTransaction = (transactionToRemove: TransactionData): {
  error: any,
  removing: boolean,
  transaction: TransactionData
} => {
  const [error, setError] = useState(null);
  const [removing, setRemoving] = useState(false);
  const [transaction, setTransaction] = useState<TransactionData>(null);

  useEffect(() => {
    setError(null);
    setRemoving(false);
    setTransaction(null);
    if (transactionToRemove) {
      setRemoving(true);
      fetch(removeTransactionURL, {
        method: 'POST',
        headers: getJSONHeaders(),
        body: JSON.stringify(transactionToRemove)
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((transaction) => {
                setTransaction(transaction);
                setRemoving(false);
              })
              .catch((e) => {
                setError(e);
                setRemoving(false);
              })
          } else {
            setError(new Error(response.statusText));
            setRemoving(false);
          }
        })
        .catch((e) => {
          setError(e);
          setRemoving(false);
        })
    }
  }, [transactionToRemove]);

  return { error, removing, transaction };
}
