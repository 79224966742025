import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme} from "@material-ui/core";
import { emphasize } from '@material-ui/core/styles'

export const footerHeight = 32;
export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 20,
    background: theme.palette.primary[800],
    paddingLeft: theme.spacing(2)
  },
  headerField: {
    paddingLeft: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary[800],
    height: footerHeight,
    flexBasis: 'auto',
    flex: '0 0',
    width: '100%',
  },
  footerItem:{
    marginLeft: 10,
    marginRight: 10,
  },
  footerSelect:{
    font: "caption",
  },
  dialogTitle: {
    background: theme.palette.background[900]
  },
  dialogActions: {
  },
  tableRoot: {
    flex: 1,
    overflowY: 'auto',
  },
  headerCell: {
    position: 'sticky',
    top: 0,
    zIndex: 20,
    whiteSpace: "nowrap",
    padding: 0,
    margin: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: theme.palette.primary[800],
  },
  sortButton:{
    marginTop: theme.spacing(2.5),
  },
  tableRow: {
    cursor: "pointer",
  },
  tableCell: {
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    fontSize: 18,
  },
  hiddenCell: {
    display: "none",
  },
  icon:{
    color: theme.palette.secondary[100],
  },
  narrowCell: {
    padding: 0,
    margin: 0,
  },
  dialogContents: {
    minHeight: "50vh",
    maxHeight: "70vh"
  },
  gridCell: {
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: 10,
  },
  formControl: {
    width: "100%",
  },
  totalTextContainer: {
    display: 'flex-column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  divider: {
    height: theme.spacing(2),
  },
}));
