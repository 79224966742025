import React, { useState, useMemo, useEffect } from 'react';
import { Typography, Grid, TextField, Button, Radio, FormControlLabel, FormLabel, Icon, Paper } from '@material-ui/core';
import { Store } from '../store/Store';
import { DeviceData } from '../wireData/DeviceData';
import { UserCompanyData } from '../wireData/UserCompanyData';
import { RegisterData } from '../wireData/RegisterData';
import { DataSelect } from '../common/SelectData';
import { useStyles } from './styles';
import { DeviceAuthorizations } from './DeviceAuthorizations';
import { deviceEditStoreName, getDeviceEditStore } from './deviceHandler';
import { getStoreByName } from 'react-hookstore';
import { useField } from '../common/useField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface StatusDialogProps {
  message: string;
  open: boolean;
}

export interface EditDeviceProps {
  device: DeviceData;
  companies: Array<UserCompanyData>;
  stores: Array<Store>;
  registers: Array<RegisterData>;
  currentCompany: UserCompanyData;
  currentStore: Store;
  onSelectCompany: (company: UserCompanyData) => void;
  onSelectStore: (store: Store) => void;
  onUpdate: (device: DeviceData) => void;
  onRemove: (device: DeviceData) => void;
  onCancel: () => void;
}

export function EditDevice(props: EditDeviceProps) {
  const classes = useStyles({});
  const store = getDeviceEditStore();
  const [companyID] = useField(store, 'companyID');
  const [storeID] = useField(store, 'storeID');
  const [registerID, setRegisterID] = useField(store, 'registerID');
  const [deviceName, setDeviceName] = useField(store, 'deviceName');
  const [password, setPassword] = useField(store, 'password');
  const [confirmationPassword, setConfirmationPassword] = useState(props.device.password);
  const [role, setRole] = useField(store, 'role');
  const [authorizations, setAuthorizations] = useField(store, 'authorizations');

  const currentCompany = props.companies.find((company) => company.companyID == companyID);
  const currentStore = props.stores.find((store) => store.storeID == storeID);
  const currentRegister = props.registers.find((register) => register.registerID == registerID);
  const currentAuths = authorizations ? authorizations.split(/[ ,]+/) : [];

  const label = useMemo(() => (
    props.device.deviceID != null
      ? "Update Device"
      : "Add Device"),
    [props.device.deviceID]);

  const handleCompanySelect = (company: UserCompanyData) => {
    props.onSelectCompany(company);
  }

  const handleStoreSelect = (store: Store) => {
    props.onSelectStore(store);
    setRegisterID(0);
  }

  const handleRegisterSelect = (register: RegisterData) => {
    setRegisterID(register.registerID);
  }

  const handleUpdate = async () => {
    const device = new DeviceData();
    device.companyID = companyID;
    device.storeID = storeID;
    device.registerID = registerID;
    device.deviceID = props.device.deviceID;
    device.deviceName = deviceName;
    device.password = password;
    device.role = role;
    device.authorizations = authorizations;
    props.onUpdate(device);
  }

  const handleCancel = () => {
    props.onCancel();
  }

  const handleRemove = async () => {
    props.onRemove(props.device);
  }

  const checkFieldsAreComplete = () => {
    if (deviceName == null || deviceName.trim().length == 0)
      return false;
    if (props.device.deviceID == null && (password == null || password.length == 0))
      return false;
    if (password !== confirmationPassword)
      return false;
    if (companyID == 0 || storeID == 0 || registerID == 0)
      return false;
    if (role == null || role.length == 0)
      return false;
    return true;
  }

  const handleRoleChange = (event, checked: boolean) => {
    if (checked)
      setRole(event.target.value);
  }

  const handleAuthItemSelect = (value: string, checked: boolean) => {
    if (checked && currentAuths.indexOf(value) == -1) {
      const newAuth = [...currentAuths, value]
      setAuthorizations(newAuth.join(","));
    } else if (!checked) {
      const newAuths = currentAuths.filter((auth) => auth != value);
      setAuthorizations(newAuths.join(","));
    }
  }

  return (
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Typography variant='subtitle1' align='center'>
          {label}
        </Typography>

      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='deviceName'
          name='deviceName'
          label='Device Name'
          fullWidth
          value={deviceName}
          onChange={(e) => setDeviceName(e.target.value)}
        />
      </Grid>
      <Grid item xs={10} sm={5}>
        <TextField
          required={props.device.deviceID == null}
          type="password"
          id='password'
          name='password'
          label='Password'
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <div className={classes.center}>
          <Icon className={classes.passwordMatchIconBox}>
            <CheckCircleIcon className={password == confirmationPassword ? classes.passwordsMatchedIcon : classes.passwordsNotMatchedIcon} />
          </Icon>
        </div>
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          required={props.device.deviceID == null}
          type="password"
          id='confirmPassword'
          name='confirmPassword'
          label='Confirm Password'
          fullWidth
          value={confirmationPassword}
          onChange={(e) => setConfirmationPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <DataSelect
          dataList={props.companies}
          disableClearIcon
          label="Company"
          classes={classes}
          currentSelection={currentCompany}
          onSelectItem={handleCompanySelect}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DataSelect
          dataList={props.stores}
          insertClear
          disableClearIcon
          label="Store"
          currentSelection={currentStore}
          onSelectItem={handleStoreSelect}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DataSelect
          dataList={props.registers}
          insertClear
          disableClearIcon
          label="Register"
          currentSelection={currentRegister}
          onSelectItem={handleRegisterSelect}
        />
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12} className={classes.centerGridItem}>
          <FormLabel>Assign Role</FormLabel>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.centerGridItem}>
          <FormControlLabel
            value="Customer"
            control={<Radio value="customer" onChange={handleRoleChange} checked={role == "customer"} />}
            label="Customer"
            labelPlacement="top"
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.centerGridItem}>
          <FormControlLabel
            value="Clerk"
            control={<Radio value="clerk" onChange={handleRoleChange} checked={role == "clerk"} />}
            label="Clerk"
            labelPlacement="top"
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.centerGridItem}>
          <FormControlLabel
            value="Sales"
            control={<Radio value="sales" onChange={handleRoleChange} checked={role == "sales"} />}
            label="Sales"
            labelPlacement="top"
          />
        </Grid>
        <Grid item xs={12}>
          <DeviceAuthorizations authorizations={currentAuths} onItemChanged={handleAuthItemSelect} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant='contained'
          fullWidth
          onClick={handleCancel}>
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant='contained'
          fullWidth
          disabled={!props.device.deviceID}
          onClick={handleRemove}
        >
          Remove
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant='contained'
          fullWidth
          disabled={!checkFieldsAreComplete()}
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  )
}
